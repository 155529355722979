import React, { FC, useEffect, useState, useContext } from "react"
import BackToButton from "../BackToButton"
import Loader from "../ui/loader"
import SubscriptionPlanCard from "./SubscriptionPlanCard"
import TrialSubscriptionCard from "./TrialSubscriptionCard"
import useScrollToTop from "../../hooks/useScrollToTop"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgChatEllipses } from "../icons/"
import { useIntercom } from "react-use-intercom"
import { useNavigate } from "react-router-dom"
import { SubscriptionContext } from "./SubscriptionContext"
import { SubscriptionRequests } from "../../api/app.service"
import { useLocation } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

type Props = {}

const SubscriptionPlans: FC<Props> = () => {
  const { show } = useIntercom()
  const { setPopupNotification } = useContext(CommonFunctionCtx)
  const {
    setSelectedSubscription,
    showSubscriptionComponents,
    setShowSubscriptionCheckout,
    setShowSubscription,
  } = useContext(SubscriptionContext)
  const navigate = useNavigate()
  const { user } = useContext(UserCtx)
  const [plans, setPlans] = useState<any>(null)
  const { renderError } = useContext(CommonFunctionCtx)
  const [selectedPlan, setSelectedPlan] = useState<string>("annual")
  const location = useLocation()
  const planType = location.state?.planType
  const subscription = location.state?.subscription

  const getSubscriptionPlans = () => {
    SubscriptionRequests.getZoeeSubscriptionPlans({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        const annual = data?.plans?.find(
          (plan: any) =>
            plan.recurring_interval === "annual" &&
            plan.is_promotional === false
        )
        const monthly = data?.plans?.find((plan: any) => plan.amount === 49)
        const lifetime = data?.plans?.find(
          (plan: any) =>
            plan.amount === 500 &&
            plan.is_promotional === true &&
            plan.recurring_interval_count !== 2
        )
        const twoYears = data?.plans?.find(
          (plan: any) =>
            plan.amount === 500 &&
            plan.is_promotional === true &&
            plan.recurring_interval_count === 2
        )

        setPlans({
          annual,
          monthly,
          lifetime,
          twoYears,
        })
      })
      .catch((ex) => {
        console.error(ex)
        renderError(ex.response.data.message)
      })
  }

  useEffect(() => {
    getSubscriptionPlans()
  }, [])

  useEffect(() => {
    if (planType) {
      setSelectedPlan(planType)
    }
  }, [planType])

  useScrollToTop()

  const handleChoosePlanClick = (chosenSubscription: any): void => {
    if (selectedPlan === "annual" && user.organization === "BBHC") {
      localStorage.setItem("referrerPromoCode", "BBHC20")
    }
    setSelectedSubscription(chosenSubscription)
    showSubscriptionComponents(setShowSubscriptionCheckout)
  }

  const goBack = () => {
    navigate("/coach/subscription/current")
  }

  if (!plans) {
    return <Loader />
  }

  return (
    <div className="-mt-0 md:-mt-[43px] p-8">
      <div className="sm:ml-24">
        <BackToButton
          onClick={goBack}
          current="Plans & Pricing"
          prev="My Subscription"
        />
        <div className="w-full flex flex-col items-right">
          <h1 className="font-bold flex text-[22px] sm:text-[36px] text-center mt-[40px] mb-[8px]">
            Plans &amp; Pricing
          </h1>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="relative flex justify-center items-center w-full max-w-xs rounded-[20px] border-2 border-black h-[50px] p-1 mb-[24px]">
          <button
            onClick={() => setSelectedPlan("monthly")}
            className={`w-[40%] h-full rounded-[18px] ${
              selectedPlan === "monthly"
                ? "bg-[black] text-[white]"
                : "bg-white text-blue-500"
            }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setSelectedPlan("annual")}
            className={`w-[60%] h-full rounded-[18px] ${
              selectedPlan === "annual"
                ? "bg-[black] text-[white]"
                : "bg-white text-blue-500"
            }`}
          >
            Annual (Save 16%)
          </button>
        </div>
        <div className="w-full flex flex-col md:flex-row items-center justify-center gap-8 mx-auto mb-16">
          <TrialSubscriptionCard />
          {selectedPlan === "monthly" && (
            <SubscriptionPlanCard
              plan={plans?.monthly}
              handleChoosePlanClick={handleChoosePlanClick}
              title={`Monthly Pro`}
              subscription={subscription}
            />
          )}
          {selectedPlan === "annual" && (
            <SubscriptionPlanCard
              plan={plans?.annual}
              handleChoosePlanClick={handleChoosePlanClick}
              title={`Annual Pro`}
              subscription={subscription}
            />
          )}
        </div>
        <div className="text-[16px] flex items-center gap-[8px] mb-[24px] md:mb-0">
          <SvgChatEllipses />
          <p>Need help deciding?</p>
          <button
            onClick={() => {
              show()
            }}
            className="text-blurple font-bold"
          >
            Chat with our support
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPlans
