import React, { useEffect, useState, useContext } from "react"
import Loader from "../ui/loader"
import useScrollToTop from "../../hooks/useScrollToTop"
import { Button } from "../ui/button"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  ContactRequests,
  MeetingRequests,
  ServiceRequests,
} from "../../api/app.service"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import { getTextColor } from "../../utils/getTextColor"
import { isCoachInCommissionTracking } from "../../utils/commissionTracking"
import { useNavigate } from "react-router-dom"
import { SvgCalendar, SvgClock, SvgVideoOutline, SvgWebsite } from "../icons/"
import { UserCtx } from "../../context/userContext"

interface Props {
  showConfirm: boolean
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>
  consultation: any
  startTime: Date
  endTime: Date
  initialSize?: string
}

export const FreeConsultationConfirm: React.FC<Props> = ({
  showConfirm,
  setShowConfirm,
  consultation,
  startTime,
  endTime,
  initialSize,
}) => {
  const [contacts, setContacts] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const { user } = useContext(UserCtx)
  const { renderError } = useContext(CommonFunctionCtx)
  const { coachPublicProfileData } = useContext(CoachPublicProfileContext)
  const navigate = useNavigate()

  const getMemberName = (member: any) => {
    let name = null
    if (member?.firstName) {
      if (member?.lastName) {
        name = `${member?.firstName} ${member?.lastName}`
      } else {
        name = member?.firstName
      }
    }
    return name
  }

  const generateMeetingName = (
    coachParticipant: any,
    memberParticipant: any
  ) => {
    const serviceTitle = consultation?.service_details?.title || ""
    const memberName =
      getMemberName(memberParticipant) || memberParticipant?.email || ""
    const coachName = coachParticipant?.contact_name || ""

    return `${coachName} <> ${memberName}${
      serviceTitle ? ` // ${serviceTitle}` : ""
    }`
  }

  const createMeeting = (service_usage_id: any) => {
    const coachParticipant = contacts.find(
      (contact: any) =>
        contact.profile_id ===
        parseInt(coachPublicProfileData?.coach_profile_id)
    )

    const memberParticipant = {
      user_id: parseInt(user?.user_id),
      profile_id: user?.activeProfileId,
      email: user?.email,
      contact_name: user?.firstName
        ? `${user.firstName} ${user.lastName || ""}`
        : "",
      first_name: user?.firstName || "",
      last_name: user?.lastName || "",
    }

    const parsedStartTime = startTime.toISOString().split(".")[0] + "Z"
    const parsedEndTime = endTime.toISOString().split(".")[0] + "Z"
    MeetingRequests.createMeeting({
      user_id: parseInt(user?.user_id),
      coach_user_id: parseInt(coachPublicProfileData?.user_id),
      profile_id: user.activeProfileId,
      meeting_details: {
        name: generateMeetingName(memberParticipant, coachParticipant),
        meeting_type: "individual",
        start_time: parsedStartTime,
        end_time: parsedEndTime,
        medium: "zoee_video",
        status: "busy",
        reminders: [],
        participants: [memberParticipant, coachParticipant],
        service_usage_id: service_usage_id,
      },
    })
      .then(() => {
        setSuccessModal(true)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitServicePurchase = () => {
    setLoading(true)
    ContactRequests.createContactConnection({
      sender_profile_id: coachPublicProfileData?.coach_profile_id,
      receiver_profile_id: user?.activeProfileId,
      user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      status: isCoachInCommissionTracking(coachPublicProfileData?.endpoint_slug)
        ? "zoee lead"
        : "connected",
    })
      .then((data) => getContacts())
      .then((data) => {
        return ServiceRequests.purchaseFreeService({
          user_id: parseInt(user.user_id),
          coach_user_id: coachPublicProfileData?.user_id,
          client_profile_id: user?.activeProfileId,
          coach_profile_id: coachPublicProfileData?.coach_profile_id,
          payment_method_id: null,
          service: consultation.service_details,
          stripe_product_id: consultation?.stripe_product_id,

          user_environment: "",
        })
      })
      .then((data) => {
        createMeeting(data.service_usage_id)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
        setLoading(false)
      })
  }

  const getContacts = async () => {
    setLoading(true)
    await ContactRequests.getContactList({
      profile_id: user.activeProfileId,
      profile_type: user.activeProfile,
    })
      .then((data) => {
        setContacts(data.contact_list)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const coachName = `${coachPublicProfileData.first_name} ${coachPublicProfileData.last_name}`

  const startDate = new Date(startTime)
  const endDate = new Date(endTime)

  const formattedStartDate = startDate.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  })

  function formatTime(date: Date): string {
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  }

  const formattedStartTime = formatTime(startDate)
  const formattedEndTime = formatTime(endDate)

  const getUserTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const longTimezoneName = new Date().toLocaleTimeString("en", {
      timeZoneName: "short",
    })
    const extractedTimezone = longTimezoneName.split(" ").pop()?.toUpperCase()
    return extractedTimezone ? extractedTimezone : "UTC"
  }

  const userTimezone = getUserTimezone()

  const handleCancel = () => {
    setLoading(false)
    setShowConfirm(false)
  }

  useEffect(() => {
    getContacts()
  }, [])

  useScrollToTop()

  const handleRouteToScheduling = () => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { consultation, contacts },
    })
  }

  return (
    <Dialog open={showConfirm} onOpenChange={setShowConfirm}>
      <DialogContent>
        {!successModal ? (
          <>
            <DialogHeader>
              <DialogTitle className="text-center">
                Confirm Your Free Consultation
              </DialogTitle>
            </DialogHeader>

            <div className="flex items-center gap-3 flex-col border-b py-6">
              <div className="w-20 h-20 relative shadow-md rounded-full overflow-hidden">
                {coachPublicProfileData?.has_avatar ? (
                  <img
                    src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.user_id}?`}
                    alt="avatar"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div
                    className={`flex items-center justify-center rounded-[20px]
                    w-full h-full font-bold ${
                      initialSize ? initialSize : "text-[32px] md:text-[40px]"
                    }`}
                    style={{
                      backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
                      color: `${getTextColor(
                        coachPublicProfileData?.profile_information
                          ?.profileColor
                      )}`,
                    }}
                  >
                    {coachPublicProfileData?.first_name?.charAt(0)}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 items-center text-center">
                <h3 className="font-bold text-lg">{coachName}</h3>
                <h4 className="font-bold text-sm text-muted-foreground">
                  {coachPublicProfileData.profile_information.title}
                </h4>
              </div>
            </div>

            <div className="text-muted-foreground grid grid-cols-[auto_1fr] gap-3 my-3">
              <SvgVideoOutline />
              <p className="capitalize">
                {consultation?.service_details?.type}
              </p>
              <SvgVideoOutline />
              <p>{formattedStartDate}</p>
              <SvgVideoOutline />
              <p className="uppercase">
                {formattedStartTime} - {formattedEndTime} {userTimezone}{" "}
              </p>
            </div>

            <DialogFooter className="grid grid-cols-2 gap-3">
              <Button
                variant="ghost"
                size="lg"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>

              <Button
                variant="default"
                size="lg"
                onClick={submitServicePurchase}
                disabled={loading}
              >
                {loading ? <Loader /> : "Confirm & Book"}
              </Button>
            </DialogFooter>
          </>
        ) : (
          <>
            <DialogHeader>
              <div className="flex items-center gap-3 flex-col border-b py-6">
                <div className="w-20 h-20 relative flex items-center justify-center rounded-full overflow-hidden">
                  {coachPublicProfileData?.has_avatar ? (
                    <img
                      src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.user_id}?`}
                      alt="avatar"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div
                      className={`flex items-center justify-center rounded-[20px]
                    w-full h-full font-bold ${
                      initialSize ? initialSize : "text-[32px] md:text-[40px]"
                    }`}
                      style={{
                        backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
                        color: `${getTextColor(
                          coachPublicProfileData?.profile_information
                            ?.profileColor
                        )}`,
                      }}
                    >
                      {coachPublicProfileData?.first_name?.charAt(0)}
                    </div>
                  )}
                </div>
              </div>
              <DialogTitle className="text-center text-balance">
                Success! Your consultation has been scheduled with{" "}
                {`${coachPublicProfileData.first_name} ${coachPublicProfileData.last_name} `}
                🎉
              </DialogTitle>
              <DialogDescription className="text-center">
                You should have received a calendar invite at your email
                address. If you don't find it in your inbox, please check your
                spam folder, and add no-reply@zoee.com to your contacts.
              </DialogDescription>
            </DialogHeader>

            <div className="text-muted-foreground grid grid-cols-[auto_1fr] gap-3 my-3 uppercase">
              <SvgCalendar />
              <p>
                {formattedStartTime} - {formattedEndTime}, {formattedStartDate}
              </p>
              <SvgWebsite />
              <p className="uppercase">{userTimezone}</p>

              <SvgClock />
              <p>Web conferencing details to follow.</p>
            </div>
            <DialogFooter className="items-center grid justify-center gap-6 mx-auto">
              <Button
                variant="default"
                size="lg"
                onClick={handleRouteToScheduling}
                disabled={loading}
              >
                View My Schedule
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default FreeConsultationConfirm
