import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import * as Testimonials from "../Testimonials"
import OverviewBento from "./OverviewBento"
import PrivateProfileFeatureTab from "./PrivateProfileFeatureTab"
import ProfileAboutTab from "./ProfileAboutTab"
import ProfileServices from "./ProfileServices"
import ShareModal from "./ShareModal"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import {
  SvgArticle,
  SvgBusiness,
  SvgDownload,
  SvgGrid,
  SvgHelp,
  SvgList,
} from "../icons/"
import { ProfileRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"

interface Props {
  post?: any
  service?: string
}

const TilesCarousel: React.FC<Props> = ({ post, service }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const urlTab = searchParams.get("tab") || "overview"
  const [activeTab, setActiveTab] = useState("overview")
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const { user } = React.useContext(UserCtx)
  const [filledButton, setfilledButton] = useState<boolean>(false)
  const endPoint = coachPublicProfileData?.endpoint_slug

  const tabLabels = [
    { name: "Overview", activeTab: "overview", icon: <SvgGrid /> },
    { name: "About", activeTab: "about", icon: <SvgList /> },
    { name: "Services", activeTab: "services", icon: <SvgBusiness /> },
    { name: "Featured Content", activeTab: "featured", icon: <SvgArticle /> },
    { name: "Testimonials", activeTab: "testimonials", icon: <SvgHelp /> },
  ]

  useEffect(() => {
    setActiveTab(urlTab)
  }, [])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    setSearchParams({ tab })
    setfilledButton(true)
  }

  const handleDownloadClick = async () => {
    try {
      const rawResponse: any =
        await ProfileRequests.getCoachNewsletterSubscribers({
          user_id: parseInt(user.user_id),
        })
      if (rawResponse.download_url) {
        window.open(rawResponse.download_url, "_blank")
      } else {
        console.error("No download URL:", rawResponse)
        alert(
          rawResponse.message || "No subscribers found or an error occurred."
        )
      }
    } catch (error) {
      alert("No subscribers found.")
    }
  }

  return (
    <>
      <div className="flex flex-col gap-[12px] w-full relative">
        <div className="flex items-stretch gap-8 overflow-x-auto">
          {tabLabels.map((tab, index) => (
            <Button
              className={`h-[50px] mb-2 ml-2 ${
                (filledButton || tab.activeTab === "overview") &&
                activeTab === tab.activeTab
                  ? "bg-white"
                  : ""
              }`}
              key={index}
              variant="utility"
              onClick={() => handleTabClick(tab.activeTab)}
            >
              {tab.icon}
              <h3>{tab.name}</h3>
            </Button>
          ))}
          {coachPublicProfileData?.newsletter_opt_in_enabled && (
            <div className="lg:ml-[350px]">
              <Button
                className="h-12 pl-3 pr-4 py-3 rounded-lg border-2 border-[#4750f5] flex items-center gap-2"
                variant={"outline"}
                onClick={handleDownloadClick}
              >
                <SvgDownload className="w-5 h-5" />
                <span className="text-[#4750f5] text-base font-bold font-['Beausite Classic'] leading-normal">
                  Download Email List
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>

      {shareModalOpen && (
        <>
          <ShareModal
            endpoint_slug={endPoint}
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      )}
      {activeTab === "overview" && (
        <OverviewBento renderServices={() => handleTabClick} />
      )}
      {activeTab === "about" && (
        <ProfileAboutTab coachPublicProfileData={coachPublicProfileData} />
      )}
      {activeTab === "services" && <ProfileServices />}
      {activeTab === "featured" && <PrivateProfileFeatureTab />}
      {activeTab === "testimonials" && (
        <Testimonials.ProfileTestimonialTab
          isEditable
          coachPublicProfileData={coachPublicProfileData}
        />
      )}
    </>
  )
}

export default TilesCarousel
