import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import IconMobile from "./cm-images/IconMobile.png"
import HelpMobile from "./cm-images/HelpMobile.png"
import IntercomMobile from "./cm-images/IntercomMobile.png"

export default function CMTechSupportMobile() {
  return (
    <SectionWrapper id="tech-support">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Getting <span className="text-[#4750F5]">Technical Support</span>
        </h1>
      </div>

      <p className="text-gray-700 mt-6 text-center">
        If you encounter technical issues while using the platform, there are
        multiple resources available to help troubleshoot and resolve any issues
        you run into.
      </p>

      {/* Accessing Help Articles and Videos */}
      <h3
        id="help-center"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Accessing How-To Articles and Videos
      </h3>
      <p className="text-gray-700 mt-2">
        Before reaching out to our live support team, you can access Zoee’s help
        center, which contains articles and videos outlining how to use the
        platform’s features. To access the help center, follow these steps:
      </p>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Click on your profile icon to reveal the dropdown.</li>
          <div className="flex justify-center mt-4">
            <img
              src={IconMobile}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Select <strong>"Help Articles"</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={HelpMobile}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            You will be able to search for a specific issue in the search bar
            related to your member profile.
          </li>
        </ul>
      </div>

      {/* Live Chat Support */}
      <h3 id="live-chat" className="text-2xl font-semibold text-blue-700 mt-8">
        Intercom Live Chat
      </h3>
      <p className="text-gray-700 mt-2">To access the live chat:</p>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Click on your profile icon to reveal the dropdown.</li>
          <div className="flex justify-center mt-4">
            <img
              src={IconMobile}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Select <strong>"Support"</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={IntercomMobile}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            This will bring up the Intercom chat window, allowing you to send a
            message to our team.
          </li>
        </ul>
      </div>
      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
