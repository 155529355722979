import React, { useContext, useState, useEffect } from "react"
import useLoading from "../../hooks/useLoading"
import { ClientPaymentRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { useLocation } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

export default function MigrateToPayPalBanner() {
  const { user } = useContext(UserCtx)
  const [paypalStatus, setPaypalStatus] = useState<any>(null)
  const query = new URLSearchParams(useLocation().search)
  const merchantIdInPayPal = query.get("merchantIdInPayPal")
  const { startLoading, stopLoading } = useLoading()
  const { renderError } = useContext(CommonFunctionCtx)
  const authenticatedUser = useAuthenticatedUser(user)

  useEffect(() => {
    if (authenticatedUser) {
      getPaypalOnboardingStatus()
    }
  }, [authenticatedUser])

  const getPaypalOnboardingStatus = async () => {
    if (!authenticatedUser) {
      localStorage.removeItem("user")
    }

    startLoading()
    try {
      const onboardingStatus =
        await ClientPaymentRequests.getPaypalOnboardingStatus({
          seller_payer_id: merchantIdInPayPal,
          seller_user_id: parseInt(user?.user_id),
        })
      setPaypalStatus(onboardingStatus)
    } catch (error) {
      renderError(
        "An error occurred trying to fetch your PayPal onboarding status"
      )
    } finally {
      stopLoading()
    }
  }

  if (!authenticatedUser || user.organization !== "BBHC") return null

  const paypalAccountConnected = paypalStatus?.data?.merchant_id
  const emailVerified = paypalStatus?.data?.primary_email_confirmed
  const payPalOnboardingStatus = paypalStatus?.data?.onboarding_completed
  const paymentsReceivable = paypalStatus?.data?.payments_receivable

  const shouldShowBanner =
    !paypalAccountConnected ||
    !emailVerified ||
    !payPalOnboardingStatus ||
    !paymentsReceivable

  if (!shouldShowBanner) return null
  return (
    <>
      {authenticatedUser && (
        <section className="subscription-cta-gradient relative py-4 pr-4">
          <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-32 justify-center">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-4 text-center sm:text-left">
              <span className="text-lg lg:text-base">
                <span className="font-bold">Zoee Zone:</span> Ensure you
                complete PayPal onboarding to create paid services and to adjust
                your website visibility to share your website with others.{" "}
                <a href="/coach/client-payments" className="underline">
                  Click here to complete onboarding.
                </a>
              </span>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
