import React, { useState } from "react"
import ContactAvatar from "./ContactAvatar"
import ContactStatusTag from "./ContactStatusTag"
import useDate from "../../hooks/useDate"
import { Button } from "../ui/button"
import { SvgClose } from "../icons/"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface Props {
  setInvites: any
  setShowConfirmInviteModal: any
  contact: any
  getContacts: any
  setSelectedContact: any
  setShowContactProfile: any
  setContactRequestFull: any
  acceptContactRequest: any
  denyContactRequest: any
  revokeContactRequest: any
  resendContactRequest: any
  shouldApplyMargin: any
}

const ContactCard: React.FC<Props> = ({
  setInvites,
  setShowConfirmInviteModal,
  getContacts,
  contact,
  setSelectedContact,
  setShowContactProfile,
  resendContactRequest,
  setContactRequestFull,
  acceptContactRequest,
  denyContactRequest,
  revokeContactRequest,
  shouldApplyMargin,
}) => {
  const {
    profile_type,
    contact_type,
    contact_name,
    tagline,
    contact_date,
    status,
    profile_connection_id,
    email,
  } = contact || {}
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const { smDate } = useDate()

  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const loadContactProfile = () => {
    const profile_type = user.activeProfile
    navigate(`/${profile_type}/contacts/${contact.profile_id}`, {
      state: {
        contact: contact,
      },
    })
  }

  const showFullRequest = () => {
    setContactRequestFull({ show: true, contact: contact })
  }

  const getTagline = () => {
    let value = ""
    if (tagline) {
      value = tagline
    } else if (contact_type === "invited") {
      value = `Invited ${smDate(contact_date)}`
    } else if (contact_type === "prospect") {
      value = email
    } else {
      if (contact.profile_type === "member") {
        value = "Zoee Member"
      } else {
        value = "Professional Coach"
      }
    }
    return value
  }

  const getStatus = () => {
    let profileStatus = "Unknown"
    if (contact_type === "prospect") {
      return "prospect"
    }
    if (profile_type === "coach" && user.activeProfile === "coach") {
      profileStatus = "c2c"
    } else if (status === "connected") {
      profileStatus = "active"
    } else if (status === "lead") {
      profileStatus = "lead"
    } else if (status === "zoee lead") {
      profileStatus = "zoee lead"
    } else if (status === "prospect") {
      profileStatus = "prospect"
    } else if (contact_type === "invited") {
      profileStatus = "invited"
    } else if (contact_type === "requested") {
      profileStatus = "requested"
    }
    return profileStatus
  }

  return (
    <div
      className={`md:w-[258px] w-[184px] md:h-[302px] h-[213px] promotion-shadow flex flex-col 
        items-center rounded-[16px] relative cursor-pointer hover:transform hover:scale-105 transition-transform duration-300 shadow-custom ${
          shouldApplyMargin ? "mb-[80px]" : ""
        } ${isHovered ? "bg-royalBlue" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={contact.profile_id ? loadContactProfile : () => null}
    >
      <div
        className="absolute top-[4px] left-[50%] -translate-x-[50%]
                md:w-[250px] w-[178px] md:h-[78px] h-[69px] bg-grayFlash rounded-[12px]"
      >
        {contact_type === "requested" && (
          <button
            className="absolute w-[32px] h-[32px] bg-white rounded-full
                        top-[8px] right-[8px] flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation()
              denyContactRequest(contact)
            }}
          >
            <SvgClose className="w-[20px] h-[20px]" />
          </button>
        )}
        {/* <ContactStatusTag
          contactStatus={getStatus()}
          origin=""
          contactDate={contact_date}
        /> */}
         {/* commented out as it serves no purpose right now, ticket dev-733 */}
        <div className="absolute top-[52px] left-[50%] -translate-x-[50%]">
          <div className="hidden md:block">
            <ContactAvatar
              contact={contact}
              width={80}
              height={80}
              border="border border-[4px] border-white"
            />
          </div>
          <div className="md:hidden">
            <ContactAvatar
              contact={contact}
              width={48}
              height={48}
              border="border border-[4px] border-white"
              isNotBold={true}
            />
          </div>
        </div>
      </div>
      <h3
        className={`font-bold md:mt-[160px] mt-[112px] w-full md:w-auto text-center px-[2px] truncate ${
          contact_type === "invited" ? "text-base" : "text-[18px]"
        } ${isHovered ? "text-white" : "text-graySlate"}`}
      >
        {`${contact_name?.substring(0, 18)}${
          contact_name?.length > 18 ? "..." : ""
        }`}
      </h3>
      <p
        className={`${
          isHovered ? "text-white" : "text-graySlate"
        } text-base md:mt-[2px]`}
      >
        {getTagline()}
      </p>{" "}
      {contact_type === "invited" && (
        <div className="flex items-center gap-[8px] mt-[12px] md:mt-[48px]">
          <Button
            className={`h-[32px] ${isHovered ? "border border-white" : ""}`}
            onClick={(e) => {
              e.stopPropagation()
              resendContactRequest(contact)
            }}
          >
            Resend
          </Button>
          <button
            className="btn-primary btn-secondary h-[32px]"
            onClick={(e) => {
              e.stopPropagation()
              revokeContactRequest(contact)
            }}
          >
            Revoke
          </button>
        </div>
      )}
      {contact_type === "requested" && (
        <div className="flex items-center gap-[8px] mt-[12px] md:mt-[48px]">
          <Button
            className="h-[32px] text-md"
            onClick={(e) => {
              e.stopPropagation()
              acceptContactRequest(contact)
            }}
          >
            Accept
          </Button>
          <button
            className="btn-primary btn-secondary h-[32px]"
            onClick={showFullRequest}
          >
            View
          </button>
        </div>
      )}
    </div>
  )
}

export default ContactCard
