import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import * as z from "zod"
import GoogleRegister from "./google-register"
import Loader from "../../ui/loader"
import PasswordRequirements from "../../account-settings/PasswordRequirements"
import Recaptcha from "./Recaptcha"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import { Link } from "react-router-dom"
import { RegisterFormContext } from "./context"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { UserExists } from "../../../api/auth.service"
import { validateFirstAndLastName } from "./name-form"

export const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })
    return { found: data.user_found, IIN_data: data.IIN_data }
  } catch (error) {
    console.error(error)
  }
}

export const RegistrationForm: React.FC = () => {
  const {
    email,
    setEmail,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    createAccount,
  } = useContext(RegisterFormContext)
  const navigate = useNavigate()
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false)
  const [loadingGoogle, setLoadingGoogle] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const { setAccountType, setView } = useContext(RegisterFormContext)

  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const { password, setPassword, accountType } = useContext(RegisterFormContext)
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false)
  const [confUserPassword, setConfUserPassword] = useState<string>("")
  const [isNotRobot, setIsNotRobot] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  )
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isGoogleSignUp, setIsGoogleSignUp] = useState<boolean>(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const emailFromLocation = searchParams.get("email")
  const requestedCoachName = `${searchParams.get("fname")} ${searchParams.get(
    "lname"
  )}`
  const coachEndpoint = searchParams.get("endpoint")
  const [showNameFields, setShowNameFields] = useState(false)
  const [showPasswordFields, setShowPasswordFields] = useState(false)
  const [emailDelayTimeout, setEmailDelayTimeout] =
    useState<NodeJS.Timeout | null>(null)
  const [nameDelayTimeout, setNameDelayTimeout] =
    useState<NodeJS.Timeout | null>(null)
  const [showLastStep, setShowLastStep] = useState(false)
  const currentLocation = `${location.pathname}${location.search}`

  const goToSignIn = () => navigate("/sign_in")

  useEffect(() => {
    if (email && firstName && lastName && isGoogleSignUp) {
      handleSubmit()
    }
  }, [email, firstName, lastName, isGoogleSignUp])

  useEffect(() => {
    const validateForm = () => {
      const emailIsValid = z.string().email().safeParse(email).success
      const firstNameIsValid =
        firstName.trim() !== "" && validateFirstAndLastName(firstName).success
      const lastNameIsValid =
        lastName.trim() !== "" && validateFirstAndLastName(lastName).success
      const passwordsMatch = isEntreeValid && password === confUserPassword

      setIsFormValid(
        emailIsValid &&
          firstNameIsValid &&
          lastNameIsValid &&
          passwordsMatch &&
          isNotRobot
      )
    }

    validateForm()
  }, [
    email,
    firstName,
    lastName,
    password,
    confUserPassword,
    isNotRobot,
    accountType,
  ])

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    setLoadingCreateAccount(true)
    setLoadingGoogle(false)

    const emailSchema = z.string().email().safeParse(email)

    if (!emailSchema.success) {
      setError(emailSchema.error.issues[0].message)
      setLoadingCreateAccount(false)
      return
    }
    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setError("Email is already in use")
      setLoadingCreateAccount(false)
      return
    }
    try {
      await createAccount()
      if (emailFromLocation) {
        navigate(`/${accountType}/contacts?received_invites=true`)
      }
      setLoadingCreateAccount(false)
    } catch (error) {
      console.error("Error creating account:", error)
      setError("Failed to create account. Please try again.")
      setLoadingCreateAccount(false)
    }
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    let inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const errorMsg = "Invalid Input"

    if (inputName === "first" || inputName === "last") {
      inputValue = capitalizeFirstLetter(inputValue)
    }

    if (inputName === "email") {
      setError(undefined)
      setEmail(inputValue)
    }

    if (inputName === "first") {
      setFirstNameError(undefined)
      setFirstName(inputValue)
      if (!inputSuccess) {
        setFirstNameError(errorMsg)
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined)
      setLastName(inputValue)
      if (!inputSuccess) {
        setLastNameError(errorMsg)
      }
    }

    if (inputName === "password") {
      setPassword(inputValue)
    }

    if (inputName === "conf-pass") {
      setConfUserPassword(inputValue)
    }
  }

  useEffect(() => {
    if (password !== confUserPassword) {
      setPasswordError("Passwords must match")
    } else {
      setPasswordError(undefined)
    }
  }, [confUserPassword])

  // useEffect(() => {
  //   if (password === confUserPassword && password !== "") {
  //     setShowLastStep(true)
  //   } else {
  //     setShowLastStep(false)
  //   }
  // }, [password, confUserPassword])
  // useEffect(() => {
  //   if (password === confUserPassword && password !== "") {
  //     setShowLastStep(true)
  //   } else {
  //     setShowLastStep(false)
  //   }
  // }, [password, confUserPassword])

  const handleAccountSelection = (
    accountType: "member" | "coach",
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    setAccountType(accountType)
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const emailFromLocation = searchParams.get("email")

    if (emailFromLocation) {
      setEmail(emailFromLocation)
      // setShowNameFields(true)
      // setShowNameFields(true)
    }
  }, [location.search])
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value)

    const emailSchema = z.string().email().safeParse(value)
    if (!emailSchema.success) {
      return
    }

    // if (emailDelayTimeout) {
    //   clearTimeout(emailDelayTimeout)
    // }
    // if (emailDelayTimeout) {
    //   clearTimeout(emailDelayTimeout)
    // }

    // const newEmailTimeout = setTimeout(() => {
    //   setShowNameFields(true)
    // }, 500)
    // setEmailDelayTimeout(newEmailTimeout)
    // const newEmailTimeout = setTimeout(() => {
    //   setShowNameFields(true)
    // }, 500)
    // setEmailDelayTimeout(newEmailTimeout)
  }

  const handleNameChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: "first" | "last"
  ) => {
    const value = e.target.value
    if (field === "first") {
      setFirstName(value)
    } else {
      setLastName(value)
    }

    // if (firstName && lastName) {
    //   if (nameDelayTimeout) {
    //     clearTimeout(nameDelayTimeout)
    //   }
    // if (firstName && lastName) {
    //   if (nameDelayTimeout) {
    //     clearTimeout(nameDelayTimeout)
    //   }

    //   const newTimeout = setTimeout(() => {
    //     setShowPasswordFields(true)
    //   }, 500)
    //   setNameDelayTimeout(newTimeout)
    // }
    //   const newTimeout = setTimeout(() => {
    //     setShowPasswordFields(true)
    //   }, 500)
    //   setNameDelayTimeout(newTimeout)
    // }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-2"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault()
        }
      }}
    >
      {/* Step 1: Role Selection (Always visible) & Step 2: Email Input */}
      <div className="grid sm:grid-cols-2 gap-2">
        <Button
          className="text-md h-auto py-6"
          variant={accountType === "member" ? "default" : "outline"}
          onClick={(e) => handleAccountSelection("member", e)}
        >
          I'm a Client
        </Button>
        <Button
          className="text-md h-auto py-6"
          variant={accountType === "coach" ? "default" : "outline"}
          onClick={(e) => handleAccountSelection("coach", e)}
        >
          I'm a Coach
        </Button>
      </div>
      <Input
        label="Email"
        placeholder="Email"
        value={email}
        onChange={handleEmailChange}
        onFocus={() => setShowPassReq(false)}
        error={error}
        disabled={loadingCreateAccount || loadingGoogle}
        autoFocus
      />
      {/* Step 3: First and Last Name Inputs */}
      {/* {showNameFields && (
        <> */}
      <Input
        label="First Name"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => handleNameChange(e, "first")}
        onFocus={() => setShowPassReq(false)}
        error={firstNameError}
        disabled={loadingCreateAccount || loadingGoogle}
      />
      <Input
        label="Last Name"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => handleNameChange(e, "last")}
        onFocus={() => setShowPassReq(false)}
        error={lastNameError}
        disabled={loadingCreateAccount || loadingGoogle}
      />
      {/* </>
      )} */}
      {/* Step 4: Password Inputs */}
      {showPaswReqs && (
        <PasswordRequirements
          validEntree={password}
          setIsEntryValid={setIsEntryValid}
        />
      )}
      {/* {showPasswordFields && (
        <> */}
      <Input
        label="Password"
        placeholder="New password"
        value={password}
        onFocus={() => setShowPassReq(true)}
        onChange={(e) => handleChange(e, "password")}
        type="password"
        autoComplete="off"
        disabled={loadingCreateAccount || loadingGoogle}
      />
      <Input
        label="Confirm your password"
        placeholder="Confirm new password"
        value={confUserPassword}
        error={passwordError}
        onChange={(e) => handleChange(e, "conf-pass")}
        type="password"
        onFocus={() => setShowPassReq(false)}
        autoComplete="off"
        disabled={loadingCreateAccount || loadingGoogle}
      />
      {/* </>
      )} */}
      {/* Step 5: reCAPTCHA and Create Account Button */}
      {/* {showLastStep && password === confUserPassword && (
        <> */}
      <Recaptcha setIsNotRobot={setIsNotRobot} />
      <Button
        type="submit"
        className="w-full"
        disabled={loadingCreateAccount || !isFormValid}
      >
        {loadingCreateAccount ? <Loader /> : "Create Account"}
      </Button>
      <p className="text-muted-foreground text-xs text-pretty w-full text-center mx-auto">
        By clicking continue, I agree{" "}
        {emailFromLocation ? (
          <>
            to connect with Coach{" "}
            <Link
              to={`/find-a-coach/${coachEndpoint}`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {requestedCoachName}
            </Link>{" "}
            and to Zoee{" "}
          </>
        ) : (
          "to Zoee "
        )}
        <Link
          to="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          Terms
        </Link>{" "}
        &{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          Privacy Policy
        </Link>
      </p>
      {/* </>
      )} */}
      {/* <GoogleRegister
        setEmail={setEmail}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setIsGoogleSignUp={(isGoogleSignUp) => {
          setLoadingGoogle(true)
          setLoadingCreateAccount(false)
          setIsGoogleSignUp(isGoogleSignUp)
        }}
        loading={loadingGoogle}
      /> */}
      {currentLocation !== "/sign_in" && (
        <p className="text-muted-foreground text-sm text-center">
          Already have an account?{" "}
          <Button
            className="w-fit mx-auto"
            variant="link"
            size="default"
            onClick={goToSignIn}
          >
            Login
          </Button>
        </p>
      )}
    </form>
  )
}
