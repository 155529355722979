import React, { useContext } from "react"
import { AuthContext } from "./context"
import { Button } from "../ui/button"
import { useNavigate } from "react-router-dom"

export const SwapAuthAction: React.FC = () => {
  const { authAction, setAuthAction } = useContext(AuthContext)
  const navigate = useNavigate()
  const goToSignIn = () => navigate("/sign_in")

  if (authAction === "register") {
    return (
      <p className="text-muted-foreground text-sm text-center">
        Already have an account?{" "}
        <Button variant="link" size="sm" type="button" onClick={goToSignIn}>
          Login
        </Button>
      </p>
    )
  } else {
    return (
      <p className="text-muted-foreground text-sm text-center">
        Don't have an account?{" "}
        <Button
          variant="link"
          size="sm"
          type="button"
          onClick={() => setAuthAction("register")}
        >
          Register
        </Button>
      </p>
    )
  }
  return null
}
