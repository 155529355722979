import React, { FC } from "react"
import LinkedFormsItem from "./LinkedFormsItem"
import { Button } from "../ui/button"
import { FormLink } from "../../models/app.interface"

interface Props {
  formList: FormLink[]
  deleteForm: (formId: number) => void
  setCreateFormModal: (statement: boolean) => void
  getFormList: () => void
}

const LinkedForms: FC<Props> = ({
  formList,
  deleteForm,
  getFormList,
  setCreateFormModal,
}) => {
  return (
    <div>
      <div className="w-full flex flex-col items-center rounded-[16px] gap-[8px]">
        {formList.length > 0 ? (
          formList.map((form) => (
            <LinkedFormsItem
              key={form.form_link_id}
              formLink={form}
              deleteForm={deleteForm}
              getFormList={getFormList}
            />
          ))
        ) : (
          <div
            className="w-full flex items-center justify-center h-[399px] border border-1
            border-grayMist rounded-[16px] bg-[#f8f8f8]"
          >
            <div className="w-[900px] text-center flex flex-col items-center gap-[12px]">
              <h2 className="font-bold text-[22px]">
                You have no linked forms.
              </h2>
              <p className="mb-4 text-[#3A434A] text-base">
                Attach any form to your services—like health history, discovery,
                or intake questionnaires—and let Zoee handle the rest! Your
                clients automatically receive the right forms at the right
                time—no manual sending required. Save hours of back-and-forth
                while gathering the essential information you need before each
                session. Set it up once, then watch as your client onboarding
                transforms from tedious admin to seamless automation. Watch this{" "}
                <a
                  href="https://intercom.help/zoee/en/articles/9294036-forms-coach-perspective-part-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  <span className="font-bold text-blurple">short video</span>
                </a>{" "}
                to learn how to set up forms on Zoee.
              </p>
              <Button
                variant="default"
                size="sm"
                className="mt-4"
                onClick={() => setCreateFormModal(true)}
              >
                Create a New Form
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LinkedForms
