import React from "react";
import ServiceTypeButton from "./ServiceTypeButton";
import {
  SvgBundle,
  SvgConsultation,
  SvgRecurring,
  SvgUserCheck,
} from "../../icons";

interface Props {
  setServiceType: any;
  serviceType: any;
  isGroupService: boolean;
  disableConsultation: boolean;
}

const ServiceTypeSelector: React.FC<Props> = ({
  setServiceType,
  serviceType,
  isGroupService,
  disableConsultation,
}) => {
  // Define descriptions based on isGroupService
  const bundleDescription = isGroupService
    ? "This is a structured program focused on achieving specific outcomes through recurring client meetings. Outline the frequency and duration of sessions (e.g., a 10-week coaching journey with weekly meetings)."
    : "This is a package or series of sessions that clients buy together. Imagine it as a collection of individual sessions bundled for clients to prepay and use at their convenience.";

  return (
    <div className="mb-[32px]">
      <h2 className="font-bold text-[22px]">
        Choose a {isGroupService ? "group" : "1:1"} service type
      </h2>
      <div className={"grid mt-[22px] gap-4"}>
        {!isGroupService && !disableConsultation && (
          <ServiceTypeButton
            type="consultation"
            icon={<SvgConsultation />}
            title="Consultation"
            description="Customize Your Discovery Call: A discovery call, consultation, or ‘get to know you’ session is an opportunity to connect with potential clients and determine if you're a good fit."
            setServiceType={setServiceType}
            serviceType={serviceType}
          />
        )}
        <ServiceTypeButton
          type="individual"
          icon={<SvgUserCheck />}
          title="Single"
          description="This is your pay-by-the-hour approach to coaching. It's great for one-off meetings with new or existing clients."
          setServiceType={setServiceType}
          serviceType={serviceType}
        />
        <ServiceTypeButton
          type="bundle"
          icon={<SvgBundle />}
          title="Package"
          description={bundleDescription}
          setServiceType={setServiceType}
          serviceType={serviceType}
        />
        {/* {!isGroupService && (
          <ServiceTypeButton
            type="recurring"
            icon={<SvgRecurring />}
            title="Recurring"
            description="This is a structured program focused on achieving specific outcomes through recurring client meetings. Outline the frequency and duration of sessions (e.g., a 10-week coaching journey with weekly meetings)."
            setServiceType={setServiceType}
            serviceType={serviceType}
          />
        )} */}
      </div>
    </div>
  );
};

export default ServiceTypeSelector;
