import React, {
  FC,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react"
import NoteModalDetailsCard from "./NoteModalDetailsCard"
import NoteEditor from "./Editor/NoteEditor"
import NoteSavedToast from "./NoteSavedToast"
import { format, parseISO } from "date-fns"
import { createEditor, Descendant } from "slate"
import { Slate, Editable, withReact } from "slate-react"
import Element from "./Editor/Element"
import Leaf from "./Editor/Leaf"
import { NoteRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import BackToButton from "../BackToButton"
import CloseModal from "../CloseModal"
import useScrollToTop from "../../hooks/useScrollToTop"
import DeleteDialogueModal from "../DialogueModal"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { SvgCheckCircle, SvgEdit, SvgTrash } from "../icons/"
import ContactAvatar from "../contacts/ContactAvatar"
import { Note } from "../../models/app.interface"

type Props = {
  note?: Note | undefined
  isNewNote: boolean
  setIsNewNote: any
  setActiveNoteId: any
  setShowNoteModal: any
  user: any
  contact: any
  getMemberNotes: any
  getNotes?: boolean
}

const NoteModal: FC<Props> = ({
  note,
  setActiveNoteId,
  isNewNote,
  setShowNoteModal,
  setIsNewNote,
  user,
  contact,
  getMemberNotes,
  getNotes = true,
}) => {
  const titleInputRef = useRef<HTMLInputElement>(null)
  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
  const [editor] = useState(() => withReact(createEditor()))
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [isNoteEditable, setIsNoteEditable] = useState<boolean>(isNewNote)
  const [showNoteSavedToast, setShowSavedNoteToast] = useState<boolean>(false)
  const [selectedProgram, setSelectedProgram] = useState<any>(null)
  const [showDialogueModal, setShowDialogueModal] = useState<boolean>(false)

  let initialTitleValue = ""
  if (note?.title) {
    initialTitleValue = note.title
  }

  const [titleValue, setTitleValue] = useState<string>(initialTitleValue)

  const handleTitleContentChange = (event: any) => {
    setTitleValue(event.target.value)
  }

  const emptyNoteValue: Descendant[] = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]

  const [noteValue, setNoteValue] = useState<Descendant[]>(
    note?.content || emptyNoteValue
  )

  useEffect(() => {
    if (titleInputRef.current) {
      ;(titleInputRef.current as HTMLInputElement).focus()
    }
  }, [isNoteEditable])

  const setNote = () => {
    if (user?.coachProfile) {
      // validation for Title and Content of note
      if (!titleValue || !noteValue.length) {
        renderError("You must have a title and note content before you save.")
        return
      }
      // CREATING NEW NOTE
      startLoading()
      if (!note) {
        console.log("no note")
        const newNoteData = {
          note_id: null,
          title: titleValue,
          content: noteValue,
          writer_profile_id: user.activeProfileId,
          relates_to_profile_id: contact.profile_id,
          session_id: null,
          service_usage_id: parseInt(selectedProgram?.service_usage_id) || null,
        }

        NoteRequests.setNote(newNoteData)
          .then(() => {
            renderSuccess("Note posted successfully.")
            setIsNewNote(false)
            setShowNoteModal(false)
          })
          .then(() => {
            if (getNotes) {
              getMemberNotes()
              setShowSavedNoteToast(true)
            }
            setTimeout(() => {
              setShowSavedNoteToast(false)
            }, 3000)
            stopLoading()
          })
          .catch((ex) => {
            stopLoading()
            renderError(ex.response.data.message)
          })
      }

      // EDITING NOTE
      if (note) {
        console.log("note")
        const editNoteData = {
          note_id: note.id,
          title: titleValue,
          content: noteValue,
          session_id: null,
          service_usage_id: parseInt(selectedProgram?.service_usage_id) || null,
        }
        NoteRequests.setNote(editNoteData)
          .then(() => {
            renderSuccess("Note has been updated.")
            getMemberNotes()
            setShowSavedNoteToast(true)
            // TODO: Rework so that toast says 'Saved' instead of 'Updated' when a note is saved
            setTimeout(() => {
              setShowSavedNoteToast(false)
            }, 3000)
            setShowNoteModal(false)
            stopLoading()
          })
          .catch((ex) => {
            stopLoading()
            renderError(ex.response.data.message)
          })
      }
    }
  }
  // Delete Function
  const handleDelete = () => {
    if (note?.id) {
      setShowDialogueModal(true)
    }
  }

  const handleConfirmDelete = () => {
    if (note) {
      startLoading()
      NoteRequests.deleteNote({ note_id: note?.id })
        .then(() => {
          renderSuccess("Deleted note!")
          setActiveNoteId(null)
          setIsNewNote(false)
          setShowNoteModal(false)
          setShowDialogueModal(false)
          getMemberNotes()
          stopLoading()
        })
        .catch((ex) => {
          stopLoading()
          renderError(ex.response.data.message)
        })
    }
  }

  useEffect(() => {
    if (note?.service_details) {
      setSelectedProgram({
        service_details: note?.service_details,
        stripe_product_id: note?.stripe_product_id,
      })
    } else {
      setSelectedProgram(null)
    }
  }, [])

  useScrollToTop()

  return (
    <>
      {showDialogueModal && (
        <DeleteDialogueModal
          setShowDialogueModal={setShowDialogueModal}
          confirmCallbackFn={handleConfirmDelete}
          confirmButtonText={"Delete Note"}
          header={"Caution! You are about to permanently delete this note."}
          helpText={"Are you sure you want to deleted this note?"}
          isWarningVariant={true}
        />
      )}
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
        <div className="md:z-[600] z-[700] w-full h-screen flex flex-col items-center justify-center">
          <div className="bg-white max-h-[90%] w-[80%] p-8 md:max-w-[50%] md:min-h-[90%] md:min-w-[50%] rounded-[16px]">
            <div className="md:hidden">
              <BackToButton
                current="Editing"
                prev="Notes"
                onClick={() => {
                  setActiveNoteId(null)
                  setIsNewNote(false)
                  setShowNoteModal(false)
                }}
              />
            </div>
            {/* MOBILE EDIT BUTTON */}
            <div className="md:hidden w-full md:mt-[48px] flex justify-center gap-[12px]">
              <Button
                onClick={() => {
                  if (isNoteEditable) {
                    setNote()
                  }
                  if (titleValue && noteValue.length) {
                    setIsNoteEditable(() => !isNoteEditable)
                  }
                }}
                variant={isNoteEditable ? "default" : "secondary"}
                size="sm"
              >
                {isNoteEditable ? (
                  <>
                    Save
                    <SvgCheckCircle />
                  </>
                ) : (
                  <>
                    Edit
                    <SvgEdit />
                  </>
                )}
              </Button>
              {!isNewNote && (
                <Button variant="destructive" onClick={handleDelete} size="sm">
                  Delete
                  <SvgTrash />
                </Button>
              )}
            </div>
            <div className="flex flex-col md:flex-row md:items-center justify-between mb-[24px]">
              {!isNoteEditable && (
                <>
                  <div className="md:hidden mt-[12px] mb-[32px] h-[2px] w-full bg-grayMist"></div>
                  {/* <div className="mr-8">
                    <ContactAvatar
                      contact={contact}
                      width={48}
                      height={48}
                      border="border-none"
                    />
                  </div> */}
                  <div className="w-full flex flex-col gap-[10px] md:gap-0">
                    <h3 className="-mt-6 md:mt-0 -mb-4 md:mb-0 mr-[20px] text-[22px] md:text-[16px] max-w-[250px] font-bold overflow-ellipsis">
                      {`${titleValue.substring(0, 15)}`}
                    </h3>
                    <div>
                      <p className="mr-[10px] text-base text-graySlate -mb-4 md:mb-0">
                        Created{" "}
                        {note
                          ? format(parseISO(note.created_at), "M/d/yy")
                          : ""}
                      </p>
                      <p className="mr-[10px] hidden md:block text-base text-graySlate">
                        Last updated{" "}
                        {note
                          ? format(parseISO(note.updated_at), "M/d/yy")
                          : ""}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {/* DESKTOP EDIT AND CLOSE BUTTONS */}
              <div className="hidden shrink md:flex items-center gap-[20px]">
                <Button
                  onClick={() => {
                    if (isNoteEditable) {
                      setNote()
                    }
                    if (titleValue && noteValue.length) {
                      setIsNoteEditable(() => !isNoteEditable)
                    }
                  }}
                  variant={isNoteEditable ? "default" : "secondary"}
                  size="sm"
                >
                  {isNoteEditable ? (
                    <>
                      Save
                      <SvgCheckCircle />
                    </>
                  ) : (
                    <>
                      Edit
                      <SvgEdit />
                    </>
                  )}
                </Button>
                {!isNewNote && (
                  <Button
                    variant="destructive"
                    onClick={handleDelete}
                    size="sm"
                  >
                    Delete
                    <SvgTrash />
                  </Button>
                )}

                <CloseModal
                  callback={() => {
                    setActiveNoteId(null)
                    setIsNewNote(false)
                    setShowNoteModal(false)
                  }}
                  styling="min-w-[32px] min-h-[20px] mr-[10px] position-fixed"
                />
              </div>
            </div>
            <div className="mb-4 md:mb-[24px] h-[1px] w-full bg-grayMist"></div>
            <div className="mb-4 md:mb-[24px]">
              <NoteModalDetailsCard
                isEditable={isNoteEditable}
                contact={contact}
                setSelectedProgram={setSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>

            {isNoteEditable ? (
              <div className="mb-[24px] rounded-[10px] px-[20px] py-[8px] border border-grayMist focus-within:border-royalBlue">
                <h6 className="text-base text-graySlate">Title</h6>
                <input
                  className="w-full outline-none"
                  type="textarea"
                  ref={titleInputRef}
                  placeholder="Enter a title for your note..."
                  value={titleValue}
                  onChange={handleTitleContentChange}
                />
              </div>
            ) : (
              <></>
            )}

            {isNoteEditable ? (
              <div className=" w-[100%]">
                <NoteEditor noteValue={noteValue} setNoteValue={setNoteValue} />
              </div>
            ) : (
              <Slate editor={editor} value={noteValue}>
                <Editable
                  readOnly={true}
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                />
              </Slate>
            )}
          </div>

          <div className="absolute top-[756px] z-[800] right-[888px]">
            {showNoteSavedToast && <NoteSavedToast isUpdate={true} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default NoteModal
