import React, { useContext } from "react"
import { SvgZoeeLogo } from "../../icons"
import { AccountTypeForm } from "./account-type-form"
import { RegisterFormContext } from "./context"
import { RegistrationForm } from "./registration-form"
import { PasswordForm } from "./password-form"
import { NameForm } from "./name-form"
import { PhoneForm } from "./phone-form"
import { SvgZoeeGradientLogo } from "../../icons/logos/zoee-gradient-logo"

export const Form: React.FC = () => {
  const { view, accountType } = useContext(RegisterFormContext)

  const renderForm = () => {
    if (view === "account-type") {
      return <RegistrationForm />
    } else if (view === "email") {
      return <RegistrationForm />
    } else if (view === "password") {
      return <PasswordForm />
    } else if (view === "name") {
      return <NameForm />
    } else if (view === "phone") {
      return <PhoneForm />
    } else {
      throw new Error("Invalid login view")
    }
  }

  return (
    <>
      <h2 className="-mt-4 mb-4 font-bold text-lg text-center capitalize text-blurple">
        {accountType !== "coach"
          ? "Create Free Member Account"
          : `Create ${accountType} Account`}
      </h2>

      <SvgZoeeGradientLogo className="mx-auto -mt-2 mb-4" />
      {renderForm()}
    </>
  )
}
