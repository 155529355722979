import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import NewSignIn from "./cm-images/NewSignIn.png"
import EmailInvite from "./cm-images/EmailInvite.png"
import ProfileDropdown from "./cm-images/ProfileDropdown.png"
import ProfileIcon from "./cm-images/ProfileIcon.png"
import AccountSettings from "./cm-images/AccountSettings.png"
import EditIcon from "./cm-images/EditIcon.png"
import ChangePW from "./cm-images/ChangePW.png"
import ForgotPW from "./cm-images/ForgotPW.png"
import PW from "./cm-images/PW.png"
import PWReset from "./cm-images/PWReset.png"
import UpdatePW from "./cm-images/UpdatePW.png"
import CompletePW from "./cm-images/CompletePW.png"
import PersonalInfo from "./cm-images/PersonalInfo.png"
import AboutMe from "./cm-images/AboutMe.png"

export default function CMPasswordPersonalInfo() {
  return (
    <SectionWrapper id="password-info">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Managing Your{" "}
          <span className="text-[#4750F5]">Password & Personal Info</span>
        </h1>
      </div>

      <p className="text-gray-700 mt-8 text-center">
        You can manage your account by{" "}
        <a
          href="#change-password"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          changing your password{" "}
        </a>
        or{" "}
        <a
          href="#update-info"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          updating your personal information
        </a>
        .
      </p>
      <div
        id="change-password"
        className="mt-8 bg-blue-50 p-6 rounded-lg w-full"
      >
        <h3 className="text-2xl font-semibold text-blue-700 text-center">
          Changing Passwords
        </h3>
        <p className="text-gray-700 mt-2">
          There are two ways to change your password in Zoee: from{" "}
          <a
            href="#change-from-settings"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            Account Settings{" "}
          </a>
          or from the{" "}
          <a
            href="#change-from-login"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            Sign-In Screen
          </a>
          .
        </p>
      </div>
      <h3
        id="change-from-settings"
        className="text-xl font-semibold text-gray-800 mt-8"
      >
        1. Changing Your Password From Account Settings
      </h3>
      <div className="bg-white p-6 rounded-lg">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li> Log in to Zoee.</li>
          <li>Click on your profile icon.</li>
        </ul>
        <div className="flex justify-center mt-4">
          <img
            src={ProfileIcon}
            alt="Profile Icon Example"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>
            Select <strong>Account Settings</strong> from the dropdown menu.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ProfileDropdown}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Navigate to the <strong>Login & Security</strong> section.
          </li>
        </ul>
        <div className="flex justify-center mt-4">
          <img
            src={AccountSettings}
            alt="Account Settings Example"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>Click on the edit icon.</li>
          <div className="flex justify-center mt-4">
            <img
              src={EditIcon}
              alt="Edit Icon Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Enter your old password, then input your new password.</li>
          <li>
            Click <strong>Update Password</strong> to save changes.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ChangePW}
              alt="Change Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <h3
        id="change-from-login"
        className="text-xl font-semibold text-gray-800 mt-8"
      >
        2. Changing Your Password from the Sign-In Screen
      </h3>
      <div className="bg-white p-6 rounded-lg mt-4">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Enter your email to sign in.</li>
          <li>
            Click on the <strong>Forgot Password</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PW}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>Follow the instructions in the email to reset your password.</li>
          <div className="flex justify-center mt-4">
            <img
              src={PWReset}
              alt="Password Reset Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Set your new password and click <strong>Continue</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={UpdatePW}
              alt="Update Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            A pop-up will appear confirming your new password has been set.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CompletePW}
              alt="Complete Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <div id="update-info" className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h3 className="text-xl font-semibold text-blue-700">
          1. Updating Profile Information
        </h3>
      </div>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Click on your profile icon and select{" "}
            <strong>Account Settings</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ProfileDropdown}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>
            Click on the <strong>Personal Info</strong> section.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PersonalInfo}
              alt="Personal Info Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>

        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>
            Here you can update your name, phone number, display name, timezone,
            and region.
          </li>
          <li>
            You can also add information in the <strong>About</strong> section.
          </li>
          <li>
            Click <strong>Save Changes</strong> to update your profile.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={AboutMe}
              alt="About Me Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
