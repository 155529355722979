import React, { useRef, useEffect, useState, useContext } from "react"
import ContactAvatar from "../ContactAvatar"
import ContactDropdown from "./ContactDropdown"
import ContactStatusTag from "../ContactStatusTag"
import useDate from "../../../hooks/useDate"
import useLoading from "../../../hooks/useLoading"
import { Button } from "../../ui/button"
import { getUserColor } from "../../../utils/getUserColor"
import { SvgChat, SvgChevronDown, SvgChevronUp } from "../../icons"
import { useNavigate, Link } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"
import { CreateTestimonial } from "../../Testimonials"

interface Props {
  contact: any
  setShowBilling: React.Dispatch<React.SetStateAction<boolean>>
}

const ContactPrimaryDetails: React.FC<Props> = ({
  contact,
  setShowBilling,
}) => {
  const { user } = useContext(UserCtx)
  const { startLoading, stopLoading } = useLoading()
  const {
    contact_date,
    contact_name,
    contact_type,
    about,
    user_color,
    public_profile_published,
    endpoint_slug,
    status,
  } = contact
  const navigate = useNavigate()
  const { smDate, mdDate } = useDate()
  const [openChatModal, setOpenChatModal] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const firstName = contact_name.split(" ")[0]
  const [createTestimonialOpen, setCreateTestimonialOpen] =
    useState<boolean>(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  const getStatus = () => {
    let profileStatus = "Unknown"
    if (status === "connected") {
      profileStatus = "active"
    } else if (status === "lead") {
      profileStatus = "lead"
    } else if (status === "zoee lead") {
      profileStatus = "zoee lead"
    } else if (status === "prospect") {
      profileStatus = "prospect"
    } else if (contact_type === "invited") {
      profileStatus = "invited"
    } else if (contact_type === "requested") {
      profileStatus = "requested"
    }
    return profileStatus
  }

  const getTagline = () => {
    let value = ""
    if (about?.title) {
      value = about.title
    } else if (contact_type === "invited") {
      value = `Invited ${smDate(contact_date)}`
    } else {
      if (contact.profile_type === "member") {
        value = "Zoee Member"
      } else {
        value = "Professional Coach"
      }
    }
    return value
  }

  const startMeetingChat = (contactId: string) => {
    const participantIds = [
      contactId.toString(),
      user.activeProfileId.toString(),
    ]
    setOpenChatModal(true)
    user.isCoach
      ? navigate(`/coach/inbox`, {
          state: {
            participantIds: participantIds,
          },
        })
      : navigate(`/member/inbox`, {
          state: {
            participantIds: participantIds,
          },
        })
  }

  const openDropDown = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsDropDownOpen((prevState) => !prevState)
  }

  const handleStartMessage = () => {
    startLoading()
    window.location.href = `/${user.activeProfile}/inbox?chat=${contact.profile_id}`
  }

  return (
    <div
      className={`main-shadow flex flex-col rounded-[16px] bg-white
            p-[16px] md:p-[24px] md:pb-[40px]`}
    >
      <div
        className={`h-[114px] w-full rounded-[12px] md:h-[140px] ${getUserColor(
          user_color
        )} relative mb-[44px] md:mb-[32px]`}
      >
        {/* <ContactStatusTag
          contactStatus={getStatus()}
          origin=""
          contactDate={contact_date}
        /> */}
        {/* commented out as it serves no purpose right now, ticket dev-733 */}
        <div
          className="absolute right-[8px] top-[8px] items-center gap-[6px] rounded-[8px]
                    bg-white px-[8px] py-[6px] text-base font-bold md:flex"
        >
          {`Connected since ${mdDate(contact_date)}`}
        </div>
        <div className="absolute left-[24px] top-[61px]">
          <div className="md:hidden">
            <ContactAvatar
              contact={contact}
              width={80}
              height={80}
              border="border border-[4px] border-white"
            />
          </div>
          <div className="hidden md:block">
            <ContactAvatar
              contact={contact}
              width={140}
              height={140}
              border="border border-[4px] border-white"
            />
          </div>
        </div>
      </div>
      <div className="mb-[73px] hidden w-full items-center justify-between md:flex">
        <div className="ml-[196px] flex flex-col gap-[8px]">
          <h4 className="text-[22px] font-bold">{contact_name}</h4>
          <p className="text-graySlate text-base">{getTagline()}</p>
          {Boolean(public_profile_published) && (
            <Link
              to={`/find-a-coach/${endpoint_slug}`}
              className="text-blurple mr-auto text-base font-bold"
            >
              View {firstName}'s Website
            </Link>
          )}
        </div>
        <div className="relative flex items-start gap-[12px]">
          {/* Schedule Button */}
          <Button
            variant="default"
            size="lg"
            className="flex items-center justify-between w-[240px]"
            onClick={openDropDown}
            ref={buttonRef}
          >
            <span className="truncate">{`Schedule with ${firstName}`}</span>
            {!isDropDownOpen ? <SvgChevronDown /> : <SvgChevronUp />}
          </Button>

          {/* Message & Testimonial Buttons */}
          <div className="flex flex-col items-start gap-2 w-[200px]">
            <Button
              variant="secondary"
              onClick={() => startMeetingChat(contact.profile_id)}
              size="lg"
              className="flex items-center justify-between w-full"
            >
              <span className="truncate">Message</span>
              <SvgChat fill="white" />
            </Button>
            {contact?.profile_type === "coach" && (
              <Button
                variant="secondary"
                onClick={() => setCreateTestimonialOpen(true)}
                size="lg"
                className="flex items-center justify-between w-full"
              >
                <span className="truncate">Write a Testimonial</span>
              </Button>
            )}
          </div>

          {isDropDownOpen && (
            <>
              {contact.profile_type === "coach" ? (
                <div
                  ref={dropdownRef}
                  className="absolute top-full left-0 -mt-12"
                >
                  <ContactDropdown contact={contact} />
                </div>
              ) : (
                <div ref={dropdownRef} className="absolute top-full left-0">
                  <ContactDropdown contact={contact} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {createTestimonialOpen && (
        <CreateTestimonial
          contact={contact}
          isOpen={setCreateTestimonialOpen}
        />
      )}
      {about && Object.keys(about).length > 0 && (
        <div className="mx-[24px] hidden flex-col md:flex">
          <p className="text-graySlate mb-[16px] text-base font-bold">About</p>
          <h4 className="mb-[16px] text-[18px] font-bold">{about?.quote}</h4>
          <p className="text-graySlate text-base">{about?.description}</p>
        </div>
      )}
      {public_profile_published ? (
        <div className="mx-auto mb-[20px] flex md:hidden">
          <Link
            to={`/find-a-coach/${endpoint_slug}`}
            className="text-blurple mr-auto font-bold"
          >
            View {firstName}'s' Website
          </Link>
        </div>
      ) : null}
      <div className="flex w-full sm:items-center justify-center gap-[12px] sm:flex-row flex-col relative md:hidden">
        <span className="flex w-full gap-[12px]">
          <Button
            className="flex gap-[10px] h-[40px] sm:hidden text-md"
            onClick={handleStartMessage}
            variant="secondary"
          >
            Message
            <SvgChat fill="white" />
          </Button>
          {contact?.profile_type === "coach" && (
            <Button
              variant="secondary"
              className="flex gap-[10px] h-[40px] sm:hidden text-md"
              onClick={() => setCreateTestimonialOpen(true)}
            >
              <span className="truncate">Testimonial</span>
            </Button>
          )}
        </span>
        <span className="relative">
          <Button
            variant="default"
            size="lg"
            className="flex items-center justify-between w-[240px] sm:mr-4"
            onClick={openDropDown}
            ref={buttonRef}
          >
            <span className="truncate">{`Schedule with ${firstName}`}</span>
            {!isDropDownOpen ? <SvgChevronDown /> : <SvgChevronUp />}
          </Button>

          {isDropDownOpen && (
            <div
              ref={dropdownRef}
              className="absolute top-full z-[999] bg-white shadow-lg border border-gray-200 w-[240px] 
        sm:left-1/2 sm:transform sm:-translate-x-1/2 
        xs:left-0 xs:w-full"
            >
              <ContactDropdown contact={contact} />
            </div>
          )}
        </span>

        <Button
          className="flex gap-[10px] h-[40px] hidden sm:flex text-md"
          onClick={handleStartMessage}
          variant="secondary"
        >
          Message
          <SvgChat fill="white" />
        </Button>
        {contact?.profile_type === "coach" && (
          <Button
            variant="secondary"
            onClick={() => setCreateTestimonialOpen(true)}
            size="lg"
            className="flex gap-[10px] h-[40px] hidden sm:flex text-md"
          >
            <span className="truncate">Write a Testimonial</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default ContactPrimaryDetails
