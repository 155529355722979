import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { ServiceRequests } from "../../../api/app.service"

type Props = {
  contact: any
}

const ContactDropdown: React.FC<Props> = ({ contact }) => {
  const navigate = useNavigate()
  const { endpoint_slug } = contact
  const { user } = React.useContext(UserCtx)
  const [coachServices, setCoachServices] = React.useState<any[]>([])
  const [clientServices, setClientServices] = React.useState<any[]>([])
  const [servicesLoading, setServicesLoading] = React.useState<boolean>(false)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { contact_id } = useParams()

  const getProfileServiceList = () => {
    if (user) {
      setServicesLoading(true)
      // REQUESTING COACH'S INFORMATION
      ServiceRequests.getServiceListClient({
        profile_id: parseInt(contact.profile_id),
        profile_type: "coach",
      })
        .then((data) => {
          setCoachServices(data.service_list)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })

      let coach_id = 0
      let client_id = 0

      if (!user.isCoach) {
        coach_id = contact.profile_id
        client_id = user.activeProfileId
      } else {
        if (contact.profile_type === "member") {
          coach_id = user.activeProfileId
          client_id = contact.profile_id
        } else {
          coach_id = contact.profile_id
          client_id = user.activeProfileId
        }
      }

      // REQUESTING CLIENT'S INFORMATION
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coach_id,
        member_profile_id: client_id,
      })
        .then((data) => {
          setClientServices(data.service_list)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          setServicesLoading(false)
        })
    }
  }

  const ConsultationHandleClick = () => {
    // ACTIVEPROFILE: CLIENT
    if (user.activeProfile === "member") {
      // DOES CLIENT HAVE AN ENROLLED CONSULTATION?
      if (clientServices.length > 0) {
        const consultationService = clientServices.find(
          (service: any) =>
            service.service_details.type === "consultation" &&
            service.service_status === "active"
        )
        if (consultationService) {
          handleScheduleSessionClick(consultationService, contact)
        } else {
          renderError("No active consultation service found for the client.")
        }
      } else {
        // DOES COACH HAVE ANY CONSULTATION SERVICES?
        if (coachServices.length > 0) {
          const coachConsultationService = coachServices.find(
            (service: any) => service.service_details.type === "consultation"
          )
          if (coachConsultationService) {
            navigate(
              `/member/contacts/${contact_id}/services/${coachConsultationService.service_id}`
            )
          } else {
            renderError("Coach's consultation service not found.")
          }
        } else {
          renderError("No services found for the coach.")
        }
      }
      // ACTIVEPROFILE: COACH
    } else if (user.activeProfile === "coach") {
      // DOES CLIENT HAVE AN ENROLLED CONSULTATION?
      if (clientServices.length > 0) {
        const consultationService = clientServices.find(
          (service: any) =>
            service.service_details.type === "consultation" &&
            service.service_status === "active"
        )
        if (consultationService) {
          handleScheduleSessionClick(consultationService, contact)
        } else {
          renderError(
            "No enrolled Consultation found. Please invite to Service!"
          )
        }
      } else {
        renderError(
          "No enrolled services found for the client. Please invite to service!"
        )
      }
    }
  }

  React.useEffect(() => {
    getProfileServiceList()
  }, [])

  const handleScheduleSessionClick = (service: any, contact: any) => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { service, contact },
    })
  }

  const BookSessionHandleClick = () => {
    navigate({
      pathname:
        user.activeProfile === "coach"
          ? `/${user.activeProfile}/contacts/${contact.profile_id}/services`
          : `/find-a-coach/${endpoint_slug}`,
      search: user.activeProfile !== "coach" ? "?services=true" : "",
    })
  }

  const BookSessionHandleClickCoach = () => {
    navigate({
      pathname: `/find-a-coach/${endpoint_slug}`,
      search: "?services=true",
    })
  }

  return (
    <div className="w-[240px] px-[16px] py-[12px] rounded-[12px] bg-white box-shadow-2">
      {/* <button
        onClick={ConsultationHandleClick}
        className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
      >
        <p>Consultation</p>
      </button> */}
      <button
        onClick={BookSessionHandleClick}
        className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
      >
        {user.activeProfile === "coach"
          ? "Invite to Service"
          : "See All Services"}
      </button>
      {contact.profile_type === "coach" && user.activeProfile === "coach" ? (
        <button
          onClick={BookSessionHandleClickCoach}
          className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
        >
          See All Services
        </button>
      ) : (
        <button
          onClick={ConsultationHandleClick}
          className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
        >
          <p>Consultation</p>
        </button>
      )}
    </div>
  )
}

export default ContactDropdown
