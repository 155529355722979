export const regions = [
    {value: "united_states", label: "United States"},
    {value: "canada", label: "Canada"},
    {value: "united_kingdom", label: "United Kingdom"},
    {value: "central_america", label: "Central America"},
    {value: "south_america", label: "South America"},
    {value: "europe", label: "Europe"},
    {value: "asia", label: "Asia"},
    {value: "africa", label: "Africa"},
    {value: "australia", label: "Australia"},
    {value: "new_zealand", label: "New Zealand"},
    {value: "caribbean", label: "Caribbean"},
]