import { FC, useState, useContext } from "react"
import ClientNotification from "../../client/ClientNotification"
import { Button } from "../../ui/button"
import { Switch } from "../../ui/switch"
import { SvgLightning } from "../../icons"
import { useAuthModal } from "../../auth/use-auth-modal"
import { UserCtx } from "../../../context/userContext"
import { useNavigate } from "react-router-dom"

const PricingToggleSection: FC = () => {
  const [isToggled, setIsToggled] = useState<boolean>(true)
  const [planType, setPlanType] = useState<string>("monthly")
  const { user } = useContext(UserCtx)
  const navigate = useNavigate()
  const { showRegister } = useAuthModal()
  const [clientNotification, setClientNotification] = useState(false)

  const handleToggle = () => {
    setIsToggled((prev) => !prev)

    setPlanType((prevPlanType) => {
      const newPlanType = prevPlanType === "monthly" ? "annual" : "monthly"
      return newPlanType
    })
  }

  const handleButtonClick = () => {
    if (user) {
      if (user.activeProfile === "coach") {
        navigate("/coach/subscription/plans", { state: { planType } })
      } else {
        setClientNotification(false)
        setTimeout(() => {
          setClientNotification(true)
        }, 0)
      }
    } else {
      showRegister({
        accountType: "coach",
        onSuccess: () => {
          navigate("/coach/subscription/plans", { state: { planType } })
        },
      })
    }
  }

  return (
    <section className="px-[24px] pb-[188px] md:pb-[212px]">
      {clientNotification && (
        <ClientNotification showNotification={clientNotification} />
      )}{" "}
      <div className="max-w-[862px] bg-white  mx-auto flex flex-col justify-center items-center py-[64px] rounded-[16px] border border-grayMist">
        <h3 className="font-bold text-[56px] text-grayCharcoal mb-[24px]">
          Pricing
        </h3>
        <div className="flex flex-col md:flex-row md:gap-[44px] gap-[8px] mb-[32px]">
          <div className="flex items-center gap-[10px]">
            <h4 className="font-bold text-[16px]">
              Annual
              <span className="text-green">&nbsp;(Save 16%)</span>
            </h4>
            <Switch defaultChecked={isToggled} onCheckedChange={handleToggle} />
            <h4 className="font-bold text-[16px]">Monthly</h4>
          </div>
          <div className="text-center">
            {isToggled ? (
              <>
                <h3 className="font-bold text-[48px]">
                  $49
                  <span className="text-base text-graySlate font-normal">
                    /month
                  </span>
                </h3>
              </>
            ) : (
              <>
                <h3 className="font-bold text-[48px]">
                  $490
                  <span className="text-base text-graySlate font-normal">
                    /year
                  </span>
                </h3>
              </>
            )}
          </div>
        </div>
        <div className="max-w-[596px] bg-[#DEC8FB] bg-opacity-40 flex items-center gap-[20px] rounded-[16px] px-[24px] py-[20px] mx-[36px] mb-[32px]">
          <div className="flex items-center justify-center bg-white rounded-full h-[40px] w-[40px] min-w-[40px]">
            <SvgLightning />
          </div>
          <div className="flex flex-col justify-center gap-[4px]">
            <h5 className="font-bold">Let&apos;s Do Life Together</h5>
            <p className="gray-charcoal text-base">
              With a Zoee membership, you&apos;ll get back to coaching clients
              and away from juggling your back office.
            </p>
          </div>
        </div>
        <Button onClick={handleButtonClick} size="lg">
          Start Now
        </Button>
      </div>
    </section>
  )
}

export default PricingToggleSection
