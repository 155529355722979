import { Outlet } from "react-router-dom"
import Footer from "../../components/marketing/footer/Footer"
import ClientManualNav from "./ClientManualNav"

export default function ClientManualLayout() {
  return (
    <>
      <ClientManualNav />
      <Outlet />
    </>
  )
}
