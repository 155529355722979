import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import MobileHamburger from "./cm-images/MobileHamburger.png"
import VideoNavMobile from "./cm-images/VideoNavMobile.png"
import JoinMobile from "./cm-images/JoinMobile.png"
import PermissionsMobile from "./cm-images/PermissionsMobile.png"
import RoomTestMobile from "./cm-images/RoomTestMobile.png"
import JoinNowMobile from "./cm-images/JoinNowMobile.png"
import RoomSettingsMobile from "./cm-images/RoomSettingsMobile.png"
import DeviceSettingsMobile from "./cm-images/DeviceSettingsMobile.png"
import DevicesMobile from "./cm-images/DevicesMobile.png"

export default function CMJoiningVideoSessionMobile() {
  return (
    <SectionWrapper id="sessions">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Joining Your{" "}
          <span className="text-[#4750F5]">Coaching Video Session</span>
        </h1>
      </div>
      <p className="text-gray-700 mt-8 text-center">
        Learn how to{" "}
        <a
          href="#when-to-join"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          join your session{" "}
        </a>
        ,{" "}
        <a
          href="#camera-setup"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          set up your camera and microphone{" "}
        </a>
        ,{" "}
        <a
          href="#screen-sharing"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          share your screen{" "}
        </a>
        , and{" "}
        <a
          href="#troubleshooting"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          troubleshoot connection issues
        </a>
        .
      </p>

      <h3
        id="when-to-join"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        When to Join Your Session
      </h3>
      <div className="bg-white p-6 rounded-lg mt-4">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            You can join your session up to 5 minutes before the scheduled start
            time.
          </li>
          <li>
            A <strong>"Join"</strong> button will appear once the session is
            available to enter.
          </li>
          <li>
            Your coach may already be in the session when you join or may enter
            shortly after.
          </li>
        </ul>
      </div>

      <h3
        id="joining-options"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Three Ways to Join Your Session
      </h3>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Option 1: From the Homepage
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Log in to your Zoee account.</li>
          <li>Locate the calendar section on the homepage.</li>
          <li>
            Find your scheduled session in the list of upcoming appointments.
          </li>
          <li>
            Click the <strong>"Join"</strong> button next to your session.
          </li>
          <li>
            You'll be redirected to the Video Hub, where your session will open.
          </li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Option 2: From My Calendar
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>

          <li>Select “My Calendar” from the emerging side menu.</li>
          <li>Find your coaching session on the calendar.</li>

          <li>
            Sessions ready to join will have a <strong>"Join"</strong> button
            visible.
          </li>
          <li>
            Click the <strong>"Join"</strong> button, and your session will
            automatically open in the Active Sessions panel.
          </li>
        </ul>
      </div>
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Option 3: From the Video Hub
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “Video Hub” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={VideoNavMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Look for your scheduled session in the Active Sessions panel.</li>
          <li>Confirm it's the correct session with your coach</li>
          <li>
            Click the <strong>"Join"</strong> button, and your session will
            automatically open.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={JoinMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <h3
        id="camera-setup"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Setting Up Your Camera and Microphone
      </h3>
      <div className="bg-white p-6 rounded-lg mt-4">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            When you first join, a pop up will appear asking you to allow Zoee
            to use your microphone and camera.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PermissionsMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Click either of the “Allow” options.</li>

          <li>You'll see a preview of your video before entering.</li>
          <div className="flex justify-center mt-4">
            <img
              src={RoomTestMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Ensure your face is clearly visible and well-lit.</li>

          <li>Speak briefly to check that your microphone is working.</li>
          <li>
            Look for the audio level indicator showing your voice is detected.
          </li>
          <li>
            Click "Join Session" after confirming your audio and video are
            working.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={JoinNowMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h3
        id="screen-sharing"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Sharing Your Screen During a Session
      </h3>
      <div className="bg-white p-6 rounded-lg mt-4">
        <p className="mt-[-24px] italic">
          Note: There is no screen sharing option on mobile devices(phones and
          tablets) at this time. If you plan to share your screen with your
          coach during your session, endeavor to join on a laptop or computer.
        </p>
      </div>

      <h3
        id="troubleshooting"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Troubleshooting Connection Issues
      </h3>
      <div className="bg-white p-6 rounded-lg mt-4">
        {/* Camera or Microphone Problems */}
        <h4 className="text-xl font-semibold text-gray-700">
          Camera or Microphone Problems
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li className="italic">If your coach can't see or hear you:</li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>Check Browser Permissions:</strong>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>
                  Click on the three dots on the top right of your browser.
                </li>
                <li>Scroll down to until you see settings.</li>
                <li>Click on settings.</li>
                <li>
                  Scroll down to the advanced section for the settings menu.
                </li>
                <li>Locate and click on “site settings”.</li>
                <li>
                  Click on camera and ensure the toggle is set to enabled.
                </li>
                <li>Go back to the previous page and select microphone.</li>
                <li>Ensure this is also enabled.</li>
              </ul>
            </li>
            <li>
              <strong>Verify Device Selection:</strong>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>
                  Click on the settings icon(⚙️)above the “Join Now” button.
                </li>
                <div className="flex justify-center mt-4">
                  <img
                    src={RoomSettingsMobile}
                    alt="Profile Dropdown Example"
                    className="w-full max-w-md rounded-lg"
                  />
                </div>
                <li>Click on Device Settings in the appearing video.</li>
                <div className="flex justify-center mt-4">
                  <img
                    src={DeviceSettingsMobile}
                    alt="Profile Dropdown Example"
                    className="w-full max-w-md rounded-lg"
                  />
                </div>
                <li>
                  Under the audio section, select what you want the default
                  audio setting to be.
                </li>
                <li>
                  This will apply to what device picks up your voice and where
                  you will hear audio from.
                </li>
                <div className="flex justify-center mt-4">
                  <img
                    src={DevicesMobile}
                    alt="Profile Dropdown Example"
                    className="w-full max-w-md rounded-lg"
                  />
                </div>
                <li>Click on the “X” to close the window.</li>
              </ul>
            </li>
            <li>
              <strong>Restart Your Browser:</strong> Close and reopen your
              browser, then try joining the session again.
            </li>
          </ul>
        </ul>
        <h4 className="text-xl font-semibold text-gray-700 mt-6">
          Session Access Issues
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li className="italic">If you can't join your scheduled session:</li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>Refresh the Page:</strong> Click the refresh button in
              your browser and try joining again.
            </li>
            <li>
              <strong>Check the Time:</strong> Verify that it's within 5 minutes
              of your scheduled session time. The "Join" button only appears
              close to the appointment time.
            </li>
            <li>
              <strong>Contact Your Coach:</strong> If you still can't join,
              message your coach through the Zoee inbox and let them know you're
              having difficulty joining the session.
            </li>
          </ul>
        </ul>

        <h4 className="text-xl font-semibold text-gray-700 mt-6">
          Internet Connection Problems
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li className="italic">
            If your video or audio is freezing or breaking up:
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>Check Your Internet Connection:</strong> Move closer to
              your Wi-Fi router if possible. Consider using a wired connection
              for better stability.
            </li>
            <li>
              <strong>Close Other Applications:</strong> Shut down any
              applications that might be using your internet bandwidth. Pause
              any downloads or streaming services.
            </li>
            <li>
              <strong>Turn Off Video Temporarily:</strong> If audio is more
              important, turn off your video to preserve bandwidth. Click the
              camera icon to toggle your video off.
            </li>
          </ul>
        </ul>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
