import React, { useRef, useState, useEffect } from "react"
import useOutsideClick from "../../hooks/useOutsideClick"
import { SvgCheckbox, SvgChevronDown } from "../icons/"

interface Props {
  contacts: any[]
  setContacts: React.Dispatch<React.SetStateAction<any[]>>
  selectedSort: string
  selectedFilter: string
  setSelectedSort: React.Dispatch<React.SetStateAction<string>>
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>
}

const ContactFilters: React.FC<Props> = ({
  contacts,
  setContacts,
  selectedSort,
  selectedFilter,
  setSelectedSort,
  setSelectedFilter,
}) => {
  const [sortByExpanded, setSortByExpanded] = useState<boolean>(false)

  const originalContacts = useRef<any[]>([])

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (originalContacts.current.length === 0) {
      originalContacts.current = contacts
    }
  }, [contacts])

  useOutsideClick(ref, () => {
    if (sortByExpanded) {
      setSortByExpanded(false)
    }
  })

  const toggleSortBy = () => {
    setSortByExpanded(!sortByExpanded)
  }

  const sortContactsBy = (sortValue: string) => {
    const sorted = [...contacts]

    if (sortValue === "first") {
      sorted.sort((a: any, b: any) => {
        const firstNameA = a.contact_name?.split(" ")[0] || ""
        const firstNameB = b.contact_name?.split(" ")[0] || ""
        return firstNameA.localeCompare(firstNameB, undefined, {
          sensitivity: "base",
        })
      })
    } else if (sortValue === "last") {
      sorted.sort((a: any, b: any) => {
        const lastNameA = a.contact_name?.split(" ")[1] || ""
        const lastNameB = b.contact_name?.split(" ")[1] || ""
        return lastNameA.localeCompare(lastNameB, undefined, {
          sensitivity: "base",
        })
      })
    }

    setContacts(sorted)
    setSelectedSort(sortValue)
  }

  const renderCheckbox = (sortValue: string) => (
    <div
      className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                  flex items-center justify-center ${
                    selectedSort === sortValue && "bg-blurple"
                  }`}
    >
      {selectedSort === sortValue && <SvgCheckbox />}
    </div>
  )

  return (
    <div className="flex items-center gap-[32px]">
      <div className="relative" ref={ref}>
        <button
          className="flex items-center gap-[8px] font-bold text-base"
          onClick={toggleSortBy}
        >
          Sort by
          <SvgChevronDown
            className={`${sortByExpanded ? "rotate-[180deg]" : ""}`}
          />
        </button>
        {sortByExpanded && (
          <div className="absolute right-0 top-[40px] py-[24px] px-[32px] bg-white rounded-[12px] main-shadow flex flex-col z-[300]">
            <span className="mb-[16px] text-graySlate text-base mr-auto">
              Sort by
            </span>
            <div className="w-[184px] flex flex-col gap-[10px] text-base">
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortContactsBy("none")}
              >
                None
                {renderCheckbox("none")}
              </button>
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortContactsBy("first")}
              >
                First Name
                {renderCheckbox("first")}
              </button>
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortContactsBy("last")}
              >
                Last Name
                {renderCheckbox("last")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactFilters
