import * as React from "react";
import { SvgZoeeLogo } from "../../icons";
import { LoginFormContext } from "./context";
import { EmailForm } from "./email-form";
import { OneTimePasswordForm } from "./one-time-password-form";
import { PasswordForm } from "./password-form";

export const Form: React.FC = () => {
  const { view } = React.useContext(LoginFormContext);

  const renderForm = () => {
    if (view === "email") {
      return <EmailForm />;
    } else if (view === "password") {
      return <PasswordForm />;
    // } else if (view === "one-time-password") {
    //   return <OneTimePasswordForm />;
    } else {
      throw new Error("Invalid login view");
    }
  };

  return (
    <>
      <h2 className="mb-6 font-bold text-lg text-center">Sign In</h2>
      <SvgZoeeLogo className="mx-auto mb-6" />
      {renderForm()}
    </>
  );
};
