import React, { useContext, useState } from "react"
import ContactAvatar from "../cards/ContactAvatar"
import InfoModal from "../InfoModal"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  SvgBusiness,
  SvgChevronRight,
  SvgHelp,
  SvgIdea,
  SvgLogout,
  SvgSettings,
  SvgStar,
} from "../icons"
import { useAuthModal } from "../auth/use-auth-modal"
import { UserCtx } from "../../context/userContext"

type Props = {
  setShowMobileNav: any
}

export default function MobileNav({ setShowMobileNav }: Props) {
  const { user } = useContext(UserCtx)
  const [showReferCoachComingSoon, setShowReferCoachComingSoon] =
    useState<boolean>(false)
  const userContactObj = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    has_avatar: user.hasAvatar,
    profile_id: user.activeProfileId,
    user_color: user.userColor,
    user_id: parseInt(user.user_id),
  }

  const location = useLocation()
  const { logout } = useAuthModal()

  const handleSelect = () => {
    setShowMobileNav(false)
  }

  return (
    <>
      {showReferCoachComingSoon && (
        <InfoModal
          setShowModal={setShowReferCoachComingSoon}
          header={`Exciting News! 🎉`}
          helpText={`This feature is currently in the works and will be coming soon.`}
          confirmButtonText="OK"
          isWarningVariant={false}
        />
      )}
      <div className="z-40 top-0 left-0 bg-white min-w-full min-h-screen overflow-scroll">
        <div className="mt-[48px] px-[32px] flex items-center gap-[14px] mb-[32px]">
          <ContactAvatar
            width={48}
            height={48}
            bold={false}
            contact={userContactObj}
            fontSize="text-[18px]"
          />
          <div>
            <h3 className="font-bold">
              {user.firstName} {user.lastName}
            </h3>
          </div>
        </div>

        <div className="px-[20px] pb-[120px]">
          {user.isCoach === true && (
            <div className="flex flex-col">
              <Link
                to="/coach"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgBusiness className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Manage Business
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>
              <Link
                to="/coach/subscription"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgStar className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    My Subscription
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>
              <Link
                to="/affiliate"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={(e) => {
                  e.preventDefault()
                  setShowReferCoachComingSoon(!showReferCoachComingSoon)
                }}
              >
                <div className="flex items-center">
                  <SvgStar className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Refer a Coach
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>

              <Link
                to="/account-settings"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgSettings fill="black" className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Account settings
                  </div>
                </div>
                <SvgChevronRight />
              </Link>
              <Link
                to="/feature-request"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgIdea className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Feature request
                  </div>
                </div>
                <SvgChevronRight />
              </Link>
              <a
                href="https://intercom.help/zoee/en"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center">
                  <SvgHelp className="mr-[16px] intercom-custom-launcher" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Support
                  </div>
                </div>
                <SvgChevronRight />
              </a>
              <Link
                to="#"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgLogout className="mr-[16px]" />
                  <div
                    className="text-black text-[18px] font-normal group-hover:underline underline-offset-1"
                    onClick={logout}
                  >
                    Log out
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>
            </div>
          )}

          {/* MEMBER MOBILE NAV */}
          <div className="px-[20px]">
            {user.isCoach === false && (
              <div className="pb-[24px] flex flex-col">
                <Link
                  to="/account-settings"
                  className="flex items-center group w-full justify-between py-[20px] border-t border-b border-grayMist"
                  onClick={handleSelect}
                >
                  <div className="flex items-center">
                    <SvgSettings fill="black" className="mr-[16px]" />
                    <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                      Account settings
                    </div>
                  </div>
                  <SvgChevronRight className="" />
                </Link>
                <Link
                  to="/feature-request"
                  className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                  onClick={handleSelect}
                >
                  <div className="flex items-center">
                    <SvgIdea className="mr-[16px]" />
                    <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                      Feature request
                    </div>
                  </div>
                  <SvgChevronRight />
                </Link>
                <a
                  href="https://intercom.help/zoee/en"
                  className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex items-center">
                    <SvgHelp className="mr-[16px] intercom-custom-launcher" />
                    <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                      Support
                    </div>
                  </div>
                  <SvgChevronRight />
                </a>
                <Link
                  to="#"
                  className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                  onClick={handleSelect}
                >
                  <div className="flex items-center">
                    <SvgLogout className="mr-[16px]" />
                    <div
                      className="text-black text-[18px] font-normal group-hover:underline underline-offset-1"
                      onClick={logout}
                    >
                      Log out
                    </div>
                  </div>
                  <SvgChevronRight className="" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
