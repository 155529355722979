import React, { useContext } from "react"
import ServiceCardBannerOverlay from "./ServiceCardBannerOverlay"
import { Button } from "../ui/button"
import { getSessionType } from "../../utils/services/get-session-type/get-session-type"
import { getPrice } from "../../utils/services/get-price"
import { getUnits } from "../../utils/services/get-units/get-units"
import { getSessionTypeImage } from "../../utils/services/get-session-type-image"
import { pluralize } from "../../utils/pluralize"
import { SvgCheckCircle } from "../icons"
import { UserCtx } from "../../context/userContext"

interface Props {
  service: any
  buttonText: string
  buttonCallback: React.MouseEventHandler<HTMLButtonElement>
  secondaryButtonCallback: any
  showUsageSummary?: boolean
  setServiceUsage?: any
  markCompletedButton?: React.ReactNode
  contact: any
}

const ServiceCardPreview: React.FC<Props> = ({
  service,
  buttonText,
  buttonCallback,
  secondaryButtonCallback,
  showUsageSummary,
  setServiceUsage,
  markCompletedButton,
  contact,
}) => {
  const { billing, expiration, image, session, title, type, symbol } =
    service.service_details
  const { user } = useContext(UserCtx)

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(type)) {
      return `${session.count} session`
    } else if (["bundle"].includes(type)) {
      return pluralize(session?.count, "session")
    } else if (["recurring"].includes(type)) {
      return (
        <div>
          {pluralize(session?.count, "session")}
          <span className="text-darkGray">/ {`${billing?.unit}`}</span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration"
    if (["consultation", "individual", "bundle"].includes(type)) {
      if (expiration?.expires) {
        expirationString = `Expires after ${expiration?.time} ${expiration?.type}`
      } else {
        expirationString = "No expiration"
      }
    } else if (["recurring"].includes(type)) {
      const units = expiration?.type === "weeks" ? "week" : "month"
      expirationString = `${expiration?.time} ${units} program`
    } else {
      expirationString = "Expiration not found"
    }
    return expirationString
  }

  return (
    <>
      <div
        className={`w-full main-shadow rounded-[16px] relative pb-[24px] md:pb-[32px] pt-[146px] ${
          showUsageSummary ? "min-h-[687px]" : "min-h-[500px]"
        }`}
      >
        <div
          className={`w-full h-[120px] rounded-t-[16px] absolute top-0 left-0 ${image?.color}`}
        >
          <ServiceCardBannerOverlay service={service} />
          {image.exists && (
            <img
              src={`https://clientservice-images-${
                process.env.REACT_APP_USER_ENVIRONMENT
              }.s3.amazonaws.com/${
                service.stripe_product_id
              }?t=${new Date().getTime()}`}
              alt="service"
              className="w-full h-full object-cover rounded-t-[16px]"
            />
          )}
        </div>

        <div className="w-full mx-auto flex flex-col px-[24px] h-full justify-between">
          {/* TITLE AND PRICE */}
          <div className="flex items-center w-full justify-between mb-[20px] md:mb-[24px] flex-grow min-h-[54px]">
            <h3 className="font-bold text-[18px] max-w-[200px] truncate">{title}</h3>
            <h4 className="font-bold text-[15px] max-w-[200px]"> </h4>
            <h3 className="font-bold text-[22px] text-blurple">
              {getPrice(parseInt(billing?.amount), symbol)}
              <span className="text-graySlate text-base font-normal">
                {getUnits(billing.unit, parseInt(billing?.amount), type)}
              </span>
            </h3>
          </div>

          <div className="flex flex-col h-full">
            <div className="w-full h-[1px] bg-grayMist mb-[16px]"></div>
            {/* PROGRAM DETAILS */}

            <div className="flex items-center justify-between w-full mb-[16px] text-base font-bold">
              <div className="w-[142px] h-[32px] bg-grayFlash rounded-[8px] flex items-center justify-center">
                Program Details
              </div>
              <div className="flex items-center gap-[8px]">
                {getSessionTypeImage(type)}
                {getSessionType(type)}
              </div>
            </div>
            <div
              className={`flex flex-col mr-auto gap-[12px] text-base ${
                showUsageSummary ? "" : "mb-[32px]"
              }`}
            >
              <div className="flex items-center gap-[12px]">
                <SvgCheckCircle />

                {getSessionCount()}
              </div>
              <div className="flex items-center gap-[12px]">
                <SvgCheckCircle />
                <div>
                  {parseInt(session?.duration) / 60} minutes
                  <span className="text-darkGray">/ session</span>
                </div>
              </div>
              <div className="flex items-center gap-[12px]">
                <SvgCheckCircle />
                {getLengthOrExpiration()}
              </div>
            </div>

            {showUsageSummary && (
              <>
                <div className="w-full h-[1px] bg-grayMist my-[18px]"></div>
                <div className="mb-[40px] flex flex-col items-center w-full">
                  <div className="w-full justify-between flex items-center mb-[40px]">
                    <div className="rounded-[8px] bg-grayFlash py-[4px] px-[8px] flex items-center justify-center font-bold text-[16px]">
                      Usage Summary
                    </div>
                    {!service.is_group_service && (
                      <Button
                        onClick={() =>
                          setServiceUsage({ show: true, service: service })
                        }
                        variant="link"
                      >
                        View details
                      </Button>
                    )}
                  </div>
                  <div className="flex justify-evenly items-center w-full">
                    {!service.is_group_service ? (
                      <>
                        <div className="flex flex-col">
                          <span className="font-bold text-[32px] mb-[4px]">
                            {service?.redeemed_session_count || 0}
                          </span>
                          <span className="font-bold text-base text-grayCharcoal">
                            Completed
                          </span>
                        </div>
                        <div className="w-[1px] h-[50px] bg-graySlate"></div>
                        <div className="flex flex-col">
                          <span className="font-bold text-[32px] mb-[4px]">
                            {service?.scheduled_session_count || 0}
                          </span>
                          <span className="font-bold text-base text-grayCharcoal">
                            Scheduled
                          </span>
                        </div>
                        <div className="w-[1px] h-[50px] bg-graySlate"></div>
                        <div className="flex flex-col">
                          <span className="font-bold text-[32px] mb-[4px]">
                            {service?.available_session_count || 0}
                          </span>
                          <span className="font-bold text-base text-grayCharcoal">
                            Unused
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-col">
                          <span className="font-bold text-[32px] mb-[4px]">
                            {service?.group_completed_count
                              ? service?.group_completed_count
                              : 0}
                          </span>
                          <span className="font-bold text-base text-grayCharcoal">
                            Completed
                          </span>
                        </div>
                        <div className="w-[1px] h-[50px] bg-graySlate"></div>
                        <div className="flex flex-col">
                          <span className="font-bold text-[32px] mb-[4px]">
                            {service?.group_scheduled_count
                              ? service.group_scheduled_count
                              : 0}
                          </span>
                          <span className="font-bold text-base text-grayCharcoal">
                            Scheduled
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="flex items-center justify-between gap-[10px]">
              {/* {service?.is_group_service && user.activeProfile === 'coach' ?
                <button className="btn-primary w-full h-[48px] text-base" disabled = {true}>
                  Meetings are scheduled
                </button>
               : ( */}

              <Button
                onClick={() => buttonCallback(service)}
                className="w-full"
                disabled={
                  service.service_status === "past" ||
                  service.available_session_count < 1 ||
                  buttonText === "Meetings are Scheduled"
                }
              >
                {buttonText}
              </Button>
              {/* )} */}

              {user.activeProfile === "member" &&
                service.service_status === "pending" && (
                  <button
                    onClick={() => secondaryButtonCallback(service)}
                    className="btn-primary btn-outline-primary w-full
                                    h-[48px]"
                  >
                    Decline
                  </button>
                )}

              {markCompletedButton && markCompletedButton}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceCardPreview
