import React, { useState } from "react"
import GeneralSignInForm from "./GeneralSignInForm"
import { LoginFormProvider } from "../login-form/context"
import { OneTimePasswordForm } from "../login-form/one-time-password-form"
import { SvgZoeeLogoTM, SvgZoeeLogoGradiant } from "../../icons"

const GeneralSignIn = () => {
  const [showOtpForm, setShowOtpForm] = useState(false)
  const [email, setEmail] = useState<string>("")

  const handleSendOtp = (email: string) => {
    setEmail(email)
    setShowOtpForm(true)
  }

  const handleBackToSignIn = () => {
    setShowOtpForm(false)
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen w-full bg-gradient-to-b from-[#8A90FF] via-[#4750F5] to-[#292F8F] p-4 overflow-x-hidden">
      {" "}
      <div className="w-full max-w-md md:mt-6">
        {showOtpForm ? (
          <LoginFormProvider defaultEmail="" onSuccess={() => true}>
            <OneTimePasswordForm
              email={email}
              profileType="coach"
              onBack={handleBackToSignIn}
            />
          </LoginFormProvider>
        ) : (
          <LoginFormProvider defaultEmail="" onSuccess={() => true}>
            <GeneralSignInForm onSendOtp={handleSendOtp} />
          </LoginFormProvider>
        )}
      </div>
    </div>
  )
}

export default GeneralSignIn
