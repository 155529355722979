import React from "react"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import { UserCtx } from "../../context/userContext"

const PrivateRouteStorageAvailable = ({ homePath = "/home" }) => {
  const { user } = React.useContext(UserCtx)
  const location = useLocation()
  const pathname = location.pathname
  const [searchParams] = useSearchParams()
  const queryString = searchParams.toString()
  const fullRedirectURL = queryString ? `${pathname}?${queryString}` : pathname

  let route
  if (isLocalStorageAvailable()) {
    if (user) {
      route = <Outlet />
    } else {
      if (pathname.includes("/member")) {
        localStorage.setItem("memberRedirect", fullRedirectURL)
      } else if (pathname.includes("/coach")) {
        localStorage.setItem("coachRedirect", fullRedirectURL)
      }

      route = (
        <Navigate
          to="/sign_in"
          state={{
            storageAvailable: true,
            pathname,
            searchParams: Object.fromEntries(searchParams.entries()),
          }}
        />
      )
    }
  } else {
    route = <Navigate to={homePath} state={{ storageAvailable: false }} />
  }

  return route
}

export default PrivateRouteStorageAvailable
