import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from "react"
import CharLimit from "../ui/charLimit"
import CloseModal from "../CloseModal"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests, SessionsRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"
import { SvgCheckbox } from "../icons"

interface Props {
  roomId?: any
  coachId?: any
  isOpen: Dispatch<SetStateAction<boolean>>
  contact?: any
}

const CreateTestimonial: React.FC<Props> = ({
  roomId,
  isOpen,
  coachId,
  contact,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const [coachPublicProfileData, setCoachPublicProfileData] = useState<any>()
  const { user } = useContext(UserCtx)
  const [testimonialText, setTestimonialText] = useState<string>("")
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const [lastName, setLastName] = useState<string>()
  const [firstName, setFirstName] = useState<string>()

  const getCoachPrivateProfile = async () => {
    const coachProfileId =
      Array.isArray(coachId) && coachId.length > 0
        ? coachId[0]
        : contact?.profile_id

    if (!coachProfileId) {
      console.error("Error: coachId and contact.profile_id are both undefined")
      return
    }

    try {
      const data = await ProfileRequests.getCoachPrivateProfile({
        coach_profile_id: coachProfileId,
      })
      setCoachPublicProfileData(data)
      setFirstName(`${data?.coach_public_profile?.first_name || ""}`)
      setLastName(`${data?.coach_public_profile?.last_name || ""}`)
    } catch (ex) {
      console.error("Error fetching coach profile:", ex)
    }
  }

  const createCoachTestimonials = async () => {
    setIsLoading(true)
    try {
      const data = await ProfileRequests.createTestimonial({
        coach_public_profile_id:
          coachPublicProfileData?.coach_public_profile?.coach_public_profile_id,
        testimonial: testimonialText,
        client_name: anonymous ? "" : `${user.firstName}${" "}${user.lastName}`,
        is_shown: false,
      })
      setIsLoading(false)
      renderSuccess("Testimonial has been sent successfully!")
      isOpen(false)
    } catch (ex: any) {
      setIsLoading(false)
      if (ex.response && ex.response.data) {
        renderError(ex.response.data.message)
      } else {
        console.error("An error occurred:", ex)
        renderError("An unexpected error occurred. Please try again later.")
      }
    }
  }

  const updateSendReview = () => {
    SessionsRequests.updateSendReview({
      send_review: false,
      room_id: roomId || null,
    })
      .then(() => {
        isOpen(false)
      })
      .catch((ex) => {
        console.log(ex)
      })
  }

  useEffect(() => {
    getCoachPrivateProfile()
  }, [])

  const handleCancel = () => {
    isOpen(false)
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
      <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] max-h-[75vh] bg-white fixed flex flex-col items-start overflow-y-auto">
        <div className="h-full w-full flex flex-col justify-center items-center mb-6">
          <CloseModal
            callback={() => {
              updateSendReview()
            }}
            styling="absolute top-4 right-4 z-[100]"
          />
          <h1 className="text-[20px] font-bold text-center -translate-y-4">
            🌟 Would you like to share your experience with {firstName}{" "}
            {lastName}? 🌟
          </h1>
          <p className="w-[90%] text-center text-charcoalBlack">
            Leave a testimonial to help others learn about working with{" "}
            {firstName}.
          </p>
          <div className="w-[90%] flex mt-6 justify-between items-end">
            <div className="flex flex-row gap-4  items-center w-[50%] mr-auto">
              <div
                className="min-w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent flex items-center justify-center text-white"
                onClick={() => setAnonymous(!anonymous)}
              >
                {anonymous && <SvgCheckbox />}
              </div>
              <h2 className="text-sm">Remain anonymous</h2>
            </div>
            <div className="ml-auto text-sm text-right">
              <CharLimit charNumber={500} inputText={testimonialText} />
            </div>
          </div>
          <div className="w-full h-92 flex flex-col justify-center items-center mt-2">
            <textarea
              name="about"
              cols={30}
              rows={8}
              value={testimonialText}
              maxLength={500}
              onChange={(e) => setTestimonialText(e.target.value)}
              className={`whitespace-pre-line resize-none border-[1px] border-solid 
                                    text-graySlate text-[16px] bg-white w-[90%]
                                    py-[16px] px-[20px] rounded-[10px] focus:outline-0
                                    focus:border focus:border-solid focus:border-royalBlue
                                    focus:text-black border-grayCloud mb-6`}
              placeholder="Start typing your testimonial here!"
            ></textarea>

            <div className="flex gap-2">
              <Button onClick={handleCancel} variant="ghost" size={"lg"}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (testimonialText.trim() !== "") {
                    createCoachTestimonials()
                    updateSendReview()
                  }
                }}
                size={"lg"}
                disabled={testimonialText.trim() === ""}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTestimonial
