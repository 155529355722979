export const getUnits = (unit: any, amount?: any, type?: any) => {
  if (amount === 0) return ""

  if (["bulk", "installment"].includes(unit)) {
    return " / for program"
  } else if (["month", "monthly"].includes(unit)) {
    return " / month"
  } else if (["year", "yearly"].includes(unit)) {
    return " / year"
  } else if (["week", "weekly"].includes(unit)) {
    return " / week"
  } else if (type && ["consultation", "individual"].includes(type)) {
    return " / per session"
  }

  return ""
}