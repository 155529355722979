import * as React from "react"

const CoachHowTos: React.FC = () => {
  const guides = [
    {
      title: "Get to know Zoee - Overview",
      link: "https://intercom.help/zoee/en/articles/8754581-get-to-know-zoee",
    },
    {
      title: "Setting up Calendars and Availabilities",
      link: "https://intercom.help/zoee/en/articles/8685614-calendar-integration-for-scheduling-and-video-sessions",
    },
    {
      title: "Service Creation",
      link: "https://intercom.help/zoee/en/articles/9307848-creating-a-service",
    },
    {
      title: "Create a Form",
      link: "https://intercom.help/zoee/en/articles/9294036-forms-coach-perspective-part-1",
    },
    {
      title: "Schedule, Invite, and Hosting Sessions",
      link: "https://intercom.help/zoee/en/articles/8107152-schedule-invite-and-host-a-session1",
    },
    {
      title: "Coach to Coach Services",
      link: "https://intercom.help/zoee/en/articles/10029577-coach-to-coach-connections",
    },
    {
      title: "How to get paid",
      link: "https://intercom.help/zoee/en/articles/8685596-how-to-get-paid-with-zoee",
    },
  ]


  return (
    <>
      <div className="main flex h-[551px] min-w-[299px] flex-col rounded-[20px] bg-white shadow md:max-w-[300px]">
        <div
          className="flex h-[25px] items-center justify-center rounded-t-[16px] px-[24px] text-4 font-bold"
          style={{ backgroundColor: "#4750F5" }}
        ></div>
        <h1 className="-mb-[10px] px-4 py-4 pt-[7px] text-lg font-bold">
          Get started with helpful guides and videos.
        </h1>
        <div className="break-words px-4 py-4 pb-4">
          {guides.map((guide, index) => (
            <a
              key={index}
              href={guide.link}
              className="block w-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="mb-2 w-full text-left py-2 px-2 border border-gray-300 transition-colors duration-200 hover:border-[#4750F5] hover:text-[#4750F5] hover:font-bold">
                {guide.title}
              </div>
            </a>
          ))}
        </div>
        <p className="px-4 py-4 pt-[7px] text-xs italic">

          </p>
      </div>
    </>
  )
}

export default CoachHowTos
