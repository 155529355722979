import React, { useContext, useState, useEffect } from "react"
import SelectSessionOrProgramModal from "./SelectSessionOrProgramModal"
import { UserCtx } from "../../context/userContext"
import useLoading from "../../hooks/useLoading"
import { ServiceRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgEdit, SvgPlus, SvgTrash } from "../icons"

// TODO: Add props and dynamic data to component
type Props = {
  isEditable: boolean
  contact: any
  setSelectedProgram: any
  selectedProgram: any
}

const NoteModalDetailsCard: React.FC<Props> = ({
  isEditable,
  contact,
  setSelectedProgram,
  selectedProgram,
}) => {
  // Next 2 variables are temporary variables for debugging component.
  // TODO: Need to replace with useState variables when shape of incoming data is clearer.
  const { user } = useContext(UserCtx)

  const [showSelectModal, setShowSelectModal] = useState<boolean>(false)
  const [isSelectSessionModalOpen, setIsSelectSessionModalOpen] =
    useState<boolean>(false)
  const [isSelectProgramModalOpen, setIsSelectProgramModalOpen] =
    useState<boolean>(false)
  const [services, setServices] = useState<any[]>([])
  const { startLoading, stopLoading } = useLoading()
  const { renderError } = useContext(CommonFunctionCtx)

  type ModalOptions = "Session" | "Program"

  const handleOpenClick = (option: ModalOptions): void => {
    if (option === "Session") {
      setIsSelectSessionModalOpen(true)
    } else if (option === "Program") {
      setIsSelectProgramModalOpen(true)
    }
    setShowSelectModal(true)
  }

  const getTypeCapitalized = () => {
    const str = selectedProgram?.service_details?.type
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)
  }

  const getProfileServiceList = () => {
    // if logged_in user = member => coach = contact & member/client = logged in
    // else (if contatct = member then coach = logged in  & member = contact else )
    let coach_id = 0
    let client_id = 0

    if (!user.isCoach) {
      coach_id = contact.profile_id
      client_id = user.activeProfileId
    } else {
      if (contact.profile_type === "member") {
        coach_id = user.activeProfileId
        client_id = contact.profile_id
      } else {
        coach_id = contact.profile_id
        client_id = user.activeProfileId
      }
    }
    if (user && contact) {
      startLoading()
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coach_id,
        member_profile_id: client_id,
      })
        .then((data) => {
          const filteredServices = data.service_list.filter((service: any) =>
            ["active", "past"].includes(service.service_status)
          )
          setServices(filteredServices)
          stopLoading()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  useEffect(() => {
    getProfileServiceList()
  }, [])

  return (
    <>
      <div className="border border-grayMist rounded-[16px] py-[16px]">
        {/* HIDE SESSION DETAILS FOR 2.0 */}
        {/* <div className="relative px-[16px] pb-[16px] border-b border-grayMist">
                    <h6 className="text-base text-graySlate">
                        Session details
                    </h6>
                    {isSessionDetails ? (
                        <div className="flex gap-[24px] mt-[12px]">
                            <div className="flex items-center px-[8px] py-[4px] rounded-[8px] bg-[#8ED1FC3D]">
                                <img
                                    className=""
                                    src={video}
                                    alt="video icon"
                                />
                                <p className="text-base font-bold text-blurple">
                                    Lasted 27 mins
                                </p>
                            </div>
                            <div className="flex items-center text-base text-graySlate">
                                <p>Started at</p>
                                <p className="text-black font-bold">
                                    &nbsp;9:00 AM
                                </p>
                                <p className="mx-[12px]">-</p>
                                <p>Ended at</p>
                                <p className="text-black font-bold">
                                    &nbsp;9:27: AM
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p className="mt-[4px] text-base font-bold">
                            Not specified
                        </p>
                    )}

                    {isEditable && !isSessionDetails ? (
                        <div className="absolute right-[24px] bottom-[22px]">
                            <button onClick={() => handleOpenClick("Session")}>
                                <img src={plusBlue} alt="blue plus sign" />
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}

                    {isEditable && isSessionDetails ? (
                        <div className="absolute flex items-center gap-[16px] right-[24px] bottom-[32px]">
                            <button>
                                <SvgTrash  />
                            </button>
                            <button onClick={() => handleOpenClick("Session")}>
                                <SvgEdit  />
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </div> */}

        <div className="relative px-[16px]">
          <h6 className="text-base text-graySlate">Program details</h6>
          {selectedProgram ? (
            <div className="flex gap-[16px] mt-[12px] items-center">
              <div
                className={`w-[48px] h-[48px] rounded-full relative flex items-center justify-center
                                ${selectedProgram?.service_details?.image?.color}`}
              >
                {selectedProgram?.service_details?.image?.exists ? (
                  <img
                    src={`https://clientservice-images-${
                      process.env.REACT_APP_USER_ENVIRONMENT
                    }.s3.amazonaws.com/${
                      selectedProgram.stripe_product_id
                    }?${new Date().getTime()}`}
                    alt="service"
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <>{selectedProgram?.service_details?.title?.charAt(0)}</>
                )}
              </div>
              <div className="flex flex-col">
                <h4 className="font-bold text-base">
                  {selectedProgram?.service_details?.title}
                </h4>
                <p className="text-base text-graySlate">
                  {getTypeCapitalized()}
                </p>
              </div>
            </div>
          ) : (
            <p className="mt-[4px] text-base font-bold">Not specified</p>
          )}

          {!selectedProgram && isEditable && services.length !== 0 ? (
            <div className={`absolute right-[24px] top-[10px]`}>
              <button onClick={() => handleOpenClick("Program")}>
                <SvgPlus fill="#4750F5" />
              </button>
            </div>
          ) : (
            <></>
          )}

          {isEditable && selectedProgram ? (
            <div className="absolute flex items-center gap-[16px] right-[24px] top-[28px] -mt-8">
              <button onClick={() => setSelectedProgram(null)}>
                <SvgTrash fill="#ABB8C3" />
              </button>
              <button onClick={() => handleOpenClick("Program")}>
                <SvgEdit fill="#ABB8C3" />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {isSelectSessionModalOpen && showSelectModal ? (
        <SelectSessionOrProgramModal
          sessionOrProgram="Session"
          setShowSelectModal={setShowSelectModal}
          contact={contact}
          setSelectedProgram={setSelectedProgram}
        />
      ) : (
        <></>
      )}

      {isSelectProgramModalOpen && showSelectModal ? (
        <SelectSessionOrProgramModal
          sessionOrProgram="Program"
          setShowSelectModal={setShowSelectModal}
          contact={contact}
          setSelectedProgram={setSelectedProgram}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default NoteModalDetailsCard
