import React, { useContext } from "react"
import CloseModal from "../../components/CloseModal"
import { UserCtx } from "../../context/userContext"

interface Props {
  setShowVideoDetails: any
}

const VideoDetails: React.FC<Props> = ({ setShowVideoDetails }) => {
  const { user } = useContext(UserCtx)

  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
    >
      <div
        className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                        main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
      >
        <CloseModal
          callback={setShowVideoDetails}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Your Private Video Hub: Connect Your Way
        </h1>
        <div className="w-full flex flex-col gap-[40px] text-[18px]">
          {user.activeProfile === "coach"
            ? "Create instant sessions or schedule meetings with clients in your secure video space—all without leaving Zoee! Launch quick calls when inspiration strikes or plan ahead with scheduled sessions that automatically appear in everyone's calendar. Your professional video hub eliminates app-switching and keeps all your client communications in one place. "
            : "Schedule meetings with coaches in your secure video space—all without leaving Zoee! Your professional video hub eliminates app-switching and keeps all your client communications in one place. "}
          <p>
            Check out our{" "}
            <a
              href="https://intercom.help/zoee/en/articles/8107152-schedule-invite-and-host-a-session"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-blurple underline inline-block"
            >
              how-to guide
            </a>{" "}
            to get started in minutes and{" "}
            {user.activeProfile === "coach"
              ? "to unlock all the powerful features that make connecting with clients effortless."
              : "to unlock all the powerful features that make connecting with coaches effortless."}
          </p>
        </div>
      </div>
    </div>
  )
}

export default VideoDetails
