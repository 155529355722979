import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from "react"
import CloseModal from "../CloseModal"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { Link } from "react-router-dom"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { SvgEye, SvgEyeOff, SvgMarketplace } from "../icons/"
import { UserCtx } from "../../context/userContext"

interface Props {
  isOpen: Dispatch<SetStateAction<boolean>>
}

const ProfileVisibilityModal: React.FC<Props> = ({ isOpen }) => {
  const { coachPublicProfileData, getCoachPrivateProfile } = useContext(
    CoachPrivateProfileContext
  )
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { user } = useContext(UserCtx)
  const { activeSubscription } = useContext(SubscriptionCtx)

  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  const [published, setPublished] = useState<boolean>(
    coachPublicProfileData?.published
  )
  const [marketVis, setMarketVis] = useState<boolean>(
    coachPublicProfileData?.marketplace_visibility
  )
  const [subscription, setSubscription] = useState<boolean>(false)

  useEffect(() => {
    if (activeSubscription && Object.keys(activeSubscription).length > 0) {
      setSubscription(true)
    } else {
      setSubscription(false)
    }
  }, [activeSubscription])

  const savePublishedStatus = (
    publishedStatus: any,
    marketplaceStatus: any
  ) => {
    setLoading(true)
    ProfileRequests.toggleProfilePublished({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
      published: publishedStatus,
      marketplace_visibility: marketplaceStatus,
      user_id: user?.user_id,
    })
      .then(() => {
        getCoachPrivateProfile()
        renderSuccess(
          `Website successfully set to ${
            !publishedStatus && !marketVis
              ? "Draft Mode"
              : publishedStatus && !marketVis
              ? "Private Mode"
              : publishedStatus && marketVis
              ? "Public Mode"
              : ""
          }`
        )
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.error_detail)
      })
      .finally(() => {
        setLoading(false)
        isOpen(false)
      })
  }

  const handlePrivateModeClick = () => {
    setPublished(true)
    setMarketVis(false)
  }

  const handlePublicModeClick = () => {
    if (!activeSubscription || Object.keys(activeSubscription).length === 0) {
      setShowError(true)
    } else {
      setPublished(true)
      setMarketVis(true)
      setShowError(false)
    }
  }

  return (
    <>
      <div className="zoee-backdrop-filter fixed left-0 top-0 z-[1009] flex h-screen w-screen items-center justify-center">
        <div
          className={`main-shadow fixed flex w-[775px] max-w-[95%] flex-col items-start overflow-y-auto rounded-[16px] bg-white px-[16px] pb-[40px] pt-[76px] md:px-[55px] ${
            user?.organization === "BBHC" ? "h-[500px]" : ""
          }`}
        >
          <CloseModal
            callback={() => isOpen(false)}
            styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h1 className="absolute left-[30px] top-[50px] text-[20px] font-bold lg:left-[70px] lg:top-[60px] lg:text-[25px]">
            Website Visibility
          </h1>
          <p className="text-graySlate absolute left-[30px] top-[85px] w-[310px] w-[80%] text-[14px] lg:left-[70px] lg:top-[100px] lg:text-[16px]">
            Set your website visibility to determine when and how you share your
            Zoee website.
          </p>
          <div className="mt-28 flex h-96 w-full flex-col gap-6 text-[14px] lg:mt-20 lg:gap-12 lg:text-[16px]">
            <div className="flex">
              <div>
                <button
                  onClick={() => {
                    setPublished(false)
                    setMarketVis(false)
                  }}
                  className={`h-6 w-6 rounded-full ${
                    !published && !marketVis
                      ? "bg-blurple"
                      : "border-graySlate border bg-white"
                  }`}
                ></button>
              </div>
              <div>
                <h2 className="ml-4 flex gap-2">
                  <span className="text-gray">
                    <SvgEyeOff />
                  </span>
                  <span className="font-bold">Draft Mode</span>
                </h2>
                <p className="text-grayCharcoal ml-[18px]">
                  Draft mode means your website is{" "}
                  <span className="font-bold text-black">
                    not visible to anyone
                  </span>{" "}
                  - perfect for when you are getting started building your
                  website.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <button
                  onClick={handlePrivateModeClick}
                  className={`h-6 w-6 rounded-full ${
                    published && !marketVis
                      ? "bg-blurple"
                      : "border-graySlate border bg-white"
                  }`}
                ></button>
              </div>
              <div>
                <h2 className="ml-4 flex gap-2">
                  <span>
                    <SvgEye />
                  </span>
                  <span className="font-bold">Publish Mode</span>
                </h2>
                <p className="text-grayCharcoal ml-[18px]">
                  Lights, camera, coach-tion! Publish your website and start
                  earning money! 🎉💪🏻💰
                </p>
              </div>
            </div>
            {user?.organization !== "BBHC" && (
              <div className="flex">
                <div>
                  <button
                    onClick={handlePublicModeClick}
                    className={`h-6 w-6 rounded-full ${
                      published && marketVis
                        ? "bg-blurple"
                        : "border-graySlate border bg-white"
                    }`}
                  ></button>
                </div>
                <div>
                  <h2 className="ml-4 flex gap-2">
                    <span>
                      <SvgMarketplace />
                    </span>
                    <span className="font-bold">
                      Elevate your website in our premier Coach Network
                    </span>
                  </h2>
                  <p className="text-grayCharcoal ml-[18px]">
                    Showcase your expertise in our Coach Market: Where Coaches
                    Shine and Clients Find Their Perfect Match!
                  </p>
                </div>
              </div>
            )}
            {showError && (
              <div className="flex items-center -mt-6 w-full">
                <span className="inline-block w-6 h-6"></span>{" "}
                <p className="bg-grayFlash w-full ml-2">
                  Please{" "}
                  <Link
                    to="/coach/subscription"
                    className="text-blue-600 underline"
                  >
                    upgrade your subscription
                  </Link>{" "}
                  to access the Market Community
                </p>
              </div>
            )}
          </div>
          <div className="flex w-full items-center justify-center gap-2">
            <Button onClick={() => isOpen(false)} variant="ghost" size="lg">
              Cancel
            </Button>
            {loading ? (
              <Loader />
            ) : (
              <Button
                onClick={() => {
                  savePublishedStatus(published, marketVis)
                }}
                size="lg"
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileVisibilityModal
