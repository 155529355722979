import React, {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import Loader from "../../ui/loader"
import PasswordRequirements from "../../account-settings/PasswordRequirements"
import Recaptcha from "./Recaptcha"
import { Button } from "../../ui/button"
import { Dialog, DialogContent } from "../../ui/dialog"
import { handleOutsideClick } from "../handleOutsideClick"
import { Input } from "../../ui/input"
import { RegisterFormContext } from "./context"
import { UserExists } from "../../../api/auth.service"
import { useAuthModal } from "../use-auth-modal"
import { validateFirstAndLastName } from "./name-form"
import { z } from "zod"

interface Props {
  showConfirm: boolean
  setShowConfirm: Dispatch<SetStateAction<boolean>>
  coachName: string
  connectAndRedirect: (user: any) => void
}

const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })
    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const ProfileMessageForm: FC<Props> = ({
  showConfirm,
  setShowConfirm,
  coachName,
  connectAndRedirect,
}) => {
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(false)
  const { showLogin } = useAuthModal()
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const [confUserPassword, setConfUserPassword] = useState<string>("")
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false)
  const {
    accountType,
    email,
    firstName,
    lastName,
    password,
    setEmail,
    setFirstName,
    setLastName,
    setPassword,
    createAccount,
    setAccountType,
  } = useContext(RegisterFormContext)
  const [isNotRobot, setIsNotRobot] = useState<boolean>(false)
  const [isGoogleSignUp, setIsGoogleSignUp] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  )
  const [showNameFields, setShowNameFields] = useState(false)
  const [showPasswordFields, setShowPasswordFields] = useState(false)
  const [emailDelayTimeout, setEmailDelayTimeout] =
    useState<NodeJS.Timeout | null>(null)
  const [nameDelayTimeout, setNameDelayTimeout] =
    useState<NodeJS.Timeout | null>(null)
  const [error, setError] = useState<string | undefined>(undefined)
  const [showLastStep, setShowLastStep] = useState(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const lengthError = !firstName.length || !lastName.length || !email.length
  const inputError =
    firstNameError !== undefined ||
    lastNameError !== undefined ||
    emailError !== undefined ||
    !isEntreeValid ||
    password !== confUserPassword
  const isDisabled = lengthError || inputError || !isNotRobot

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const errorMsg = "Invalid Input"

    if (inputName === "email") {
      setEmailError(undefined)
      setEmail(inputValue)
    }

    if (inputName === "first") {
      setFirstNameError(undefined)
      setFirstName(inputValue)
      if (!inputSuccess) {
        setFirstNameError(errorMsg)
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined)
      setLastName(inputValue)
      if (!inputSuccess) {
        setLastNameError(errorMsg)
      }
    }

    if (inputName === "password") {
      setPassword(inputValue)
    }
    if (inputName === "conf-pass") {
      setConfUserPassword(inputValue)
    }
  }

  const handleAccountSelection = (
    accountType: "member" | "coach",
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    setAccountType(accountType)
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value)

    const emailSchema = z.string().email().safeParse(value)
    if (!emailSchema.success) {
      return
    }

    if (emailDelayTimeout) {
      clearTimeout(emailDelayTimeout)
    }

    const newEmailTimeout = setTimeout(() => {
      setShowNameFields(true)
    }, 500)
    setEmailDelayTimeout(newEmailTimeout)
  }

  const handleAuthentication = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    setLoading(true)
    const emailSchema = z.string().email().safeParse(email)
    if (!emailSchema.success) {
      setError(emailSchema.error.issues[0].message)
      setLoading(false)
      return
    }
    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setError("Email is already in use")
      setLoading(false)
      return
    }
    try {
      await createAccount().then(() => {
        let userJSON = localStorage.getItem("user")
        if (userJSON !== null) {
          let user = JSON.parse(userJSON)
          connectAndRedirect(user)
        }
      })
      setLoading(false)
    } catch (error) {
      console.error("Error creating account:", error)
      setError("Failed to create account. Please try again.")
      setLoading(false)
    }
  }

  const handleNameChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: "first" | "last"
  ) => {
    const value = e.target.value
    if (field === "first") {
      setFirstName(value)
    } else {
      setLastName(value)
    }

    if (firstName && lastName) {
      if (nameDelayTimeout) {
        clearTimeout(nameDelayTimeout)
      }

      const newTimeout = setTimeout(() => {
        setShowPasswordFields(true)
      }, 500)
      setNameDelayTimeout(newTimeout)
    }
  }

  useEffect(() => {
    if (email && firstName && lastName && isGoogleSignUp) {
      handleAuthentication()
    }
  }, [email, firstName, lastName, isGoogleSignUp])

  useEffect(() => {
    handleOutsideClick(setShowConfirm, showConfirm)
  }, [showConfirm])

  useEffect(() => {
    if (password !== confUserPassword) {
      setPasswordError("Passwords must match")
    } else {
      setPasswordError(undefined)
    }
  }, [confUserPassword])

  useEffect(() => {
    if (password === confUserPassword && password !== "") {
      setShowLastStep(true)
    } else {
      setShowLastStep(false)
    }
  }, [password, confUserPassword])

  useEffect(() => {
    const validateForm = () => {
      const emailIsValid = z.string().email().safeParse(email).success
      const firstNameIsValid =
        firstName.trim() !== "" && validateFirstAndLastName(firstName).success
      const lastNameIsValid =
        lastName.trim() !== "" && validateFirstAndLastName(lastName).success
      const passwordsMatch = isEntreeValid && password === confUserPassword

      setIsFormValid(
        emailIsValid &&
          firstNameIsValid &&
          lastNameIsValid &&
          passwordsMatch &&
          isNotRobot
      )
    }

    validateForm()
  }, [
    email,
    firstName,
    lastName,
    password,
    confUserPassword,
    isNotRobot,
    accountType,
  ])

  return (
    <Dialog open={showConfirm} onOpenChange={setShowConfirm} modal={false}>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <h3 className="font-bold text-lg text-center">{`Message ${coachName}`}</h3>
        <p className="text-sm text-center">
          Please provide your information below to establish a secure, private
          messaging account.
        </p>
        <form
          onSubmit={(e) => handleAuthentication(e)}
          className="flex flex-col gap-3"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
            }
          }}
        >
          <div className="grid sm:grid-cols-2 gap-2">
            <Button
              className="text-md h-auto py-6"
              variant={accountType === "member" ? "default" : "outline"}
              onClick={(e) => handleAccountSelection("member", e)}
            >
              I'm a Client
            </Button>
            <Button
              className="text-md h-auto py-6"
              variant={accountType === "coach" ? "default" : "outline"}
              onClick={(e) => handleAccountSelection("coach", e)}
            >
              I'm a Coach
            </Button>
          </div>
          <Input
            label="Email"
            placeholder="Enter Your Email"
            value={email}
            onChange={handleEmailChange}
            onFocus={() => setShowPassReq(false)}
            error={error}
            disabled={loading}
            autoFocus
          />
          {/* Step 3: First and Last Name Inputs */}
          {showNameFields && (
            <>
              <Input
                label="First Name"
                placeholder="Enter Your First Name"
                value={firstName}
                onChange={(e) => handleNameChange(e, "first")}
                onFocus={() => setShowPassReq(false)}
                error={firstNameError}
                disabled={loading}
              />
              <Input
                label="Last Name"
                placeholder="Enter Your Last Name"
                value={lastName}
                onChange={(e) => handleNameChange(e, "last")}
                onFocus={() => setShowPassReq(false)}
                error={lastNameError}
                disabled={loading}
              />
            </>
          )}
          {/* Step 4: Password Inputs */}
          {showPaswReqs && (
            <PasswordRequirements
              validEntree={password}
              setIsEntryValid={setIsEntryValid}
            />
          )}
          {showPasswordFields && (
            <>
              <Input
                label="Password"
                placeholder="Enter Your Password"
                value={password}
                onFocus={() => setShowPassReq(true)}
                onChange={(e) => handleChange(e, "password")}
                type="password"
                autoComplete="off"
                disabled={loading}
              />
              <Input
                label="Confirm your password"
                placeholder="Confirm new password"
                value={confUserPassword}
                error={passwordError}
                onChange={(e) => handleChange(e, "conf-pass")}
                type="password"
                onFocus={() => setShowPassReq(false)}
                autoComplete="off"
                disabled={loading}
              />
            </>
          )}
          {/* Step 5: reCAPTCHA and Create Account Button */}
          {showLastStep && password === confUserPassword && (
            <>
              <Recaptcha setIsNotRobot={setIsNotRobot} />
              <Button
                type="submit"
                className="w-full"
                disabled={loading || !isFormValid}
              >
                {loading ? <Loader /> : "Create Account"}
              </Button>
            </>
          )}
          <p className="text-muted-foreground text-sm text-center">
            Already have an account?{" "}
            <Button
              className="w-fit mx-auto"
              variant="link"
              size="default"
              onClick={() => {
                setShowConfirm(false)
                showLogin()
              }}
            >
              Login
            </Button>
          </p>
        </form>
      </DialogContent>
    </Dialog>
  )
}
export default ProfileMessageForm
