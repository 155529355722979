import React, { useContext, useEffect, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog"
import { UserCtx } from "../context/userContext"

const NewFeature: React.FC = () => {
  const { user } = useContext(UserCtx)
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    const showNewFeature =
      localStorage.getItem("showNewFeature") === "false"
    if (!showNewFeature) {
      setShowModal(true)
      localStorage.setItem("showNewFeature", "true")
    }
  }, [showModal])

  const handleOpenChange = (open: boolean) => {
    setShowModal(false)
    localStorage.setItem("showNewFeature", "false")
  }

  const first_name = user?.firstName

  return (
    <>
      <Dialog open={showModal} onOpenChange={handleOpenChange}>
        <DialogContent className="max-w-lg max-h-screen overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-center mb-4 text-2xl">
              {"\u2728"} Announcement: Profile Pages are now "Zoee Websites"!{" "}
            </DialogTitle>
            <DialogDescription className="text-center text-lg">
              We've given your profile page a new name! What was formerly called
              your "Profile Page" is now your "Zoee Website." Your Zoee website
              is now ready to start converting visitors into paying clients! No
              developers. No designers. No monthly website fees. Just pure,
              client-attracting magic. Nothing has changed in functionality -
              just a new name that better reflects the powerful
              client-attracting tool you already have at your fingertips.
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NewFeature
