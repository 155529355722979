import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import Consultation from "./cm-images/Consultation.png"
import Book from "./cm-images/Book.png"
import Schedule from "./cm-images/Schedule.png"
import BookForm from "./cm-images/BookForm.png"
import VerifyEmail from "./cm-images/VerifyEmail.png"
import BacktoAcc from "./cm-images/BackToAcc.png"
import Enroll from "./cm-images/Enroll.png"
import Enrolled from "./cm-images/Enrolled.png"
import MyCoaches from "./cm-images/MyCoaches.png"
import SelectingCoach from "./cm-images/SelectingCoaach.png"
import ScheduleWith from "./cm-images/ScheduleWith.png"
import SelectConsult from "./cm-images/SelectConsult.png"
import ScheduleNext from "./cm-images/ScheduleNext.png"
import ScheduleConfirm from "./cm-images/ScheduleConfirm.png"
import SeeAll from "./cm-images/SeeAll.png"
import ViewDetails from "./cm-images/ViewDetails.png"
import EnrollIn from "./cm-images/EnrollIn.png"
import PromoCode from "./cm-images/PromoCode.png"
import PayNow from "./cm-images/PayNow.png"
import PendingInvite from "./cm-images/PendingInvite.png"
import AcceptInvite from "./cm-images/AcceptInvite.png"
import SchedNow from "./cm-images/SchedNow.png"
import ChooseService from "./cm-images/ChooseService.png"
import Recurring from "./cm-images/Recurring.png"
import ManageSess from "./cm-images/ManageSess.png"
import Reschedule from "./cm-images/Reschedule.png"
import CalendarManage from "./cm-images/CalendarManage.png"
import Cancel from "./cm-images/Cancel.png"
import CalendarNav from "./cm-images/CalendarNav.png"

export default function CMScheduling() {
  return (
    <SectionWrapper id="scheduling">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Booking & Managing{" "}
          <span className="text-[#4750F5]">Sessions and Appointments</span>
        </h1>
      </div>
      <p className="text-gray-700 mt-8 text-center">
        You can book sessions in Zoee by booking via a{" "}
        <a
          href="#book-profile"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          coach's profile{" "}
        </a>
        or using the{" "}
        <a
          href="#book-dropdown"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          scheduling dropdown.{" "}
        </a>
        You can also{" "}
        <a
          href="#reschedule-session"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          reschedule{" "}
        </a>
        or{" "}
        <a
          href="#cancel-session"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          cancel{" "}
        </a>
        a session.
      </p>

      <div id="book-profile" className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h3 className="text-2xl font-semibold text-blue-700">
          Booking a Session via Profile Page
        </h3>
      </div>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Click on the link provided by your coach.</li>
          <li>
            Scroll down to the <strong>"Schedule a Consultation"</strong>{" "}
            section.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Consultation}
              alt="Consultation Booking Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Pick a date and time, then click <strong>"Book"</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Book}
              alt="Booking Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Fill out the form in the pop-up and click <strong>"Next"</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={BookForm}
              alt="Booking Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>

        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>
            Verify your email by clicking the{" "}
            <strong>"Confirm My Email"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={VerifyEmail}
              alt="Booking Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on <strong>"Back to Account"</strong> on the top right-hand
            side of the navigation bar to return.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={BacktoAcc}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Preview the time of the consultation that appears and click{" "}
            <strong>"Enroll in Service"</strong> to proceed.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Enroll}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A pop-up will confirm your consulation has been scheduled.</li>
          <div className="flex justify-center mt-4">
            <img
              src={Enrolled}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <div id="book-dropdown" className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h3 className="text-2xl font-semibold text-blue-700">
          Booking a Session via Coach Contact Card
        </h3>
      </div>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Log into your Zoee account.</li>
          <li>
            Click <strong>"My Coaches"</strong> in the navigation bar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoaches}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectingCoach}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click the <strong>"Schedule with Coach"</strong> dropdown.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleWith}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            This will produce 2 options: “See all Services” and “Consultation”.
          </li>
        </ul>
        <h3 className="text-2xl font-semibold text-blue-700 mt-8">
          Booking a Consultation
        </h3>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>
            Select <strong>"Consultation"</strong> from the dropdown menu.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectConsult}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select a time on the new page.</li>
          <li>Click on the "Next" button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <h3 className="text-2xl font-semibold text-blue-700 mt-8">
          Purchasing a Service
        </h3>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-6">
          <li>
            Click on the <strong>"See All Services"</strong> button from the
            dropdown menu.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SeeAll}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the <strong>"See Details"</strong> button for the service
            you want to purchase.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ViewDetails}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the <strong>"Enroll in Service"</strong> button on the
            page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={EnrollIn}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            <strong>Optional:</strong> If you were provided with a promocode,
            click on the <strong>"Add Promocode"</strong> button and insert the
            code in the appearing space.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PromoCode}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Review the details of your purchase.</li>
          <li>
            Pay through PayPal or by entering your card details and clicking the{" "}
            <strong>"Pay Now"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PayNow}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A pop-up confirming your enrollment should appear.</li>
        </ul>
      </div>

      <h3
        id="invited-to-service"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Invited to Service
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        You will receive an email or text informing you that your coach has
        invited you to a service, and prompting you to sign into your account.
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>Log into Zoee.</li>
          <li>
            Locate and click on <strong>"My Coaches"</strong> in the navigation
            bar at the top of the page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoaches}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectingCoach}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down the page to the{" "}
            <strong>"Services I Enrolled Into"</strong> section.
          </li>
          <li>
            Click on the <strong>"Pending"</strong> button. It will have a
            notification identifier beside it.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PendingInvite}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the <strong>"Accept Invite"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={AcceptInvite}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Review the details of your purchase.</li>
          <li>
            Pay through PayPal or by entering your card details and clicking the{" "}
            <strong>"Pay Now"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PayNow}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A popup confirming your enrollment should pop up.</li>
          <li>
            Click on the <strong>"Schedule"</strong> button of the pop-up
            confirming your enrollment after purchasing a service.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SchedNow}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the <strong>"Next"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the{" "}
            <strong>"Confirm"</strong> button to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h3
        id="booking-after-purchase"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Booking a Session After Purchasing a Service
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        You can schedule your session for your service either immediately after
        purchasing or at a later time.
        <h4 className="text-xl font-semibold text-gray-700 mt-2">
          After Purchasing
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>
            Click on the <strong>"Schedule"</strong> button of the pop-up
            confirming your enrollment.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SchedNow}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the <strong>"Next"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the{" "}
            <strong>"Confirm"</strong> button to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <h4 className="text-xl font-semibold text-gray-700 mt-6">
          Scheduling at a Later Time
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Locate and click on <strong>"My Coaches"</strong> in the navigation
            bar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoaches}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectingCoach}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Navigate down their page to the{" "}
            <strong>"Services I Enrolled Into"</strong> section.
          </li>
          <li>Select the service you want to schedule a session for.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ChooseService}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the <strong>"Next"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the{" "}
            <strong>"Confirm"</strong> button to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h3
        id="recurring-sessions"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Scheduling Recurring Sessions
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        For a recurring service purchased from your coach, you or your coach
        will need to schedule the next session till the total amount of sessions
        has been redeemed. For each session you need to schedule, you can follow
        these steps:
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Locate and click on <strong>"My Coaches"</strong> in the navigation
            bar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoaches}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectingCoach}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Navigate down their page to the{" "}
            <strong>"Services I Enrolled Into"</strong> section.
          </li>
          <li>Select the service you want to schedule a session for.</li>
          <div className="flex justify-center mt-4">
            <img
              src={Recurring}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the <strong>"Next"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the{" "}
            <strong>"Confirm"</strong> button to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <p className="text-gray-700 mt-4">
          If you encounter a problem scheduling sessions in a recurring package,
          reach out to Zoee support.
        </p>
      </div>

      <h3
        id="rescheduling-session"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Rescheduling Your Session
      </h3>
      <div className="bg-white p-6 rounded-lg mt-2">
        <h4
          id="reschedule-homepage"
          className="text-xl font-semibold text-gray-700"
        >
          From the Homepage
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2  mt-2">
          <li>Log into Zoee.</li>
          <li>
            Click on the <strong>"Manage Sessions"</strong> button on the
            session displayed on the calendar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ManageSess}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down to the <strong>"When"</strong> section.
          </li>
          <li>
            Click on the <strong>"Reschedule"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Reschedule}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a new time that works for you
            then click the <strong>"Next"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the{" "}
            <strong>"Confirm"</strong> button to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>

        <h4
          id="reschedule-session"
          className="text-xl font-semibold text-gray-700 mt-6"
        >
          From My Calendar
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Locate and click on <strong>"My Calendar"</strong> from the
            navigation bar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CalendarNav}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the <strong>"Manage Sessions"</strong> button on the
            session displayed on the page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ManageSess}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down to the <strong>"When"</strong> section.
          </li>
          <li>
            Click on the <strong>"Reschedule"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Reschedule}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a new time that works for you
            then click the <strong>"Next"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleNext}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the{" "}
            <strong>"Confirm"</strong> button to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduleConfirm}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h3
        id="cancel-session"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Cancelling Your Session
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <p className="text-gray-700">
          We understand that plans can change unexpectedly, and you may need to
          cancel a scheduled session. Here are the steps you can take to do so.
        </p>

        <h4 className="text-xl font-semibold text-gray-700 mt-4">
          From the Homepage
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Click on the <strong>"Manage Sessions"</strong> button on the
            session displayed on the calendar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ManageSess}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down to the <strong>"When"</strong> section.
          </li>
          <li>
            Click on the <strong>"Cancel Meeting"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Cancel}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            A quick pop-up will confirm your meeting has been successfully
            cancelled.
          </li>
        </ul>

        <h4 className="text-xl font-semibold text-gray-700 mt-6">
          From My Calendar
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Locate and click on <strong>"My Calendar"</strong> from the
            navigation bar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CalendarNav}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the <strong>"Manage Sessions"</strong> button on the
            session displayed on the page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ManageSess}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down to the <strong>"When"</strong> section.
          </li>
          <li>
            Click on the <strong>"Cancel Meeting"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Cancel}
              alt="Scheduling Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            A quick pop-up will confirm your meeting has been successfully
            cancelled.
          </li>
        </ul>
        <p className="text-gray-700 mt-4">
          Be sure to communicate with your coach and let them know you plan to
          cancel the session so they are not left blindsided.
        </p>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
