import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"

type ClientNotificationProps = {
  showNotification: boolean
}

const ClientNotification: React.FC<ClientNotificationProps> = ({
  showNotification,
}) => {
  const [isOpen, setIsOpen] = useState(showNotification)

  useEffect(() => {
    setIsOpen(showNotification)
  }, [showNotification])

  const closeDialog = () => {
    setIsOpen(false)
  }

  if (!showNotification) return null 

  return (
    <Dialog open={isOpen} onOpenChange={closeDialog}>
      <DialogContent className="max-w-lg max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-center mb-4 text-2xl">
            {"\u2728"} Ready for the Next Step? Become a Coach on Zoee!{" "}
          </DialogTitle>
          <DialogDescription className="text-center text-lg">
            You’re using a client account, which lets you book and work with
            amazing coaches on Zoee! Want to take the next step and become a
            coach? Coaching accounts come with exclusive tools to help you grow
            your business. Curious? Reach out to us at{" "}
            <a href="mailto:support@zoee.com" className="underline">
              support@zoee.com
            </a>{" "}
            to learn more!
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default ClientNotification