import React, { useEffect, useContext, useState } from "react"
import CloseModal from "../CloseModal"
import ContactAvatar from "../contacts/ContactAvatar"
import moment from "moment"
import useDate from "../../hooks/useDate"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ServiceRequests } from "../../api/app.service"
import {
  SvgUser3,
  SvgCalendar,
  SvgWebsite,
  SvgVideoOutline,
  SvgCheckCircleSolid,
  SvgFilledInformationCircle,
  SvgUsersSolid,
  SvgWarning,
} from "../icons"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface Props {
  checkoutSuccessPopup: any
  setCheckoutSuccessPopup: any
  contact: any
  meetingDetails: {
    startTime: Date
    endTime: Date
  }
  activeContact: any
  activeService: any
}

const FreeCheckoutSuccessPopup: React.FC<Props> = ({
  checkoutSuccessPopup,
  setCheckoutSuccessPopup,
  contact,
  meetingDetails,
  activeContact,
  activeService,
}) => {
  const { user } = useContext(UserCtx)
  const navigate = useNavigate()
  const { startLoading } = useLoading()
  const [service, setService] = useState<any>(null)
  const { renderError } = useContext(CommonFunctionCtx)
  const location = useLocation()

  const getUserTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const longTimezoneName = new Date().toLocaleTimeString("en", {
      timeZoneName: "long",
    })
    const extractedTimezone = longTimezoneName.split(" ")[2]
    const timezoneWithTime = `${extractedTimezone} Time`
    let timezoneDisplay = timezoneWithTime.replace("_", " ")
    timezoneDisplay = timezoneDisplay.replace("/", " & ")
    timezoneDisplay = `${timezoneDisplay} - ${timezone}`
    return timezoneDisplay
  }
  const userTimezone = getUserTimezone()
  const { mdDate } = useDate()

  const getProfileService = () => {
    let coach_id = 0
    let client_id = 0

    if (!user.isCoach) {
      coach_id = activeContact.profile_id
      client_id = user.activeProfileId
    } else {
      if (activeContact.profile_type === "member") {
        coach_id = user.activeProfileId
        client_id = activeContact.profile_id
      } else {
        coach_id = activeContact.profile_id
        client_id = user.activeProfileId
      }
    }

    if (user) {
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coach_id,
        member_profile_id: client_id,
      })
        .then((data) => {
          const serviceIdToMatch = parseInt(location.pathname.split("/")[5])
          const selectedService = data.service_list.find(
            (service: any) => service.service_id === serviceIdToMatch
          )
          setService(selectedService)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  useEffect(() => {
    getProfileService()
  }, [])

  const handleScheduleSessionClick = () => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { service, contact },
    })
  }

  const handleStartMessage = () => {
    startLoading()
    window.location.href = `/${user.activeProfile}/inbox?chat=${activeContact.profile_id}`
  }

  const serviceDetails = activeService?.service_details
  const groupStart = serviceDetails?.group?.meetingDates
  const groupDuration = serviceDetails?.session?.duration
  const isGroupService = activeService?.service_details?.isGroupService

  const firstValidGroup = serviceDetails?.group?.meetingDates?.find(
    (date: string) => {
      const parsedDate = new Date(date)
      return !isNaN(parsedDate.getTime())
    }
  )

  const groupEnd =
    firstValidGroup && groupDuration
      ? new Date(
          new Date(firstValidGroup).getTime() + (groupDuration / 60) * 60000
        )
      : null

  return (
    <div className="zoee-backdrop-filter credentials fixed left-0 top-0 z-[1000] flex h-screen w-screen items-center justify-center">
      <div className="main-shadow fixed left-1/2 flex h-auto max-h-[85vh] w-[90%] max-w-[504px] -translate-x-1/2 flex-col items-center gap-4 overflow-y-auto rounded-xl bg-white px-4 py-10 md:px-10">
        <CloseModal
          callback={() =>
            user.activeProfile === "member"
              ? navigate(`/${user.activeProfile}`)
              : navigate(`/${user.activeProfile}/dashboard`)
          }
          styling="absolute top-6 right-8"
        />
        <ContactAvatar
          contact={{
            contact_name: activeContact.contact_name,
            has_avatar: activeContact.avatar_url,
            user_color: activeContact.user_color,
            user_id: activeContact.user_id,
          }}
          width={80}
          height={80}
          border="rounded-full"
        />

        {meetingDetails || isGroupService ? (
          <div>
            <h5 className="flex items-center justify-center text-center text-2xl font-bold">
              <SvgCheckCircleSolid className="mr-2" />{" "}
              <span>You are scheduled!</span>
            </h5>
            <div className="text-center">
              <p className="text-muted-foreground mt-2 mb-2 text-[#45474a]">
                A calendar invitation has been sent to your email address.
              </p>
            </div>
            <div className="border-2 border-gray p-4 rounded-lg">
              <div className="text-muted-foreground grid grid-cols-[auto_1fr] gap-3 my-3 text-[#45474a]">
                <SvgUser3 />
                <p>{activeContact.contact_name}</p>

                {!checkoutSuccessPopup?.state?.service.is_group_service ? (
                  meetingDetails ? (
                    <>
                      <SvgCalendar />
                      <p>
                        {moment(meetingDetails.startTime).format("h:mm A")} -{" "}
                        {moment(meetingDetails.endTime).format("h:mm A, dddd")},{" "}
                        <br className="md:hidden" />
                        {mdDate(meetingDetails.startTime)}
                      </p>
                      <SvgWebsite />
                      <p>{userTimezone}</p>
                      <SvgVideoOutline />
                      <p className="capitalize">
                        Web conferencing <br className="md:hidden" /> details to
                        follow.
                      </p>
                    </>
                  ) : (
                    isGroupService &&
                    firstValidGroup &&
                    groupEnd && (
                      <>
                        <SvgCalendar />
                        <p>
                          {moment(firstValidGroup).format("h:mm A")} -{" "}
                          {moment(groupEnd).format("h:mm A, dddd")},{" "}
                          <br className="md:hidden" />
                          {mdDate(firstValidGroup)}
                        </p>
                        <SvgWebsite />
                        <p>{userTimezone}</p>
                        <SvgVideoOutline />
                        <p className="capitalize">
                          Web conferencing <br className="md:hidden" /> details
                          to follow.
                        </p>
                      </>
                    )
                  )
                ) : (
                  <>
                    <SvgUsersSolid />
                    <p>{service?.service_details?.title}</p>
                    <SvgFilledInformationCircle className="w-4 h-4" />
                    <p>
                      Please save the attached .ics file from the confirmation
                      email to add the event(s) to your calendar.
                    </p>
                    <SvgWarning />
                    <p>
                      Group meetings have been scheduled. To cancel or opt out,
                      please contact your coach.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <h5 className="text-2xl font-bold">
            {service?.service_details?.type === "consultation"
              ? "Continue to Scheduling"
              : "Enrollment Confirmed"}
          </h5>
        )}

        {meetingDetails || isGroupService ? (
          <>
            <Button onClick={handleStartMessage} size="lg">
              Message {activeContact.contact_name}
            </Button>
          </>
        ) : (
          <div className="flex flex-col items-center gap-6 text-center">
            <>
              <p>
                You{" "}
                {service?.service_details?.type === "consultation"
                  ? "are confirmed for"
                  : "enrolled in"}{" "}
                {service?.service_details?.title}. Get started by scheduling
                your{" "}
                {service?.service_details?.type === "consultation"
                  ? "consultation."
                  : "service."}
              </p>
              <Button size="lg" onClick={handleScheduleSessionClick}>
                {service?.service_details?.type === "consultation"
                  ? "Schedule Now"
                  : "Schedule Service"}
              </Button>
            </>
          </div>
        )}
      </div>
    </div>
  )
}

export default FreeCheckoutSuccessPopup
