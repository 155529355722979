import React, { useEffect, useContext } from "react"
import LoadingPage from "../../components/LoadingPage"
import useLoading from "../../hooks/useLoading"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import { UserInfo } from "../../api/app.service"
import { VerifyEmail } from "../../api/auth.service"
import { RedirectType } from "next/navigation"

export default function ConfirmEmail() {
  const { user } = useContext(UserCtx)
  const { renderError, getUser, hideAlert } = useContext(CommonFunctionCtx)
  const navigate = useNavigate()
  const { stopLoading } = useLoading()
  const params = new URLSearchParams(window.location.search)
  const redirectPath = params.get("redirectPath") || "/"
  const code = params.get("t")

  // const sendConfirmationCode = (code: any) => {
  //   VerifyEmail.verifyEmailCode({
  //     verification_code: code,
  //   })
  //     .then((data) => {
  //       if (data?.token_status === "token_activated") {
  //         checkForVerification()
  //         console.log('token status:', data.token_status)
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //       renderError(err.response.data.message)
  //     })
  //   stopLoading()
  // }

  const verifyEmailTokenStatus = (code: any) => {
    VerifyEmail.verifyEmailCode({
      verification_code: code,
  })
      .then((data) => {
        console.log('data:', data)
        if (data?.token_status === "token_not_found") {
          renderError("Please check your email to verify")
        } else if (data?.token_status === "token_activated") {
          checkForVerification()
        } else if (data?.token_status === "token_already_activate") {
          checkForVerification()  
        }
      })
      .catch((err) => {
        renderError(err.response.data.status)
      })
      stopLoading()
  }

  const getFlags = () => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    const redirectPath = searchParams.get("redirectPath")
  
    if (redirectPath) {
      const decodedPath = decodeURIComponent(redirectPath)
      const params = new URLSearchParams(decodedPath)
      const received_invites = params.get("received_invites")
      return received_invites
    }
    return false
  }

  const checkForVerification = async () => {
    hideAlert()
    await getUser() 
    const userToValidateEmail = await UserInfo.getUser({
      user_id: parseInt(user.user_id),
    })

    if (!userToValidateEmail.user_data.email_verified) {
      renderError("Please check your email to verify")
    } else {
      if (getFlags()) {
        navigate(`/${user.activeProfile}/contacts?received_invites=true`)
      } else {
        navigate(redirectPath)
      }
    }
  }

  useEffect(() => {
    if (code) {
      verifyEmailTokenStatus(code)
    } else {
      checkForVerification()
    }
  }, [])

  return <LoadingPage />
}
