import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import MyCoaches from "./cm-images/MyCoaches.png"
import SelectingCoaach from "./cm-images/SelectingCoaach.png"
import Testimonial from "./cm-images/Testimonial.png"
import LeaveTestimonial from "./cm-images/LeaveTestimonial.png"
import AnonTestimonial from "./cm-images/AnonTestimonial.png"

export default function CMTestimonials() {
  return (
    <SectionWrapper id="testimonials">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Writing <span className="text-[#4750F5]">Coach Testimonials</span>
        </h1>
      </div>

      <p className="text-gray-700 mt-6 text-center">
        Your coach may reach out to you to leave them a testimonial after you
        have had a session with them. You can do this at the top of the contact
        page. Follow these steps:
      </p>

      <h3 className="text-2xl font-semibold text-blue-700 mt-8">
        Navigating to the Contact Page
      </h3>

      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            From the Homepage, go to the <strong>My Coaches</strong> page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoaches}
              alt="Zoee Help Center"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectingCoaach}
              alt="Zoee Help Center"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the <strong>Write a Testimonial</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Testimonial}
              alt="Zoee Help Center"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A pop up form should appear.</li>
          <li>
            Fill out your testimonial and then click Save when you are done.
          </li>

          <div className="flex justify-center mt-4">
            <img
              src={LeaveTestimonial}
              alt="Zoee Help Center"
              className="w-full max-w-md rounded-lg"
            />
          </div>

          <li className="italic">
            Optional: If you want to send the testimonial anonymously, click the
            field next to the “Remain anonymous” text.
          </li>

          <div className="flex justify-center mt-4">
            <img
              src={AnonTestimonial}
              alt="Zoee Help Center"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>

        <div className="flex justify-center mt-8">
          <Button variant="default" asChild>
            <a href="#table-of-contents">Back to Quick Start</a>
          </Button>
        </div>
      </div>
    </SectionWrapper>
  )
}
