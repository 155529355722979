import React, { createRef, FC, useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useLocation } from "react-router-dom"

import { VerifyRecaptcha } from "../../../api/auth.service"

interface RecaptchaProps {
  setIsNotRobot: (statement: boolean) => void
}

const Recaptcha: FC<RecaptchaProps> = ({ setIsNotRobot }) => {
  // Logic for ReCAPTCHA - https://www.npmjs.com/package/react-google-recaptcha

  const recaptchaRef = createRef<ReCAPTCHA>()
  const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === "true"
  const location = useLocation() 

  const [scale, setScale] = useState(1) 

  useEffect(() => {
    const updateScale = () => {
      if (location.pathname === "/sign_in") {
        const width = window.innerWidth
        setScale(width < 480 ? Math.max(0.85 * (width / 480), 189 / 304) : 0.85)
      } else {
        setScale(1) 
      }
    }

    updateScale()
    window.addEventListener("resize", updateScale)
    return () => window.removeEventListener("resize", updateScale)
  }, [location.pathname]) 

  const recaptchaSubmit = (tokenValue: string | null) => {
    if (tokenValue) {
      VerifyRecaptcha.recaptchaVerification({ access_token: tokenValue })
        .then(() => setIsNotRobot(true))
        .catch(() => setIsNotRobot(false))
    }
  }

  if (!recaptchaEnabled) {
    setIsNotRobot(true)
    return null
  }

  return (
    <div className="flex w-full justify-center items-center">
      <div
        className="flex justify-center items-center"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: "center",
          width: "fit-content",
        }}
      >
        <ReCAPTCHA
          size="normal"
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          onChange={recaptchaSubmit}
          onExpired={() => setIsNotRobot(false)}
        />
      </div>
    </div>
  )
}

export default Recaptcha
