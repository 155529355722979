import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import CardSelector from "./CardSelector"
import PayPalCardFields from "./PayPalCardFields"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { PayPalButton } from "./PayPalButton"
import { SubscriptionContext } from "./SubscriptionContext"
import { SubscriptionRequests } from "../../api/app.service"
import { useIntercom } from "react-use-intercom"
import { UserCtx } from "../../context/userContext"
import { useNavigate } from "react-router-dom"

declare global {
  interface Window {
    paypal: any
  }
}

interface Props {
  appliedDiscount: {
    amount: number
    promo_code: string
  }
  createSetupIntent: any
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  redirectUrl: string
  selectedPlanFromChild: any
  isIIN: boolean
}

export const PayPalSubscriptionPayLater: React.FC<Props> = ({
  appliedDiscount,
  createSetupIntent,
  isTermsAgreed,
  setIsTermsAgreed,
  redirectUrl,
  selectedPlanFromChild,
  isIIN,
}) => {
  const {
    paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    showCreatePayment,
  } = useContext(SubscriptionContext)
  const { user } = useContext(UserCtx)
  const navigate = useNavigate()
  const { renderSuccess, renderError } = useContext(CommonFunctionCtx)

  const [isChecked, setIsChecked] = useState(false)
  const [isSdkReady, setIsSdkReady] = useState(false)
  const isCheckedRef = useRef(false)

  const user_id = parseInt(user.user_id)
  const zoee_subscription_id = selectedPlanFromChild?.zoee_subscription_id
  const promo = appliedDiscount
  const value = selectedPlanFromChild?.amount
  const promoRef = useRef(promo)
  const zoeeSubscriptionIdRef = useRef(zoee_subscription_id)
  const valueRef = useRef(value)

  const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID
  const merchantID = process.env.REACT_APP_PAYPAL_PAYER_ID

  const isSubx = true
  const { shutdown } = useIntercom()
  useEffect(() => {
    shutdown()
  }, [shutdown])

  useEffect(() => {
    promoRef.current = promo
  }, [promo])

  useEffect(() => {
    zoeeSubscriptionIdRef.current = zoee_subscription_id
  }, [zoee_subscription_id])

  useEffect(() => {
    valueRef.current = value
  }, [value])

  // Sets up the transaction when a payment button is clicked
  const createVaultSetupToken = useCallback(
    (data: any, type: "paypal" | "card") => {
      return SubscriptionRequests.createPaypalSubscription({
        user_id,
        type: type,
      })
        .then((response: any) => {
          return response.data
        })
        .then((order: any) => {
          return order.id
        })
    },
    []
  )

  // Finalize the transaction after payer approval
  const onApprove = useCallback((data: any, type: "paypal" | "card") => {
    const promoCode = promoRef.current
    const subIdRef = zoeeSubscriptionIdRef.current
    const AmountValueRef = isIIN ? 743.65 : valueRef.current
    const zoeeSubscriptionId = isIIN ? "1005003" : subIdRef
    return SubscriptionRequests.createPaymentToken({
      user_id,
      subscription: {
        id: zoeeSubscriptionId,
        promo_code: promoCode,
      },
      token: data,
    })
      .then((response) => response)
      .then((orderData) => {
        console.log("data: ", data)
        renderSuccess(
          "🎉 Subscription confirmed! You’ll be charged on April 18, 2025."
        )

        const userObject = JSON.parse(localStorage.getItem("user") || "{}")

        if (userObject) {
          userObject.isSubscribed = true
          localStorage.setItem("user", JSON.stringify(userObject))
        } else {
          console.error("Error: userObject is invalid or missing.")
        }
        localStorage.removeItem("referrerPromoCode")
        setTimeout(() => {
          window.location.replace(redirectUrl)
        }, 2500)
      })
      .catch((error) => {
        renderError(error.response.data.error_detail)
        console.log("error in approve:", error)
      })
  }, [])

  useEffect(() => {
    setIsChecked(true)
  }, [isChecked])

  useEffect(() => {
    const loadPayPalSDK = () => {
      if (document.getElementById("paypal-sdk")) {
        console.log("PayPal SDK already loaded.")
        setIsSdkReady(true)
        return
      }

      const script = document.createElement("script")
      script.id = "paypal-sdk"
      script.src = `https://www.paypal.com/sdk/js?components=buttons,card-fields&client-id=${clientID}&merchant-id=${merchantID}&currency=USD&intent=capture&disable-funding=paylater`
      script.async = true

      script.onload = () => {
        console.log("PayPal SDK loaded successfully.")
        setIsSdkReady(true)
      }

      script.onerror = (err) => {
        console.error("Failed to load PayPal SDK:", err)
      }

      document.body.appendChild(script)
    }

    loadPayPalSDK()
  }, [])

  if (!isSdkReady) {
    return <div>Loading PayPal SDK...</div>
  }

  return (
    <div className="justify-flex flex-col items-center justify-center">
      <PayPalButton
        createVaultSetupToken={createVaultSetupToken}
        onApprove={onApprove}
      />
      <div className="flex items-center w-full mb-2 mt-2">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-2 text-gray-500 text-graySlate">OR</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>

      {paymentMethods ? (
        <>
          {showCreatePayment ? (
            <>
              <PayPalCardFields
                isCheckedRef={isCheckedRef}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                onApprove={onApprove}
                createVaultSetupToken={createVaultSetupToken}
                isTermsAgreed={isTermsAgreed}
                setIsTermsAgreed={setIsTermsAgreed}
                redirectUrl={redirectUrl}
                selectedPlanFromChild={selectedPlanFromChild}
                isSubx={isSubx}
                activeService=""
              />
              {}
            </>
          ) : (
            <CardSelector
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              callback={() => {
                createSetupIntent()
                  .then(() => {
                    console.log("Payment method setup intent created")
                  })
                  .catch((error: any) => {
                    console.error("Error creating setup intent", error)
                  })
              }}
            />
          )}
        </>
      ) : (
        <>
          <PayPalCardFields
            isCheckedRef={isCheckedRef}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onApprove={onApprove}
            createVaultSetupToken={createVaultSetupToken}
            isTermsAgreed={isTermsAgreed}
            setIsTermsAgreed={setIsTermsAgreed}
            redirectUrl={redirectUrl}
            selectedPlanFromChild={selectedPlanFromChild}
            isSubx={isSubx}
            activeService=""
          />
          {}
        </>
      )}
    </div>
  )
}

export default PayPalSubscriptionPayLater
