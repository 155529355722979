import React, { FC, useState, useContext, useEffect, useRef } from "react"
import BackToButton from "../BackToButton"
import Loader from "../ui/loader"
import PayPalSubscriptionPayLater from "./PayPalSubscriptionPayLater"
import SubscriptionCheckoutCard from "./SubscriptionCheckoutCard"
import useDate from "../../hooks/useDate"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { PayPalSubscriptionPayNow } from "./PayPalSubscriptionPayNow"
import { SubscriptionContext } from "./SubscriptionContext"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { SubscriptionRequests, ReferralRequests } from "../../api/app.service"
import {
  SvgLock2,
  SvgPiechartOneQuarter,
  SvgPromoCode,
  SvgRecurring,
} from "../icons/"
import { useLocation } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import { useNavigate } from "react-router-dom"
import { ValidatePromoCodeResponseType } from "../../models/app.interface"

type Props = {}

type SelectedPlanType = {
  amount?: number
  is_promotional?: boolean
  recurring_interval?: string
  recurring_interval_count?: number
  zoee_product_name?: string
  zoee_product_metadata?: Record<string, string>
  [key: string]: any
  subscription: any
}

interface LocationState {
  from?: string
  subscription?: any
}

const SubscriptionCheckout: FC<Props> = () => {
  const { user, setUser } = useContext(UserCtx)
  const [loadingReferralCode, setLoadingReferralCode] = useState<boolean>(false)
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false)
  const [isPromocode, setIsPromocode] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState<string>("")
  const [isAutopopulatedPromoCode, setIsAutopopulatedPromoCode] =
    useState<boolean>(false)
  const [appliedDiscount, setAppliedDiscount] = useState<any>({
    amount: 0,
    promoCode: "",
  })
  const { renderError, renderSuccess, hideAlert, getUser } =
    useContext(CommonFunctionCtx)
  const user_id = parseInt(user.user_id)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [isSubscriptionUpdate, setIsSubscriptionUpdate] = useState(false)
  const locationState = location.state as LocationState
  const { subscription } = locationState || {}
  const [isIIN, setIsIIN] = useState(false)
  const { activeSubscription } = useContext(SubscriptionCtx)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const flow = queryParams.get("flow")
    const from = locationState?.from || ""
    if (
      flow === "IIN" ||
      from.toLowerCase() === "IINBusinessBlueprint".toLowerCase()
    ) {
      setIsIIN(true)
    } else {
      setIsIIN(false)
    }
  }, [location, locationState])

  const {
    createSetupIntent,
    selectedSubscription,
    setPaymentMethodModal,
    stripeRedirectParams,
  } = useContext(SubscriptionContext)
  const { mdDate } = useDate()
  const subxRedirectUrl = "/coach/subscription/mysubscription"
  const navigate = useNavigate()
  const [selectedPlanFromChild, setSelectedPlanFromChild] =
    useState<SelectedPlanType | null>(null)
  const isCheckedRef = useRef(false)
  const promo = appliedDiscount
  const zoee_subscription_id = selectedPlanFromChild?.zoee_subscription_id
  const value = selectedPlanFromChild?.amount
  const currentDate = new Date()
  const promoCodeStartDate = new Date("2025-04-03T00:00:00")

  const goBack = () => {
    localStorage.removeItem("referrerPromoCode")
    navigate("/coach/subscription/plans")
  }

  const orderTotal =
    parseFloat((selectedPlanFromChild?.amount || 0).toFixed(2)) -
    parseFloat((appliedDiscount?.amount || 0).toFixed(2))

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value)
  }

  const handlePromoResponse = (data: ValidatePromoCodeResponseType) => {
    if (!data.promo_valid) {
      renderError(data.promo_invalid_reason)
      setAppliedDiscount({ amount: 0, promoCode: "" })
      setIsPromocode(false)
      setPromoCode("")
    } else {
      const details = data.promo_code
      const discountType = details.promo_value_type
      const discountUnits = details.promo_value
      const promoCode = details.promo_code
      if (discountType === "percent") {
        const discountDollars =
          selectedPlanFromChild?.amount !== undefined
            ? (discountUnits / 100) * selectedPlanFromChild.amount
            : 0
        setAppliedDiscount({
          amount: discountDollars,
          promoCode: promoCode,
        })
        renderSuccess(
          `Congrats! Your promotion code is saving you $${discountDollars.toFixed(
            2
          )} on this payment`
        )
      } else if (discountType === "amount") {
        setAppliedDiscount({
          amount: discountUnits,
          promoCode: promoCode,
        })
        renderSuccess(
          `Congrats! Your ${
            isAutopopulatedPromoCode ? "Zoee Referral Program" : ""
          } promotion code ${
            isAutopopulatedPromoCode ? `${promoCode}` : ""
          } is saving you $${discountUnits.toFixed(2)} on this payment`
        )
      }
      setIsPromocode(false)
    }
  }

  const createOrder = async (data: any) => {
    setLoading(true)
    const saveCard = isCheckedRef.current
    try {
      const response = await SubscriptionRequests.purchaseFreeSubscription({
        user_id,
        subscription: {
          name: zoee_subscription_id,
          promo_details: promo,
          amount: {
            currency_code: "USD",
            value: value,
          },
          save_for_future: saveCard,
        },
      })
      renderSuccess(" 🎉Congrats! You are now subscribed!")
      setTimeout(() => {
        window.location.replace(subxRedirectUrl)
        setLoading(false)
      }, 2500)
    } catch (error) {
      setLoading(false)

      console.error("Error creating order:", error)
      throw error
    }
  }

  const validatePromoCode = () => {
    const interval = selectedPlanFromChild?.recurring_interval ?? ""
    const currentDate = new Date()

    const promoCodeStartDate = new Date("2025-04-03T00:00:00")
    const promoCodeEndDate = new Date("2025-04-18T23:59:59")

    if (promoCode.toLowerCase() === "radiant50") {
      renderError("This promo code is not valid.")
      return
    }

    if (
      promoCode.toLowerCase() === "bbhc20" &&
      (currentDate < promoCodeStartDate || currentDate > promoCodeEndDate)
    ) {
      renderError("This promo code is not valid.")
      return
    }

    if (promoCode !== "") {
      setLoadingReferralCode(true)
      SubscriptionRequests.validatePromoCode({
        promo_code: promoCode,
        promo_duration_interval: interval,
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          handlePromoResponse(data)
          setLoadingReferralCode(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingReferralCode(false)
        })
    }
  }

  const getReferralPromoCode = () => {
    setLoadingReferralCode(true)
    ReferralRequests.getReferralPromoCode({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        if (data.promo_code) {
          setIsPromocode(true)
          setPromoCode(data.promo_code)
          setIsAutopopulatedPromoCode(true)
        }
        setLoadingReferralCode(false)
      })
      .catch((ex) => {
        console.log(ex)
        console.error(ex.response.data.message)
        setLoadingReferralCode(false)
      })
  }

  const handleSelectedPlanChange = (selectedPlan: any) => {
    setSelectedPlanFromChild(selectedPlan)
  }

  useEffect(() => {
    const referrerPromoCode = localStorage.getItem("referrerPromoCode")
    if (referrerPromoCode) {
      setPromoCode(referrerPromoCode)
      setIsAutopopulatedPromoCode(true)
    }
  }, [])

  useEffect(() => {
    if (stripeRedirectParams) {
      setPaymentMethodModal(true)
    }
  }, [stripeRedirectParams])

  useEffect(() => {
    if (isAutopopulatedPromoCode) {
      validatePromoCode()
      setIsAutopopulatedPromoCode(false)
    }
  }, [isAutopopulatedPromoCode])

  useEffect(() => {
    if (
      selectedPlanFromChild?.amount === 500 &&
      selectedPlanFromChild?.recurring_interval_count === 2
    ) {
      setAppliedDiscount({
        amount: 0,
        promoCode: "500TWOYEAR",
      })
    }
    getReferralPromoCode()
  }, [])

  return (
    <div className="-mt-0 mb-[32px] md:-mt-[43px] md:mb-0 p-8">
      <div className="sm:ml-24">
        <BackToButton
          onClick={goBack}
          current={isSubscriptionUpdate ? "My Subscription" : "Plans & Pricing"}
          prev={isSubscriptionUpdate ? "My Subscription" : "Plans & Pricing"}
        />
        <h1 className="mb-8 mt-6 text-[36px] font-bold">Manage Subscription</h1>
      </div>
      <div className="mt-6 flex w-full flex-wrap flex-col md:mt-0 md:flex-row md:justify-center md:gap-[60px] md:px-0">
        <div>
          <div className="mb-[32px] flex flex-col items-center gap-[32px] md:flex-row md:gap-[24px]">
            <div className="w-fit">
              <h3 className="mb-[12px] text-[22px] font-bold md:mb-[24px]">
                New Plan
              </h3>
              <SubscriptionCheckoutCard
                isNewPlan={true}
                subscription={selectedSubscription}
                onSelectedPlanChange={handleSelectedPlanChange}
              />
            </div>
          </div>
        </div>

        <div className="border-grayCloud mt-[60px] flex flex-col border-0 md:mt-0 md:min-h-[856px] md:w-[50%] md:min-w-[416px] md:border-l md:pl-[80px]">
          <h3 className="mb-[32px] text-[22px] font-bold">Payment Details</h3>
          <h5 className="text-graySlate mb-[16px] text-base">
            PAYMENT SCHEDULE
          </h5>
          {isSubscriptionUpdate && (
            <p className="mb-[16px] text-base font-bold">
              The new subscription will start when the current period ends.
            </p>
          )}
          <div className="mb-[14px] flex items-center">
            <SvgPiechartOneQuarter className="mr-[16px] h-6 w-6" />
            {isSubscriptionUpdate ? (
              <p className="min-w-fit text-base">
                1st payment on{" "}
                {mdDate(subscription?.subscription_next_payment_date)}
              </p>
            ) : user.organization === "BBHC" &&
              activeSubscription.subscription_end_date &&
              currentDate >= promoCodeStartDate ? (
              <p className="min-w-fit text-base">
                1st payment on {mdDate(subscription?.subscription_end_date)}
              </p>
            ) : (
              <p className="min-w-fit text-base">1st payment today</p>
            )}
            <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
            <p className="min-w-fit text-base">
              $
              {(
                parseFloat(selectedPlanFromChild?.amount?.toFixed(2) || "0") -
                parseFloat(appliedDiscount?.amount?.toFixed(2) || "0")
              ).toFixed(2)}
            </p>
          </div>
          <div className="mb-[12px] flex items-center">
            <SvgRecurring className="mr-[16px] h-6 w-6 md:h-8 md:w-8" />
            <p className="min-w-fit text-base">Recurring payments</p>
            <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
            <p className="min-w-fit text-base">
              $
              {selectedPlanFromChild?.is_promotional
                ? 0
                : selectedPlanFromChild?.amount?.toFixed(2)}
            </p>
          </div>

          {selectedPlanFromChild?.amount !== 500 && (
            <p className="text-graySlate mb-[24px] text-base">
              Payments will be charged
              {selectedPlanFromChild?.recurring_interval === "annual" ? (
                <span className="text-mainBlack font-semibold text-black">
                  {" "}
                  annually{" "}
                </span>
              ) : (
                <span className="text-mainBlack font-semibold text-black">
                  {" "}
                  monthly{" "}
                </span>
              )}
              to your selected payment method.
            </p>
          )}
          <div className="bg-grayMist mb-[24px] h-[1px] w-full" />

          <div className="relative">
            <div className="mb-[16px] flex w-full items-center justify-between">
              <h5 className={`text-graySlate text-base`}>SUMMARY</h5>
              {loadingReferralCode ? (
                <Loader />
              ) : (
                <>
                  {isPromocode ? (
                    <button
                      onClick={() => setIsPromocode(() => !isPromocode)}
                      className="text-blurple text-base font-bold"
                    >
                      Cancel
                    </button>
                  ) : selectedPlanFromChild?.amount === 500 ? (
                    ``
                  ) : (
                    <button
                      onClick={() => setIsPromocode(() => !isPromocode)}
                      className="text-blurple flex items-center gap-[8px] text-base font-bold"
                    >
                      <SvgPromoCode fill="#3395FF" />
                      Add Promocode
                    </button>
                  )}
                </>
              )}
            </div>
            {isPromocode && (
              <div className="mb-[16px] flex items-center gap-[16px]">
                <div className="default-input w-[222px]">
                  <input
                    type="text"
                    placeholder="Enter Promocode"
                    onChange={handlePromoCodeChange}
                    value={promoCode}
                  />
                  <div className="overlay">Promocode</div>
                </div>
                <button
                  className="btn-primary bg-blurple h-14 w-[98px]"
                  onClick={validatePromoCode}
                >
                  Save
                </button>
              </div>
            )}
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">Interest or fees</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              <p className="min-w-fit text-base">$0.00</p>
            </div>
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">
                {`Promotions ${
                  appliedDiscount?.promoCode && `(${appliedDiscount.promoCode})`
                }`}
              </p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              <p className="min-w-fit text-base whitespace-nowrap">
                {appliedDiscount.promoCode === "ICFCOACH90"
                  ? "(3 months off)"
                  : `($${appliedDiscount.amount?.toFixed(2)})`}
              </p>
            </div>
          </div>

          <div className="mb-[24px] flex items-center">
            <p className="min-w-fit text-[18px] font-bold">Order total</p>
            <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
            <p>
              $
              {(
                parseFloat(selectedPlanFromChild?.amount?.toFixed(2) || "0") -
                parseFloat(appliedDiscount?.amount?.toFixed(2) || "0")
              ).toFixed(2)}
            </p>
          </div>
          <div className="bg-grayMist mb-[24px] h-[1px] w-full" />
          <div className="mb-[16px] flex items-center justify-between">
            <h5 className="text-graySlate text-base">PAYMENT METHOD</h5>
          </div>
          {orderTotal > 0 ? (
            <>
              <div className="mb-[24px] flex gap-[16px]">
                <div className="text-graySlate text-base">
                  By continuing, I have read and agree to the{" "}
                  <a
                    href="https://zoee.com/terms-and-conditions"
                    className="text-blurple"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Agreement Disclosures
                  </a>{" "}
                  and {/* TODO add href */}
                  <a href="#" className="text-blurple">
                    Payment Authorization
                  </a>
                  , and the
                  {/* TODO add href */}
                  <a href="#" className="text-blurple">
                    {" "}
                    refund policy{" "}
                  </a>
                  provided by Zoee. This payment method will be used for future
                  renewals unless canceled before your next scheduled payment.
                </div>
              </div>
              {user.organization !== "BBHC" ||
              (user.organization === "BBHC" &&
                !activeSubscription.subscription_end_date) ? (
                <PayPalSubscriptionPayNow
                  appliedDiscount={appliedDiscount}
                  createSetupIntent={createSetupIntent}
                  isTermsAgreed={isTermsAgreed}
                  setIsTermsAgreed={setIsTermsAgreed}
                  redirectUrl={subxRedirectUrl}
                  selectedPlanFromChild={selectedPlanFromChild}
                  isIIN={isIIN}
                />
              ) : user.organization === "BBHC" &&
                activeSubscription.subscription_end_date &&
                currentDate >= promoCodeStartDate ? (
                <PayPalSubscriptionPayLater
                  appliedDiscount={appliedDiscount}
                  createSetupIntent={createSetupIntent}
                  isTermsAgreed={isTermsAgreed}
                  setIsTermsAgreed={setIsTermsAgreed}
                  redirectUrl={subxRedirectUrl}
                  selectedPlanFromChild={selectedPlanFromChild}
                  isIIN={isIIN}
                />
              ) : (
                <PayPalSubscriptionPayNow
                  appliedDiscount={appliedDiscount}
                  createSetupIntent={createSetupIntent}
                  isTermsAgreed={isTermsAgreed}
                  setIsTermsAgreed={setIsTermsAgreed}
                  redirectUrl={subxRedirectUrl}
                  selectedPlanFromChild={selectedPlanFromChild}
                  isIIN={isIIN}
                />
              )}
              <div className="mx-auto flex items-center gap-[8px] mt-2 mb-4">
                <SvgLock2 />
                <p className="text-gray text-base">
                  Guaranteed safe and secure payments
                </p>
              </div>
            </>
          ) : (
            <div className="flex flex-col mt-2">
              <p className="text-gray text-base mb-4 text-left">
                No payment required for this subscription.
              </p>
              <div className="bg-grayMist mb-[24px] h-[1px] w-full" />

              <Button
                onClick={() => {
                  createOrder({})
                }}
              >
                {loading ? <Loader /> : "Complete Order"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCheckout
