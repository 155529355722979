import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import CalendarNav from "./cm-images/CalendarNav.png"
import Calendar from "./cm-images/Calendar.png"
import ProfileIcon from "./cm-images/ProfileIcon.png"
import ProfileDropdown from "./cm-images/ProfileDropdown.png"
import CalSettings from "./cm-images/CalSettings.png"
import NewCal from "./cm-images/NewCal.png"
import CalPicker from "./cm-images/CalPicker.png"
import CalConfirm from "./cm-images/CalConfirm.png"
import ViewCals from "./cm-images/ViewCals.png"
import CalDropdwon from "./cm-images/CalDropdown.png"
import MakePrimary from "./cm-images/MakePrimary.png"
import Availability from "./cm-images/Availability.png"
import AvailRules from "./cm-images/AvailRules.png"
import SaveAvail from "./cm-images/SaveAvail.png"
import AddNewAvail from "./cm-images/AddNewAvail.png"
import MakeDefault from "./cm-images/MakeDefault.png"
import SaveNewAvail from "./cm-images/SaveNewAvail.png"

export default function CMCoachingCalendar() {
  return (
    <SectionWrapper id="coaching-calendar">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Managing Your{" "}
          <span className="text-[#4750F5]">Coaching Session Calendar</span>
        </h1>
      </div>

      <p className="text-gray-700 mt-8 text-center">
        Learn how to access your{" "}
        <a
          href="#access-calendar"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          calendar settings
        </a>
        ,{" "}
        <a
          href="#connect-calendar"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          connect your personal calendar
        </a>
        ,{" "}
        <a
          href="#manage-availability"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          set availability{" "}
        </a>
        for coaching sessions,{" "}
        <a
          href="#custom-schedule"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          create custom schedules
        </a>
        , and{" "}
        <a
          href="#calendar-troubleshooting"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          troubleshoot common calendar issues
        </a>
        .
      </p>

      <h3
        id="access-calendar"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Accessing Your Calendar Settings
      </h3>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Option 1: From My Calendar
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Click on <strong>"My Calendar"</strong> in the main navigation bar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CalendarNav}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Your current calendar view will appear.</li>
          <li>
            Look for and click on the <strong>"Calendar"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Calendar}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>This will take you to your calendar settings page.</li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Option 2: From Account Settings
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Click on your profile icon in the top corner.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ProfileIcon}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select "Account Settings" from the dropdown menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ProfileDropdown}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Find and click on "Calendar Settings" in the menu</li>
          <div className="flex justify-center mt-4">
            <img
              src={CalSettings}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Your calendar management options will appear</li>
        </ul>
      </div>

      <h3
        id="connect-calendar"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Connecting Your Personal Calendar
      </h3>
      <p className="text-gray-700 mt-4">
        Connecting your personal calendar ensures your coaching sessions appear
        in your regular schedule.
      </p>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            In <strong>Calendar Settings</strong>, find the{" "}
            <strong>"Sync Another Calendar"</strong> section.
          </li>
          <li>
            Click the <strong>"New Calendar"</strong> button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NewCal}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your calendar provider:</li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Google Calendar</li>
            <li>Office 365</li>
            <li>Outlook</li>
            <li>Apple iCloud</li>
          </ul>
          <div className="flex justify-center mt-4">
            <img
              src={CalPicker}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A new window will open asking for permission.</li>
          <div className="flex justify-center mt-4">
            <img
              src={CalConfirm}
              alt="Calendar Navigation"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click <strong>"Allow"</strong> or <strong>"Authorize"</strong> to
            permit Cronofy (Zoee's calendar sync tool) to access your calendar.
          </li>
          <li>
            You'll see a confirmation message when the connection is complete.
          </li>
        </ul>
      </div>

      <h3
        id="manage-multiple-calendars"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Managing Multiple Calendars
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <p className="text-gray-700">
          {" "}
          If you connect more than one calendar to Zoee:
        </p>
        <h3 className="text-xl font-semibold text-gray-700 mt-4">
          Setting Your Primary Calendar
        </h3>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>
            Click on the “View Calendars” button in the connected accounts
            section.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ViewCals}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the settings icon(⚙️) beside the calendar you want to be
            your primary.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CalDropdwon}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>In the appearing popup click on the “Make primary” button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={MakePrimary}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Close the window once you are finished.</li>
          <li>
            This calendar will be used as the default for syncing coaching
            sessions.
          </li>
        </ul>

        <h3 className="text-xl font-semibold text-gray-700 mt-6">
          Disconnecting a Calendar
        </h3>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Locate the calendar you wish to disconnect.</li>

          <li>Click the "Disconnect" option next to that calendar.</li>

          <li>Confirm your choice when prompted. </li>

          <li className="italic">
            Note: You can only disconnect calendars if you have more than one
            connected
          </li>
        </ul>
      </div>

      <h3
        id="manage-availability"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Setting Your Availability for Coaching Sessions
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            In <strong>Calendar Settings</strong>, select{" "}
            <strong>"Availability"</strong>.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={Availability}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>You'll see your current default availability schedule.</li>
          <li>Review the pre-generated default schedule.</li>
          <li>Click on days to toggle availability on/off.</li>
          <div className="flex justify-center mt-4">
            <img
              src={AvailRules}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            For available days, adjust the time ranges when you can attend
            sessions.
          </li>
          <li>
            Set buffer times between sessions if needed(time gaps before/after
            appointments).
          </li>
          <li>
            Specify minimum booking notice (how far in advance sessions must be
            scheduled).
          </li>
          <li>After adjusting your availability, click the "Save" button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SaveAvail}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Your new availability settings will take effect immediately.</li>
        </ul>
      </div>

      <h3
        id="custom-schedule"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Creating Custom Availability Schedules
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            In <strong>Availability Settings</strong>, find the{" "}
            <strong>"Schedules"</strong> section.
          </li>
          <li>
            Click <strong>"Add New"</strong> to create a custom schedule.
          </li>

          <div className="flex justify-center mt-4">
            <img
              src={AddNewAvail}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Give your new schedule a descriptive name (e.g., "Summer Hours").
          </li>
          <li>Set the specific days and times for this schedule.</li>
          <li>
            Configure any buffer times or booking notices for this schedule.
          </li>
          <li>
            Click <strong>"Save"</strong> to store your custom schedule.
          </li>
          <li>After adjusting your availability, click the "Save" button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SaveNewAvail}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            To use a custom schedule as your new default, click the star icon
            next to its name.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MakeDefault}
              alt="Managing Availability"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>This will replace your previous default schedule.</li>
        </ul>
      </div>

      <h3
        id="calendar-troubleshooting"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Troubleshooting Calendar Issues
      </h3>
      <div className="bg-white p-6 rounded-lg mt-2">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            <strong>Sessions not appearing:</strong> Check that you've connected
            the calendar you regularly use.
          </li>
          <li>
            <strong>Sync delays:</strong> Allow up to 15 minutes for new
            sessions to appear in your external calendar.
          </li>
          <li>
            <strong>Availability conflicts:</strong> Ensure your Zoee
            availability matches when you're actually free.
          </li>
        </ul>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
