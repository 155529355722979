import React from "react"
import { capitalize } from "../../utils/capitalize"
import ContactAvatar from "../cards/ContactAvatar"
import { SvgCheckbox } from "../icons/"

interface Props {
  contact: any
  selectedContacts: any
}

const User: React.FC<Props> = ({ contact, selectedContacts }) => {
  const ids = selectedContacts.map((a: any) => a.profile_id)

  return (
    <div
      className={`h-[68px] w-full p-[14px] flex items-center justify-between
            ${
              selectedContacts.includes(contact?.profile_id) &&
              "bg-primaryBlue/[0.04] rounded-[14px]"
            }`}
    >
      <div className="flex items-center">
        <div className="w-[40px] h-[40px] mr-[16px]">
          <ContactAvatar
            contact={contact}
            width={40}
            height={40}
            bold={false}
            fontSize={"text-[16px]"}
          />
        </div>
        <div className="flex flex-col">
          <h6 className="font-bold text-[16px]">{contact?.contact_name}</h6>
          <p className="text-graySlate text-base">
            {capitalize(contact?.profile_type ?? "")}
          </p>
        </div>
      </div>
      <div
        className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                flex items-center justify-center
                ${ids.includes(contact?.profile_id) && "bg-cyanBlue"}`}
      >
        {ids.includes(contact?.profile_id) && <SvgCheckbox />}
      </div>
    </div>
  )
}

export default User
