import {
  SvgCredentials,
  SvgScheduleSession,
  SvgNotifications,
  SvgCalendar,
  SvgBilling,
  SvgChatBubble,
  SvgVideoIcon,
  SvgNotebook,
  SvgInvite,
  SvgHelp,
  SvgUser2,
  SvgChat,
  SvgVideoOutline,
  SvgLearn,
  SvgQuoteLeft2,
} from "../../components/icons"

export default function CMTableOfContents() {
  return (
    <nav id="table-of-contents" className="mb-12 bg-gray-100 p-6 rounded-xl">
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 justify-center items-center">
        {/* Account Setup */}
        <a
          href="#setup"
          className="group flex flex-col items-center justify-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgUser2 className="w-12 h-12 text-[#4750F5] group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium group-hover:text-white text-center">
            Account Setup
          </span>
        </a>


        {/* Personal Info */}
        <a
          href="#password-info"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgCredentials className="w-12 h-12  text-[#4750F5]  group-hover:text-white " />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Password and Personal Info
          </span>
        </a>
        {/* Notifications */}
        <a
          href="#notifications"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgNotifications className="w-12 h-12  text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Set Your Notifications
          </span>
        </a>

        {/* Inbox */}
        <a
          href="#inbox"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgChat className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Exchange Secure Messages
          </span>
        </a>

        {/* Calendar */}
        <a
          href="#coaching-calendar"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgCalendar className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Manage Your Calendar
          </span>
        </a>

        {/* Scheduling */}
        <a
          href="#scheduling"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgScheduleSession className="w-12 h-12 text-white stroke-[#4750F5] stroke-2  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Schedule Sessions
          </span>
        </a>

        {/* Sessions */}
        <a
          href="#sessions"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgVideoOutline className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium  group-hover:text-white">
            Join a Session
          </span>
        </a>

        {/* Billing */}
        <a
          href="#billing"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgBilling className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2  text-center text-gray-700 font-medium  group-hover:text-white">
            Manage Your Services and Payments
          </span>
        </a>

        {/* Notebook */}
        <a
          href="#session-notebook"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgLearn className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium  group-hover:text-white">
            Create Notes
          </span>
        </a>

        {/* Forms */}
        <a
          href="#coach-forms"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgInvite className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Access Shared Forms
          </span>
        </a>

        {/* Testmonials */}
        <a
          href="#testimonials"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgQuoteLeft2 className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium text-center  group-hover:text-white">
            Leave a Testimonial
          </span>
        </a>

        {/* Tech Support */}
        <a
          href="#tech-support"
          className="group flex flex-col items-center bg-white p-4 rounded-lg shadow transition-colors duration-300 hover:bg-[#4750F5]"
        >
          <SvgHelp className="w-12 h-12 text-[#4750F5]  group-hover:text-white" />
          <span className="mt-2 text-gray-700 font-medium  group-hover:text-white">
            Tech Support
          </span>
        </a>
      </div>
    </nav>
  )
}
