import React, { useEffect, useContext, useState, useMemo } from "react"
import CoachHowTos from "./CoachHowTos"
import NavShell from "./NavShell"
import NotificationCenter from "../notifications/NotificationCenter"
import useName from "../../hooks/useName"
import UserButton from "./UserButton"
import UserButtonMobile from "./UserButtonMobile"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getUserColor } from "../../utils/getUserColor"
import { getTextColor } from "../../utils/getTextColor"
import { Link, useLocation } from "react-router-dom"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { NotificationCtx } from "../../context/notificationContext"
import { NotificationRequests } from "../../api/app.service"
import { NavLinkItem } from "./NavLinkItem"
import {
  SvgBilling,
  SvgCalendar,
  SvgChat,
  SvgClose,
  SvgHamburger,
  SvgHome,
  SvgListCheck,
  SvgNotifications,
  SvgSettingsSlider,
  SvgUser3,
  SvgVideoOutline,
  SvgZoeeIcon,
  SvgZoeeLogo,
} from "../icons/"
import { UserCtx } from "../../context/userContext"
import { SvgZoeeGradientFavicon } from "../icons/logos/zoee-gradient-favicon"
import { SvgZoeeGradientLogo } from "../icons/logos/zoee-gradient-logo"

interface Props {
  guideOpened: any
  setGuideOpened: any
}

const CoachAuthedNav: React.FC<Props> = ({ guideOpened, setGuideOpened }) => {
  const { user } = useContext(UserCtx)
  const { initial } = useName()
  const authenticatedUser = useAuthenticatedUser(user)
  const location = useLocation()
  const { renderError, previewImgUrl } = useContext(CommonFunctionCtx)
  const { notificationAlert } = useContext(NotificationCtx)
  const [isNewNotificationAlert] = useState<boolean>(
    notificationAlert.message !== ""
  )
  const {
    inboxNotifications,
    setInboxNotifications,
    contactsNotifications,
    setContactsNotifications,
  } = useContext(NotificationCtx)
  const [showNotificationCenter, setShowNotificationCenter] =
    useState<boolean>(false)
  const [notifications, setNotifications] = useState<any[]>([])
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [showUserButton, setShowUserButton] = useState<boolean>(false)
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
  const createdAt = new Date(user.createdAt)
  createdAt.setMonth(createdAt.getMonth() + 1)

  const isCoach = user.activeProfile === "coach"
  const unreadInboxNotificationNumber = useMemo(() => {
    return inboxNotifications.filter((n: any) => !n.viewed).length
  }, [inboxNotifications])

  const unreadContactsNotificationNumber = useMemo(() => {
    return contactsNotifications.filter((n: any) => !n.viewed).length
  }, [contactsNotifications])

  const getNotifications = () => {
    if (authenticatedUser) {
      NotificationRequests.getNotifications({
        profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          const generalNotifications = data.notifications.filter(
            (n) => n.notification_category !== "messages"
          )
          const inboxNotifs = data.notifications.filter(
            (n) => n.notification_category === "messages"
          )
          setInboxNotifications(inboxNotifs)
          setNotifications([...generalNotifications, ...inboxNotifs])
          const unreadContactNotifications = data.notifications.filter((n) => {
            return n.slug === "received_connection_request" && n.viewed === 0
          })
          setContactsNotifications(unreadContactNotifications)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  useEffect(() => {
    if (showNotificationCenter) {
      setShowNotificationCenter(false)
    }
  }, [location])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    getNotifications()
  }, [notificationAlert.show])

  useEffect(() => {
    if (isNewNotificationAlert) {
      getNotifications()
    }
  }, [isNewNotificationAlert])

  const isMobile = window.innerWidth <= 642

  return (
    <>
      <NavShell
        className="flex items-center justify-between"
        outerClassName="hidden lg:flex"
      >
        <Link to="/">
          <SvgZoeeGradientFavicon />
        </Link>

        <div className="hidden items-center lg:flex lg:gap-2">
          <NavLinkItem to="/coach/dashboard">
            <SvgHome />
            Home
          </NavLinkItem>
          <NavLinkItem
            to="/coach/inbox"
            notifications={unreadInboxNotificationNumber}
          >
            <SvgChat />
            Inbox
          </NavLinkItem>
          <NavLinkItem
            to="/coach/contacts"
            notifications={unreadContactsNotificationNumber}
          >
            <SvgUser3 />
            Contacts
          </NavLinkItem>
          <NavLinkItem to="/coach/scheduling">
            <SvgCalendar />
            Calendar
          </NavLinkItem>
          <NavLinkItem to="/coach/sessions">
            <SvgVideoOutline />
            Sessions
          </NavLinkItem>
          <NavLinkItem to="/coach" end>
            <SvgListCheck />
            Manage
          </NavLinkItem>
        </div>

        <div className="relative flex items-center justify-end lg:gap-[24px]">
          <div className="relative">
            <SvgNotifications
              className="cursor-pointer"
              onClick={() => {
                setShowNotificationCenter((prev) => !prev)
              }}
            />
            {notifications.filter((n) => !n.viewed).length > 0 && (
              <Badge dot variant="alert" className="absolute -right-1 -top-1" />
            )}
            <style>
              {`
                  @media (min-width: 1024px) and (max-width: 1043px) {
                    .gap-20 {
                      gap: 5px;
                    }
                  }
                `}
            </style>
          </div>

          {showNotificationCenter && (
            <NotificationCenter
              notifications={notifications}
              setNotifications={setNotifications}
              showNotificationCenter={showNotificationCenter}
              setShowNotificationCenter={setShowNotificationCenter}
            />
          )}

          <UserButton
            guideOpened={guideOpened}
            setGuideOpened={setGuideOpened}
          />
        </div>
      </NavShell>

      {/* Mobile */}
      <nav className="relative sticky top-2.5 z-30 mx-2.5 mb-10 flex lg:hidden">
        <div
          className="relative mx-auto flex h-20 w-full max-w-screen-xl items-center justify-between rounded-lg bg-white px-2.5 py-5 sm:px-4"
          style={{
            boxShadow:
              "0 10px 20px -5px rgba(111, 89, 246, 0.15), 0 -5px 10px -5px rgba(111, 89, 246, 0.15), 0 6px 10px -5px rgba(111, 89, 246, 0.10)",
          }}
        >
          <Button
            variant="ghost"
            className="ml-4 lg:hidden"
            size="icon"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            {showMobileNav ? <SvgClose /> : <SvgHamburger />}
          </Button>

          {showMobileNav && (
            <div className="zoee-backdrop-filter fixed left-0 top-0 z-[601] h-screen w-screen"></div>
          )}

          <div
            className={`fixed left-0 top-0 flex h-screen flex-col overflow-y-auto rounded-lg bg-white bg-white p-8 transition-all duration-500 ease-in-out ${
              showMobileNav ? "left-0 w-[279px]" : "left-[-279px] w-0"
            } z-[1000]`}
            style={{
              boxShadow:
                "5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.1)",
            }}
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <Button
              className="bg-grayFlash absolute right-[20px] top-[25px] rounded-full"
              size="icon"
              variant="ghost"
              onClick={() => setShowMobileNav(!showMobileNav)}
            >
              <SvgClose />
            </Button>
            <Link
              to="/"
              onClick={() => setShowMobileNav(false)}
              className="mb-[48px] mt-[10px]"
            >
              <SvgZoeeGradientLogo className="h-auto w-[155px]" />
            </Link>
            <div className="mb-[56px] flex flex-col gap-[20px] text-center text-[18px]">
              <NavLinkItem to="/coach/dashboard">
                <SvgHome />
                Home
              </NavLinkItem>
              <NavLinkItem
                to="/coach/inbox"
                notifications={unreadInboxNotificationNumber}
              >
                <SvgChat />
                Inbox
              </NavLinkItem>
              <NavLinkItem
                to="/coach/contacts"
                notifications={unreadContactsNotificationNumber}
              >
                <SvgUser3 />
                Contacts
              </NavLinkItem>
              <NavLinkItem to="/coach/services">
                <SvgBilling />
                Services
              </NavLinkItem>
              <NavLinkItem to="/coach/scheduling">
                <SvgCalendar />
                Calendar
              </NavLinkItem>
              <NavLinkItem to="/coach/sessions">
                <SvgVideoOutline />
                Sessions
              </NavLinkItem>
              <NavLinkItem to="/coach" end>
                <SvgSettingsSlider />
                Manage
              </NavLinkItem>
            </div>
          </div>

          <div className="relative mr-2 flex items-center justify-end gap-[20px] lg:gap-[24px]">
            <div className="relative">
              <SvgNotifications
                className="cursor-pointer"
                onClick={() => {
                  setShowNotificationCenter((prev) => !prev)
                }}
              />
              {notifications.filter((n) => !n.viewed).length > 0 && (
                <Badge
                  dot
                  variant="alert"
                  className="absolute -right-1 -top-1"
                />
              )}
            </div>

            {showNotificationCenter && (
              <NotificationCenter
                notifications={notifications}
                setNotifications={setNotifications}
                showNotificationCenter={showNotificationCenter}
                setShowNotificationCenter={setShowNotificationCenter}
              />
            )}
            {showUserButton ? (
              <SvgClose />
            ) : (
              <Button
                variant="ghost"
                className="h-10 w-10 rounded-full"
                onClick={() => setShowUserButton(() => !showUserButton)}
              >
                <Avatar size="xs">
                  {Boolean(user.hasAvatar) && (
                    <AvatarImage
                      alt="avatar"
                      src={previewImgUrl || user.avatar_url}
                    />
                  )}
                  <AvatarFallback className={getUserColor(user.userColor)}>
                    <div
                      style={{
                        color: getTextColor(user.userColor),
                      }}
                    >
                      {initial}
                    </div>
                  </AvatarFallback>
                </Avatar>
              </Button>
            )}
            <UserButtonMobile
              setShowUserButton={setShowUserButton}
              showUserButton={showUserButton}
              guideOpened={guideOpened}
              setGuideOpened={setGuideOpened}
            />
          </div>
        </div>
      </nav>
      <div className="hidden h-24 md:block"></div>
      {isCoach &&
      guideOpened &&
      location.pathname !== "/coach/edit-website" &&
      (location.pathname !== "/account-settings" || !isMobile) ? (
        <div
          className={` ${
            isSidePanelOpen ? "left-0" : "-left-[300px]"
          } hover:shadow-custom fixed top-[100px] md:top-[150px] z-[1001] flex h-[550px] w-[300px] cursor-pointer items-center justify-center rounded-xl transition-all duration-500 ease-in-out`}
          style={{ backgroundColor: "#4750F5" }}
        >
          <CoachHowTos />
          <div
            onClick={() => setIsSidePanelOpen(!isSidePanelOpen)}
            className={`shadow-outline } fixed sm:top-[606px] md:top-[295px] z-[1000] -mr-[349px] flex h-[50px] w-[155px] -rotate-90 transform cursor-pointer items-center justify-center rounded-bl-lg rounded-br-lg
          `}
            style={{ backgroundColor: "#4750F5" }}
          >
            {isSidePanelOpen ? (
              <h1 className="text-sm font-bold text-white">
                <span>Close</span>
              </h1>
            ) : (
              <h1 className="text-sm font-bold text-white">
                <span>How</span>
                <span className="ml-2">To's</span>
              </h1>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CoachAuthedNav
