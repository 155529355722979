import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import VerifyMobile from "./cm-images/VerifyMobile.png"
import ConsultMobile from "./cm-images/ConsultMobile.png"
import PickTimeMobile from "./cm-images/PickTimeMobile.png"
import ConsultConfirmMobile from "./cm-images/ConsultConfirmMobile.png"
import EnrollMobile from "./cm-images/EnrollMobile.png"
import ScheduledMobile from "./cm-images/ScheduledMobile.png"
import MobileHamburger from "./cm-images/MobileHamburger.png"
import MyCoachesMobile from "./cm-images/MyCoachesMobile.png"
import SelectCoachMobile from "./cm-images/SelectCoachMobile.png"
import ContactPageMobile from "./cm-images/ContactPageMobile.png"
import ContactConsult from "./cm-images/ContactConsult.png"
import ContactSeeAll from "./cm-images/ContactSeeAll.png"
import SeeDetailsMobile from "./cm-images/SeeDetailsMobile.png"
import EnrollServiceMobile from "./cm-images/EnrollServiceMobile.png"
import PromoMobile from "./cm-images/PromoMobile.png"
import PayNowMobile from "./cm-images/PayNowMobile.png"
import PendingMobile from "./cm-images/PendingMobile.png"
import AcceptMobile from "./cm-images/AcceptMobile.png"
import SchedMobile from "./cm-images/SchedMobile.png"
import NextMobile from "./cm-images/NextMobile.png"
import ConfirmTimeMobile from "./cm-images/ConfirmTimeMobile.png"
import SchedLater from "./cm-images/SchedLater.png"
import RecurringMobile from "./cm-images/RecurringMobile.png"
import CalendarNavMobile from "./cm-images/CalendarNavMobile.png"
import ReschedMobile from "./cm-images/ReschedMobile.png"
import ReschedPageMobile from "./cm-images/ReschedPageMobile.png"
import CalendarSchedMobile from "./cm-images/CalendarSchedMobile.png"
import CancelSchedMobile from "./cm-images/CancelSchedMobile.png"
import CalMobile from "./cm-images/CalMobile.png"

export default function CMSchedulingMobile() {
  return (
    <SectionWrapper id="scheduling">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Booking & Managing{" "}
          <span className="text-[#4750F5]">Sessions and Appointments</span>
        </h1>
        <p className="text-gray-700 mt-8 text-center">
          You can book sessions in Zoee by booking via a{" "}
          <a
            href="#book-profile"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            coach's profile{" "}
          </a>
          or using the{" "}
          <a
            href="#book-dropdown"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            scheduling dropdown.{" "}
          </a>
          You can also{" "}
          <a
            href="#reschedule-session"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            reschedule{" "}
          </a>
          or{" "}
          <a
            href="#cancel-session"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            cancel{" "}
          </a>
          a session.
        </p>
      </div>

      <div className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h3 id="book-profile" className="text-2xl font-semibold text-blue-700">
          Booking Sessions With Your Coach
        </h3>
      </div>

      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <h4 className="text-xl font-semibold text-gray-700 mb-4">
          Book Consultation via Coach's Zoee Website
        </h4>
        <p>
          You will have received a link from your coach inviting you to view
          their Zoee profile by email or text. You can book a consultation using
          the following steps:
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Click on the link provided to you by your coach.</li>
          <li>
            Click on the “Book a Consult” sidebar item on the left-hand side of
            your screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConsultMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Pick a day and time that is convenient for you and click the “Book”
            button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PickTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Fill out the appropriate information in the appearing pop-up.</li>
          <li>Click the next button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ConsultConfirmMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            You will receive an email to verify your new Zoee account. Click on
            the “Confirm my email” button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={VerifyMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the “Back to Account” button on the top right-hand side of
            the navigation bar.
          </li>
          <li>
            Preview the time of the consultation that appears and click on
            “Enroll in Service” to proceed.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={EnrollMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            A pop-up will appear to confirm your consultation has been
            scheduled.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ScheduledMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <div className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h4 id="book-dropdown" className="text-xl font-semibold text-blue-700">
          Book Consultation via Coach Contact Card
        </h4>
      </div>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-0">
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Coaches” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoachesMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select Your Coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectCoachMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the dropdown button labeled “Schedule with Coach.” This
            will produce 2 options: “See all Services” and “Consultation.”
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ContactConsult}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Click on the “Consultation” button from the dropdown menu.</li>
          <li>Select a time on the new page.</li>
          <li>Click on the “Next” button.</li>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
        </ul>
      </div>

      <h4 className="text-2xl font-semibold text-blue-700 mt-8">
        Purchasing Services
      </h4>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-4">
          <li>
            Click on the “See All Services” button from the dropdown menu.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ContactSeeAll}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the “See Details” button for the service you want to
            purchase.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SeeDetailsMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Click on the “Enroll in Service” button on the page.</li>
          <div className="flex justify-center mt-4">
            <img
              src={EnrollServiceMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Optional: If you were provided with a promocode, click on the “Add
            promocode” button and insert the code in the appearing space.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PromoMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Review the details of your purchase.</li>
          <li>
            Pay through PayPal or by entering your card details and clicking the
            “Pay now” button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PayNowMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A pop-up confirming your enrollment should appear.</li>
        </ul>
      </div>

      <h4 className="text-2xl font-semibold text-blue-700 mt-8 mb-4">
        Invited to Service
      </h4>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <p>
          You will receive an email or text informing you that your coach has
          invited you to a service, and prompting you to sign into your account.
          To view the invitation, follow these steps:
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Coaches” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoachesMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectCoachMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down the page to the “Services I enrolled into” section.
          </li>
          <li>
            Click on the “Pending” button. It will have a notification
            identifier beside it.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PendingMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Click on either the “Accept invite” button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={AcceptMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Review the details of your purchase.</li>
          <li>
            Pay through PayPal or by entering your card details and clicking the
            “Pay now” button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={PayNowMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>A pop-up confirming your enrollment should appear.</li>
          <li>
            Click on the "Schedule" button of the pop-up confirming your
            enrollment after purchasing a service.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SchedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the next button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NextMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConfirmTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h4 className="text-2xl font-semibold text-blue-700 mt-8 mb-4">
        Booking Session After Purchasing Service
      </h4>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <p>
          You can schedule your session for your service either immediately
          after purchasing or at a later time.
        </p>
        <h4 className="text-xl font-semibold text-blue-700 mt-8 mb-2">
          1. After purchasing
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Click on the "Schedule" button of the pop-up confirming your
            enrollment after purchasing a service.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SchedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the next button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NextMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConfirmTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <h4 className="text-xl font-semibold text-blue-700 mt-8 mb-2">
          2. Scheduling at a later time
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Log into Zoee.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Coaches” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoachesMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectCoachMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Navigate down their page to the “Services I enrolled into” section.
          </li>
          <li>
            Locate the service you want to schedule a session for and click the
            “Schedule Session”.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SchedLater}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the next button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NextMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConfirmTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h4 className="text-2xl font-semibold text-blue-700 mt-8 mb-4">
        Scheduling Recurring Sessions
      </h4>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <p>
          For a recurring service purchased from your coach, you or your coach
          will need to schedule the next session till the total amount of
          sessions has been redeemed. For each session you need to schedule, you
          can follow these steps:
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Coaches” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoachesMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectCoachMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Scroll down the page to the “Services I enrolled into” section.
          </li>
          <li>Select the service you want to schedule a session for.</li>
          <div className="flex justify-center mt-4">
            <img
              src={RecurringMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a time that works for you then
            click the next button.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NextMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConfirmTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li className="italic">
            If you encounter a problem scheduling sessions in a recurring
            package, reach out to Zoee support..
          </li>
        </ul>
      </div>

      <h4
        id="reschedule-session"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Rescheduling your Session
      </h4>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <h4 className="text-xl font-semibold text-blue-700 mt-4">
          From the Homepage
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Click on the “Reschedule session” button on the session displayed on
            the calendar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ReschedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Scroll down to the When section.</li>
          <li>Click on the “Reschedule” button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ReschedPageMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a new time that works for you
            then click the next button
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NextMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConfirmTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
        <h4 className="text-xl font-semibold text-blue-700 mt-8">
          From My Calendar
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Calendar” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={CalMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the “Manage sessions” button on the session displayed on
            the page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CalendarSchedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Scroll down to the When section.</li>
          <li>Click on the “Reschedule” button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={ReschedPageMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Browse the available times and select a new time that works for you
            then click the next button
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NextMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Review the event details on the page and click the “Confirm” button
            to complete scheduling.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConfirmTimeMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>

      <h4
        id="cancel-session"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Cancelling your session
      </h4>
      <div className="bg-white p-6 rounded-lg mt-[-14px] ">
        <p>
          We understand that plans can change unexpectedly, and you may need to
          cancel a scheduled session. Here are the steps you can take to do so.
        </p>
        <h4 className="text-xl font-semibold text-blue-700 mt-8">
          From the Homepage
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            Click on the “Reschedule session” button on the session displayed on
            the calendar.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ReschedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Scroll down to the When section.</li>
          <li>Click on the “Cancel Meeting” button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={CalendarSchedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            You will see a quick popup confirming your meeting has been
            successfully cancelled.
          </li>
        </ul>
        <h4 className="text-xl font-semibold text-blue-700 mt-8">
          From My Calendar
        </h4>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>Log into Zoee.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Calendar” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={CalMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Click on the “Manage sessions” button on the session displayed on
            the page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={CalendarSchedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Scroll down to the When section.</li>
          <li>Click on the "Cancel" button.</li>
          <div className="flex justify-center mt-4">
            <img
              src={CancelSchedMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            You will see a quick popup confirming your meeting has been
            successfully cancelled.
          </li>
        </ul>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
