import React, { useContext, useEffect } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { AuthContext, OnSuccessfulAuthCallback } from "./context"
import { LoginForm } from "./login-form"
import { RegisterForm } from "./register-form"
import { Dialog, DialogContent } from "../ui/dialog"
import { useNavigate } from "react-router-dom"

export const AuthModal = () => {
  const {
    authAction,
    open,
    setOpen,
    email,
    accountType,
    onSuccessfulAuth,
    phone,
    firstName,
    lastName,
    password,
  } = useContext(AuthContext)
  const [parent] = useAutoAnimate()
  const navigate = useNavigate()

  const onSuccess: OnSuccessfulAuthCallback = (user) => {
    setOpen(false)

    if (onSuccessfulAuth) {
      onSuccessfulAuth(user)
    } else {
      if (user.isCoach) {
        navigate("/coach/dashboard")
      } else {
        navigate("/member")
      }
    }
  }

  const renderForm = () => {
    switch (authAction) {
      case "register":
        return (
          <RegisterForm
            defaultEmail={email}
            defaultAccountType={accountType}
            onSuccess={onSuccess}
            defaultPhone={phone}
            defaultFirstName={firstName}
            defaultLastName={lastName}
            defaultPassword={password}
          />
        )
      case "login":
        return <LoginForm defaultEmail={email} onSuccess={onSuccess} />
      default:
        return null
    }
  }

  useEffect(() => {}, [open])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null
      if (open && target && !target.closest(".modal-container")) {
        setOpen(false)
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleOutsideClick)
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={setOpen} modal={false}>
      <DialogContent
        className="max-w-lg modal-container"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
        aria-describedby="modal-description"
      >
        <p id="modal-description" className="sr-only">
          This modal contains a form for logging in or registering an account.
        </p>

        <div ref={parent}>{renderForm()}</div>
      </DialogContent>
    </Dialog>
  )
}
