import { useContext, useState, FC, ChangeEvent, useEffect } from "react"
import { NotificationRequests } from "../../../../api/app.service"
import { UserCtx } from "../../../../context/userContext"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import NotificationCategory from "../../../../components/notifications/NotificationSettingsCategory"
import {
  Notification,
  UpdateNotificationRequestItem,
} from "../../../../types/notificationTypes"
import useScrollToTop from "../../../../hooks/useScrollToTop"
import Loader from "../../../../components/ui/loader"
import useLoading from "../../../../hooks/useLoading"
import { Button } from "../../../../components/ui/button"
import { SvgCheckCircle } from "../../../../components/icons/"
import { capitalize } from "../../../../utils/capitalize"

const NotificationsPage: FC = () => {
  const { user } = useContext(UserCtx)
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [notificationValues, setNotificationValues] = useState<Notification[]>(
    []
  )
  const [updateNotificationRequest, setUpdateNotificationRequest] = useState<
    UpdateNotificationRequestItem[]
  >([])
  const [categories, setCategories] = useState<string[]>([])
  const [hasUnsavedChange, setHasUnsavedChange] = useState<boolean>(false)
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(false)

  const getNotificationSettings = () => {
    if (user) {
      setLoadingNotifications(true)

      let profile_ids = []
      if (user.coachProfile.profile_id) {
        profile_ids.push(user.coachProfile.profile_id)
      }
      if (user.memberProfile.profile_id) {
        profile_ids.push(user.memberProfile.profile_id)
      }

      NotificationRequests.getNotificationSettings({
        profile_ids: profile_ids,
      })
        .then((data) => {
          setCategories(
            data.notifications
              .map((n) => n.category)
              .filter((value, index, self) => self.indexOf(value) === index)
          )

          setNotificationValues(data.notifications)
          setLoadingNotifications(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingNotifications(false)
        })
    }
  }

  const updateNotificationSettings = () => {
    if (user) {
      startLoading()
      NotificationRequests.updateNotificationSettings({
        notifications: updateNotificationRequest,
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          renderSuccess("Your notification preferences have been saved.")
          setHasUnsavedChange(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChange(true)
    const target = e.target as HTMLButtonElement
    const notificationType = target.name.split("-")[0]
    const profileNotificationId = parseInt(target.name.split("-")[1])
    const existingNotification = notificationValues.find(
      (n) => n.profile_notification_id === profileNotificationId
    )

    // Update options based on selected notification type
    let emailOn = existingNotification?.email_on || false
    let smsOn = existingNotification?.sms_on || false
    let pushOn = existingNotification?.push_on || false

    switch (notificationType) {
      case "email":
        emailOn = !emailOn
        break
      case "sms":
        smsOn = !smsOn
        break
      case "push":
        pushOn = !pushOn
        break
      case "none":
        emailOn = false
        smsOn = false
        pushOn = false
        break
      default:
        break
    }

    // Update notificationValues to render change on user click
    const newNotificationValues = notificationValues.map((n) =>
      n.profile_notification_id === profileNotificationId
        ? { ...n, email_on: emailOn, sms_on: smsOn, push_on: pushOn }
        : n
    )
    setNotificationValues(newNotificationValues)

    // Build UpdateNotification request
    const newRequest: UpdateNotificationRequestItem = {
      profile_notification_id: profileNotificationId,
      email_on: emailOn,
      sms_on: smsOn,
      push_on: pushOn,
    }

    if (
      updateNotificationRequest.find(
        (n) => n.profile_notification_id === profileNotificationId
      )
    ) {
      const updatedRequest = updateNotificationRequest.map((n) =>
        n.profile_notification_id === profileNotificationId ? newRequest : n
      )
      setUpdateNotificationRequest(updatedRequest)
    } else {
      setUpdateNotificationRequest([...updateNotificationRequest, newRequest])
    }
  }

  const handleSubmit = () => {
    updateNotificationSettings()
  }

  const generateCategoryDescriptions = (category: string): string => {
    switch (category) {
      case "messages":
        return "Settings for your inbox and messaging notifications."
      case "contacts":
        return "Settings related to contacts and connection requests notifications."
      case "services":
        return "Settings related to your services notifications."
      case "meetings":
        return "Settings for notifications related to meetings."
      case "subscription":
        return "Settings for notifications related to your coach subscription."
      case "testimonials":
        return "Settings related to testimonial submission notifications"
      default:
        return ""
    }
  }

  useEffect(() => {
    getNotificationSettings()
  }, [])

  useScrollToTop()

  return (
    <div className="mx-auto w-full px-[16px] md:max-w-[1312px] md:px-[20px]">
      <div className="mb-[16px] flex items-center justify-between">
        <h1 className="mr-auto hidden text-[28px] font-semibold md:block ">
          {user.isCoach === true
            ? "Coach Account Notifications"
            : "Member Account Notifications"}
        </h1>
        <Button
          onClick={handleSubmit}
          disabled={!hasUnsavedChange}
          className="ml-auto"
        >
          Save
          <SvgCheckCircle />
        </Button>
      </div>
      <div className="main-shadow mb-[32px] flex w-full flex-col gap-[32px] rounded-[16px] p-[16px] pb-[80px] md:p-[32px] md:pb-0 lg:p-[40px]">
        {loadingNotifications && (
          <div className="flex w-full flex-col items-center gap-[12px]">
            <Loader />
            <div className="font-bold">Loading notification settings...</div>
          </div>
        )}

        {categories.map((category, idx) => {
          return (
            <>
              <NotificationCategory
                key={idx}
                categoryName={capitalize(category)}
                categoryDescription={generateCategoryDescriptions(category)}
                handleSelect={handleSelect}
                notifications={notificationValues.filter(
                  (n) => n.category === category
                )}
              />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default NotificationsPage
