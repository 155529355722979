import React, { useState, useEffect } from "react"
import { Button } from "./ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog"
import { Helmet } from "react-helmet"
import { UserCtx } from "../context/userContext"
import { SvgChevronRight } from "./icons/"

const OnboardingCallModal: React.FC = () => {
  const { user, setUser } = React.useContext(UserCtx)
  const [showModal, setShowModal] = React.useState<boolean>(
    user.showWelcomeVideo &&
      user.isCoach &&
      localStorage.getItem("showOrientationPopup") !== "false"
  )
  const handleOpenChange = (open: boolean) => {
    setShowModal(open)
    setUser({ ...user, showWelcomeVideo: false })
    localStorage.setItem("showOrientationPopup", "false")
  }
  return (
    <Dialog open={showModal} onOpenChange={handleOpenChange}>
      <DialogContent className="max-w-lg max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-center">Welcome to Zoee</DialogTitle>
          <DialogDescription className="text-center">
            Schedule an orientation call to get the most out of your Zoee
            experience. We’ll guide you through a few essentials steps to get
            you set up in minutes.
          </DialogDescription>
        </DialogHeader>

        <div className="w-[438px] rounded-lg main-shadow overflow-hidden">
          <Helmet>
            <script
              type="text/javascript"
              src="https://assets.calendly.com/assets/external/widget.js"
              async
            ></script>
          </Helmet>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/zoeeapp/personal-zoee-discovery-session-clone?month=2025-03"
            style={{ minWidth: "335px", height: "450px" }}
          ></div>
        </div>

        <div className="flex gap-6 items-center justify-between flex-col sm:flex-row">
          <blockquote className="text-sm [text-wrap:balance] italic">
            "Finally - the right full-service tool for coaches"
            <br />
            <strong>- Pepper L.</strong>
          </blockquote>

          <Button variant="ghost" onClick={() => handleOpenChange(false)}>
            Skip this step <SvgChevronRight />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default OnboardingCallModal
