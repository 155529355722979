import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import MobileHamburger from "./cm-images/MobileHamburger.png"
import MyCoachesMobile from "./cm-images/MyCoachesMobile.png"
import SelectCoachMobile from "./cm-images/SelectCoachMobile.png"
import BillingMobile from "./cm-images/BillingMobile.png"

export default function CMContactPageMobile() {
  return (
    <SectionWrapper id="billing">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Managing Your{" "}
          <span className="text-[#4750F5]">Coach Page and Billing</span>
        </h1>
      </div>

      <p className="text-gray-700 mt-8 text-center">
        Learn how to{" "}
        <a
          href="#navigating-contact"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          navigate the contact page
        </a>
        ,{" "}
        <a
          href="#billing-overview"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          check billing history
        </a>
        , and{" "}
        <a
          href="#managing-payments"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          manage payments and refunds
        </a>
        .
      </p>
      <h3
        id="navigating-contact"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Navigating to the Contact Page
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select “My Coaches” from the emerging side menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={MyCoachesMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach.</li>
          <div className="flex justify-center mt-4">
            <img
              src={SelectCoachMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <h3
        id="scheduling-messaging"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Scheduling and Messaging
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <p className="text-gray-700">
          At the top of the Contact Page, you'll find:
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
          <li>
            A <strong>Schedule</strong> button to book a session.
          </li>
          <li>
            A <strong>Message</strong> button to initiate a chat with your
            coach.
          </li>
          <li>
            A <strong>Billing</strong> button that takes you directly to your
            billing page.
          </li>
        </ul>
      </div>

      <h3
        id="sessions-overview"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Sessions Overview
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Below the top section, you can view both upcoming and past sessions
            with your coach.
          </li>
          <li>
            Toggle between <strong>Active</strong> and <strong>Past</strong>{" "}
            sessions to switch views.
          </li>
        </ul>
      </div>

      <h3
        id="services-overview"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Services Overview
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Below the Sessions section, you’ll find details on services you’ve
            purchased, used, or been invited to with this coach.
          </li>
          <li>
            Use the toggle button to switch between different service
            categories:
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>Active:</strong> Displays ongoing, unscheduled, or
              unredeemed services.
            </li>
            <li>
              <strong>Past:</strong> Shows completed services.
            </li>
            <li>
              <strong>Pending:</strong> Lists service invitations from the
              coach. You can either accept or decline.
            </li>
          </ul>
        </ul>
      </div>

      <h3
        id="billing-overview"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Billing History
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            At the bottom of the page, the Billing section provides an overview
            of your total payments to the coach.
          </li>
          <li>
            A detailed breakdown includes each purchased service, transaction
            date, and amount spent.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={BillingMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </ul>
      </div>
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Issues with Enrollment?
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <p className="text-gray-700">
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              Service Enrollment Failed: Refresh your page, and try the
              enrollment process again. If this does not resolve the issue,
              contact Zoee support for further assistance. Be sure to provide
              details of the error so we can help troubleshoot.
            </li>
            <li>
              Payment Issues: Ensure your payment details are accurate and up to
              date. Double-check the credit card information or payment method.
              If the issue persists, try using a different payment method. For
              errors outside of this scope, contact Zoee support for further
              assistance.
            </li>
          </ul>
        </p>
      </div>
      <h3
        id="managing-payments"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Managing Payment Methods and Refunds
      </h3>

      {/* Updating Payment Methods */}
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Updating Payment Methods
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <p className="text-gray-700">
          You can update your payment method during the service purchase
          process. To add a new card, fill in the information and mark the
          option to save the card for future payments. You can view the
          available cards by clicking on the dropdown available in the payment
          details screen for service purchase.
        </p>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        Refund Requests
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Reach out to your coach and explain the reason for your refund
            request.
          </li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen
          </li>
          <li>Select “Inbox” from the emerging side menu.</li>
          <li>Send a message to your coach explaining the situation.</li>
          <li>If accepted, your coach will initiate and approve the refund.</li>
          <li>
            Once approved, the refund will be credited back to your original
            payment method within 3-5 business days. Refunds may be immediate if
            purchase was conducted with Paypal balance.
          </li>
        </ul>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
