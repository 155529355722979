import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"

export default function CMIntroduction() {
  return (
    <SectionWrapper id="introduction">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Welcome to Your{" "}
          <span className="text-[#4750F5]">Zoee Client Portal</span>
        </h1>
      </div>
      <div className="mt-6 text-lg text-gray-700 text-center">
        <p>
          Welcome to Zoee, your secure online portal for connecting with your
          coach. This guide will show you exactly how to use the platform to get
          the most out of your coaching relationship.
        </p>
      </div>
      <div className="mt-8 bg-gray-100 p-6 rounded-lg w-full">
        <h3 className="text-2xl font-semibold text-gray-800 text-center">
          As a client, Zoee provides you with a private, dedicated space where
          you can:
        </h3>
        <ul className="mt-4 space-y-3 text-gray-700 text-lg list-disc pl-6 max-w-3xl mx-auto">
          <li>Communicate directly with your coach through secure messaging</li>
          <li>Schedule and attend virtual coaching sessions</li>
          <li>Receive resources and materials shared by your coach</li>
          <li>
            Track your progress and maintain personal notes throughout your
            coaching journey
          </li>
        </ul>
      </div>
      <div className="mt-8 bg-white p-6 rounded-lg w-full">
        <p className="text-lg text-gray-700">
          This manual provides step-by-step instructions for every action you'll
          need to take—from setting up your account to attending sessions and
          exchanging messages with your coach. Each section follows a clear,
          sequential format to help you navigate the platform with confidence.
        </p>
        <p className="text-lg text-gray-700 mt-4">
          Think of Zoee as the virtual office where you meet with your coach.
          Just as you would visit a professional's office for an in-person
          appointment, this platform creates a private, secure environment for
          your coaching relationship to flourish.
        </p>
      </div>
      <div className="flex justify-center mt-8">
        <Button variant="default" size="lg" asChild>
          <a href="#table-of-contents">Let's Get Started!</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
