import React, { useState } from "react"
import { ProfileRequests } from "../../api/app.service"
import useLoading from "../../hooks/useLoading"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgClose, SvgEmail } from "../icons"

const NewsletterSettingsModal = ({
  onClose,
  onSave,
  defaultTitle,
  defaultSubheading,
  defaultEnabled,
  userId,
}: {
  onClose: () => void
  onSave: (title: string, subheading: string, enabled: boolean) => void
  defaultTitle: string
  defaultSubheading: string
  defaultEnabled: boolean
  userId: number
}) => {
  const [enabled, setEnabled] = useState(defaultEnabled)
  const [title, setTitle] = useState(defaultTitle)
  const [subheading, setSubheading] = useState(defaultSubheading)
  const [error, setError] = useState<string | null>(null)
  const { startLoading, stopLoading } = useLoading()
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)

  const handleSave = async () => {
    if (!title.trim()) {
      setError("Title is required.")
      return
    }
    setError(null)

    const payload = {
      user_id: userId,
      newsletter_title: title,
      newsletter_subheading: subheading || "",
      newsletter_opt_in_enabled: enabled,
    }

    try {
      startLoading()
      console.log("Saving newsletter with payload:", payload)
      await ProfileRequests.updateCoachNewsletterSettings(payload)
      renderSuccess("Newsletter settings updated successfully!")
      onSave(title, subheading, enabled)
      onClose()
    } catch (ex: any) {
      console.error("Error updating newsletter settings:", ex)
      renderError(
        ex.response?.data?.message || "Failed to update newsletter settings."
      )
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="fixed inset-0 bg-black/30 flex justify-center items-center z-50">
      <div
        className={`bg-white rounded-2xl flex flex-col justify-start items-center relative ${
          window.innerWidth < 768 ? "w-full h-full p-4" : "w-96 h-auto p-6"
        }`}
      >
        <div className="self-stretch flex justify-between items-center mb-4">
          <div className="flex items-center gap-2.5">
            <div className="w-8 h-8 bg-[#f2f5f7] rounded-full flex justify-center items-center">
              <SvgEmail />
            </div>
            <div className="text-[#3a434a] text-base font-bold">
              Email Opt-in Form
            </div>
          </div>
          <button
            onClick={onClose}
            className="w-8 h-8 bg-[#f2f5f7] rounded-full flex justify-center items-center"
          >
            <SvgClose />
          </button>
        </div>

        <div className="self-stretch bg-[#ebf0ff] p-4 rounded-lg flex items-center gap-4 mb-6">
          <div className="flex-grow flex flex-col">
            <div className="text-black text-base font-bold">
              Enable on website
            </div>
            <div className="text-black text-sm">
              Enable to collect email submissions on your website. Disable this
              feature to temporarily pause collections, while still keeping your
              design and progress.
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-base">Off</span>
            <button
              className={`w-16 h-8 relative rounded-full flex ${
                enabled ? "bg-[#4750f5]" : "bg-gray-300"
              }`}
              onClick={() => setEnabled(!enabled)}
            >
              <div
                className={`w-7 h-7 bg-white rounded-full shadow-lg absolute transition-transform ${
                  enabled ? "translate-x-[34px]" : "translate-x-0"
                }`}
              ></div>
            </button>
            <span className="text-base">On</span>
          </div>
        </div>
        <div className="self-stretch mb-6">
          <div className="flex justify-between mb-2">
            <label
              htmlFor="newsletter-title"
              className="text-[#0d0d0d] text-base font-bold"
            >
              Title
            </label>
            <span className="text-[#62696e] text-base">
              {45 - title.length} Characters Remaining
            </span>
          </div>
          <input
            id="newsletter-title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={45}
            className="w-full h-12 p-3 rounded-lg border border-[#abb8c3] text-base"
            placeholder="Type title here"
          />
        </div>
        <div className="self-stretch mb-6">
          <div className="flex justify-between mb-2">
            <label
              htmlFor="newsletter-subheading"
              className="text-[#0d0d0d] text-base font-bold"
            >
              Subheading (optional)
            </label>
            <span className="text-[#62696e] text-base">
              {45 - subheading.length} Characters Remaining
            </span>
          </div>
          <input
            id="newsletter-subheading"
            type="text"
            value={subheading}
            onChange={(e) => setSubheading(e.target.value)}
            maxLength={45}
            className="w-full h-12 p-3 rounded-lg border border-[#abb8c3] text-base"
            placeholder="Type subheading here"
          />
        </div>
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
        <div className="self-stretch flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded-lg text-black text-base font-bold"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-[#4750f5] rounded-lg text-white text-base font-bold"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewsletterSettingsModal
