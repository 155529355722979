import AuthModal from "./authentication/AuthModal"
import { AnimatePresence } from "framer-motion"
import { CommonFunctionCtx } from "../context/commonFunctionContext"
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser"
import { useEffect, useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { UserCtx } from "../context/userContext"

const DirectLinkRouter = () => {
  const { user } = useContext(UserCtx)
  const authenticatedUser = useAuthenticatedUser(user)
  const { showAuthModal, setShowAuthModal } = useContext(CommonFunctionCtx)
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location.state || {}
  const state = location.state
  const [isFirstRenderComplete, setIsFirstRenderComplete] =
    useState<boolean>(false)

  useEffect(() => {
    if (!authenticatedUser && !showAuthModal && isFirstRenderComplete) {
      navigate(`/home`)
    }
  }, [showAuthModal])

  useEffect(() => {
    if (!authenticatedUser) {
      setShowAuthModal(true)
      localStorage.removeItem("user")
    } else {
      if (pathname) {
        navigate(pathname)
      } else {
        user?.isCoach ? navigate("/coach") : navigate("/member")
      }
    }
    setIsFirstRenderComplete(true)
  }, [])

  return (
    <div className="">
      <AnimatePresence>
        {showAuthModal && (
          <div className="fixed top-[20%] left-0 right-0 mx-auto">
            <AuthModal
              successRedirectPathname={state.pathname || null}
              successRedirectSearchParams={state.searchParams || null}
              preRedirectActions={state.actions || null}
            />
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default DirectLinkRouter
