import { type FC } from "react"
import { motion } from "framer-motion"
import { Button } from "../../ui/button"

interface Props {
  setInstructionModal: any
}

const SessionTips: FC<Props> = ({ setInstructionModal }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
        z-[801] flex items-center justify-center pb-[88px] md:pb-[40px]"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="relative flex flex-col items-center bg-white max-w-xl h-auto rounded-[16px] py-12 md:px-16 px-4"
      >
        <div className="w-full rounded-[14px] flex flex-col">
          <h4 className="font-bold mb-[32px] text-center mt-3 sm:mt-0">
            How to optimize your Zoee video session:
          </h4>
          <dl>
            <dt className="font-bold">Recommended browser:</dt>
            <dd className="mb-4">
              Use Chrome for best results (
              <a
                className="text-blurple"
                rel="noreferrer"
                target="_blank"
                href="https://support.google.com/chrome/answer/95346?hl=en&co=GENIE.Platform%3DDesktop"
              >
                Download
              </a>
              )
            </dd>
            <dt className="font-bold">Joining from mobile device:</dt>
            <dd className="mb-4">Use headphones for best audio</dd>
          </dl>
          <p className="text-grayCharcoal italic mb-4">
            Having issues with Safari? See our{" "}
            <a
              className="text-blurple"
              href="https://intercom.help/zoee/en/articles/8264369-how-do-i-setup-safari-so-that-audio-and-video-work-in-my-session"
              target="_blank"
              rel="noreferrer"
            >
              troubleshooting article
            </a>{" "}
            or contact our{" "}
            <a
              className="text-blurple"
              href="https://zoee.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              support team
            </a>
          </p>
        </div>
        <Button onClick={() => setInstructionModal(false)}>Close</Button>
      </motion.div>
    </motion.div>
  )
}

export default SessionTips
