import React, { useContext } from "react"
import CloseModal from "../../components/CloseModal"
import { UserCtx } from "../../context/userContext"

interface Props {
  setShowSessionDetails: any
}

const SessionDetailsModal: React.FC<Props> = ({ setShowSessionDetails }) => {
  const { user } = useContext(UserCtx)

  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
    >
      <div
        className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                        main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
      >
        <CloseModal
          callback={setShowSessionDetails}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Craft Your Coaching Ecosystem
        </h1>
        <div className="w-full flex flex-col gap-[40px] text-center text-[18px]">
          <p className="text-black mb-4">
            Design the perfect services that power your coaching
            business—whether you offer 1:1 sessions, group experiences, or
            complete programs. Set your global rules once and watch as pricing,
            installments, discounts, and session scheduling run on autopilot for
            every client. With just one initial setup, you'll create a seamless
            experience that works for unlimited clients. Click, customize, and
            let automation handle the rest while you focus on what matters
            most—coaching.{" "}
          </p>
          <p className="mb-4 mb-[30px] -mt-8">
            Learn more about setting up your services in this{" "}
            <a
              href="https://intercom.help/zoee/en/articles/9307848-creating-a-service"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              <span className="font-bold text-blurple">short video guide</span>.
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SessionDetailsModal
