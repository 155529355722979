import React from "react"
import CloseModal from "../../CloseModal"

interface Props {
  setShowBookingWindowDesc: any
}

const BookingWindowsDetails: React.FC<Props> = ({
  setShowBookingWindowDesc,
}) => {
  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
        z-[9001] flex items-center justify-center"
    >
      <div
        className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                    main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
      >
        <CloseModal
          callback={setShowBookingWindowDesc}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Minimum Booking Notice
        </h1>
        <div className="w-full flex flex-col gap-[40px] text-center text-[18px]">
          <p className="text-black">
            Set how much advance warning you need before sessions. With Minimum
            Booking Notice, you control how close to a time slot clients can
            book with you—whether that's 24 hours, 2 days, or just 30 minutes
            before. Prevent last-minute surprises and give yourself the
            preparation time you deserve. Perfect for maintaining your workflow
            while still offering flexibility to your clients.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BookingWindowsDetails
