"use client"

import React, { useContext, useState, useEffect } from "react"
import { Button } from "../../../components/ui/button"
import { motion, useAnimation } from "framer-motion"
import { useAuthModal } from "../../../components/auth/use-auth-modal"
import { useInView } from "react-intersection-observer"
import { UserCtx } from "../../../context/userContext"

export function ShowcaseVideo() {
  const [shouldPlay, setShouldPlay] = useState(false)
  const { user } = useContext(UserCtx)

  const { showRegister } = useAuthModal()
  const controls = useAnimation()
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      setShouldPlay(true)
    }
  }, [inView])

  useEffect(() => {
    if (shouldPlay) {
      controls.start({ opacity: 1, scale: 1 })
    } else {
      controls.start({ opacity: 0, scale: 0.8 })
    }
  }, [shouldPlay, controls])

  return (
    <div className="bg-white py-24">
      <div className="container grid md:grid-cols-2 gap-12 items-center">
        <div className="flex flex-col items-center md:items-start text-center md:text-left gap-4">
          <h3 className="text-[24px] md:text-[36px] lg:text-[42px] text-black font-bold">
            Streamline + Simplify
          </h3>
          <p className="text-sm md:text-base lg:text-lg">
            Created by coaches and entrepreneurs, Zoee streamlines and
            simplifies the details of running your business, so you can focus on
            what matters most - your clients.
          </p>
          {!user && (
            <Button
              onClick={() => showRegister({ accountType: "coach" })}
              size="lg"
            >
              Start Your Free Trial
            </Button>
          )}
        </div>

        <motion.div
          ref={inViewRef}
          className="w-full border-4 lg:border-8 border-black rounded-xl overflow-hidden relative"
          animate={controls}
        >
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <iframe
              src="https://fast.wistia.com/embed/medias/da7jerndvs"
              title="Zoee Video"
              allow="autoplay; fullscreen"
              allowFullScreen
              className="absolute inset-0 w-full h-full"
            ></iframe>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
