import type { SVGProps } from "react"

export const SvgZoeeGradientLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="120"
    height="24"
    viewBox="0 0 505 124"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M102.32 99.62V117.95C102.347 119.044 101.943 120.105 101.195 120.904C100.446 121.703 99.4138 122.176 98.3201 122.22H4.80011C3.70637 122.176 2.6739 121.703 1.92554 120.904C1.17718 120.105 0.772966 119.044 0.800112 117.95V102.73C0.798238 101.729 1.13283 100.757 1.75011 99.97L53.1601 35.66C55.3801 32.89 53.5101 28.66 50.0801 28.66H6.28011C5.18548 28.6183 4.15148 28.1465 3.40259 27.347C2.6537 26.5476 2.25035 25.485 2.28011 24.39V6.06001C2.25035 4.96499 2.6537 3.90242 3.40259 3.10297C4.15148 2.30353 5.18548 1.83174 6.28011 1.79001H96.3801C97.4747 1.83174 98.5087 2.30353 99.2576 3.10297C100.007 3.90242 100.41 4.96499 100.38 6.06001V21.26C100.372 22.2672 100.019 23.2412 99.3801 24.02L47.9801 88.33C45.7601 91.1 47.6301 95.33 51.0601 95.33H98.2001C98.7537 95.3364 99.3006 95.453 99.8087 95.673C100.317 95.893 100.776 96.2119 101.159 96.6113C101.543 97.0106 101.843 97.4823 102.042 97.9989C102.242 98.5155 102.336 99.0666 102.32 99.62Z"
      fill="url(#paint0_linear_677_2)"
    />
    <path
      d="M113.11 62C113.11 25.74 139.55 0 175.73 0C211.91 0 238.15 25.74 238.15 62C238.15 98.26 211.92 124 175.73 124C139.54 124 113.11 98.26 113.11 62ZM204.72 62C204.72 41.18 192.24 28.65 175.72 28.65C159.2 28.65 146.53 41.18 146.53 62C146.53 82.82 159.22 95.35 175.72 95.35C192.22 95.35 204.72 82.82 204.72 62Z"
      fill="url(#paint1_linear_677_2)"
    />
    <path
      d="M499.84 71.85H423.33C422.617 71.8767 421.921 72.0772 421.303 72.434C420.686 72.7908 420.164 73.2932 419.785 73.8974C419.405 74.5016 419.179 75.1894 419.126 75.9009C419.073 76.6124 419.194 77.3261 419.48 77.98C424.32 89.31 435.3 95.98 450.14 95.98C460.7 95.98 468.66 93.06 475.73 87.07C476.129 86.7144 476.596 86.4424 477.102 86.2702C477.608 86.0979 478.143 86.0289 478.677 86.0671C479.21 86.1053 479.73 86.25 480.206 86.4927C480.683 86.7354 481.106 87.0711 481.45 87.48L493.6 101.48C494.345 102.346 494.742 103.457 494.714 104.599C494.687 105.741 494.236 106.831 493.45 107.66C482.95 118.38 468.07 124.01 449.29 124.01C409.94 124.01 384.29 97.82 384.29 62.01C384.29 26.01 410.29 0.0100098 445.02 0.0100098C478.45 0.0100098 504.26 23.73 504.26 62.46C504.26 64.01 504.12 65.93 503.96 67.84C503.896 68.9014 503.438 69.9007 502.676 70.6423C501.914 71.3839 500.903 71.8146 499.84 71.85ZM422.47 51.48H467.84C468.528 51.4571 469.2 51.2728 469.804 50.9421C470.407 50.6114 470.924 50.1435 471.314 49.5761C471.703 49.0087 471.953 48.3578 472.045 47.6758C472.136 46.9938 472.066 46.2999 471.84 45.65C467.93 33.79 458.12 26.41 445.3 26.41C432.3 26.41 422.42 33.65 418.52 45.66C418.297 46.3054 418.227 46.994 418.317 47.671C418.407 48.348 418.653 48.9948 419.037 49.5598C419.42 50.1249 419.93 50.5927 420.526 50.926C421.122 51.2594 421.788 51.4491 422.47 51.48Z"
      fill="url(#paint2_linear_677_2)"
    />
    <path
      d="M368.36 71.85H291.85C291.137 71.8775 290.442 72.0783 289.824 72.4351C289.206 72.7918 288.684 73.2938 288.304 73.8975C287.924 74.5013 287.697 75.1886 287.642 75.8999C287.587 76.6113 287.707 77.3252 287.99 77.98C292.84 89.31 303.81 95.98 318.66 95.98C329.21 95.98 337.18 93.06 344.25 87.07C344.649 86.716 345.115 86.4453 345.62 86.274C346.125 86.1026 346.659 86.034 347.191 86.0722C347.723 86.1104 348.242 86.2546 348.718 86.4964C349.193 86.7382 349.616 87.0726 349.96 87.48C353.29 91.29 358.84 97.66 362.12 101.48C362.865 102.346 363.262 103.457 363.234 104.599C363.207 105.741 362.756 106.831 361.97 107.66C351.47 118.38 336.59 124.01 317.81 124.01C278.46 124.01 252.81 97.82 252.81 62.01C252.81 26.01 278.81 0.0100098 313.54 0.0100098C346.97 0.0100098 372.78 23.73 372.78 62.46C372.78 64.01 372.64 65.93 372.48 67.84C372.416 68.9014 371.958 69.9007 371.196 70.6423C370.434 71.3839 369.423 71.8146 368.36 71.85ZM290.99 51.48H336.36C337.048 51.4571 337.72 51.2728 338.324 50.9421C338.927 50.6114 339.444 50.1435 339.834 49.5761C340.223 49.0087 340.473 48.3578 340.565 47.6758C340.656 46.9938 340.586 46.2999 340.36 45.65C336.45 33.79 326.64 26.41 313.82 26.41C300.82 26.41 290.94 33.65 287.04 45.66C286.817 46.3054 286.747 46.994 286.837 47.671C286.927 48.348 287.173 48.9948 287.557 49.5598C287.94 50.1249 288.45 50.5927 289.046 50.926C289.642 51.2594 290.308 51.4491 290.99 51.48Z"
      fill="url(#paint3_linear_677_2)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_677_2"
        x1="51.5603"
        y1="1.79001"
        x2="51.5603"
        y2="122.22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_677_2"
        x1="175.63"
        y1="0"
        x2="175.63"
        y2="124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_677_2"
        x1="444.275"
        y1="0.0100098"
        x2="444.275"
        y2="124.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_677_2"
        x1="312.795"
        y1="0.0100098"
        x2="312.795"
        y2="124.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
    </defs>
  </svg>
)
