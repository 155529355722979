import { useEffect, useState } from "react"
import CMCoachForms from "./CMCoachForms"
import CMCoachingCalendar from "./CMCoachingCalendar"
import CMCoachNotifications from "./CMCoachNotifications"
import CMContactPage from "./CMContactPage"
import CMInbox from "./CMInbox"
import CMIntroduction from "./CMIntroduction"
import CMJoiningVideoSession from "./CMJoiningVideoSession"
import CMNotebook from "./CMNotebook"
import CMPasswordPersonalInfo from "./CMPasswordPersonalInfo"
import CMPasswordPersonalInfoMobile from "./CMPasswordPersonalInfoMobile"
import CMScheduling from "./CMScheduling"
import CMSetupGuide from "./CMSetupGuide"
import CMSetupGuideMobile from "./CMSetupGuideMobile"
import CMTableOfContents from "./CMTableOfContents"
import CMTechSupport from "./CMTechSupport"
import { Button } from "../../components/ui/button"
import { SvgArrowRight } from "../../components/icons"
import CMCoachNotificationsMobile from "./CMCoachNotificationsMobile"
import CMSchedulingMobile from "./CMSchedulingMobile"
import CMInboxMobile from "./CMInboxMobile"
import CMJoiningVideoSessionMobile from "./CMJoiningVideoSessionMobile"
import CMNotebookMobile from "./CMNotebookMobile"
import CMCoachFormsMobile from "./CMCoachFormsMobile"
import CMContactPageMobile from "./CMContactPageMobile"
import CMCoachingCalendarMobile from "./CMCoachingCalendarMobile"
import CMTechSupportMobile from "./CMTechSupportMobile"
import CMTestimonials from "./CMTestimonials"
import CMTestimonialsMobile from "./CMTestimonialsMobile"

export default function ClientManual() {
  const [isVisible, setIsVisible] = useState(false)
  const [view, setView] = useState<"desktop" | "mobile">("desktop")

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <div className="max-w-5xl mx-auto px-6 py-12 text-gray-800">
      <CMIntroduction />
      <header className="text-center mb-6">
        <h1 className="text-3xl md:text-4xl font-bold text-blurple text-center mb-6">
          QUICK START
        </h1>
        <div className="flex flex-col md:flex-row justify-center gap-4 mb-8">
          <Button
            variant={view === "desktop" ? "default" : "outline"}
            className="w-full md:w-auto"
            onClick={() => setView("desktop")}
          >
            View Desktop Instructions
          </Button>
          <Button
            variant={view === "mobile" ? "default" : "outline"}
            className="w-full md:w-auto"
            onClick={() => setView("mobile")}
          >
            View Mobile Instructions
          </Button>
        </div>
      </header>

      <CMTableOfContents />
      {view === "desktop" ? (
        <>
          <CMSetupGuide />
          <CMPasswordPersonalInfo />
          <CMCoachNotifications />
          <CMInbox />
          <CMCoachingCalendar />
          <CMScheduling />
          <CMJoiningVideoSession />
          <CMContactPage />
          <CMNotebook />
          <CMCoachForms />
          <CMTestimonials />
          <CMTechSupport />
        </>
      ) : (
        <>
          <CMSetupGuideMobile />
          <CMPasswordPersonalInfoMobile />
          <CMCoachNotificationsMobile />
          <CMInboxMobile />
          <CMCoachingCalendarMobile />
          <CMSchedulingMobile />
          <CMJoiningVideoSessionMobile />
          <CMContactPageMobile />
          <CMNotebookMobile />
          <CMCoachFormsMobile />
          <CMTestimonialsMobile />
          <CMTechSupportMobile />
        </>
      )}
    </div>
  )
}
