import React, { useState, useContext, useEffect } from "react"
import * as z from "zod"
import Loader from "../../ui/loader"
import { Button } from "../../ui/button"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { EmailLogin, VerifyConfirmationCode } from "../../../api/auth.service"
import { FieldError } from "../../ui/field-error"
import { getUserStats } from "../../../utils/getUserStats"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../ui/input-otp"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"
import { Label } from "../../ui/label"
import { LoginFormContext } from "./context"
import { SvgZoeeLogoGradiant } from "../../icons"
import { UserCtx } from "../../../context/userContext"
import { useNavigate } from "react-router-dom"

interface Props {
  email: string
  profileType: string
  onBack?: () => void
}

export const OneTimePasswordForm: React.FC<Props> = ({
  email,
  profileType,
  onBack,
}) => {
  const {
    oneTimePassword,
    setOneTimePassword,
    getIdentityStatus,
    getSetupInfo,
    updateTimezone,
    onSuccess,
  } = useContext(LoginFormContext)

  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [sessionData, setSessionData] = useState<any>({})
  const [disableResend, setDisableResend] = useState(false)
  const { user } = useContext(UserCtx)
  const navigate = useNavigate()
  const coachRedirect = localStorage.getItem("coachRedirect") || "/coach"
  const memberRedirect = localStorage.getItem("memberRedirect") || "/member"
  
  const { renderError, getUser } = useContext(CommonFunctionCtx)

  const sendOneTimePasscode = () => {
    setOneTimePassword("")
    setDisableResend(true)
    setLoading(true)
    EmailLogin.loginOTP({ email })
      .then((data) => {
        setSessionData(data)
      })
      .catch((ex) => {
        console.error(ex)
      })
      .finally(() => {
        setLoading(false)

        setTimeout(() => {
          setDisableResend(false)
        }, 15000)
      })
  }

  const redirectURL = () => {
    if (user?.activeProfile === "coach") {
      navigate(coachRedirect)
    } else {
      navigate(memberRedirect)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const oneTimePasswordSchema = z
      .string()
      .length(6, {
        message: "Must be exactly 6 digits long",
      })
      .regex(/^\d+$/, {
        message: "Must contain only digits",
      })
      .safeParse(oneTimePassword)

    if (!oneTimePasswordSchema.success) {
      setError(oneTimePasswordSchema.error.issues[0].message)
      setLoading(false)
      return
    }

    await login()
    redirectURL()

    setLoading(false)
  }

  const login = async () => {
    try {
      const data = await VerifyConfirmationCode.verifyConfirmationCode({
        confirmation_code: oneTimePassword,
        session: sessionData.session,
        user_id: sessionData.user_id,
        stats: getUserStats(),
      })

      if (data) {
        if (isLocalStorageAvailable()) {
          localStorage.setItem("user", JSON.stringify(data))
        } else {
          renderError("Local storage is not available")
        }

        await getIdentityStatus(data.user_id)
        await getSetupInfo(data.user_id)
        await updateTimezone(data.user_id, data.access_token)
        const user = await getUser()

        onSuccess(user)
      }
    } catch (error: any) {
      console.error(error)
      if (
        error.response.data.message ===
        "Error: OTP authentication. User login failed."
      ) {
        setError("Incorrect password")
        setOneTimePassword("")
      } else {
        console.error(error)
        renderError(error.response.data.message)
      }
    }
  }

  useEffect(() => {
    if (!disableResend) {
      sendOneTimePasscode()
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 bg-white p-6 rounded-xl items-center"
    >
      <div className="mt-4 mb-4">
        <SvgZoeeLogoGradiant className="h-[50px] w-auto block mx-auto" />
      </div>
      <div className="space-y-2 mx-auto flex flex-col">
        <Label htmlFor="otp" className="text-center">
          Enter the code sent to your email
        </Label>
        <InputOTP
          id="otp"
          maxLength={6}
          value={oneTimePassword}
          onChange={(value) => setOneTimePassword(value)}
          disabled={loading}
          autoFocus
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} className="bg-white" />
            <InputOTPSlot index={1} className="bg-white" />
            <InputOTPSlot index={2} className="bg-white" />
            <InputOTPSlot index={3} className="bg-white" />
            <InputOTPSlot index={4} className="bg-white" />
            <InputOTPSlot index={5} className="bg-white" />
          </InputOTPGroup>
        </InputOTP>

        <FieldError error={error} />
      </div>

      <Button className="w-full" disabled={loading}>
        {loading ? <Loader /> : "Sign In"}
      </Button>

      <div className="flex flex-col gap-2">
        <Button
          variant="link"
          type="button"
          onClick={() => sendOneTimePasscode()}
          disabled={disableResend}
        >
          Resend One-time Password
        </Button>
        {profileType === "coach" && (
          <>
            <div className="flex items-center gap-4">
              <div className="h-px bg-border flex-1"></div>
              <span className="text-sm">or</span>
              <div className="h-px bg-border flex-1"></div>
            </div>
            <Button variant="link" type="button" onClick={onBack}>
              Back to Sign In
            </Button>
          </>
        )}
      </div>
    </form>
  )
}
