import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import MobileHamburger from "./cm-images/MobileHamburger.png"
import MyCoachesMobile from "./cm-images/MyCoachesMobile.png"
import SelectCoachMobile from "./cm-images/SelectCoachMobile.png"
import NotesMobile from "./cm-images/NotesMobile.png"
import NewNoteMobile from "./cm-images/NewNoteMobile.png"
import SaveNoteMobile from "./cm-images/SaveNoteMobile.png"
import EditNoteMobile from "./cm-images/EditNoteMobile.png"
import DeleteNoteMobile from "./cm-images/DeleteNoteMobile.png"
import ConfirmDeleteMobile from "./cm-images/ConfirmDeleteMobile.png"

export default function CMNotebookMobile() {
  return (
    <SectionWrapper id="session-notebook">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Your Personal <span className="text-[#4750F5]">Session Notebook</span>
        </h1>
      </div>
      <p className="text-center mt-6">
        Your Session Notebook is a private space to record thoughts, insights,
        and action items from your coaching journey. Use this tool to prepare
        for upcoming sessions, document your progress, and reflect on key
        takeaways from your coaching experience.
      </p>{" "}
      <h3 className="text-2xl font-semibold text-gray-700 mt-6">
        What You Can Do With Your Session Notebook
      </h3>
      <ul className="list-disc list-inside mt-2">
        <li>Document important insights from your coaching sessions</li>
        <li>Prepare questions or topics to discuss with your coach</li>
        <li>Track your progress toward coaching goals</li>
        <li>Record action items and commitments</li>
        <li>
          Share specific notes with your coach before sessions through your
          inbox messages
        </li>
      </ul>
      <h3 className="text-2xl font-semibold text-gray-700 mt-6">
        Accessing Your Notebook
      </h3>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        From Your Coach's Profile
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>
          From the Homepage, click on the hamburger menu on the top left of the
          screen.
        </li>
        <div className="flex justify-center mt-4">
          <img
            src={MobileHamburger}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <li>Select “My Coaches” from the emerging side menu.</li>
        <div className="flex justify-center mt-4">
          <img
            src={MyCoachesMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <li>Find and select your coach's profile from the list.</li>
        <div className="flex justify-center mt-4">
          <img
            src={SelectCoachMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
      </ol>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Open Your Notebook
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Scroll to the bottom of your coach's profile</li>
        <li>Locate the "Session Notebook" section</li>

        <li>Your existing notes will be displayed here</li>
        <div className="flex justify-center mt-4">
          <img
            src={NotesMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
      </ol>
      <h3 className="text-2xl font-semibold text-gray-700 mt-6">
        Creating a New Note
      </h3>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Start a New Note
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Click the "New Note" button in your Session Notebook</li>
        <div className="flex justify-center mt-4">
          <img
            src={NewNoteMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <li>A new note creation form will appear.</li>
      </ol>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Select Your Coach
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Select your coach from the menu.</li>
        <li>Click the "Create Note" button to proceed.</li>
      </ol>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Choose the Related Service
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>
          In the "Program Details" tab, select which coaching service this note
          is for.
        </li>
        <li>This helps organize your notes by program or service.</li>
      </ol>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Write Your Note
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Enter a clear title that summarizes the note's content.</li>
        <li>
          Fill in the details section with your thoughts, questions, or
          reflections.
        </li>
        <li>Be as detailed as you wish—this is your personal space.</li>
      </ol>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Save Your Note
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Click the "Save" button to store your note.</li>
        <div className="flex justify-center mt-4">
          <img
            src={SaveNoteMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <li>Your note will now appear in your Session Notebook.</li>
      </ol>
      <h3 className="text-2xl font-semibold text-gray-700 mt-6">
        Managing Your Notes
      </h3>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Editing a Note
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Find and click on the note you want to change.</li>

        <li>The full note will open on your screen.</li>
        <li>Click the "Edit" button.</li>
        <div className="flex justify-center mt-4">
          <img
            src={EditNoteMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <li>Update the title or content as needed.</li>
        <li>Click "Save" when you're finished.</li>
      </ol>
      <h4 className="text-xl font-semibold text-gray-700 mt-4">
        Deleting a Note
      </h4>
      <ol className="list-disc list-inside mt-2">
        <li>Find and click on the note you want to remove.</li>

        <li>The full note will open on your screen.</li>
        <li>Click the "Delete" button.</li>
        <div className="flex justify-center mt-4">
          <img
            src={DeleteNoteMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
        <li>A confirmation message will appear.</li>

        <li>Confirm that you want to permanently delete the note.</li>
        <div className="flex justify-center mt-4">
          <img
            src={ConfirmDeleteMobile}
            alt="Managing Availability"
            className="w-full max-w-md rounded-lg"
          />
        </div>
      </ol>
      <h3 className="text-2xl font-semibold text-gray-700 mt-6 mb-4">
        Preparing for Your Next Session
      </h3>
      <p>Consider creating a note before each coaching session with:</p>
      <ul className="list-disc list-inside mt-2 mb-2">
        <li>Topics you want to discuss</li>
        <li>Progress updates since your last session</li>
        <li>Challenges you're currently facing</li>
        <li>Questions you want to ask your coach</li>
      </ul>
      <p>
        You may share these notes with your coach through the inbox for them to
        review before your session to provide a more personalized and effective
        coaching experience.
      </p>
      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
