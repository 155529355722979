import React, { useState, useContext } from "react"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { SvgWarning } from "../icons/"
import { UserCtx } from "../../context/userContext"

interface Props {
  setShowInviteModal: any
  invites: any
  setInvites: any
  setShowConfirmInviteModal: any
  isReferralInviteVariant?: boolean
  invitedContacts?: any
}

const ContactInvite: React.FC<Props> = ({
  setShowInviteModal,
  invites = [],
  setInvites,
  setShowConfirmInviteModal,
  isReferralInviteVariant,
  invitedContacts = [],
}) => {
  const { user } = useContext(UserCtx)
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })

  const coachValidateForm = () => {
    let valid = true
    const newErrors = invites.map(() => ({ email: "" }))
    const sentInvites = invitedContacts.map(
      (invCont: any) => invCont.contact_name
    )
    invites.forEach((invite: any) => {
      const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
      if (!invite.email.match(mailformat)) {
        newErrors.email = "Please add a valid email address"
        valid = false
      } else if (invite.email === user.email) {
        newErrors.email = "Cannot use your own email address"
        valid = false
      } else if (sentInvites.includes(invite.email)) {
        newErrors.email = "This email has already been used in contact request"
        valid = false
      }
    })

    setErrors(newErrors)
    return valid
  }

  const memberValidateForm = () => {
    let valid = false

    invites.forEach((invite: any) => {
      const mailformat = /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
      if (invite.email.match(mailformat) && invite.email !== user.email) {
        valid = true
      }
    })

    return valid
  }

  const inviteConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (coachValidateForm() || memberValidateForm()) {
      setShowInviteModal(false)
      setShowConfirmInviteModal(true)
    }
  }

  const updateInviteArray = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const newState = [...invites]
    newState[index][e.target.name] = e.target.value
    setInvites(newState)

    if (e.target.name === "email") {
      setErrors((prev) => ({ ...prev, email: "" }))
    }
  }

  const closeAndReset = () => {
    setInvites([{ email: "" }])
    setShowInviteModal(false)
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-[801] flex items-center justify-center pb-[220px] md:pb-[30px] zoee-backdrop-filter">
      <div className="pt-[22px] pb-[30px] md:py-[32px] px-[24px] sm:px-[48px] w-[351px] md:w-[551px] sm:w-auto bg-white main-shadow rounded-[16px] flex flex-col items-center relative">
        <div className="text-xl font-bold mb-[20px] w-full text-center">
          Invite a Contact
        </div>
        <form onSubmit={inviteConfirmation} noValidate>
          <div className="flex flex-col w-full">
            {invites.map((invite: any, index: any) => (
              <div className="w-full md:w-[404px] mb-2" key={index}>
                <div>
                  <Input
                    label="Contact's Email"
                    placeholder="Enter Contact's Email"
                    type="email"
                    name="email"
                    onChange={(e) => updateInviteArray(e, index)}
                    id={`email${index}`}
                  />

                  {errors.email && (
                    <div
                      style={{ color: "red" }}
                      className="flex items-center text-red-500 text-sm"
                    >
                      <SvgWarning className="w-[18px] h-[18px] mr-1" />{" "}
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center gap-2 mt-4 justify-center">
            <Button
              variant="outline"
              type="button"
              onClick={closeAndReset}
              className="flex-1"
            >
              Cancel
            </Button>
            <Button type="submit" className="flex-1">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactInvite
