import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import ProfileIcon from "./cm-images/ProfileIcon.png"
import ProfileDropdown from "./cm-images/ProfileDropdown.png"
import AccountSettings from "./cm-images/AccountSettings.png"
import Notifications from "./cm-images/Notifications.png"
import MemberNotifications from "./cm-images/MemberNotifications.png"
import SaveNotifications from "./cm-images/SaveNotifications.png"

export default function CMCoachNotifications() {
  return (
    <SectionWrapper id="notifications">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Managing Your{" "}
          <span className="text-[#4750F5]">
            Coach Communication Notifications
          </span>
        </h1>
      </div>
      <p className="text-gray-700 mt-8 text-center">
        You can manage your notifications by accessing your{" "}
        <a
          href="#access-settings"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          notification settings{" "}
        </a>
        and{" "}
        <a
          href="#customize-alerts"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          customizing your alerts
        </a>
        .
      </p>
      <div
        id="access-settings"
        className="mt-8 bg-blue-50 p-6 rounded-lg w-full"
      >
        <h3 className="text-2xl font-semibold text-blue-700">
          Accessing Your Notification Settings
        </h3>
      </div>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <h4 className="text-xl font-semibold text-gray-700">
          1. Open the Account Menu
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Look for your profile icon in the top-right corner of any Zoee page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ProfileIcon}
              alt="Profile Icon Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Click on your profile icon to open the dropdown menu.</li>
        </ul>
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          2. Navigate to Account Settings
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Find and click on <strong>"Account Settings"</strong> in the
            dropdown menu.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ProfileDropdown}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>The Account Settings page will open.</li>
        </ul>
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          3. Select Notification Preferences
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Locate and click on the <strong>"Notifications"</strong> option in
            the Account Settings menu.
          </li>
          <li>The Notification Settings page will appear.</li>
        </ul>
        <div className="flex justify-center mt-4">
          <img
            src={MemberNotifications}
            alt="Member Notifications Tab Example"
            className="w-full max-w-md rounded-lg"
          />
        </div>
      </div>
      <div
        id="customize-alerts"
        className="mt-8 bg-blue-50 p-6 rounded-lg w-full"
      >
        <h3 className="text-2xl font-semibold text-blue-700 ">
          Customizing Your Coach Communication Alerts
        </h3>
      </div>
      <div className="bg-white p-6 rounded-lg mt-[-24px]">
        <h4 className="text-xl font-semibold text-gray-700">
          1. Message Notifications
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Find the <strong>"Messages"</strong> section.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MemberNotifications}
              alt="Member Notifications Tab Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Select how you want to be notified when your coach sends you a
            message:
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>Email:</strong> Receive an email notification.
            </li>
            <li>
              <strong>SMS:</strong> Receive a text message notification.
            </li>
            <li>
              <strong>In-app:</strong> Receive a notification within Zoee.
            </li>
          </ul>
          <li>Choose one or multiple options based on your preference.</li>
        </ul>
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          2. Saving Your Preferences
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            After making your selections, click the <strong>"Save"</strong>{" "}
            button at the top of the page.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SaveNotifications}
              alt="Save Notifications Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            A confirmation message will appear when your changes are
            successfully saved.
          </li>
        </ul>
      </div>
      <div className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h3 className="text-2xl font-semibold text-blue-700">
          Troubleshooting Notification Issues
        </h3>
        <p className="text-gray-700 mt-2">
          If you're not receiving notifications from your coach:
        </p>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Verify your email address is correct in your account settings.
          </li>
          <li>Check your email spam/junk folder.</li>
          <li>
            Ensure you haven't blocked notifications in your browser settings.
          </li>
          <li>
            For SMS notifications, confirm your phone number is correctly
            entered.
          </li>
        </ul>
        <p className="text-gray-700 mt-4">
          Your notification settings can be updated at any time as your
          preferences change.
        </p>
      </div>
      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
