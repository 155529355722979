import React from "react"
import AvatarVideo from "./AvatarVideo"
import axios from "axios"
import CloseModal from "../CloseModal"
import DragDropArea from "../DragDropArea"
import Loader from "../ui/loader"
import ReactCrop, {
  centerCrop,
  type Crop,
  makeAspectCrop,
} from "react-image-crop"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests, UtilityRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"
import { UserInfo } from "../../api/app.service"
import { SvgCrop, SvgTrash, SvgUploadImage } from "../icons"

export default function EditProfileAvatar() {
  const {
    coachPublicProfileData,
    setEditPrimaryProfileVideo,
    getCoachPrivateProfile,
  } = React.useContext(CoachPrivateProfileContext)
  const { startLoading, stopLoading } = useLoading()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {
    setPopupNotification,
    renderSuccess,
    renderError,
    previewImgUrl,
    setPreviewImgUrl,
    hideAlert,
    getUser,
  } = React.useContext(CommonFunctionCtx)
  // VIDEO STATE
  const { user } = React.useContext(UserCtx)
  const [profileVideoFile, setProfileVideoFile] = React.useState<File>()
  const [awaitNewVideo, setAwaitNewVideo] = React.useState<boolean>(false)
  const [videoProperties, setVideoProperties] = React.useState<any>(null)
  const [activeView, setActiveView] = React.useState<string>("photo")
  const [showCrop, setShowCrop] = React.useState<boolean>(false)
  const [profileImgFile, setProfileImgFile] = React.useState<File>()
  const [awaitNewImage, setAwaitNewImage] = React.useState<boolean>(false)
  const [crop, setCrop] = React.useState<Crop>()

  const validateFile = (imageFile: File) => {
    if (
      (imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg") &&
      imageFile.size <= 5000000 &&
      imageFile.size > 0
    ) {
      return true
    } else if (
      (imageFile.type === "video/mp4" ||
        imageFile.type === "video/mov" ||
        imageFile.type === "video/HEVC") &&
      imageFile.size <= 200000000 &&
      imageFile.size > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const deleteProfileImage = () => {
    hideAlert()
    if (user) {
      setLoading(true)
      startLoading()
      UserInfo.deleteUserAccountImage({ user_id: parseInt(user.user_id) })
        .then(() => {
          getUser().then(() => {
            setLoading(false)
            renderSuccess("Deleted account image")
            stopLoading()
          })
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const postVideoToS3 = async (presignedUrl: any) => {
    let form = new FormData()
    Object.keys(presignedUrl?.fields).forEach((key) =>
      form.append(key, presignedUrl?.fields[key])
    )
    if (profileVideoFile) {
      form.append("file", profileVideoFile)
    }
    await axios
      .post(presignedUrl?.url, form)
      .then(() => {
        getCoachPrivateProfile().then(() => {
          renderSuccess("Uploaded profile video")
          setEditPrimaryProfileVideo(false)
          stopLoading()
        })
      })
      .catch((err) => {
        console.error(err)
        renderError("Failed to upload video.")
        stopLoading()
      })
  }

  const getPresignedUrl = async (videoId: number) => {
    if (videoProperties?.base64extension) {
      UtilityRequests.getPresignedS3Url({
        object_name: `${
          coachPublicProfileData?.coach_public_profile_id
        }_main_${videoId}.${getExtension(videoProperties?.base64extension)}`,
      })
        .then((data) => {
          postVideoToS3(data.presigned_url)
        })
        .catch((ex: any) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    } else {
      stopLoading()
    }
  }

  const getExtension = (fileExtension: any) => {
    if (fileExtension === "quicktime") {
      return "mov"
    } else {
      return fileExtension
    }
  }

  const saveVideoDetails = async () => {
    startLoading()
    const requestObject = {
      video_id: coachPublicProfileData?.main_video_id || null,
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
      title: "Website Video",
      video_file_extension:
        getExtension(videoProperties?.base64extension) || null,
      video_type: "main",
    }
    await ProfileRequests.editProfileVideo(requestObject)
      .then((data) => {
        getPresignedUrl(data.video_id)
      })
      .catch((ex: any) => {
        console.log(ex)
        setProfileVideoFile(undefined)
        setVideoProperties(null)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const deleteProfileVideo = () => {
    if (coachPublicProfileData?.main_video_id) {
      startLoading()
      ProfileRequests.deleteProfileVideo({
        coach_public_profile_id:
          coachPublicProfileData?.coach_public_profile_id,
        video_id: coachPublicProfileData?.main_video_id,
        video_type: "main",
        video_extension: coachPublicProfileData?.main_video_extension,
      })
        .then(() => {
          getCoachPrivateProfile().then(() => {
            renderSuccess("Deleted main profile video")
            setProfileVideoFile(undefined)
            setAwaitNewVideo(false)
            setEditPrimaryProfileVideo(false)
          })
        })
        .catch((ex: any) => {
          console.log(ex)
          setProfileVideoFile(undefined)
          setVideoProperties(null)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const setProfileVideoDetails = () => {
    if (profileVideoFile) {
      if (validateFile(profileVideoFile)) {
        let base64 = ""
        const type = profileVideoFile.type.split("/")[1]
        const reader = new FileReader()
        reader.readAsDataURL(profileVideoFile)
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",")
          })
          base64 = string[0][1]
          setVideoProperties({
            base64: base64,
            base64extension: type,
          })
        }
        reader.onerror = function (error) {
          console.log("Error: ", error)
        }
        // setAwaitNewVideo(false);
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your video file is too big.",
          message:
            "Give it another try with a file under 200 megabytes, and in mp4, mov, or HEVC format.",
          callback: null,
        })
        setProfileVideoFile(undefined)
        setAwaitNewVideo(true)
        setVideoProperties(null)
      }
    }
  }

  const saveUserProfileImage = (base64String: string, type: string) => {
    hideAlert()
    if (user) {
      startLoading()
      UserInfo.editUserAccountImage({
        user_id: parseInt(user.user_id),
        access_token: user.access_token,
        base_64_string: base64String,
        file_extension: type,
      })
        .then(() => {
          getUser().then(() => {
            renderSuccess("Uploaded account image")
            setProfileImgFile(undefined)
            setAwaitNewImage(false)
            stopLoading()
          })
        })
        .catch((ex: any) => {
          console.log(ex)
          setProfileImgFile(undefined)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const cropUserProfileImage = (
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    hideAlert()
    if (user) {
      startLoading()
      UserInfo.cropUserAccountImage({
        user_id: parseInt(user.user_id),
        x,
        y,
        width,
        height,
      })
        .then(() => {
          getUser().then(() => {
            renderSuccess("Cropped account image")
            setPreviewImgUrl(user.avatar_url + "&id=" + new Date().getTime())
            setShowCrop(false)

            setEditPrimaryProfileVideo(false)
            setProfileVideoFile(undefined)
            setVideoProperties(null)
          })
        })
        .catch((ex: any) => {
          console.error(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const handleProfileImageUpload = () => {
    if (profileImgFile) {
      if (validateFile(profileImgFile)) {
        const type = profileImgFile.type.split("/")[1]
        const reader = new FileReader()
        reader.readAsDataURL(profileImgFile)
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",")
          })
          const base64 = string[0][1]
          saveUserProfileImage(base64, type)
        }
        reader.onerror = function (error) {
          console.log("Error: ", error)
        }
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your file is too big.",
          message:
            "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
          callback: null,
        })
        setProfileImgFile(undefined)
      }
    }
  }

  React.useEffect(() => {
    handleProfileImageUpload()
    if (profileImgFile) {
      setPreviewImgUrl(URL.createObjectURL(profileImgFile))

      handleProfileImageUpload()
    }
  }, [profileImgFile])

  React.useEffect(() => {
    setProfileVideoDetails()
  }, [profileVideoFile])

  function onImageLoad(e: any) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
        },
        1,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop)
  }

  return (
    <div className="zoee-backdrop-filter fixed left-0 top-0 z-[1009] flex h-screen w-screen items-center justify-center md:z-[801]">
      <div className="main-shadow fixed left-[50%] flex max-h-[90%] w-[650px] max-w-[90%] -translate-x-[50%] flex-col items-center overflow-y-auto rounded-[16px] bg-white px-[40px] pb-[40px] pt-[90px]">
        <CloseModal
          callback={() => {
            setEditPrimaryProfileVideo(false)
            setProfileVideoFile(undefined)
            setVideoProperties(null)
          }}
          styling="absolute top-6 right-8"
        />
        <h2 className="absolute left-[32px] top-[26px] text-[18px] font-bold">
          Edit Website Photo/Video
        </h2>

        {showCrop ? (
          <div className="flex flex-col gap-6">
            <ReactCrop
              crop={crop}
              onChange={(_, p) => setCrop(p)}
              aspect={1}
              circularCrop
              className="min-h-[60px] min-w-[60px]"
            >
              {previewImgUrl ? (
                <img
                  src={previewImgUrl}
                  alt="avatar preview"
                  onLoad={onImageLoad}
                />
              ) : (
                <img src={user.avatar_url} alt="avatar" onLoad={onImageLoad} />
              )}
            </ReactCrop>
            <div className="mx-auto flex gap-2">
              <Button onClick={() => setShowCrop(false)} variant="link">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (
                    crop?.x !== undefined &&
                    crop?.y !== undefined &&
                    crop.width !== undefined &&
                    crop.height !== undefined
                  ) {
                    cropUserProfileImage(
                      crop.x,
                      crop.y,
                      crop.width,
                      crop.height
                    )
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div
              className="text-graySlate mb-[32px] flex w-full justify-center gap-[16px]
          text-base font-bold"
            >
              <button
                className={
                  activeView === "photo"
                    ? "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                    : ""
                }
                onClick={() => setActiveView("photo")}
              >
                Edit Photo
              </button>
              <button
                className={
                  activeView === "video"
                    ? "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                    : ""
                }
                onClick={() => setActiveView("video")}
              >
                Edit Video
              </button>
            </div>

            <>
              {activeView === "photo" ? (
                <>
                  <p className="text-graySlate mb-[8px] -translate-y-4 text-center text-sm font-bold">
                    Max file size is 5MB. 1:1 ratio recommended (ex. 300px x
                    300px)
                    <br />
                    File types: .png, .jpg, and .jpeg
                  </p>
                  {user.hasAvatar ? (
                    <div
                      className="flex w-full flex-col items-center
                md:flex-row md:justify-start md:gap-[40px]"
                    >
                      <>
                        {awaitNewImage ? (
                          <div className="mx-auto flex items-center gap-[24px]">
                            <DragDropArea
                              styling={`rounded-[20px] w-[140px] h-[140px]`}
                              mediaFile={profileImgFile}
                              setMediaFile={setProfileImgFile}
                              mediaType="image"
                            />
                            <Button
                              variant={"link"}
                              onClick={() => setAwaitNewImage(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <div className="mx-auto flex flex-col items-center gap-[24px]">
                            <div className="relative mb-[20px] h-[140px] w-[140px] rounded-[20px] md:mb-0">
                              {previewImgUrl ? (
                                <img
                                  src={previewImgUrl}
                                  className="absolute left-0 top-0 flex h-full w-full
                                  items-center justify-center rounded-full object-cover"
                                  alt="avatar preview"
                                />
                              ) : (
                                <img
                                  src={user.avatar_url}
                                  className="absolute left-0 top-0 flex h-full w-full
                                    items-center justify-center rounded-[20px] object-cover"
                                  alt="avatar"
                                />
                              )}
                            </div>

                            <div className="flex flex-col gap-[10px] sm:flex-row sm:items-center">
                              <Button
                                variant={"utility"}
                                onClick={() => setAwaitNewImage(true)}
                              >
                                <SvgUploadImage />
                                Upload
                              </Button>
                              <Button
                                variant={"utility"}
                                onClick={() => setShowCrop(true)}
                              >
                                <SvgCrop />
                                Crop
                              </Button>
                              {loading ? (
                                <Loader />
                              ) : (
                                <Button
                                  variant={"utility"}
                                  onClick={deleteProfileImage}
                                >
                                  <SvgTrash />
                                  Delete
                                </Button>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    <div className="mx-auto flex flex-col items-center">
                      <h5 className="mb-[4px] font-bold">
                        Add your photo here
                      </h5>
                      <p className="text-graySlate mb-[10px] text-base font-bold">
                        This photo will be used on your coach profile and
                        throughout the application.
                      </p>
                      <p className="text-graySlate mb-[24px] text-center text-xs font-bold">
                        Max file size 5 megabytes. Please use: png, jpg, or jpeg
                      </p>
                      <DragDropArea
                        styling={`rounded-[20px] w-[140px] h-[140px]`}
                        mediaFile={profileImgFile}
                        setMediaFile={setProfileImgFile}
                        mediaType="image"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="mb-[48px] flex items-center justify-center gap-[34px]">
                    {/* VIDEO DROP ZONE / PREVIEW */}
                    <div className="flex flex-col items-center gap-[24px] sm:flex-row">
                      <div className="flex w-[220px] flex-col items-center text-left">
                        <h5 className="mb-[12px] font-bold">
                          Add your video here
                        </h5>
                        <ul className="mb-[24px]">
                          <li className="text-graySlate list-disc text-base font-bold">
                            This will be used on your Zoee website.
                          </li>
                          <li className="text-graySlate list-disc text-base font-bold">
                            Portrait videos work best. Max file size: 200mb.
                          </li>
                          <li className="text-graySlate list-disc text-base font-bold">
                            File types: MP4, MOV, HEVC.
                          </li>
                        </ul>
                      </div>
                      <div className="mx-auto h-[340px] w-[220px]">
                        {coachPublicProfileData?.has_profile_video ? (
                          <>
                            {awaitNewVideo ? (
                              <DragDropArea
                                styling={` w-full h-full rounded-[14px]`}
                                mediaFile={profileVideoFile}
                                setMediaFile={setProfileVideoFile}
                                mediaType="video"
                              />
                            ) : (
                              <>
                                <AvatarVideo />
                              </>
                            )}
                          </>
                        ) : (
                          <DragDropArea
                            styling={`w-full h-full rounded-[14px]`}
                            mediaFile={profileVideoFile}
                            setMediaFile={setProfileVideoFile}
                            mediaType="video"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mx-auto flex items-center gap-[12px]">
                    {coachPublicProfileData?.has_profile_video ? (
                      <button
                        onClick={deleteProfileVideo}
                        className="btn-primary bg-vividRed"
                      >
                        Delete Video
                      </button>
                    ) : (
                      <></>
                    )}
                    {!awaitNewVideo &&
                    coachPublicProfileData?.has_profile_video ? (
                      <button
                        onClick={() => {
                          setProfileVideoFile(undefined)
                          setAwaitNewVideo(true)
                          setVideoProperties(null)
                        }}
                        className="btn-primary btn-secondary"
                      >
                        Change Video
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      onClick={saveVideoDetails}
                      className="btn-primary btn-blue"
                      disabled={!videoProperties}
                    >
                      Save Video
                    </button>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  )
}
