import * as React from "react"
import type { SVGProps } from "react"

export const SvgZoeeLogoGradiant = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="43"
    fill="none"
    viewBox="0 0 51 43"
  >
    <g id="Component" clipPath="url(#clip0_31_87)">
      <path
        id="Vector"
        fill="url(#paint0_linear_31_87)"
        d="M37.733 14.301V2.795a2.75 2.75 0 0 0-.791-1.96A2.8 2.8 0 0 0 34.984 0H2.749C2.011.01 1.307.31.791.834a2.75 2.75 0 0 0-.79 1.961v37.338a2.75 2.75 0 0 0 .79 1.96c.516.525 1.22.825 1.958.835h32.268a2.8 2.8 0 0 0 1.957-.834c.516-.524.8-1.23.792-1.96v-5.412a.22.22 0 0 1 .036-.143l12.837-17.558c.216-.287.115-1.724-.247-1.724l-12.561-.021c-.152.028-.098-3.225-.098-.975"
      ></path>
      <path
        id="Vector_2"
        fill="#fff"
        d="M29.27 29.228v3.78a.84.84 0 0 1-.218.608.86.86 0 0 1-.588.277H9.808a.86.86 0 0 1-.588-.277.84.84 0 0 1-.219-.608v-3.142c0-.206.067-.406.192-.57l10.265-13.29a.877.877 0 0 0-.17-1.27.9.9 0 0 0-.445-.164H10.1A.86.86 0 0 1 9.354 14a.8.8 0 0 1-.042-.326V9.882a.84.84 0 0 1 .213-.6A.85.85 0 0 1 10.1 9h17.983a.86.86 0 0 1 .594.264.84.84 0 0 1 .231.603v3.16a.96.96 0 0 1-.192.57L18.45 26.892c-.442.573-.07 1.454.614 1.454h9.405a.85.85 0 0 1 .585.277c.15.165.229.383.218.605"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_31_87"
        x1="25.376"
        x2="25.376"
        y1="0"
        y2="42.928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#292F8F"></stop>
        <stop offset="1" stopColor="#4750F5"></stop>
      </linearGradient>
      <clipPath id="clip0_31_87">
        <path fill="#fff" d="M0 0h51v43H0z"></path>
      </clipPath>
    </defs>
  </svg>
)
