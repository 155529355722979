import * as Testimonials from "../Testimonials"
import ActiveSessionPreview from "./ActiveSessionPreview"
import Loader from "../ui/loader"
import useLoading from "../../hooks/useLoading"
import UserSelect from "../user-select/UserSelect"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { Link } from "react-router-dom"
import { NotificationCtx } from "../../context/notificationContext"
import { SessionContext } from "../../pages/sessions/context/sessionContext"
import { SessionsRequests } from "../../api/app.service"
import { useContext, useState, useEffect, FC } from "react"
import { UserCtx } from "../../context/userContext"

interface SessionItem {
  room_id: string
  participant_list: {
    profile_id: number
  }[]
}

interface SessionData {
  session_data: {
    room_id: string
    session_type: number
    send_review: number
    end_time: string
    total_participant_count: number
  }
  details_list: any[]
  webhook_status: string
  message: string
}

const ActiveSessionLanding: FC = () => {
  const { user } = useContext(UserCtx)
  const [sessionList, setSessionList] = useState<any[]>([])
  const [participantsModal, setParticipantsModal] = useState<boolean>(false)
  const [hostUserId, setHostUserId] = useState<string>("")
  const { renderError } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { sessionListLoading } = useContext(SessionContext)
  const { lastWebsocketMessage } = useContext(NotificationCtx)
  const [hostJoined, setHostJoined] = useState(false)
  const [isQuick, setIsQuick] = useState(false)
  const [pastSessions, setPastSessions] = useState<SessionItem[]>([])
  const [lastSessionRoomId, setLastSessionRoomId] = useState<string>("")
  const [sessionData, setSessionData] = useState<SessionData | null>(null)
  const [coach, setCoach] = useState<any[]>([])
  const [createTestimonialOpen, setCreateTestimonialOpen] =
    useState<boolean>(false)

  const createQuickMeeting = (selectedParticipants: any[]) => {
    if (user) {
      startLoading()
      const currentUser = {
        profile_id: user.activeProfileId,
        contact_name: user.firstName
          ? `${user.firstName} ${user.lastName}`
          : user.email,
        email: user.email,
        user_id: user.user_id,
      }
      const participants = [currentUser, ...selectedParticipants]
      SessionsRequests.create100msQuickMeeting({
        profile_id: user.activeProfileId,
        meeting_name: `${
          user?.firstName ? user.firstName : user.email
        }'s Quick Meeting`,
        participants: participants,
      })
        .then((data) => {
          setIsQuick(true)
          setHostUserId(user.user_id)
          getSessionList()
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const getSessionList = () => {
    if (user) {
      SessionsRequests.get100msSessionList({
        profile_id: user.activeProfileId,
      })
        .then((data) => {
          setSessionList(data.session_list)

          if (data.session_list.length > 0) {
            data.session_list.forEach((session: any, index: any) => {})
          }

          const currentTime = new Date()

          const upcomingSessions = data.session_list
            .filter((session: any) => {
              const meetingStartTime = new Date(
                session.meeting_start_time + " UTC"
              )
              return meetingStartTime > currentTime
            })
            .sort((a: any, b: any) => {
              const localTimeA = new Date(
                a.meeting_start_time + " UTC"
              ).getTime()
              const localTimeB = new Date(
                b.meeting_start_time + " UTC"
              ).getTime()
              return localTimeA - localTimeB
            })

          const currentSession = data.session_list.find((session: any) => {
            const meetingStartTime = new Date(
              session.meeting_start_time + " UTC"
            )
            const meetingEndTime =
              session.meeting_end_time !== "None"
                ? new Date(session.meeting_end_time + " UTC")
                : null

            return (
              meetingStartTime <= currentTime &&
              (meetingEndTime ? meetingEndTime > currentTime : true)
            )
          })

          if (currentSession) {
            setHostJoined(currentSession.host_joined)
          } else {
            setHostJoined(false)
          }

          setHostJoined(data?.session_list[0]?.host_joined)
          if (data?.session_list[0]?.service_usage_id === null) {
            setIsQuick(true)
          }
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const checkUrlParams = () => {
    const params = new URLSearchParams(window.location.search)
    const redirect = params.get("redirect")
    if (redirect === "quickstart") {
      window.scrollTo(0, 0)
      setParticipantsModal(true)
    }
    window.history.replaceState(null, "", window.location.pathname)
  }

  const getPastSessions = () => {
    if (user) {
      SessionsRequests.getPastSessions({
        profile_id: user.activeProfileId,
      })
        .then((data: any) => {
          setPastSessions(data)
          const sessions = data.session_list
          setPastSessions(sessions)
          if (sessions.length > 0) {
            setLastSessionRoomId(sessions[sessions.length - 1].room_id)
          }
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
        })
    }
  }

  const getSessionDetails = () => {
    if (pastSessions && pastSessions.length > 0) {
      SessionsRequests.getPastSessionDetail({
        room_id: lastSessionRoomId,
      })
        .then((data: any) => {
          setSessionData(data)
          setCreateTestimonialOpen(true)
        })
        .catch((ex) => {
          renderError(ex.response?.data?.message || "An error occurred")
        })
        .finally(() => {})
    }
  }

  const checkParticipants = () => {
    const participants = pastSessions[pastSessions.length - 1].participant_list
    const id = user.activeProfileId
    const coachId = participants
      .filter((participant) => participant?.profile_id !== id)
      .map((participant) => participant?.profile_id)
    setCoach(coachId)
  }
  useEffect(() => {
    if (
      lastWebsocketMessage?.type === "session" &&
      (lastWebsocketMessage?.data?.event_type === "quick_meeting_started" ||
        lastWebsocketMessage?.data?.event_type === "session_ended" ||
        lastWebsocketMessage?.data?.participant_count > 0)
    ) {
      getSessionList()
      getPastSessions()
    }
  }, [lastWebsocketMessage])
  useEffect(() => {
    getPastSessions()
    checkUrlParams()
    getSessionList()
  }, [])
  useEffect(() => {
    if (lastSessionRoomId) {
      getSessionDetails()
      checkParticipants()
    }
  }, [pastSessions])

  useEffect(() => {
    if (createTestimonialOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [createTestimonialOpen])

  return (
    <>
      <>
        {/* {user.activeProfile === "coach" && (
          <div className="mb-6 -mt-4 text-graySlate text-base">
            Create instant sessions or schedule meetings with clients in your
            secure video space—all without leaving Zoee! Launch quick calls when
            inspiration strikes or plan ahead with scheduled sessions that
            automatically appear in everyone's calendar. Your professional video
            hub eliminates app-switching and keeps all your client
            communications in one place. Check out our{" "}
            <a
              href="https://intercom.help/zoee/en/articles/8107152-schedule-invite-and-host-a-session"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              <span className="font-bold text-blurple">how-to guide</span>
            </a>{" "}
            to unlock all the powerful features that make connecting with
            clients effortless.
          </div>
        )} */}
        {participantsModal && (
          <UserSelect
            setShowModal={setParticipantsModal}
            callback={createQuickMeeting}
            modalTitle="Quick Session"
            buttonText="Create Session"
          />
        )}
        <div className="mx-auto w-[376px] md:w-full flex flex-col md:flex-row-reverse justify-between">
          <div className="mb-[24px] md:m-0 w-full md:w-[396px]">
            {sessionListLoading ? (
              <div className="w-full h-[464px] flex items-center justify-center flex-col rounded-xl border border-grayMist bg-[#f8f8f8] gap-[10px]">
                <span className="mb-2">Loading active sessions...</span>
                <Loader />
              </div>
            ) : (
              <>
                {sessionList.length > 0 ? (
                  <ActiveSessionPreview
                    sessionList={sessionList}
                    getSessionList={getSessionList}
                    hostJoined={hostJoined}
                    hostUserId={hostUserId}
                    isQuick={isQuick}
                  />
                ) : (
                  <div className="w-full h-[464px] flex items-center justify-center rounded-xl border bg-muted">
                    No Active Sessions
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex flex-col gap-[24px]">
            {user.activeProfile === "coach" && (
              <div className="flex flex-col w-full md:w-[620px] p-[40px] main-shadow rounded-[16px] bg-white">
                <h2 className="text-[28px] font-semibold mb-[8px]">
                  Quick Session
                </h2>
                <p className="text-graySlate text-base mb-[24px]">
                  Jump into a quick video chat. This is perfect for non-billable
                  calls.
                </p>
                <div>
                  <Button size="lg" onClick={() => setParticipantsModal(true)}>
                    Create Session
                  </Button>
                </div>
              </div>
            )}
            <div className="flex flex-col w-full md:w-[620px] p-[40px] main-shadow rounded-[16px] bg-white mb-[80px]">
              <h2 className="text-[28px] font-semibold mb-[8px]">
                {user.activeProfile === "coach"
                  ? "Schedule a Session"
                  : "Book a Session"}
              </h2>
              <p className="text-graySlate text-base mb-[24px]">
                {user.activeProfile === "coach"
                  ? "Invite your client(s) to an upcoming Zoee session."
                  : "Schedule a future session with a Zoee coach."}
              </p>
              <div>
                <Link to={`/${user.activeProfile}/scheduling`}>
                  <Button size="lg">Schedule Session</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {Boolean(
          user.activeProfile === "member" &&
            sessionData?.session_data?.send_review === 1 &&
            sessionData?.session_data?.total_participant_count <= 2 &&
            createTestimonialOpen
        ) && (
          <Testimonials.CreateTestimonial
            roomId={lastSessionRoomId}
            isOpen={setCreateTestimonialOpen}
            coachId={coach}
          />
        )}
      </>
    </>
  )
}

export default ActiveSessionLanding
