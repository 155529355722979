import previewImage from "../../../assets/blog/howToMarketMyOnlineCoachingBusiness.webp";
import { Post } from "../../../types/postTypes";

export const howToMarketMyOnlineCoachingBusiness: Post = {
    metadata: {
        id: 15,
        title: "How do I market my online coaching business?",
        author: "Zoee",
        date: "January 12, 2022",
        urlSlug: "how-to-market-my-online-coaching-business",
        description:
            "Looking for creative solutions to marketing your online coaching business? Check out our top tips for keeping your business top of mind.",
        previewImg: previewImage,
        tags: ["Coach", "Business", "Entrepreneur"],
    },
    content: [
        {
            type: "paragraph",
            text: "Your online coaching business is like any other online business out there—which means marketing is imperative to your success. If you don’t market your business, it’s like setting up a storefront and never putting your name on the door or telling people that you’re, well, in business. And if they don’t know you’re in business, how can they buy from you?",
        },
        {
            type: "paragraph",
            text: "When you’re looking to grow your book of clients, marketing is going to be your best friend. Here are four tips from Zoee on how to market your online coaching business.",
        },
        {
            type: "header2",
            text: "1. Know your target audience",
        },
        {
            type: "paragraph",
            text: "If you read this statement and said, “anyone and everyone needs coaching, so my target audience is everyone” let us stop you right there. Would a five year old benefit from your coaching services? If you’re an academic coach for kids, maybe. But chances are you aren’t selling to kids, you’re selling to their parents. Parents of kids who need a little extra help in school. See what we did there?",
        },
        {
            type: "paragraph",
            text: "A good way to know your target audience is to look at your book of clients right now. Do you have any favorites? Any clients that if you could clone them, your business would be 10x in a month? That person is your target audience. And if you’re a brand new coach without a book of clients, then think about your ideal client. Who do you want sitting across from you in coaching sessions?",
        },
        {
            type: "paragraph",
            text: "Identifying your target audience may seem like a strategy you can skip over, but you really can’t. What income level does your ideal client need to be in order to afford your services? What life situation does your target audience need to be in to fit the programs you offer? What problems would someone face that you can help with? When you get crystal clear on who you want to work with, tailoring your message and content to that person aligns all of your marketing efforts.",
        },
        {
            type: "header2",
            text: "2. Email lists are key",
        },
        {
            type: "paragraph",
            text: "Social media platforms rise and fall with trends and generations, but email remains a consistent form of communication. It’s also one of the only ways to own contact information for leads and clients. Think about it: if Instagram were to shut down tomorrow, would you have a way of getting in touch with your audience?",
        },
        {
            type: "paragraph",
            text: "Yes, email marketing services like Flodesk or MailChimp can help with the formatting and sending of emails, but you don’t need a fancy marketing platform to get started. Here’s something you can do today: Bcc your client list on a weekly email from your free gmail account and write a plain text email with updates, offers, advice, tips, or whatever you want to share. (Just make sure to respect subscribers and remove people if they ask to be removed. Email marketing platforms will have unsubscribe buttons at the bottom).",
        },
        {
            type: "paragraph",
            text: "The most important thing with email marketing is consistency. Tell your email subscribers when you’re going to email them, and then do it. This builds an ongoing relationship digitally. You never know when your subscribers will forward your email to a friend and recommend your coaching services, or hit reply and want to book a discovery call.",
        },
        {
            type: "header2",
            text: "3. Create a lead magnet",
        },
        {
            type: "paragraph",
            text: "One of the ways you can jumpstart building an email list is by creating a lead magnet. Though this may seem like a complicated marketing term, you’ve probably participated in a few lead magnets when interacting with other businesses.",
        },
        {
            type: "paragraph",
            text: "A lead magnet is simply something a business offers a lead in exchange for their contact information. For instance, a store may offer you a discount code for subscribing to their SMS text messaging alerts. Or, you may give your email address and receive a link to download a free recipe ebook.",
        },
        {
            type: "paragraph",
            text: "As a coach, you can offer something similar while also giving potential clients a taste of what it’s like to work with you. Offering a free 30 day journaling challenge gets leads engaged, and gives you a captive audience to wow with content, then offer to work with them. January is a great time to run challenges like these because people are looking to make positive change in their lives.",
        },
        {
            type: "paragraph",
            text: "Don’t overthink it or overwhelm yourself—just keep it simple. Can you offer a free worksheet on creating a budget? Can you record an audio meditation and offer it to people who subscribe to your email list? Can you put together a free 7-day walking challenge where everyone hops on an audio call at the same time everyday and walks a mile while you coach volunteers in real time? There are endless ways to engage potential clients. Think about what you can offer the world, simplify it, then use it to amplify your message.",
        },
        {
            type: "header2",
            text: "4. Get comfortable with one social media platform and go all in",
        },
        {
            type: "paragraph",
            text: "Let’s ease your concern here—you don’t need to be on TikTok! But you do need to be on at least one form of social media. The one you choose should be one that you’re comfortable using (and will use regularly), and one that your target audience uses. These two items are critical.",
        },
        {
            type: "paragraph",
            text: "If you’re posting incredible content on Twitter but none of your clients, or potential clients, hang out on Twitter, then you’re really just screaming into the void. You’ve got to go where your audience is, and feed them the content they want to see.",
        },
        {
            type: "paragraph",
            text: "We’ll help you out: If you’re an executive coach, you’ll probably connect with most of your clients on LinkedIn since it’s a professional platform. If your target audience is millennials, most of your clients will probably hang out on Instagram. And if you’re a life coach looking to work with women who just became empty nesters? Do a little research and look at your own social media feeds. Where are those women hanging out? Facebook? Instagram? Pinterest? There are tons of resources on the internet to help you find out where your target audience is hanging out. (Just be sure to know your target audience first!)",
        },
        {
            type: "paragraph",
            text: "It’s worth saying that if you’ve tried these tips and still don’t feel like your marketing efforts are working, then it may be time to hire a consultant or a social media expert to help refine your tactics. You didn’t get into the coaching business to become a marketing expert, so outsourcing is not out of the question!",
        },
        {
            type: "paragraph",
            text: "One last thing—early in 2023, Zoee will begin bringing leads to qualified coaches on our Marketplace. That means with a personalized website you’ll be able to sell your coaching business to people all over the United States, and you don’t have to run a single social media ad. We’re really excited about this new feature, and know it will help grow your business!",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "The most successful coaches are the ones who find ways to spend more time ON their business and less time IN their business. Zoee is all about simplifying their processes so they can focus on their clients and growing their business. Get started for free today!",
                },
            ],
        },
    ],
};
