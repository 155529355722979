import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import ConnectMobile from "./cm-images/ConnectMobile.png"
import MemberMobile from "./cm-images/MemberMobile.png"
import CreateMobile from "./cm-images/CreateMobile.png"
import VerifyMobile from "./cm-images/VerifyMobile.png"
import CoachProfile from "./cm-images/CoachProfile.png"

export default function CMSetupGuideMobile() {
  return (
    <SectionWrapper id="setup">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Setting Up Your{" "}
          <span className="text-[#4750F5]">Secure Zoee Client Portal</span>
        </h1>
      </div>
      <div className="mt-8 bg-blue-50 p-6 rounded-lg w-full">
        <h3 className="text-2xl font-semibold text-blue-700">
          Before You Begin
        </h3>
        <p className="text-gray-700 mt-2">
          <strong>Recommended Browser:</strong> Google Chrome provides the best
          experience with Zoee.
        </p>
        <p className="text-gray-700">
          <strong>You'll Need:</strong> An{" "}
          <a
            href="#option1"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            email invitation from your coach
          </a>{" "}
          or{" "}
          <a
            href="#option2"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            direct access to your coach's website on Zoee
          </a>
          .
        </p>
      </div>

      <h3 id="option1" className="text-2xl font-semibold text-gray-800 mt-8">
        Option 1: Setting Up Through Email Invitation
      </h3>
      <div className="bg-white p-6 rounded-lg  mt-4">
        <h4 className="text-xl font-semibold text-gray-700">
          1. Open the Invitation Email
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Look for an email from your coach via{" "}
            <strong>no-reply@zoee.com</strong>.
          </li>
          <li>
            Find and click the <strong>"Connect"</strong> button in the email.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={ConnectMobile}
              alt="Forgot Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>If you don’t see it, check your spam folder.</li>
        </ul>
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          2. Create Your Account
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            When prompted, select <strong>"I'm a client".</strong>
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MemberMobile}
              alt="Forgot Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Enter your email address</li>
          <li>
            Create a password (minimum 8 characters with a combination of
            letters, numbers, and symbols).
          </li>
          <li>Re-enter your password to confirm.</li>
        </ul>
        <div className="flex justify-center mt-4">
          <img
            src={CreateMobile}
            alt="Forgot Password Example"
            className="w-full max-w-md rounded-lg"
          />
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          3. Verify Your Email
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Check your inbox for an email from{" "}
            <strong>no-reply@zoee.com</strong>.
          </li>
          <li>Click the verification link in the email.</li>
          <div className="flex justify-center mt-4">
            <img
              src={VerifyMobile}
              alt="Forgot Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>You will be automatically directed to your Zoee homepage.</li>
        </ul>
      </div>

      <div className="bg-green-100 p-6 rounded-lg mt-8">
        <h3 className="text-2xl font-semibold text-blurple">
          Your Account is Now Ready
        </h3>
        <p className="text-gray-700 mt-2">
          You can now access your secure client portal. All communications with
          your coach will happen through this portal.
        </p>
      </div>

      <h3 id="option2" className="text-2xl font-semibold text-gray-800 mt-8">
        Option 2: Setting Up Through Your Coach's Zoee Website
      </h3>
      <div className="bg-white p-6 rounded-lg mt-4">
        <h4 className="text-xl font-semibold text-gray-700">
          1. Visit Your Coach's Website on Zoee
        </h4>
        <p className="text-gray-700 mt-2">
          Use the direct URL provided by your coach.
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          2. Choose an Action
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>Schedule a discovery call</li>
          <li>Send a message to the coach</li>
          <li>Purchase an available service</li>
        </ul>
      </div>
      <div className="flex justify-center mt-4">
        <img
          src={CoachProfile}
          alt="Forgot Password Example"
          className="w-full max-w-md rounded-lg"
        />
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          3. Create Your Account
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            When prompted, select <strong>"I'm a client".</strong>
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MemberMobile}
              alt="Forgot Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Enter your email address.</li>
          <li>
            Create a password (minimum 8 characters with a combination of
            letters, numbers, and symbols).
          </li>
          <li>Re-enter your password to confirm.</li>
        </ul>
      </div>
      <div className="flex justify-center mt-4">
        <img
          src={CreateMobile}
          alt="Forgot Password Example"
          className="w-full max-w-md rounded-lg"
        />
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <h4 className="text-xl font-semibold text-gray-700">
          4. Verify Your Email
        </h4>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>
            Check your inbox for an email from{" "}
            <strong>no-reply@zoee.com</strong>
          </li>
          <li>Click the verification link in the email.</li>
          <div className="flex justify-center mt-4">
            <img
              src={VerifyMobile}
              alt="Forgot Password Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            You will automatically be redirected to complete your original
            action (scheduling, messaging, or purchasing).
          </li>
        </ul>
      </div>
      <div className="bg-green-100 p-6 rounded-lg mt-8">
        <h3 className="text-2xl font-semibold text-blurple">
          Your Account is Now Ready
        </h3>
        <p className="text-gray-700 mt-2">
          You can now access your secure client portal. All communications with
          your coach will happen through this portal.
        </p>
      </div>

      <div className="bg-blue-50 p-6 rounded-lg w-full mt-8">
        <h3 className="text-2xl font-semibold text-gray-800">What's Next?</h3>
        <p className="text-gray-700 mt-2">
          After setting up your account, you'll be able to:
        </p>
        <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-2">
          <li>Set your Notifications</li>
          <li>Schedule sessions with your coach</li>
          <li>Exchange secure messages</li>
          <li>Join a Session</li>
          <li>Create notes in your Session Notebook</li>
          <li>Access resources shared by your coach</li>
          <li>Manage your services and payments</li>
        </ul>
        <p className="text-gray-700 mt-4">
          <strong>Need Help?</strong> If you encounter any issues during setup,
          contact your coach directly or reach out to Zoee support at
          <a
            href="mailto:support@zoee.com"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            {" "}
            support@zoee.com
          </a>
          .
        </p>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
