import React, { useState, useContext } from "react"
import CalendarDetails from "./CalendarDetails"
import calendarsImage from "../../../assets/images/calendars-image.webp"
import { Button } from "../../ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog"
import {
  SvgApple,
  SvgGoogleCalendar,
  SvgOffice365Calendar,
  SvgOutlookCalendar,
  SvgPlus,
} from "../../icons"
import { UserCtx } from "../../../context/userContext"

interface Provider {
  Image: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  name: string
  label: string
  button?: string
}

const providers: Provider[] = [
  {
    Image: SvgGoogleCalendar,
    name: "google",
    label: "Google Calendar",
  },
  {
    Image: SvgOffice365Calendar,
    name: "office365",
    label: "Office365",
  },
  {
    Image: SvgOutlookCalendar,
    name: "live_connect",
    label: "Outlook",
    button: "Outlook",
  },
  {
    Image: SvgApple,
    name: "apple",
    label: "Apple iCloud",
  },
]

interface Props {
  isCalendarConnected: boolean
}

export const ConnectCalendarCTA: React.FC<Props> = ({
  isCalendarConnected,
}) => {
  const { user } = useContext(UserCtx)
  const [showCalendarDetails, setShowCalendarDetails] = useState<boolean>(false)

  const startConnectCalendar = async (provider_name: string) => {
    const redirectUri = `${process.env.REACT_APP_BASE_URL}/account-settings/calendars`

    const url = `https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_CRONOFY_CLIENT_ID}&redirect_uri=${redirectUri}&scope=read_write&provider_name=${provider_name}&avoid_linking=true`
    window.location.href = url
  }

  return (
    <section className="bg-white rounded-xl main-shadow p-6 flex flex-col lg:flex-row lg:items-center gap-6 xl:gap-12">
      <div className="lg:basis-1/2 lg:basis-2/5 rounded-lg overflow-hidden">
        <img
          src={calendarsImage}
          alt="calendars"
          className="h-auto lg:w-full"
        />
      </div>
      <div className="flex flex-col items-stretch lg:items-start lg:basis-1/2 lg:basis-3/5">
        <div className="flex">
          <h3 className="font-bold text-2xl mb-4">
            {isCalendarConnected
              ? "Sync Another Calendar"
              : "Connect Your Calendar"}
          </h3>
        </div>
        <p className="text-graySlate font-base mb-6">
          {user.activeProfile === "coach"
            ? "Connect your calendar and set your availability to let clients book sessions with you instantly. No more back-and-forth emails or missed appointments!"
            : "Connect your calendar and easily book sessions with your coach at a time that works for you. No more back-and-forth emails or scheduling hassles!"}{" "}
          Watch our{" "}
          <a
            href="https://intercom.help/zoee/en/articles/8685614-calendar-integration-for-scheduling-and-video-sessions"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            <span className="font-bold text-blurple">quick video guide</span>
          </a>{" "}
          to get started in minutes and{" "}
          {user.activeProfile === "coach"
            ? "make scheduling effortless on Zoee."
            : "enjoy effortless booking on Zoee."}
        </p>

        <Dialog>
          <DialogTrigger asChild>
            <Button>
              {isCalendarConnected ? "New Calendar" : "Connect Calendar"}
              <SvgPlus />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-center">
                Select Your Calendar
              </DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="flex flex-col gap-2 w-full">
                {providers.map(({ Image, name, label, button }) => (
                  <div
                    key={label}
                    className="flex flex-row p-4 lg:p-6 gap-6 gap-2 border border-1 border-border rounded-lg justify-between sm:items-center"
                  >
                    <div className="flex gap-4 items-center font-bold text-lg justify-center">
                      <Image className="w-8 h-8 sm:w-12 sm:h-12" />
                      {label}
                    </div>
                    <Button
                      onClick={() => startConnectCalendar(name)}
                      variant="secondary"
                    >
                      <span>
                        Sign in{" "}
                        <span className="hidden sm:inline">
                          with{" "}
                          <span className="capitalize">
                            {button ? button : name}
                          </span>
                        </span>
                      </span>
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      {showCalendarDetails && (
        <CalendarDetails setShowCalendarDetails={setShowCalendarDetails} />
      )}
    </section>
  )
}
