import React, { useState, useEffect, useContext } from "react"
import InfoModal from "../InfoModal"
import useName from "../../hooks/useName"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Button } from "../../components/ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { getTextColor } from "../../utils/getTextColor"
import { getUserColor } from "../../utils/getUserColor"
import { GuideRequests } from "../../api/app.service"
import { Link } from "react-router-dom"
import { selectPeers, useHMSStore } from "@100mslive/react-sdk"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import {
  SvgHelp,
  SvgInvite,
  SvgLogout,
  SvgSettings,
  SvgStar,
  SvgUser,
  SvgEducation,
  SvgCheckbox,
  SvgCheckboxOff,
} from "../icons/"
import { useAuthModal } from "../auth/use-auth-modal"
import { useIntercom } from "react-use-intercom"
import { UserCtx } from "../../context/userContext"
import { UserInfo } from "../../api/app.service"

interface Props {
  guideOpened?: any
  setGuideOpened?: any
}

const UserButton: React.FC<Props> = ({ guideOpened, setGuideOpened }) => {
  // Context
  const { user } = useContext(UserCtx)
  const { renderError, previewImgUrl } = useContext(CommonFunctionCtx)
  const { activeSubscriptionType } = useContext(SubscriptionCtx)

  // Hooks
  const { initial, username } = useName()
  const { logout } = useAuthModal()
  const peers = useHMSStore(selectPeers)
  const { show } = useIntercom()

  // State
  const [nextStepsData, setNextStepsData] = useState<any>({})
  const [, setDisabledSwitchAccount] = useState<boolean>(false)

  const [showReferCoachComingSoon, setShowReferCoachComingSoon] =
    useState<boolean>(false)

  // Functions
  const userInActiveSession =
    peers.filter((peer) => {
      const id = user.activeProfileId
      return peer.customerUserId === id.toString()
    }).length > 0

  const getNextStepsValues = async () => {
    await UserInfo.getNextStepsValues({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        // GET RID OF `OR` condition on master push
        setNextStepsData(data.next_steps_data)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
  }

  const handleToggle = () => {
    const newValue = guideOpened ? false : true
    setGuideOpened(newValue)
    updateGuideValue(newValue)
  }

  const updateGuideValue = (newValue: boolean) => {
    if (user) {
      GuideRequests.updateGuideValue({
        user_id: parseInt(user.user_id),
        guide_opened: newValue,
      })
        .then(() => {
          setGuideOpened(newValue)
        })
        .catch((error) => {
          console.error("Error updating guide value: ", error)
        })
    }
  }

  // useEffects
  useEffect(() => {
    if (userInActiveSession) {
      setDisabledSwitchAccount(true)
    } else {
      setDisabledSwitchAccount(false)
    }
  }, [peers])

  useEffect(() => {
    getNextStepsValues()
  }, [])

  return (
    <>
      {showReferCoachComingSoon && (
        <InfoModal
          setShowModal={setShowReferCoachComingSoon}
          header={`Exciting News! 🎉`}
          helpText={`This feature is currently in the works and will be coming soon.`}
          confirmButtonText="OK"
          isWarningVariant={false}
        />
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="rounded-full w-10 h-10">
            <Avatar size="xs">
              {Boolean(user.hasAvatar) && (
                <AvatarImage
                  alt="avatar"
                  src={previewImgUrl || user.avatar_url}
                />
              )}
              <AvatarFallback className={getUserColor(user.userColor)}>
                <div
                  style={{
                    color: getTextColor(user.userColor),
                  }}
                >
                  {initial}
                </div>
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-64 p-4 rounded-lg z-[9999]"
          align="end"
          sideOffset={20}
        >
          <DropdownMenuLabel className="flex items-center gap-4">
            <Avatar size="sm">
              {Boolean(user.hasAvatar) && (
                <AvatarImage
                  alt="avatar"
                  src={previewImgUrl || user.avatar_url}
                />
              )}
              <AvatarFallback className={getUserColor(user.userColor)}>
                <div
                  style={{
                    color: getTextColor(user.userColor),
                  }}
                >
                  {initial}
                </div>
              </AvatarFallback>
            </Avatar>

            <div className="flex flex-col">
              <span className="capitalize text-muted-foreground">
                {user.activeProfile}
              </span>
              <span className="text-lg text-ellipsis overflow-hidden whitespace-nowrap w-36">
                {username}
              </span>
            </div>
          </DropdownMenuLabel>

          {user.isCoach === true && (
            <>
              <DropdownMenuSeparator />
              {nextStepsData?.profile_access_status === "approved" && (
                <DropdownMenuItem asChild>
                  <Link to="/coach/edit-website" className="text-blurple">
                    <SvgUser className="mr-4 h-6 w-6" />
                    View my Website
                  </Link>
                </DropdownMenuItem>
              )}

              <DropdownMenuItem asChild>
                <Link to="/coach/subscription/current">
                  <SvgStar className="mr-4 w-6 h-6" />
                  My Subscription
                </Link>
              </DropdownMenuItem>
              {user.activeProfile === "coach" &&
                activeSubscriptionType !== "trial" && (
                  <DropdownMenuItem asChild>
                    <Link to="/affiliate">
                      <SvgInvite className="mr-4 h-6 w-6" />
                      Become an Affiliate
                    </Link>
                  </DropdownMenuItem>
                )}
            </>
          )}
          <DropdownMenuSeparator />

          <DropdownMenuItem asChild>
            <Link to="/account-settings">
              <SvgSettings fill="black" className="mr-4 w-6 h-6" />
              Account settings
            </Link>
          </DropdownMenuItem>

          {user.activeProfile === "coach" ? (
            <>
              <DropdownMenuItem asChild>
                <a
                  href="https://intercom.help/zoee/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgEducation className="mr-4 h-6 w-6" />
                  Quick Guides
                </a>
              </DropdownMenuItem>

              <DropdownMenuItem asChild>
                <button
                  onClick={() => show()}
                  className="flex items-center w-full"
                >
                  <SvgHelp className="mr-4 h-6 w-6" />
                  Support
                </button>
              </DropdownMenuItem>
            </>
          ) : (
            <>
              <DropdownMenuItem asChild>
                <a
                  href="https://intercom.help/zoee/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgEducation className="mr-4 h-6 w-6" />
                  Help Articles
                </a>
              </DropdownMenuItem>

              <DropdownMenuItem asChild>
                <button
                  onClick={() => show()}
                  className="flex items-center w-full"
                >
                  <SvgHelp className="mr-4 h-6 w-6" />
                  Support
                </button>
              </DropdownMenuItem>
            </>
          )}
          {user?.activeProfile === "coach" && (
            <DropdownMenuItem onClick={handleToggle}>
              {guideOpened ? (
                <SvgCheckboxOff className="mr-4 h-6 w-6" />
              ) : (
                <SvgCheckbox className="mr-4 h-6 w-6" />
              )}
              Turn Off How To's
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={logout} className="text-vividRed">
            <SvgLogout className="mr-4 h-6 w-6" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default UserButton
