import React, { useEffect, useContext, useState } from "react"
import Availability from "../../../../components/scheduling/availability/Availability"
import CalendarDetails from "../../../../components/account-settings/calendars/CalendarDetails"
import Loader from "../../../../components/ui/loader"
import useScrollToTop from "../../../../hooks/useScrollToTop"
import { Button } from "../../../../components/ui/button"
import { CalendarRequests } from "../../../../api/app.service"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { ConnectCalendarCTA } from "../../../../components/account-settings/calendars/ConnectCalendarCTA"
import { defaultAvailability } from "../../../../objects/availabilityObjects"
import { ListCalendars } from "../../../../components/account-settings/calendars/ListCalendars"
import { SvgFilledInformationCircle } from "../../../../components/icons"
import { UserCtx } from "../../../../context/userContext"

const CalendarSettingsPage: React.FC = () => {
  const [showCalendarTab, setShowCalendarTab] = useState<any>(true)
  const [showAvailability, setShowAvailability] = useState<boolean>(false)
  const [showSocialTab, setShowSocialTab] = useState<any>(false)
  const [completeCalendarConnect, setCompleteCalendarConnect] =
    useState<any>(false)
  const [userCalendars, setUserCalendars] = useState<any>({})
  const [userCalendarsLoading, setUserCalendarsLoading] =
    useState<boolean>(false)
  const [availabilities, setAvailabilities] = useState<any>([])
  const { user } = useContext(UserCtx)
  const { renderError, renderSuccess, updateNextStepsData } =
    useContext(CommonFunctionCtx)
  const [showCalendarDetails, setShowCalendarDetails] = useState<boolean>(false)

  const showComponent = (callback: any) => {
    setShowCalendarTab(false)
    setShowSocialTab(false)
    setShowAvailability(false)
    callback(true)
  }

  const getAvailabilityRules = () => {
    if (user) {
      CalendarRequests.getAvailabilityRules({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          setAvailabilities(data.availability_rules)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const userDefaultAvailability = (name: string, is_default: boolean) => {
    const newDefaultAvailability = {
      ...defaultAvailability,
      is_default: is_default,
      name: name,
      timezone: user.timezone,
    }
    return newDefaultAvailability
  }

  const setDefaultAvailability = async () => {
    if (user && availabilities.length === 0) {
      const defAvailability = userDefaultAvailability(
        "Default Availability",
        true
      )

      CalendarRequests.setAvailabilityRules({
        user_id: parseInt(user.user_id),
        availability_rules: [defAvailability],
      }).catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
    } else if(availabilities.length > 0){
      console.log(availabilities)
            CalendarRequests.setAvailabilityRules({
              user_id: parseInt(user.user_id),
              availability_rules: availabilities
            })
    }
  }

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight)
  }

  const completeRegisterCalendar = (code: string, data: any) => {
    const redirectUrl = `${process.env.REACT_APP_BASE_URL}/account-settings/calendars`
    if (code && user) {
      CalendarRequests.registerCalendar({
        user_id: parseInt(user.user_id),
        profile_count: data.profile_count,
        calendar_connect_code: code,
        redirect_url: redirectUrl,
      })
        .then(() => {
          renderSuccess("Calendar connected!")
          fetchUserCalendars().then(() => {
            scrollToBottom()
          })
          setCompleteCalendarConnect(true)
          updateNextStepsData(false)
        })
        // .then(() => {
        //     console.log(availabilities)
        //     CalendarRequests.setAvailabilityRules({
        //       user_id: parseInt(user.user_id),
        //       availability_rules: availabilities
        //     })
        // })
        .catch((ex: any) => {
          console.log(ex)
          renderError(ex.response.data.error_detail)
          setUserCalendarsLoading(false)
        })
      window.history.replaceState(null, "", window.location.pathname)
    }
  }

  const checkUrlParams = (data: any) => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get("code")
    if (code && user) {
      completeRegisterCalendar(code, data)
    } else {
      window.history.replaceState(null, "", window.location.pathname)
      setUserCalendarsLoading(false)
    }
  }

  const fetchUserCalendars = async () => {
    setUserCalendarsLoading(true)
    if (user) {
      await CalendarRequests.getCalendarList({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setUserCalendars({
            profileCount: data.profile_count,
            profiles: data.profiles,
          })
          checkUrlParams(data)
        })
        .catch((ex: any) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setUserCalendarsLoading(false)
        })
    } else {
      setUserCalendarsLoading(false)
    }
  }

  useEffect(() => {
    getAvailabilityRules()
    fetchUserCalendars()
  }, [user])

  useEffect(() => {
    if (completeCalendarConnect) {
      setDefaultAvailability()
    }
  }, [completeCalendarConnect])

  useScrollToTop(showCalendarTab, showSocialTab)

  return (
    <>
      <div className="mx-auto flex max-w-[424px] flex-col px-[20px] md:w-[1080px] md:max-w-none md:pt-0">
        <div className="flex">
          <h1
            className="mb-[40px] hidden text-[28px] font-semibold text-black
                    sm:text-[36px] sm:font-bold md:block"
          >
            Calendar Settings
          </h1>
          <Button
            onClick={() => setShowCalendarDetails(true)}
            variant="link"
            size="icon"
            className="hidden md:flex"
          >
            <SvgFilledInformationCircle className="w-4 h-4" />{" "}
          </Button>
        </div>
        <p className="mb-4 text-[#3A434A] text-base text-left mb-[30px] -mt-8">
          Watch our{" "}
          <a
            href="https://intercom.help/zoee/en/articles/8685614-calendar-integration-for-scheduling-and-video-sessions"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            <span className="font-bold text-blurple">quick video guide</span>
          </a>{" "}
          to get started in minutes and{" "}
          {user.activeProfile === "coach"
            ? "make scheduling effortless on Zoee."
            : "enjoy effortless booking on Zoee."}
        </p>

        {/* SECONDARY NAV HIDDEN */}
        <div className="text-gray mb-[24px] flex items-center justify-center gap-[12px] text-base md:mb-[40px] md:justify-start">
          <button
            className={`${
              showCalendarTab
                ? "btn-primary btn-secondary-nav"
                : "px-[16px] py-[12px] font-bold"
            }`}
            onClick={() => showComponent(setShowCalendarTab)}
          >
            Calendar
          </button>
          <button
            className={`${
              showAvailability
                ? "btn-primary btn-secondary bg-grayMist"
                : "px-[16px] py-[12px] font-bold"
            }`}
            onClick={() => showComponent(setShowAvailability)}
            disabled={userCalendars.profileCount === 0}
          >
            Availability
          </button>
        </div>

        {!userCalendarsLoading ? (
          <>
            {showCalendarTab && (
              <div className="flex flex-col gap-6">
                <ListCalendars
                  userCalendars={userCalendars}
                  fetchUserCalendars={fetchUserCalendars}
                />

                <ConnectCalendarCTA
                  isCalendarConnected={userCalendars.profileCount > 0}
                />
              </div>
            )}
            {showAvailability && <Availability />}
          </>
        ) : (
          <div className="flex w-full flex-col items-center gap-[12px]">
            <Loader />
            <h3 className="mb-[4px] text-center text-[16px] font-bold">
              Checking for calendars...
            </h3>
          </div>
        )}
      </div>
      {showCalendarDetails && (
        <CalendarDetails setShowCalendarDetails={setShowCalendarDetails} />
      )}
    </>
  )
}

export default CalendarSettingsPage
