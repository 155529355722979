import React from "react"
import CloseModal from "../../components/CloseModal"

interface Props {
  setShowCoachDirectoryDetails: any
}

const CoachDirectoryDetails: React.FC<Props> = ({
  setShowCoachDirectoryDetails,
}) => {
  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
    >
      <div
        className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                        main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
      >
        <CloseModal
          callback={setShowCoachDirectoryDetails}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Coach Directory
        </h1>
        <div className="text-center w-full flex flex-col gap-[40px] text-[18px]">
          <p>
            Zoee's Coach Network where you can connect with other coaches in the
            Zoee community.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CoachDirectoryDetails
