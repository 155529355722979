import React, { useState, useEffect, useContext } from "react"
import ProfileCreateFlow from "../../../components/coach/ProfileCreateFlow"
import { CoachPrivateProfileContext } from "./context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { ManageProfile } from "../../../components/coach"
import {
  ProfileRequests,
  ServiceRequests,
  ClientPaymentRequests,
} from "../../../api/app.service"
import { useLocation, useParams } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"

interface Props {
  guideOpened?: any
}

const CoachPrivateProfile: React.FC<Props> = ({ guideOpened }) => {
  const { user } = useContext(UserCtx)
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const [coachPublicProfileData, setCoachPublicProfileData] = useState<any>({})
  const [initialState, setInitialState] = useState<boolean>(false)
  const [normalState, setNormalState] = useState<boolean>(true)
  const [editBannerImage, setEditBannerImage] = useState<boolean>(false)
  const [editProfileInformation, setEditProfileInformation] =
    useState<boolean>(false)
  const [editprimaryProfileVideo, setEditPrimaryProfileVideo] =
    useState<boolean>(false)
  const [createBlogPost, setCreateBlogPost] = useState<boolean>(false)
  const [editPortraitVideo, setEditPortraitVideo] = useState<boolean>(false)
  const [editBlogPost, setEditBlogPost] = useState<any>({
    show: false,
    post: {},
  })
  const [editCredentials, setEditCredentials] = useState<boolean>(false)
  const [editSocialMedia, setEditSocialMedia] = useState<boolean>(false)
  const [profileColor, setProfileColor] = useState<string>("C6AB5B")
  const [previewImgUrl, setPreviewImgUrl] = useState<string>("")
  const [selectedTile, setSelectedTile] = useState<string>("")
  const [selectedTags, setSelectedTags] = useState<number[]>([])
  const [hasProfileVideo, setHasProfileVideo] = useState<boolean>(false)
  const [loadingServices, setLoadingServices] = useState<boolean>(false)
  const [services, setServices] = useState<any[]>([])
  const [nextStepsData, setNextStepsData] = useState<boolean>(false)
  const [connectAccountData, setConnectAccountData] = useState<any>(null)
  const [syncPrompt, setSyncPrompt] = useState<boolean>(false)
  const [loadingBento, setLoadingBento] = useState<boolean>(false)
  const [newsletterTitle, setNewsletterTitle] = useState<string>("")
  const [newsletterSubheading, setNewsletterSubheading] = useState<string>("")
  const [newsletterEnabled, setNewsletterEnabled] = useState<boolean>(false)

  const location = useLocation()

  const getCoachPrivateProfile = async () => {
    if (user?.coachProfile) {
      await ProfileRequests.getCoachPrivateProfile({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          setCoachPublicProfileData(data.coach_public_profile)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {})
    }
  }

  const getServices = async () => {
    if (user) {
      setLoadingServices(true)
      await ServiceRequests.getServiceListClient({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
      })
        .then((data) => {
          const activeServices = data?.service_list?.filter((service: any) => {
            return ["active"].includes(service.service_details.status)
          })
          setServices(activeServices)
          setLoadingServices(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingServices(false)
        })
    }
  }

  const getConnectAccount = () => {
    if (user) {
      ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setConnectAccountData(data.connect_account_data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const updateCoachNewsletterSettings = async () => {
    if (!newsletterTitle.trim()) {
      alert("Title is required.")
      return
    }
    try {
      const response = await ProfileRequests.updateCoachNewsletterSettings({
        user_id: parseInt(user.user_id),
        newsletter_title: newsletterTitle,
        newsletter_subheading: newsletterSubheading,
        newsletter_opt_in_enabled: newsletterEnabled,
      })
      alert(response.message)
    } catch (error) {
      console.error(error)
      alert("Failed to update email opt-in settings.")
    }
  }

  const getCoachNewsletterSubscribers = async () => {
    try {
      const rawResponse = await ProfileRequests.getCoachNewsletterSubscribers({
        user_id: parseInt(user.user_id, 10),
      })
      const parsedBody = JSON.parse(rawResponse.body || "{}")
      if (parsedBody.download_url) {
        window.location.href = parsedBody.download_url
      } else {
        alert(parsedBody.message || "No subscribers found.")
      }
    } catch (error) {
      console.error("Error fetching email subscribers list:", error)
      alert("Failed to download email list.")
    }
  }

  const CoachPrivateProfileContextValues = {
    coachPublicProfileData,
    setCoachPublicProfileData,
    initialState,
    setInitialState,
    normalState,
    setNormalState,
    editBannerImage,
    setEditBannerImage,
    editProfileInformation,
    setEditProfileInformation,
    profileColor,
    setProfileColor,
    editBlogPost,
    setEditBlogPost,
    createBlogPost,
    setCreateBlogPost,
    editPortraitVideo,
    setEditPortraitVideo,
    previewImgUrl,
    setPreviewImgUrl,
    selectedTile,
    setSelectedTile,
    selectedTags,
    setSelectedTags,
    hasProfileVideo,
    setHasProfileVideo,
    editprimaryProfileVideo,
    setEditPrimaryProfileVideo,
    editCredentials,
    setEditCredentials,
    editSocialMedia,
    setEditSocialMedia,
    getCoachPrivateProfile,
    services,
    getServices,
    setServices,
    loadingServices,
    setLoadingServices,
    nextStepsData,
    setNextStepsData,
    connectAccountData,
    syncPrompt,
    setSyncPrompt,
    loadingBento,
    setLoadingBento,
    newsletterTitle,
    setNewsletterTitle,
    newsletterSubheading,
    setNewsletterSubheading,
    newsletterEnabled,
    setNewsletterEnabled,
    updateCoachNewsletterSettings,
    getCoachNewsletterSubscribers,
  }

  useEffect(() => {
    if (coachPublicProfileData) {
      getServices()
    }
  }, [coachPublicProfileData])

  useEffect(() => {
    getConnectAccount()
    if (location?.state?.code_of_ethics_accepted) {
      renderSuccess("Welcome to Zoee Community!")
    }
  }, [])

  useEffect(() => {
    getCoachPrivateProfile()
  }, [loadingBento])

  return (
    <>
      <CoachPrivateProfileContext.Provider
        value={CoachPrivateProfileContextValues}
      >
        {initialState && <ProfileCreateFlow />}
        {normalState && <ManageProfile guideOpened={guideOpened} />}
      </CoachPrivateProfileContext.Provider>
    </>
  )
}

export default CoachPrivateProfile
