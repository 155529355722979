import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"
import MobileHamburger from "./cm-images/MobileHamburger.png"
import InboxNavMobile from "./cm-images/InboxNavMobile.png"
import NewChatMobile from "./cm-images/NewChatMobile.png"
import CoachChatMobile from "./cm-images/CoachChatMobile.png"
import InboxChat from "./cm-images/InboxChat.png"
import InboxActionsMobile from "./cm-images/InboxActionsMobile.png"
import FilesMobile from "./cm-images/FilesMobile.png"
import FileActions from "./cm-images/FileActions.png"
import SendFileMobile from "./cm-images/SendFileMobile.png"

export default function CMInboxMobile() {
  return (
    <SectionWrapper id="inbox">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Using Your <span className="text-[#4750F5]">Zoee Inbox</span>
        </h1>
      </div>

      <p className="text-gray-700 mt-8 text-center">
        Learn how to manage your conversations by{" "}
        <a
          href="#access-inbox"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          accessing your inbox{" "}
        </a>
        ,{" "}
        <a
          href="#sending-messages"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          sending and receiving messages{" "}
        </a>
        , and{" "}
        <a
          href="#uploading-files"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          sharing files
        </a>
        .
      </p>

      <h3
        id="access-inbox"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Accessing Your Inbox
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Log into your Zoee account.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={MobileHamburger}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            <li>Select “Inbox” from the emerging side menu.</li>
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={InboxNavMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            Your inbox will display all your conversations with your coach.
          </li>
        </ul>
      </div>

      <h3
        id="sending-messages"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Sending and Receiving Messages
      </h3>

      {/* Starting a New Conversation */}
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        1. Starting a New Conversation
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Click the <strong>"New Chat"</strong> button in your inbox.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={NewChatMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select your coach from the list (if applicable).</li>
          <div className="flex justify-center mt-4">
            <img
              src={CoachChatMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Your new conversation window will open.</li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        2. Composing and Sending Messages
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Type your message in the text box at the bottom of the chat window.
          </li>
          <li>Press Enter or click the send icon to deliver your message.</li>
          <li>Your sent message will appear in the conversation thread.</li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        3. Recieving Messages
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            New messages from your coach will appear in your conversation
            thread.
          </li>
          <li>
            You may receive notifications based on your notification
            preferences.
          </li>
          <li>
            Unread conversations will be marked to help you identify new
            messages
          </li>
        </ul>
      </div>

      <h3 className="text-xl font-semibold text-blue-700 mt-8">
        4. Managing Messages
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Click on any message to reveal a three-dot menu icon (⋮).</li>

          <li>Click on the three dots to open the message options menu.</li>
          <div className="flex justify-center mt-4">
            <img
              src={InboxChat}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Select from available actions:</li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>Reply:</strong> Respond directly to that specific message.
            </li>
            <li>
              <strong>Edit:</strong> Make changes to a message you've sent.
            </li>
            <li>
              <strong>Delete:</strong> Remove a message from the conversation.
            </li>
            <div className="flex justify-center mt-4">
              <img
                src={InboxActionsMobile}
                alt="Profile Dropdown Example"
                className="w-full max-w-md rounded-lg"
              />
            </div>
          </ul>
        </ul>
      </div>

      <h3
        id="uploading-files"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Uploading and Sharing Files
      </h3>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        1. Selecting Files to Share
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Click on the picture icon (🖼️) in the message box at the bottom of
            the chat window.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={FilesMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            A pop up will appear at the bottom of your screen prompting you to
            choose where the attached file will be shared from.
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={FileActions}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>Navigate to and select the file(s) you wish to share.</li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        2. Sending Files
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            After selecting your file(s), click "Open" in the file browser.
          </li>
          <li>Add an optional message to accompany your file(s).</li>
          <li>
            Press Enter or click the send icon to upload and share the file(s).
          </li>
          <div className="flex justify-center mt-4">
            <img
              src={SendFileMobile}
              alt="Profile Dropdown Example"
              className="w-full max-w-md rounded-lg"
            />
          </div>
          <li>
            The file(s) will appear in the conversation thread once uploaded.
          </li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        3. Viewing Received Files
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Files sent by your coach will appear in your conversation thread.
          </li>
          <li>Click on any file to open or download it.</li>
          <li>Images will display as previews in the chat.</li>
        </ul>
      </div>
      <h3
        id="managing-inbox"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Managing Your Inbox
      </h3>
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        1. Finding Past Conversations
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Scroll through your inbox to view all conversations.</li>
          <li>
            Conversations are typically sorted with the most recent activity at
            the top .
          </li>
          <li>
            Use the search function (if available) to find specific messages.
          </li>
        </ul>
      </div>

      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        2. Notification Settings
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Access your profile settings to adjust notification preferences.
          </li>
          <li>
            You can choose to receive email, browser, or mobile notifications.
          </li>
          <li>Customize when and how you're notified about new messages.</li>
        </ul>
        <p className="text-gray-700 mt-4">
          <strong>Need Help?</strong> If you encounter any issues with your
          inbox, contact your coach directly or reach out to Zoee support at
          <a
            href="mailto:support@zoee.com"
            className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
          >
            {" "}
            support@zoee.com
          </a>
          .
        </p>
      </div>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
