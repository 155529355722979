import type { SVGProps } from "react"
export const SvgZoeeGradientFavicon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="60"
    height="120"
    viewBox="0 0 141 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_670_158)">
      <path
        d="M104.32 39.91V7.8C104.344 5.75856 103.557 3.79093 102.133 2.32869C100.708 0.866445 98.7614 0.0289861 96.72 0L7.60005 0C5.55867 0.0289861 3.61213 0.866445 2.18738 2.32869C0.762635 3.79093 -0.0239906 5.75856 4.68447e-05 7.8V112C-0.0239906 114.041 0.762635 116.009 2.18738 117.471C3.61213 118.934 5.55867 119.771 7.60005 119.8H96.81C98.8514 119.771 100.798 118.934 102.223 117.471C103.647 116.009 104.434 114.041 104.41 112V96.9C104.398 96.7592 104.433 96.6183 104.51 96.5L140 47.5C140.6 46.7 140.32 42.69 139.32 42.69L104.59 42.63C104.17 42.71 104.32 33.63 104.32 39.91Z"
        fill="url(#paint0_linear_670_158)"
      />
      <path
        d="M78.8098 82.0799V92.6299C78.8398 93.2527 78.6221 93.862 78.2043 94.3248C77.7864 94.7876 77.2024 95.0663 76.5798 95.0999H24.9998C24.3772 95.0663 23.7932 94.7876 23.3754 94.3248C22.9576 93.862 22.7399 93.2527 22.7698 92.6299V83.8599C22.7676 83.2862 22.9538 82.7276 23.2998 82.2699L51.6798 45.1799C51.9612 44.8328 52.1427 44.4157 52.2049 43.9732C52.267 43.5308 52.2076 43.0798 52.0328 42.6686C51.8581 42.2574 51.5746 41.9016 51.2129 41.6393C50.8512 41.377 50.425 41.2182 49.9798 41.1799H25.8098C25.5018 41.1583 25.2011 41.0763 24.9248 40.9384C24.6486 40.8006 24.4022 40.6097 24.1997 40.3766C23.9973 40.1435 23.8427 39.8728 23.745 39.5799C23.6472 39.2871 23.608 38.9779 23.6298 38.6699V28.0899C23.5995 27.4763 23.8107 26.8752 24.2182 26.4153C24.6257 25.9555 25.197 25.6736 25.8098 25.6299H75.5298C75.8385 25.6389 76.1423 25.7087 76.424 25.8353C76.7056 25.9618 76.9596 26.1426 77.1713 26.3674C77.3831 26.5921 77.5485 26.8564 77.658 27.1451C77.7676 27.4338 77.8191 27.7412 77.8098 28.0499V36.8699C77.807 37.4428 77.6213 37.9998 77.2798 38.4599L48.8898 75.5599C47.6698 77.1599 48.6998 79.6199 50.5898 79.6199H76.5898C77.2098 79.6535 77.7912 79.9311 78.2072 80.392C78.6231 80.8529 78.8398 81.4597 78.8098 82.0799Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_670_158"
        x1="70.1565"
        y1="0"
        x2="70.1565"
        y2="119.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#292F8F" />
        <stop offset="1" stopColor="#4750F5" />
      </linearGradient>
      <clipPath id="clip0_670_158">
        <rect width="140.29" height="119.8" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
