import React from "react";
import ContactAvatar from "./ContactAvatar";
import ContactStatusTag from "./ContactStatusTag";
import moment from "moment";
import BackToButton from "../BackToButton";
import { Button } from "../ui/button";

interface Props {
  contactRequestFull: any;
  setContactRequestFull: any;
  acceptContactRequest: any;
  denyContactRequest: any;
  resendContactRequest: any;
  revokeContactRequest: any;
}

const ContactRequestFull: React.FC<Props> = ({
  contactRequestFull,
  setContactRequestFull,
  acceptContactRequest,
  denyContactRequest,
  resendContactRequest,
  revokeContactRequest,
}) => {
  const { contact_type, contact_name, tagline, contact_date } =
    contactRequestFull.contact;
  const variant = contactRequestFull.variant;

  const goBack = () => {
    setContactRequestFull({ show: false, contact: null });
  };

  const acceptAndHideView = () => {
    acceptContactRequest(contactRequestFull.contact);
    setContactRequestFull({ show: false, contact: null });
  };

  const denyAndHideView = () => {
    denyContactRequest(contactRequestFull.contact);
    setContactRequestFull({ show: false, contact: null });
  };

  const resendAndHideView = () => {
    resendContactRequest(contactRequestFull.contact);
    setContactRequestFull({ show: false, contact: null });
  };

  const revokeAndHideView = () => {
    revokeContactRequest(contactRequestFull.contact);
    setContactRequestFull({ show: false, contact: null });
  };

  const getStatus = () => {
    let status = "Unknown";

    if (contact_type === "connected") {
      status = "active";
    } else if (contact_type === "invited") {
      status = "invited";
    } else if (contact_type === "requested") {
      status = "requested";
    }
    return status;
  };

  const getTagline = () => {
    let value = "";
    if (tagline) {
      value = tagline;
    } else if (contact_type === "invited") {
      const date = moment(contact_date).format("l");
      value = `Invited ${date}`;
    } else {
      value = "Zoee Member";
    }
    return value;
  };

  return (
    <div className="mx-auto flex flex-col px-[20px] pb-[140px] pt-[12px] md:max-w-[1120px] md:pt-[128px]">
      <BackToButton
        prev="Contacts"
        current="Contact Request"
        onClick={() => setContactRequestFull({ show: false, contact: null })}
      />
      <div className="main-shadow flex w-full flex-col rounded-[16px] bg-white p-[24px]">
        <div className="bg-grayFlash relative mb-[64px] h-[96px] w-full rounded-[12px] md:h-[140px]">
          {/* <ContactStatusTag
            contactStatus={getStatus()}
            origin="full"
            contactDate={contact_date}
          /> */}
           {/* commented out as it serves no purpose right now, ticket dev-733 */}
          <div className="absolute left-[24px] top-[115px] hidden md:block">
            <ContactAvatar
              contact={contactRequestFull.contact}
              width={140}
              height={140}
              border="border border-[4px] border-white"
            />
          </div>
          <div className="absolute left-[24px] top-[64px] md:hidden">
            <ContactAvatar
              contact={contactRequestFull.contact}
              width={80}
              height={80}
              border="border border-[4px] border-white"
            />
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-between md:mb-[73px] md:flex-row">
          <div className="mb-[32px] flex flex-col gap-[8px] md:mb-0 md:ml-[196px]">
            <h4 className="text-[22px] font-bold">{contact_name}</h4>
            <p className="text-graySlate text-base">{getTagline()}</p>
          </div>
          <div className="flex items-center gap-[12px]">
            {variant === "invited" ? (
              <>
                <Button variant="secondary" onClick={resendAndHideView}>
                  Resend Request
                </Button>
                <Button onClick={revokeAndHideView}>Revoke Request</Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={denyAndHideView}>
                  Decline Request
                </Button>
                <Button onClick={acceptAndHideView}>Accept Request</Button>
              </>
            )}
          </div>
        </div>
        {/* TODO */}
        {/* <div className="flex flex-col">
                    <p className="text-graySlate text-base font-bold mb-[16px]">About</p>
                    <h4 className="font-bold text-[16px] md:text-[18px] mb-[16px]">
                        {tagline}
                    </h4>
                    <p className="text-base text-graySlate">Insert profile description here</p>
                </div> */}
      </div>
    </div>
  );
};

export default ContactRequestFull;
