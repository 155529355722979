import * as React from "react"
import { OnSuccessfulAuthCallback } from "../context"
import { AccountType, RegisterFormProvider } from "./context"
import { Form } from "./form"

interface Props {
  defaultEmail: string
  defaultAccountType: AccountType
  defaultPhone: string
  defaultFirstName: string
  defaultLastName: string
  defaultPassword: string
  onSuccess: OnSuccessfulAuthCallback
}

export const RegisterForm: React.FC<Props> = ({
  defaultEmail,
  defaultAccountType,
  onSuccess,
  defaultPhone,
  defaultFirstName,
  defaultLastName,
  defaultPassword,
}) => {
  return (
    <RegisterFormProvider
      defaultEmail={defaultEmail}
      defaultAccountType={defaultAccountType}
      onSuccess={onSuccess}
      defaultPhone={defaultPhone}
      defaultFirstName={defaultFirstName}
      defaultLastName={defaultLastName}
      defaultPassword={defaultPassword}
    >
      <Form />
    </RegisterFormProvider>
  )
}
