import React, { useEffect, useState, useContext } from "react"
import CoachHowTos from "./CoachHowTos"
import NavShell from "./NavShell"
import UserButton from "./UserButton"
import UserButtonMobile from "./UserButtonMobile"
import useName from "../../hooks/useName"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Button } from "../ui/button"
import { getUserColor } from "../../utils/getUserColor"
import { getTextColor } from "../../utils/getTextColor"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { motion } from "framer-motion"
import {
  SvgClose,
  SvgHamburger,
  SvgZoeeIcon,
  SvgZoeeLogo,
  SvgCoachIcon,
  SvgStarIcon,
  SvgChevronLeft,
} from "../icons/"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { useAuthModal } from "../auth/use-auth-modal"
import { UserCtx } from "../../context/userContext"
import { SvgZoeeGradientLogo } from "../icons/logos/zoee-gradient-logo"
import { SvgZoeeGradientFavicon } from "../icons/logos/zoee-gradient-favicon"
import { SvgZoeeGradientLogoTM } from "../icons/logos/zoee-gradient-logo-tm"

interface Props {
  guideOpened: any
  setGuideOpened: any
}

const LINKS: {
  label: string
  href: string
}[] = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Features",
    href: "/features",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
  {
    label: "Our Story",
    href: "/why-zoee",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Find A Coach",
    href: "/find-a-coach",
  },
]
const MarketingNav: React.FC<Props> = ({ guideOpened, setGuideOpened }) => {
  const { user } = useContext(UserCtx)
  const navigate = useNavigate()
  const { initial } = useName()
  const { previewImgUrl } = useContext(CommonFunctionCtx)
  const [searchParams, setSearchParams] = useSearchParams()
  const register = searchParams.get("register")
  const coachregister = searchParams.get("coachregister")
  const location = useLocation()
  const authenticatedUser = useAuthenticatedUser(user)
  const { showRegister } = useAuthModal()
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [showUserButton, setShowUserButton] = useState<boolean>(false)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const currentPath = `${location.pathname}${location.search}`
  const isCoach = user?.activeProfile === "coach"
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)

  const signIn = () => {
    if (currentPath === "/") {
      localStorage.setItem("coachRedirect", "/coach")
      localStorage.setItem("memberRedirect", "/member")
    } else {
      localStorage.setItem(
        "coachRedirect",
        `${location.pathname}${location.search}`
      )
      localStorage.setItem(
        "memberRedirect",
        `${location.pathname}${location.search}`
      )
    }
    navigate("/sign_in")
  }

  const openCreateAccountModal = () => {
    setModalOpen(true)
    showRegister()
    searchParams.set("register", "true")
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (!modalOpen) {
      searchParams.delete("register")
      setSearchParams(searchParams)
    }
  }, [modalOpen])

  useEffect(() => {
    if (coachregister === "true") {
      searchParams.set("register", "coach")
      searchParams.delete("coachregister")
    }
    if (register === "true") {
      openCreateAccountModal()
    } else if (register === "coach") {
      setShowMobileNav(false)
      showRegister({ accountType: "coach" })
    }
  }, [register, coachregister])

  useEffect(() => {
    if (showMobileNav) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden"
      }
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showMobileNav])

  useEffect(() => {
    const currentPath = window.location.pathname
    if (currentPath === "/blog") {
      navigate("/find-a-coach/zoee-coaching?featured=true", { replace: false })
    }
  }, [navigate])

  return (
    <NavShell className="flex items-center justify-between text-base font-semibold">
      {authenticatedUser && (
        <Button
          variant="ghost"
          className="ml-4 lg:hidden"
          size="icon"
          onClick={() => setShowMobileNav(() => !showMobileNav)}
        >
          {showMobileNav ? <SvgClose /> : <SvgHamburger />}
        </Button>
      )}
      <div onClick={() => setShowMobileNav(false)}>
        {authenticatedUser ? (
          <Link to="/">
            <SvgZoeeGradientLogo className="hidden lg:block" />
          </Link>
        ) : (
          <Link to="/">
            <SvgZoeeGradientLogo className="hidden md:block !important" />

            <SvgZoeeGradientFavicon className="md:hidden" />
          </Link>
        )}
      </div>
      <div className="text-grayCharcoal hidden flex-1 items-center justify-center text-4 md:gap-7 lg:gap-12 lg:flex  text-center">
        {LINKS.map(({ label, href }) => (
          <motion.div
            key={href}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Link to={href}>{label}</Link>
          </motion.div>
        ))}
      </div>
      <div className="flex">
        {authenticatedUser ? (
          <div className="mr-2 flex items-center gap-4">
            <Button variant="link" asChild>
              <Link
                to={user.isCoach ? `/coach` : `/member`}
                className="text-grayCharcoal text-base font-semibold"
              >
                Back to Account
              </Link>
            </Button>
            <div
              className={`hidden items-center justify-end gap-[20px] md:flex lg:gap-[24px]`}
            >
              <UserButton
                guideOpened={guideOpened}
                setGuideOpened={setGuideOpened}
              />
            </div>
            <div className={`relative md:hidden`}>
              {showUserButton ? (
                <SvgClose />
              ) : (
                <Button
                  variant="ghost"
                  className="h-10 w-10 rounded-full"
                  onClick={() => setShowUserButton(!showUserButton)}
                >
                  <Avatar size="xs">
                    {Boolean(user.hasAvatar) && (
                      <AvatarImage
                        alt="avatar"
                        src={previewImgUrl || user.avatar_url}
                      />
                    )}
                    <AvatarFallback className={getUserColor(user.userColor)}>
                      <div
                        style={{
                          color: getTextColor(user.userColor),
                        }}
                      >
                        {initial}
                      </div>
                    </AvatarFallback>
                  </Avatar>
                </Button>
              )}
              <UserButtonMobile
                setShowUserButton={setShowUserButton}
                showUserButton={showUserButton}
                guideOpened={guideOpened}
                setGuideOpened={setGuideOpened}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-4">
            <Button onClick={signIn} variant="outline">
              Sign In
            </Button>
            <div className="relative">
              <Button
                variant="default"
                onClick={() => showRegister({ accountType: "coach" })}
              >
                Get Started
              </Button>
            </div>
            <div className="items-center gap-4 sm:flex">
              <div className="items-center gap-4 sm:flex">
                {dropdownOpen && (
                  <div
                    className={`${
                      window.innerWidth <= 1024
                        ? "fixed inset-x-0 top-[70px] w-full"
                        : "absolute right-0 top-[70px] !lg:py-2"
                    } p-[var(--sds-size-space-400)] bg-white rounded-2xl !lg:py-2 !lg:rounded-2xl shadow flex flex-col justify-start items-center gap-6 z-50`}
                    style={{
                      width: window.innerWidth <= 1024 ? "100%" : "300px",
                      height: "auto",
                      opacity: "var(--sds-size-stroke-border)",
                      borderRadius: window.innerWidth > 1024 ? "1rem" : "",
                    }}
                  >
                    <style>
                      {`
          @media (min-width: 768px) and (max-width: 1024px) {
            .dropdown-content {
              width: 100%;
            }
          }
        `}
                    </style>
                    <div className="h-6 px-4 justify-start items-center gap-0.5 inline-flex">
                      <div className="relative w-full">
                        <div
                          onClick={() => {
                            setDropdownOpen(false)
                          }}
                          className="lg:hidden fixed left-0 flex items-center text-black text-base font-normal font-['Beausite Classic'] leading-normal pl-4"
                        >
                          <SvgChevronLeft /> Back
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch px-4 text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-normal">
                      Are you a Coach or a Client?
                    </div>
                    <div className="self-stretch flex flex-col gap-4">
                      {/* Coach Option */}
                      <div
                        onClick={() => {
                          showRegister({ accountType: "coach" })
                          setDropdownOpen(false)
                        }}
                        className="flex items-center px-4 py-2.5 cursor-pointer hover:bg-gray-100 gap-4"
                      >
                        <div className="p-1.5 bg-[#7190ff] rounded-full flex items-center justify-center">
                          <SvgCoachIcon />
                        </div>
                        <div>
                          <div className="text-black text-base font-bold font-['Beausite Classic']">
                            Coach
                          </div>
                          <div className="text-[#62696e] text-sm font-normal font-['Beausite Classic']">
                            Sign Up as a Coach
                          </div>
                        </div>
                      </div>
                      {/* Client Option */}
                      <div
                        onClick={() => {
                          showRegister({ accountType: "member" })
                          setDropdownOpen(false)
                        }}
                        className="flex items-center px-4 py-2.5 cursor-pointer hover:bg-gray-100 gap-4"
                      >
                        <div className="p-1.5 bg-[#fcb900] rounded-full flex items-center justify-center">
                          <SvgStarIcon />
                        </div>
                        <div>
                          <div className="text-black text-base font-bold font-['Beausite Classic']">
                            Client
                          </div>
                          <div className="text-[#62696e] text-sm font-normal font-['Beausite Classic']">
                            Sign Up as a Client
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!authenticatedUser && (
        <Button
          variant="ghost"
          className="ml-4 lg:hidden"
          size="icon"
          onClick={() => setShowMobileNav(() => !showMobileNav)}
        >
          {showMobileNav ? <SvgClose /> : <SvgHamburger />}
        </Button>
      )}
      {showMobileNav && (
        <div className="zoee-backdrop-filter fixed left-0 top-0 z-[601] h-screen w-screen"></div>
      )}
      <div
        className={`fixed top-0 flex min-h-screen flex-col rounded-lg bg-white ${
          authenticatedUser ? "left-0" : "right-0"
        } bottom-0 overflow-y-auto bg-white p-8 transition-all duration-500 ${
          showMobileNav
            ? "w-[279px] " + (authenticatedUser ? "left-0" : "right-0")
            : "w-0 " + (authenticatedUser ? "left-[-279px]" : "right-[-279px]")
        } z-[1000]`}
        style={{
          boxShadow: authenticatedUser
            ? "5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.1)"
            : "-5px 0 15px -2px rgba(111, 89, 246, 0.15), -5px 0 15px -2px rgba(111, 89, 246, 0.15), -5px 0 15px -2px rgba(111, 89, 246, 0.1)",
        }}
        onClick={() => setShowMobileNav(false)}
      >
        <Button
          className="bg-grayFlash absolute right-[20px] top-[25px] rounded-full"
          size="icon"
          variant="ghost"
          onClick={() => setShowMobileNav(() => !showMobileNav)}
        >
          <SvgClose />
        </Button>
        <Link
          to="/"
          onClick={() => setShowMobileNav(false)}
          className="mb-[48px] mt-[10px]"
        >
          <SvgZoeeGradientLogoTM className="h-30 w-40 mb-[-20px] mx-auto" />
        </Link>
        <div className="mb-[56px] flex flex-col gap-[28px] text-center text-[18px]">
          {LINKS.map(({ label, href }, index) => (
            <Link
              key={index}
              to={href}
              className={`hover:bg-hover ml-1 w-fit rounded-md px-3 py-2 hover:text-black ${
                location.pathname === href ? "bg-hoverDark font-bold" : ""
              }`}
            >
              <motion.div
                whileHover={{ color: "#007AFF" }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                onClick={() => setShowMobileNav(false)}
              >
                {label}
              </motion.div>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-grayCharcoal text-base font-semibold"
              ></motion.p>
            </Link>
          ))}
        </div>
      </div>
      {isCoach && guideOpened ? (
        <div
          className={` ${
            isSidePanelOpen ? "left-0" : "-left-[300px]"
          } hover:shadow-custom fixed top-[100px] md:top-[150px] z-[1001] flex h-[550px] w-[300px] cursor-pointer items-center justify-center rounded-xl transition-all duration-500 ease-in-out`}
          style={{ backgroundColor: "#4750F5" }}
        >
          <CoachHowTos />
          <div
            onClick={() => setIsSidePanelOpen(!isSidePanelOpen)}
            className={`shadow-outline } fixed sm:top-[606px] md:top-[295px] z-[1000] -mr-[349px] flex h-[50px] w-[155px] -rotate-90 transform cursor-pointer items-center justify-center rounded-bl-lg rounded-br-lg
          `}
            style={{ backgroundColor: "#4750F5" }}
          >
            {isSidePanelOpen ? (
              <h1 className="text-sm font-bold text-white">
                <span>Close</span>
              </h1>
            ) : (
              <h1 className="text-sm font-bold text-white">
                <span>How</span>
                <span className="ml-2">To's</span>
              </h1>
            )}
          </div>
        </div>
      ) : null}
    </NavShell>
  )
}
export default MarketingNav
