import useDate from "../../hooks/useDate"
import useName from "../../hooks/useName"
import { Badge } from "../ui/badge"

interface Props {
  coach?: boolean
}

const Greeting: React.FC<Props> = ({ coach }) => {
  const { timeOfDay } = useDate()
  const { username } = useName()

  return (
    <>
      <div className="md:max-w-[1312px] w-full px-[20px] mx-auto md:mb-[28px] mb-[24px]">
        <div className="flex items-center gap-[12px]">
          <h4 className="text-black text-[18px] font-bold capitalize">
            Good {timeOfDay}, {username || "Guest"}
          </h4>

          {coach && <Badge variant="outline">Coach</Badge>}
        </div>
      </div>
    </>
  )
}

export default Greeting
