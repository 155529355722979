import React, { useEffect, useContext, useState } from "react"
import * as z from "zod"
import Cookies from "js-cookie"
import Loader from "../../ui/loader"
import Recaptcha from "../register-form/Recaptcha"
import useLoading from "../../../hooks/useLoading"
import { Button } from "../../ui/button"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { Dialog, DialogContent, DialogHeader } from "../../ui/dialog"
import { getUserStats } from "../../../utils/getUserStats"
import { Input } from "../../ui/input"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"
import { LoginFormContext } from "../login-form/context"
import {
  SvgCheckbox,
  SvgCheckboxOff,
  SvgClose,
  SvgZoeeLogoGradiant,
} from "../../icons"
import { useAuthModal } from "../use-auth-modal"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"
import { UserExists, EmailLogin } from "../../../api/auth.service"

interface Props {
  onSendOtp: (email: string) => void
}

const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: { email },
    })
    return data.user_found
  } catch (error) {
    console.error(error)
    return false
  }
}

const validateEmail = (email: string) => z.string().email().safeParse(email)

const validatePassword = (password: string) =>
  z
    .string()
    .min(8, "Invalid password")
    .regex(/^(?=.*[0-9])(?=.*\W).{8,}$/, {
      message: "Incorrect Password. Please try again",
    })
    .safeParse(password)

const GeneralSignInForm: React.FC<Props> = ({ onSendOtp }) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    setView,
    getIdentityStatus,
    getSetupInfo,
    updateTimezone,
    onSuccess,
  } = useContext(LoginFormContext)
  const { renderError, getUser } = useContext(CommonFunctionCtx)
  const { showRegister } = useAuthModal()
  const { user } = useContext(UserCtx)

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState<string | undefined>()
  const [passwordError, setPasswordError] = useState<string | undefined>()
  const [rememberMe, setRememberMe] = useState(false)
  const { startLoading, stopLoading } = useLoading()
  const [showresetModal, setShowresetModal] = React.useState(false)
  const coachRedirect = localStorage.getItem("coachRedirect") || "/coach"
  const memberRedirect = localStorage.getItem("memberRedirect") || "/member"
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)

  const redirectURL = () => {
    if (user?.activeProfile === "coach") {
      navigate(coachRedirect)
    } else {
      navigate(memberRedirect)
    }
  }

  useEffect(() => {
    const rememberedEmail = Cookies.get("rememberedEmail")
    if (rememberedEmail) {
      setEmail(rememberedEmail)
      setRememberMe(true)
    }
  }, [setEmail])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    setEmailError(undefined)
    setPasswordError(undefined)

    try {
      const emailCheck = validateEmail(email)
      if (!emailCheck.success) {
        setEmailError(emailCheck.error.issues[0].message)
        return
      }

      const passwordCheck = validatePassword(password)
      if (!passwordCheck.success) {
        setPasswordError(passwordCheck.error.issues[0].message)
        return
      }

      rememberMe
        ? Cookies.set("rememberedEmail", email, { expires: 30 })
        : Cookies.remove("rememberedEmail")

      setView("password")

      const success = await login()
      if (success) {
        redirectURL()
      }
    } catch (err: any) {
      setPasswordError("An unexpected error occurred")
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const login = async () => {
    try {
      const data = await EmailLogin.loginEmail({
        email,
        password,
        stats: getUserStats(),
      })

      if (!data) throw new Error("No data returned")

      const storedData = JSON.stringify(data)
      if (isLocalStorageAvailable()) {
        localStorage.setItem("user", storedData)
      } else {
        renderError("Local storage is not available")
      }

      await Promise.all([
        getIdentityStatus(data.user_id),
        getSetupInfo(data.user_id),
        updateTimezone(data.user_id, data.access_token),
      ])

      const user = await getUser()
      onSuccess(user)
      return true
    } catch (error: any) {
      console.error(error)
      setPasswordError(
        error.response?.data?.message || "Incorrect email or password"
      )
      return false
    }
  }

  const forgotPassword = async () => {
    startLoading("Sending instructions")
    const pathname = window.location.pathname

    setEmailError(undefined)

    if (!email) {
      setEmailError("Please enter your email.")
      stopLoading()
      return
    }

    try {
      const emailExists = await doesEmailExist(email)
      if (!emailExists) {
        setEmailError("Email not found.")
        return
      }

      const resetPassword = await EmailLogin.resetPasswordSendLink({
        email,
        pathname,
      })

      if (resetPassword.message === "complete") {
        setShowresetModal(true)
      }
    } catch (error) {
      console.error(error)
      renderError("Something went wrong. Please try again.")
    } finally {
      stopLoading()
    }
  }

  const handleSendOtp = async () => {
    const emailExists = await doesEmailExist(email)
    if (!emailExists) {
      setEmailError("Email not found.")
      return
    }

    onSendOtp(email)
  }

  return (
    <div className="bg-white rounded-xl flex flex-col items-center justify-center p-6">
      <div className="mt-4">
        <SvgZoeeLogoGradiant className="h-[50px] w-auto block mx-auto" />
      </div>
      <div className="text-center mt-4">
        <span className="text-lg font-bold block">
          Sign in to your Zoee account
        </span>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 mt-4 w-[65%]"
      >
        <p className="-mb-2 text-sm">Email</p>
        <Input
          label=""
          placeholder=""
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError}
          disabled={loading}
          autoFocus
        />
        <p className="-mb-2 text-sm">Password</p>
        <Input
          label=""
          placeholder=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordError}
          disabled={loading}
          type="password"
        />
        <label className="-mb-4 md:mb-0 flex gap-3 text-muted-foreground items-center text-sm">
          {rememberMe ? <SvgCheckbox /> : <SvgCheckboxOff fill="#d1d5db" />}
          <input
            type="checkbox"
            checked={rememberMe}
            className="hidden"
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Remember me
        </label>
        <span className="items-center justify-center flex">
          <Recaptcha setIsNotRobot={setIsNotRobot} />
        </span>
        <Button
          className="-mt-4 md:mt-0 w-full font-bold md:mb-12"
          disabled={loading || !isNotRobot}
          variant="rounded"
        >
          {loading ? <Loader /> : "Sign In"}
        </Button>
        <div className="flex items-center -mb-8 md:-mb-[20px] justify-center text-center w-[100%] whitespace-nowrap">
          <p className="text-[#63696F] text-sm font-semibold ml-2">
            Don't have an account?
          </p>
          <Button
            variant="link"
            type="button"
            onClick={() => showRegister({ accountType: "coach" })}
            className="-mr-2 -ml-[10px]"
          >
            Sign Up
          </Button>
        </div>
        <Button
          variant="link"
          type="button"
          onClick={forgotPassword}
          className="-mb-8 md:-mb-6  text-sm"
        >
          Forgot Password?
        </Button>
        <Button
          className=" text-sm"
          variant="link"
          type="button"
          onClick={handleSendOtp}
        >
          Send One-time Password
        </Button>
      </form>

      <Dialog open={showresetModal} onOpenChange={setShowresetModal}>
        <DialogContent className="">
          <DialogHeader className="text-center items-center">
            <button
              onClick={() => setShowresetModal(false)}
              className="absolute top-6 right-6 text-gray-500 hover:text-gray-800"
              aria-label="Close"
            >
              <SvgClose />
            </button>
            <p className="-mt-4">
              Instructions to reset your password have been sent to your email.
              Please check your inbox and follow the steps provided.
              <br />
              <br />
              Have questions? Contact zoee support at
              <br />
              <a
                className="underline font-bold"
                href="mailto:businessblueprint@integrativenutrition.com"
                target="_blank"
                rel="noreferrer"
              >
                support@zoee.com
              </a>
            </p>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default GeneralSignInForm
