import React, { FC, useState } from "react"
import { UserCtx } from "../../context/userContext"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { Button } from "../../components/ui/button"
import NavShell from "../../components/navigation/NavShell"
import UserButton from "../../components/navigation/UserButton"
import UserButtonMobile from "../../components/navigation/UserButtonMobile"
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { getUserColor } from "../../utils/getUserColor"
import { getTextColor } from "../../utils/getTextColor"
import useName from "../../hooks/useName"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgClose, SvgZoeeIcon, SvgZoeeLogo } from "../../components/icons"
import { SvgZoeeGradientLogo } from "../../components/icons/logos/zoee-gradient-logo"
import { SvgZoeeGradientFavicon } from "../../components/icons/logos/zoee-gradient-favicon"

const ClientManualNav: FC = () => {
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const { initial } = useName()
  const { previewImgUrl } = React.useContext(CommonFunctionCtx)
  const authenticatedUser = useAuthenticatedUser(user)
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [showUserButton, setShowUserButton] = React.useState<boolean>(false)

  return (
    <NavShell className="flex items-center justify-between text-base font-semibold">
      <div onClick={() => setShowMobileNav(false)}>
        {authenticatedUser ? (
          <Link to="/">
            <SvgZoeeGradientLogo className="hidden lg:block" />
          </Link>
        ) : (
          <Link to="/">
            <SvgZoeeGradientLogo className="hidden md:block" />
            <SvgZoeeGradientFavicon className="md:hidden" />
          </Link>
        )}
      </div>
      <div className="text-grayCharcoal hidden flex-1 items-center text-[16px] md:ml-[40px] md:gap-[26px] lg:flex xl:ml-[108px] xl:gap-[64px]"></div>
      <div className="flex">
        {authenticatedUser ? (
          <div className="mr-2 flex items-center gap-4">
            <Button variant="link" asChild>
              <Link
                to={user.isCoach ? `/coach` : `/member`}
                className="text-grayCharcoal text-base font-semibold"
              >
                Back to Account
              </Link>
            </Button>
            <div
              className={`hidden items-center justify-end gap-[20px] md:flex lg:gap-[24px]`}
            >
              <UserButton />
            </div>
            <div className={`relative md:hidden`}>
              {showUserButton ? (
                <SvgClose />
              ) : (
                <Button
                  variant="ghost"
                  className="h-10 w-10 rounded-full"
                  onClick={() => setShowUserButton(!showUserButton)}
                >
                  <Avatar size="xs">
                    {Boolean(user.hasAvatar) && (
                      <AvatarImage
                        alt="avatar"
                        src={previewImgUrl || user.avatar_url}
                      />
                    )}
                    <AvatarFallback className={getUserColor(user.userColor)}>
                      <div
                        style={{
                          color: getTextColor(user.userColor),
                        }}
                      >
                        {initial}
                      </div>
                    </AvatarFallback>
                  </Avatar>
                </Button>
              )}
              <UserButtonMobile
                setShowUserButton={setShowUserButton}
                showUserButton={showUserButton}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-4">
            <Link to="/sign_in">
              <Button variant="outline">Sign In</Button>
            </Link>
          </div>
        )}
      </div>
    </NavShell>
  )
}
export default ClientManualNav
