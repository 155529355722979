import React, { useContext } from "react"
import CloseModal from "../../CloseModal"
import { UserCtx } from "../../../context/userContext"

interface Props {
  setShowCalendarDetails: any
}

const CalendarDetails: React.FC<Props> = ({ setShowCalendarDetails }) => {
  const { user } = useContext(UserCtx)

  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
    >
      <div
        className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                        main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
      >
        <CloseModal
          callback={setShowCalendarDetails}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Calendar Magic at Your Fingertips{" "}
        </h1>
        <div className="w-full flex flex-col gap-[40px] text-[18px]">
          {user.activeProfile === "coach"
            ? "Connect your calendar and set your availability to let clients book sessions with you instantly. No more back-and-forth emails or missed appointments! "
            : "Connect your calendar and enjoy effortless scheduling with your coach. Our smart two-way sync ensures you never miss an appointment while avoiding double bookings. Say goodbye to back-and-forth emails—booking a session is now seamless and stress-free. With just one integration, your time is organized, protected, and optimized, so you can focus on what truly matters—your growth and success."}
          <p>
            Watch our{" "}
            <a
              href="https://intercom.help/zoee/en/articles/8685614-calendar-integration-for-scheduling-and-video-sessions"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-blurple underline inline-block"
            >
              quick video guide
            </a>{" "}
            to get started in minutes and{" "}
            {user.activeProfile === "coach"
              ? "make scheduling effortless on Zoee."
              : "enjoy effortless booking on Zoee."}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CalendarDetails
