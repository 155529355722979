import React, { useState } from "react"
import ReactInputMask from "react-input-mask"

import { cn } from "../../utils/cn"
import { SvgEye, SvgEyeOff } from "../icons/"
import { Button } from "./button"
import { FieldError } from "./field-error"
import { Label } from "./label"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string
  inputClasses?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, className, inputClasses, type, ...props }, ref) => {
    const [stateType, setStateType] = useState<InputProps["type"]>(type)

    return (
      <div>
        <Label
          className={cn(
            "bg-background focus-within:bg-background focus-within:hover:bg-background relative flex h-14 cursor-text flex-col overflow-hidden rounded-md border transition-colors",
            {
              "border-vividRed hover:border-vividRed focus-within:hover:border-vividRed":
                error,
            },
            {
              "hover:bg-grayFlash hover:border-grayFlash border-grayCloud focus-within:border-blurple focus-within:hover:border-blurple":
                !error,
            },
            className
          )}
        >
          <input
            type={stateType}
            className={cn(
              "placeholder:text-muted-foreground placeholder:text-graySlate peer absolute top-4 flex h-10 w-full bg-transparent px-3 py-2 text-base transition-[top] file:border-0 file:bg-transparent file:text-base file:font-medium placeholder-shown:top-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
              {
                "tracking-[8px] placeholder:tracking-normal":
                  stateType === "password",
              },
              inputClasses
            )}
            ref={ref}
            {...props}
          />
          <span className="text-graySlate absolute top-2 order-1 px-3 text-xs transition-all peer-placeholder-shown:-top-4">
            {label}
          </span>

          {type === "password" && (
            <Button
              size="icon"
              variant="ghost"
              type="button"
              className="absolute right-2 top-1/2 -translate-y-1/2 peer-placeholder-shown:hidden"
              onClick={(e) => {
                e.stopPropagation()
                if (stateType === "password") {
                  setStateType("text")
                } else {
                  setStateType("password")
                }
              }}
            >
              {stateType === "password" ? <SvgEye /> : <SvgEyeOff />}
            </Button>
          )}
        </Label>

        <FieldError error={error} />
      </div>
    )
  }
)
Input.displayName = "Input"

const InputTel = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, className, type, ...props }, ref) => {
    return (
      <ReactInputMask mask="+9 (999) 999-9999" maskChar="_" {...props}>
        <Input ref={ref} error={error} {...props} label={label} />
      </ReactInputMask>
    )
  }
)

const InputOTP = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, className, type, ...props }, ref) => {
    return (
      <ReactInputMask mask="999999" maskChar="_" {...props}>
        <Input
          ref={ref}
          {...props}
          error={error}
          label={label}
          inputClasses="text-center tracking-[8px] text-xl font-mono placeholder:text-left placeholder:text-base placeholder:tracking-normal placeholder:font-sans"
        />
      </ReactInputMask>
    )
  }
)

export { Input, InputOTP, InputTel }
