import ConnectedCalendarProfile from "./ConnectedCalendarProfile";

interface Props {
  userCalendars: any
  fetchUserCalendars: any
}

export const ListCalendars = (props: Props) => {
  const { profileCount, profiles } = props.userCalendars;

  const sortedProfiles = profiles?.sort((a: any, b: any) => {
    let aIsPrimary: any = false
    let bIsPrimary: any = false
    a.calendars.forEach((calendar: any) => {
      if (calendar.is_primary) {
        aIsPrimary = true
        return
      }
    })
    b.calendars.forEach((calendar: any) => {
      if (calendar.is_primary) {
        bIsPrimary = true
        return
      }
    })

    return bIsPrimary - aIsPrimary
  })

  return (
    <div className="flex flex-col gap-6">
      <h3 className="text-xl font-bold">
        Connected Accounts
      </h3>
      <div className="flex flex-col gap-4">
        {profileCount > 0 ? (
          sortedProfiles?.map((profile: any, index: number) => (
            <ConnectedCalendarProfile
              key={index}
              profile={profile}
              profileCount={props.userCalendars["profileCount"]}
              fetchUserCalendars={props.fetchUserCalendars}
            />
          ))
        ) : (
          <p className="text-base text-graySlate">No connected calendars</p>
        )}
      </div>
    </div>
  )
}
