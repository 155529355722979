import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import FreeCheckoutSuccessPopup from "./FreeCheckoutSuccessPopup"
import PayPalCardFields from "../subscription/PayPalCardFields"
import Loader from "../ui/loader"
import moment from "moment"
import ServiceCardSelector from "./ServiceCardSelector"
import useManageServices, { ServiceType } from "./hooks/useManageServices"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getPrice } from "../../utils/services/get-price"
import { MeetingRequests } from "../../api/app.service"
import { PayPalButton } from "../subscription/PayPalButton"
import { PaymentRequests } from "../../api/app.service"
import { ServiceRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"

interface Window {
  paypal: {
    Buttons: (options: any) => {
      render: (container: HTMLElement) => void
    }
  }
}

interface Props {
  meetingDetails: {
    startTime: Date
    endTime: Date
  }
  appliedDiscount: {
    amount: number
    promoCode: string
  }
  createSetupIntent: any
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  service: any
  isGroupService: boolean
  availabilities: any
  activeContact: any
  activeService: any
  paymentMethods: any
  selectedPaymentMethod: any
  setSelectedPaymentMethod: any
  merchantId: any
  setMerchantId: any
  checkoutSuccessPopup: any
  setCheckoutSuccessPopup: any
  contact: any
  activeContactRef: any
  selectedPlanFromChild: any
  validatePromoCode: any
  createMeeting: any
}

export const PayPalCheckoutServiceComponent: React.FC<Props> = ({
  meetingDetails,
  appliedDiscount,
  createSetupIntent,
  isTermsAgreed,
  setIsTermsAgreed,
  service,
  isGroupService,
  availabilities,
  activeContact,
  activeService,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  merchantId,
  setMerchantId,
  contact,
  selectedPlanFromChild,
  validatePromoCode,
  createMeeting,
}) => {
  const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID

  const { user } = useContext(UserCtx)
  const { getServiceObject } = useManageServices()
  const { renderError } = useContext(CommonFunctionCtx)
  const [loading, setLoading] = useState(false)
  const [isSdkReady, setIsSdkReady] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)
  const [sdkLoaded, setSdkLoaded] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState<any>(null)
  const [useSavedCard, setUseSavedCard] = useState(false)
  const [checkoutSuccessPopup, setCheckoutSuccessPopup] = useState(false)
  const [spotsLeft, setSpotsLeft] = useState<number | null>(null)
  const [activeDate, setActiveDate] = useState<Date>(
    moment(new Date()).startOf("day").tz(user?.timezone?.value)?.toDate()
  )
  const [meetings, setMeetings] = useState<any[]>([])

  const determineServiceType = (service: any): ServiceType => {
    if (activeService?.service_details?.type === "recurring") return "recurring"
    if (activeService?.service_details?.type === "group") return "individual"
    if (activeService?.service_details?.type === "individual")
      return "individual"
    if (activeService?.service_details?.type === "bundle") return "bundle"
    return "consultation"
  }
  const [serviceType, setServiceType] = useState<ServiceType>(
    determineServiceType(activeService)
  )

  const [promoDiscount, setPromoDiscount] = useState<number | null>(null)
  const [serviceValues, setServiceValues] = useState<any>({
    availability_rule_id: availabilities
      ? availabilities?.find((rule: any) => rule?.is_default).id
      : "",
    isGroupService: activeService?.is_group_service,
    color: activeService?.service_details?.image?.color,
    title: activeService?.service_details?.title,
    description: "",
    feature1: activeService?.service_details?.details?.features?.feature1,
    feature2: activeService?.service_details?.details?.features?.feature2,
    feature3: activeService?.service_details?.details?.features?.feature3,
    sessionLength: activeService?.service_details?.session?.duration,
    sessionCost: activeService?.service_details?.billing.amount,
    currency: activeService?.service_details?.symbol,
    symbol: activeService?.service_details?.symbol,
    isFree: activeService?.service_details?.isFree,
    allowPromo: activeService?.service_details?.allowPromo,
    package: {
      packageSessions: activeService?.service_details?.session?.count,
      packageBillingType:
        activeService?.service_details?.billing?.packageBillingType !==
        undefined
          ? activeService?.service_details?.billing?.packageBillingType
          : activeService?.service_details?.billing?.unit,
      packageCost: activeService?.service_details?.billing.amount,
      installmentIncrements:
        activeService?.service_details?.billing.installmentIncrements,
      upFrontPercentage:
        activeService?.service_details?.billing?.upFrontPercentage,
    },
    recurring: {
      periodCost: activeService?.service_details?.billing.amount,
      recurringFrequency: activeService?.service_details?.billing?.unit,
      sessionsPerPeriod: activeService?.service_details?.session?.count,
      unlimited: false,
    },
    expiration: {
      expireTime: activeService?.service_details?.expiration.time,
      expireType: activeService?.service_details?.expiration.type,
      expires: activeService?.service_details?.expiration?.expires,
    },
    enabled: activeService?.service_details?.enabled,
    profileServiceVisible:
      activeService?.service_details?.visibility?.profile.service,
    profileCostVisible:
      activeService?.service_details?.visibility?.profile.cost,
    marketplaceServiceVisible:
      activeService?.service_details?.visibility?.marketplace.service,
    marketplaceCostVisible:
      activeService?.service_details?.visibility?.marketplace.service,
    image: {
      exists: activeService?.service_details?.image.exists,
      base64: "",
      base64extension: "",
    },
    group: {
      isFree: activeService?.service_details?.isFree,
      weeklyDayTimes: activeService?.service_details?.group?.weeklyDayTimes,
      maximumClients: activeService?.service_details?.maximumClients,
      groupChat: activeService?.service_details?.groupChat,
    },
    serviceLinkedForms: activeService?.service_details?.serviceLinkedForms,
  })

  const user_id = parseInt(user.user_id)
  const isCheckedRef = useRef(false)
  const activeServiceRef = useRef(activeService)
  const useSavedCardRef = useRef(useSavedCard)
  const activeMerchantIdRef = useRef(merchantId)
  const appliedDiscountRef = useRef(appliedDiscount)
  const activeContactRef = useRef<any>(null)
  const isSubx = false
  useEffect(() => {
    activeServiceRef.current = activeService
    setServiceType(activeServiceRef.current.service_details.type)
  }, [activeService])

  useEffect(() => {
    activeContactRef.current = activeContact
  }, [activeContact])

  useEffect(() => {
    appliedDiscountRef.current = appliedDiscount
  }, [appliedDiscount])

  useEffect(() => {
    if (window.paypal) {
      setSdkLoaded(true)
    } else {
      console.log("sdk not loading")
    }
  }, [])

  const serviceValuesRef = useRef(serviceValues)

  useEffect(() => {
    serviceValuesRef.current = serviceValues
  }, [serviceValues])

  useEffect(() => {
    useSavedCardRef.current = useSavedCard
  }, [useSavedCard])

  useEffect(() => {
    if (activeService?.service_details?.billing?.amount) {
      const rawAmount = String(activeService.service_details.billing.amount)
      const sanitizedAmount = rawAmount.replace(/[^0-9.]/g, "")
      const parsedAmount = parseFloat(sanitizedAmount)

      if (!isNaN(parsedAmount)) {
        let calculatedPrice = parsedAmount

        if (appliedDiscountRef?.current?.amount) {
          const discount = appliedDiscountRef.current.amount

          if (!isNaN(discount)) {
            calculatedPrice = parsedAmount - discount
          }
        }

        setServiceValues((prevValues: any) => ({
          ...prevValues,
          sessionCost: calculatedPrice,
        }))

        const finalPrice = getPrice(
          calculatedPrice,
          activeService.service_details.symbol
        )
      } else {
        console.error("Invalid amount:", rawAmount)
      }
    }
  }, [activeService, appliedDiscountRef, validatePromoCode])

  useEffect(() => {
    if (activeService?.service_details) {
      setServiceValues((prev: any) => ({
        ...prev,
        symbol: activeService.service_details.symbol,
        currency: activeService.service_details.currency,
      }))
    }
  }, [activeService])

  const getGroupServiceEnrollment = async () => {
    try {
      const response = await ServiceRequests.checkGroupServiceEnrollment({
        stripe_product_id: activeService?.stripe_product_id,
      })
      const data = response
      const spotsLeft = data.spots_left
      setSpotsLeft(spotsLeft)
      return spotsLeft
    } catch (error) {
      console.error("Error fetching group service enrollment:", error)
      return null
    }
  }

  const getMeetings = () => {
    if (user) {
      const startOfActiveDate = moment(activeDate)
        .startOf("day")
        .utc()
        .tz(user?.timezone?.value)
        .toISOString()
      const endOfSixMonths = moment(activeDate)
        .add(6, "month")
        .endOf("day")
        .utc()
        .tz(user.timezone.value)
        .toISOString()

      MeetingRequests.getMeetings({
        user_id: parseInt(user.user_id),
        profile_id: user.activeProfileId,
        from_date: startOfActiveDate,
        to_date: endOfSixMonths,
      })
        .then((data) => {
          if (data && Array.isArray(data.meetings)) {
            setMeetings(data.meetings)
          } else {
            console.error("Invalid meetings data format", data)
            setMeetings([])
          }
        })
        .catch((ex) => {
          renderError(ex.response?.data?.message || "An error occurred")
        })
    }
  }

  useEffect(() => {
    getMeetings()
  }, [])

  const createOrder = useCallback(
    async (data: any, source: "paypal" | "card") => {
      if (activeService?.service_details?.isGroupService) {
        const matchingMeeting = meetings.find(
          (meeting) =>
            meeting.service_details?.title ===
            activeService?.service_details.title
        )

        if (matchingMeeting) {
          alert("You are already enrolled in this service.")
          window.location.href = `http://localhost:3000/find-a-coach/${activeContact?.endpoint_slug}?services=true`
          return
        }

        const spotsLeft = await getGroupServiceEnrollment()
        if (spotsLeft !== null && spotsLeft <= 0) {
          alert(
            "This group service is full. Please choose a different service."
          )
          window.location.href = `http://localhost:3000/find-a-coach/${activeContact?.endpoint_slug}?services=true`
          return
        }
      }

      const serviceObject = getServiceObject(
        serviceType,
        serviceValuesRef.current
      )

      const service = activeServiceRef.current
      const contactsss = activeContactRef.current
      const saveCard = isCheckedRef.current
      const useSavedCard = useSavedCardRef.current
      const promo = appliedDiscountRef.current.promoCode

      return ServiceRequests.createPurchaseService({
        user_id,
        coach_user_id: contactsss?.user_id,
        client_profile_id: user.activeProfileId,
        coach_profile_id: contactsss?.profile_id,
        payment_method_id: useSavedCard ? selectedPaymentMethod?.id : "",
        service: { ...serviceObject },
        stripe_product_id: service.stripe_product_id,
        source: source,
        save_for_future: saveCard,
        user_environment: "",
        entered_promo_code: promo,
      })
        .then((response: any) => {
          return response.data
        })
        .then((order: any) => {
          if (order.id) {
            return order.id
          } else if (order.data.status === "COMPLETED") {
            return order
          }
        })
    },
    [useSavedCard, selectedPaymentMethod]
  )

  // Finalize the transaction after payer approval
  const onApprove = useCallback(
    async (data: any, source: "paypal" | "card") => {
      const service = activeServiceRef.current
      const getContact = activeContactRef.current
      const promo = appliedDiscountRef.current.promoCode
      const serviceObject = getServiceObject(
        serviceType,
        serviceValuesRef.current
      )

      try {
        await ServiceRequests.completePurchaseService({
          user_id,
          coach_user_id: getContact?.user_id,
          client_profile_id: user.activeProfileId,
          coach_profile_id: getContact?.profile_id,
          payment_method_id: useSavedCard ? selectedPaymentMethod?.id : "",
          service: serviceObject,
          stripe_product_id: service.stripe_product_id,
          source,
          order: data.orderID || data,
          user_environment: "",
          entered_promo_code: promo,
        })

        if (meetingDetails?.startTime && meetingDetails?.endTime) {
          await createMeeting(service.service_usage_id)
        } else if (service?.service_details?.isGroupService) {
          await MeetingRequests.updateGroupMeeting({
            profile_id: user.activeProfileId,
            user_id: parseInt(user.user_id),
            coach_user_id: getContact?.user_id,
            group_service_id: service.group_service_id,
          })
        }

        setCheckoutSuccessPopup(true)
      } catch (error) {
        console.error("Error in onApprove:", error)
      }
    },
    [useSavedCard, selectedPaymentMethod]
  )

  useEffect(() => {
    isCheckedRef.current = isChecked
  }, [isChecked])

  useEffect(() => {
    if (clientID && merchantId) {
      const loadPayPalSDK = () => {
        if (document.getElementById("paypal-sdk")) {
          console.log("PayPal SDK already loaded.")
          setIsSdkReady(true)
          return
        }
        const script = document.createElement("script")
        script.id = "paypal-sdk"
        script.src = `https://www.paypal.com/sdk/js?components=buttons,card-fields&client-id=${clientID}&merchant-id=${merchantId}&currency=${serviceValues.currency}&intent=capture&disable-funding=paylater`

        script.async = true

        script.onload = () => {
          console.log("PayPal SDK loaded successfully.")
          setIsSdkReady(true)
        }

        script.onerror = (err) => {
          console.error("Failed to load PayPal SDK:", err)
        }

        document.body.appendChild(script)
      }

      loadPayPalSDK()
    }
  }, [merchantId])

  useEffect(() => {
    getPaymentMethods()
  }, [])

  const getPaymentMethods = async () => {
    if (user) {
      try {
        const data = await PaymentRequests.getSavedPaymentMethods({
          user_id: parseInt(user.user_id),
        })
        setPaymentMethods(data?.data?.payment_tokens || [])
        if (data?.payment_methods?.length > 0) {
          setSelectedPaymentMethod(data?.data?.payment_tokens[0].id)
        } else {
          setSelectedPaymentMethod({})
        }
      } catch (ex: any) {
        console.error("Error fetching or updating payment methods:", ex)
        renderError(
          ex.response?.data?.message || "Failed to fetch payment methods"
        )
      } finally {
      }
    }
  }

  useEffect(() => {
    if (isSdkReady) {
      setHasMounted(true)
    }
  }, [isSdkReady])

  if (!isSdkReady || !hasMounted) {
    return <div>Loading PayPal SDK...</div>
  }

  return (
    <>
      {checkoutSuccessPopup && (
        <FreeCheckoutSuccessPopup
          checkoutSuccessPopup={checkoutSuccessPopup}
          setCheckoutSuccessPopup={setCheckoutSuccessPopup}
          contact={activeContact}
          meetingDetails={meetingDetails}
          activeContact={activeContact}
          activeService={activeService}
        />
      )}
      <div className="justify-flex flex-col items-center justify-center">
        <PayPalButton createOrder={createOrder} onApprove={onApprove} />
        <div className="flex items-center w-full mb-2 mt-2">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-2 text-gray-500 text-graySlate">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        {paymentMethods && paymentMethods.length > 0 ? (
          <>
            <ServiceCardSelector
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={(paymentMethod: any) => {
                setSelectedPaymentMethod(paymentMethod)
                setUseSavedCard(true)
              }}
              callback={() => {
                createSetupIntent()
                  .then(() => {})
                  .catch((error: any) => {
                    console.error("Error creating setup intent", error)
                  })
              }}
            />
            <div className="mb-4"></div>
            {!useSavedCard ? (
              <PayPalCardFields
                isCheckedRef={isCheckedRef}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                onApprove={onApprove}
                createOrder={createOrder}
                isTermsAgreed={isTermsAgreed}
                setIsTermsAgreed={setIsTermsAgreed}
                redirectUrl=""
                selectedPlanFromChild={selectedPlanFromChild}
                isSubx={isSubx}
                activeService={activeService}
              />
            ) : (
              <div className="mt-4 text-gray-500">
                <Button
                  type="button"
                  className="w-[95%] text-md ml-2"
                  onClick={() => {
                    setLoading(true)
                    createOrder({}, "card")
                      .then((orderData) => {
                        return onApprove(orderData, "card")
                      })
                      .catch((error) => {
                        console.error(
                          "Error processing saved card payment",
                          error
                        )
                      })
                      .finally(() => {
                        setLoading(false)
                      })
                  }}
                >
                  {loading ? <Loader /> : "Pay Now"}
                </Button>
              </div>
            )}
          </>
        ) : (
          <PayPalCardFields
            isCheckedRef={isCheckedRef}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onApprove={onApprove}
            createOrder={createOrder}
            isTermsAgreed={isTermsAgreed}
            setIsTermsAgreed={setIsTermsAgreed}
            redirectUrl=""
            selectedPlanFromChild={selectedPlanFromChild}
            isSubx={isSubx}
            activeService={activeService}
          />
        )}{" "}
      </div>
    </>
  )
}

export default PayPalCheckoutServiceComponent
