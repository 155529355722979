import type { SVGProps } from "react"

export const SvgZoeeGradientLogoTM = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="534"
    height="124"
    viewBox="0 0 534 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M503.966 24.017V22.4545H514.875V24.017H510.301V37H508.54V24.017H503.966ZM517.593 22.4545H519.696L524.639 34.5284H524.809L529.752 22.4545H531.855V37H530.207V25.9489H530.065L525.52 37H523.929L519.383 25.9489H519.241V37H517.593V22.4545Z"
      fill="black"
    />
    <path
      d="M101.521 99.62V117.95C101.548 119.044 101.144 120.105 100.396 120.904C99.6475 121.703 98.615 122.176 97.5213 122.22H4.00128C2.90754 122.176 1.87507 121.703 1.12671 120.904C0.378356 120.105 -0.0258626 119.044 0.00128402 117.95V102.73C-0.000590072 101.73 0.334002 100.757 0.951281 99.97L52.3613 35.66C54.5813 32.89 52.7113 28.66 49.2813 28.66H5.48128C4.38665 28.6183 3.35266 28.1465 2.60376 27.3471C1.85487 26.5476 1.45152 25.4851 1.48128 24.39V6.06004C1.45152 4.96502 1.85487 3.90245 2.60376 3.103C3.35266 2.30356 4.38665 1.83177 5.48128 1.79004H95.5813C96.6759 1.83177 97.7099 2.30356 98.4588 3.103C99.2077 3.90245 99.611 4.96502 99.5813 6.06004V21.26C99.5729 22.2672 99.22 23.2412 98.5813 24.02L47.1813 88.33C44.9613 91.1 46.8313 95.33 50.2613 95.33H97.4013C97.9549 95.3364 98.5017 95.4531 99.0098 95.673C99.5179 95.893 99.9771 96.212 100.361 96.6113C100.744 97.0107 101.044 97.4824 101.244 97.9989C101.443 98.5155 101.537 99.0666 101.521 99.62Z"
      fill="url(#paint0_linear_682_24)"
    />
    <path
      d="M112.312 62C112.312 25.74 138.752 0 174.932 0C211.112 0 237.352 25.74 237.352 62C237.352 98.26 211.122 124 174.932 124C138.742 124 112.312 98.26 112.312 62ZM203.922 62C203.922 41.18 191.442 28.65 174.922 28.65C158.402 28.65 145.732 41.18 145.732 62C145.732 82.82 158.422 95.35 174.922 95.35C191.422 95.35 203.922 82.82 203.922 62Z"
      fill="url(#paint1_linear_682_24)"
    />
    <path
      d="M499.041 71.85H422.531C421.818 71.8767 421.123 72.0772 420.505 72.434C419.887 72.7908 419.365 73.2932 418.986 73.8974C418.606 74.5016 418.38 75.1894 418.327 75.9009C418.274 76.6124 418.396 77.3262 418.681 77.98C423.521 89.31 434.501 95.98 449.341 95.98C459.901 95.98 467.861 93.06 474.931 87.07C475.33 86.7144 475.797 86.4424 476.303 86.2702C476.809 86.0979 477.345 86.0289 477.878 86.0671C478.411 86.1053 478.931 86.25 479.408 86.4927C479.884 86.7354 480.307 87.0711 480.651 87.48L492.801 101.48C493.546 102.346 493.943 103.457 493.916 104.599C493.888 105.741 493.437 106.831 492.651 107.66C482.151 118.38 467.271 124.01 448.491 124.01C409.141 124.01 383.491 97.82 383.491 62.01C383.491 26.01 409.491 0.0100098 444.221 0.0100098C477.651 0.0100098 503.461 23.73 503.461 62.46C503.461 64.01 503.321 65.93 503.161 67.84C503.097 68.9014 502.639 69.9007 501.877 70.6423C501.115 71.3839 500.104 71.8146 499.041 71.85V71.85ZM421.671 51.48H467.041C467.729 51.4571 468.402 51.2728 469.005 50.9421C469.608 50.6114 470.126 50.1435 470.515 49.5761C470.904 49.0087 471.155 48.3578 471.246 47.6758C471.337 46.9938 471.267 46.2999 471.041 45.65C467.131 33.79 457.321 26.41 444.501 26.41C431.501 26.41 421.621 33.65 417.721 45.66C417.498 46.3054 417.429 46.994 417.518 47.671C417.608 48.348 417.854 48.9948 418.238 49.5598C418.621 50.1249 419.131 50.5927 419.727 50.926C420.323 51.2594 420.989 51.4491 421.671 51.48V51.48Z"
      fill="url(#paint2_linear_682_24)"
    />
    <path
      d="M367.561 71.85H291.051C290.338 71.8775 289.642 72.0783 289.024 72.4351C288.407 72.7918 287.885 73.2938 287.505 73.8975C287.125 74.5013 286.897 75.1886 286.843 75.8999C286.788 76.6112 286.907 77.3252 287.191 77.98C292.041 89.31 303.011 95.98 317.861 95.98C328.411 95.98 336.381 93.06 343.451 87.07C343.85 86.716 344.315 86.4453 344.821 86.274C345.326 86.1026 345.86 86.034 346.392 86.0722C346.924 86.1104 347.443 86.2546 347.919 86.4964C348.394 86.7382 348.816 87.0726 349.161 87.48C352.491 91.29 358.041 97.66 361.321 101.48C362.066 102.346 362.463 103.457 362.435 104.599C362.407 105.741 361.957 106.831 361.171 107.66C350.671 118.38 335.791 124.01 317.011 124.01C277.661 124.01 252.011 97.82 252.011 62.01C252.011 26.01 278.011 0.0100098 312.741 0.0100098C346.171 0.0100098 371.981 23.73 371.981 62.46C371.981 64.01 371.841 65.93 371.681 67.84C371.617 68.9014 371.159 69.9007 370.397 70.6423C369.635 71.3839 368.623 71.8146 367.561 71.85V71.85ZM290.191 51.48H335.561C336.248 51.4571 336.921 51.2728 337.525 50.9421C338.128 50.6114 338.645 50.1435 339.034 49.5761C339.424 49.0087 339.674 48.3578 339.766 47.6758C339.857 46.9938 339.787 46.2999 339.561 45.65C335.651 33.79 325.841 26.41 313.021 26.41C300.021 26.41 290.141 33.65 286.241 45.66C286.018 46.3054 285.948 46.994 286.038 47.671C286.127 48.348 286.374 48.9948 286.757 49.5598C287.141 50.1249 287.651 50.5927 288.247 50.926C288.843 51.2594 289.509 51.4491 290.191 51.48V51.48Z"
      fill="url(#paint3_linear_682_24)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_682_24"
        x1="50.7615"
        y1="1.79004"
        x2="50.7615"
        y2="122.22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_682_24"
        x1="174.832"
        y1="0"
        x2="174.832"
        y2="124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_682_24"
        x1="443.476"
        y1="0.0100098"
        x2="443.476"
        y2="124.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_682_24"
        x1="311.996"
        y1="0.0100098"
        x2="311.996"
        y2="124.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A90FF" />
        <stop offset="0.45" stopColor="#4750F5" />
        <stop offset="1" stopColor="#292F8F" />
      </linearGradient>
    </defs>
  </svg>
)
