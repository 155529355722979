import { Outlet } from "react-router-dom"
import Footer from "../../components/marketing/footer/Footer"
import MarketingNav from "../../components/navigation/MarketingNav"

interface Props {
  guideOpened: any
  setGuideOpened: any
}

const MarketingPageLayout: React.FC<Props> = ({
  guideOpened,
  setGuideOpened,
}) => {
  return (
    <>
      <MarketingNav guideOpened={guideOpened} setGuideOpened={setGuideOpened} />
      <Outlet />
      <Footer />
    </>
  )
}

export default MarketingPageLayout
