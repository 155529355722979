import React, { useEffect } from "react"
import CloseModal from "../CloseModal"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { SvgPlus } from "../icons/"
import { UserCtx } from "../../context/userContext"

interface Props {
  tagsModalOpen?: any
  setTagsUpdated?: any
  setTagsModalOpen?: any
  isBusinessPage?: boolean
}

const TagsModal: React.FC<Props> = ({
  setTagsModalOpen,
  tagsModalOpen,
  setTagsUpdated,
  isBusinessPage = false,
}) => {
  const [selectedTags, setSelectedTags] = React.useState<number[]>([])
  const { user } = React.useContext(UserCtx)
  const [allTags, setAllTags] = React.useState<any>([])
  const [coachPublicProfileData, setCoachPublicProfileData] =
    React.useState<any>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [applLoading, setApplyLoading] = React.useState<boolean>(false)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)

  const getCoachPrivateProfile = async () => {
    if (user?.coachProfile) {
      try {
        const data = await ProfileRequests.getCoachPrivateProfile({
          coach_profile_id: parseInt(user.coachProfile.profile_id),
        })
        setCoachPublicProfileData(data)
      } catch (ex: any) {
        console.log(ex)
        renderError(ex.response?.data?.message || "An error occurred.")
      }
    }
  }

  const getTags = async () => {
    setLoading(true)
    try {
      const response = await ProfileRequests.getCoachTags()
      const data = Array.isArray(response) ? response : []
      setAllTags(data)
    } catch (ex: any) {
      renderError(ex.response?.data?.message || "An error occurred.")
    } finally {
      setLoading(false)
    }
  }

  const updateProfileInformationTags = async () => {
    if (!coachPublicProfileData) return

    const profileInfo =
      coachPublicProfileData?.coach_public_profile?.profile_information
    const endPoint = coachPublicProfileData?.coach_public_profile?.endpoint_slug
    try {
      await ProfileRequests.editProfileInformation({
        coach_public_profile_id:
          coachPublicProfileData?.coach_public_profile?.coach_public_profile_id,
        profile_information: profileInfo,
        tags: selectedTags,
        endpoint_slug: endPoint,
      })
      setApplyLoading(false)
      setTagsUpdated(true)
      renderSuccess("We've saved your progress!")
    } catch (ex: any) {
      console.log(ex)
      renderError(ex.response?.data?.message || "An error occurred.")
    }
  }

  const toggleTag = (tag: number) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((selectedTag) => selectedTag !== tag)
        : prevSelectedTags.length < 5
        ? [...prevSelectedTags, tag]
        : prevSelectedTags
    )
  }

  const isTagSelected = (tag: number) => selectedTags.includes(tag)

  useEffect(() => {
    getTags()
    getCoachPrivateProfile()
  }, [])

  useEffect(() => {
    if (
      coachPublicProfileData?.coach_public_profile?.tags &&
      allTags.length > 0
    ) {
      const matchingTagIds = coachPublicProfileData?.coach_public_profile?.tags
        .map(
          (tagName: string) =>
            allTags.find((tag: any) => tag.name === tagName)?.id
        )
        .filter(
          (tagId: number | undefined): tagId is number => tagId !== undefined
        )
      setSelectedTags(matchingTagIds)
    }
  }, [coachPublicProfileData, allTags])

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
        <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] max-h-[85vh] bg-white fixed flex flex-col items-start overflow-y-auto">
          <CloseModal
            callback={() => setTagsModalOpen(false)}
            styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h2 className="absolute top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] font-bold text-[23px] md:text-[28px] lg:text-[30px] mt-[50px] sm:mt-0">
            Get your website ready for the Marketplace!
          </h2>
          <p className="font-bold text-[20px] mt-[60px] sm:mt-4">
            Tag your areas of expertise so clients can find you faster.
          </p>
          <p className="text-[14px] font-bold text-graySlate">
            Select up to 5 categories
          </p>
          <div className=" w-full mt-4">
            <div className="flex flex-wrap">
              {loading ? (
                <div className="flex justify-center items-center h-64 w-full">
                  <Loader />
                </div>
              ) : (
                allTags?.map((tag: any, index: number) => (
                  <div
                    className={`p-1 pr-2 border border-2 rounded-lg mr-2 mb-2 mt-2 text-sm flex items-center cursor-pointer ${
                      isTagSelected(tag.id)
                        ? "bg-grassGreen text-white border-grassGreen"
                        : "border-gray"
                    }`}
                    key={index}
                    onClick={() => toggleTag(tag.id)}
                  >
                    <span>
                      <SvgPlus />
                    </span>
                    <p className="ml-1">{tag.name}</p>
                  </div>
                ))
              )}
            </div>
            {isBusinessPage && (
              <p className="text-[14px] mt-8 font-bold text-graySlate">
                Not ready to add now? You can always add/edit tags in your
                website “Edit About Info” section.
              </p>
            )}
            <div className="flex justify-center items-center">
              {applLoading ? (
                <Loader />
              ) : (
                <Button
                  onClick={() => {
                    setApplyLoading(true)
                    updateProfileInformationTags()
                    setTagsModalOpen(false)
                  }}
                  className="mt-8"
                  size="lg"
                >
                  Apply
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TagsModal