import React, { useContext, useState, useEffect } from "react"
import ActiveSessionLanding from "../../components/sessions/ActiveSessionLanding"
import AttachService from "../../components/sessions/historical/AttachService"
import Conference from "../../components/sessions/Conference"
import DetailedPastSession from "../../components/sessions/historical/DetailedPastSession"
import PastSessions from "../../components/sessions/historical/PastSessions"
import SessionTips from "../../components/sessions/historical/SessionTips"
import useLoading from "../../hooks/useLoading"
import VideoDetails from "./VideoDetails"
import { Button } from "../../components/ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
  useHMSNotifications,
} from "@100mslive/react-sdk"
import { SessionContext } from "./context/sessionContext"
import { SvgFilledInformationCircle } from "../../components/icons/"
import { UserCtx } from "../../context/userContext"

interface Props {}

const Sessions: React.FC<Props> = () => {
  const { user } = useContext(UserCtx)
  const { setPopupNotification, renderSuccess, renderError } =
    useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const isConnected = useHMSStore(selectIsConnectedToRoom)
  const hmsActions = useHMSActions()
  const [viewType, setViewType] = useState("speaker")
  const [mobileView, setMobileView] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [showActiveSessionTab, setShowActiveSessionTab] =
    useState<boolean>(true)
  const [showPastSessionsTab, setShowPastSessionsTab] = useState<boolean>(false)
  const [detailedPastSession, setDetailedPastSession] = useState<any>({
    show: false,
    session: {},
  })
  const [showAttachService, setShowAttachService] = useState<boolean>(false)
  const [serviceChangeType, setServiceChangeType] = useState<string>("")
  const [currentSpeaker, setCurrentSpeaker] = useState<any>(null)
  const [inWaitingRoom, setInWaitingRoom] = useState<boolean>(false)
  const [roomId, setRoomId] = useState<string>("")
  const [sessionListLoading, setSessionListLoading] = useState<boolean>(false)
  const [sendReview, setSendReview] = useState<boolean>(false)
  const [hostRoomCode, setHostRoomCode] = useState<string>("")
  const [guestRoomCode, setGuestRoomCode] = useState<string>("")
  const [msUserToken, setMsUserToken] = useState<string>("")
  const [showVideoDetails, setShowVideoDetails] = useState<boolean>(false)
  const notification = useHMSNotifications()

  // useState for video call instructions for user
  const [instructionModal, setInstructionModal] = useState(false)

  // Modal with video best practice instructions
  const handleInstructionModal = () => {
    setInstructionModal(!instructionModal)
  }

  const handleShowActiveSession = () => {
    setShowActiveSessionTab(true)
    setShowPastSessionsTab(false)
    setInstructionModal(false)
  }

  const handleShowPastSessions = () => {
    setShowActiveSessionTab(false)
    setShowPastSessionsTab(true)
    setInstructionModal(false)
  }

  const closePopup = () => {
    setPopupNotification({
      show: false,
      callback: null,
      title: "",
      message: "",
    })
  }

  const acceptAutoplay = async () => {
    startLoading()
    await hmsActions
      .unblockAudio()
      .then(() => {
        stopLoading()
        setPopupNotification({
          show: false,
          title: "",
          message: "",
          callback: null,
        })
        renderSuccess("Unblocked audio")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const showMessage = (type: string, message: string, code: any) => {
    if (type === "error") {
      console.log(`100ms error code ${code}`)
      if (code === 3001) {
        setPopupNotification({
          show: true,
          title: `Browser Permissions Error`,
          message:
            "Your web browser does not have permission to share your full screen. Click the links for instructions on enabling permissions.",
        })
      } else if (code === 3002) {
        setPopupNotification({
          show: true,
          title: `Capture Device Not Available`,
          message:
            "Please verify that your audio/video capture device is connected.",
        })
      } else if (code === 3003) {
        setPopupNotification({
          show: true,
          title: `Capture Device Is In Use`,
          message:
            "Capture device is in use by another application. Please fix and try again.",
        })
      } else if (code === 3008) {
        setPopupNotification({
          show: true,
          title: "Autoplay blocked",
          message:
            "The browser is requiring this specific page interaction to enable audio. Click 'Ok' to proceed",
          callback: acceptAutoplay,
        })
      } else if (code === 3009) {
      } else if (code === 3010) {
        setPopupNotification({
          show: true,
          title: `Problem with audio output device`,
          message: "Try using an external device to output audio.",
        })
      } else if (code === 3011) {
        setPopupNotification({
          show: true,
          title: `Browser Permissions Error`,
          message:
            "Your web browser does not have permission to share your full screen. Click the links for instructions on enabling permissions.",
        })
      } else {
        renderError(message)
      }
    } else if (type === "notify") {
      renderSuccess(message)
    }
  }

  const sessionContextValues = {
    viewType,
    setViewType,
    mobileView,
    setMobileView,
    currentSpeaker,
    setCurrentSpeaker,
    fullScreen,
    setFullScreen,
    inWaitingRoom,
    setInWaitingRoom,
    roomId,
    setRoomId,
    msUserToken,
    setMsUserToken,
    sessionListLoading,
    setSessionListLoading,
    hostRoomCode,
    guestRoomCode,
    setHostRoomCode,
    setGuestRoomCode,
    sendReview,
    setSendReview,
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobileView(true)
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileView(true)
      } else {
        setMobileView(false)
      }
    }

    window.addEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave()
      }
    }
  }, [hmsActions, isConnected])

  useEffect(() => {
    if (!notification) {
      return
    }
    switch (notification.type) {
      case "ROOM_ENDED":
        setPopupNotification({
          show: true,
          callback: closePopup,
          title: notification.data.reason,
          message:
            "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
        })
        break
      case "PEER_JOINED":
        showMessage("notify", `${notification.data.name} joined`, null)
        break
      case "PEER_LEFT":
        showMessage("notify", `${notification.data.name} left`, null)
        break
      case "ERROR":
        showMessage("error", notification.data.message, notification.data.code)
        break
    }
  }, [notification])

  return (
    <>
      <div className="h-screen overflow-y-auto">
        {" "}
        {/* Added this wrapper */}
        <SessionContext.Provider value={sessionContextValues}>
          {detailedPastSession.show && (
            <DetailedPastSession
              detailedPastSession={detailedPastSession}
              setDetailedPastSession={setDetailedPastSession}
              setShowAttachService={setShowAttachService}
              setServiceChangeType={setServiceChangeType}
            />
          )}
          {showAttachService && (
            <AttachService
              setShowAttachService={setShowAttachService}
              setDetailedPastSession={setDetailedPastSession}
              detailedPastSession={detailedPastSession}
              setServiceChangeType={setServiceChangeType}
              serviceChangeType={serviceChangeType}
            />
          )}
          {!detailedPastSession.show && !showAttachService && (
            <div
              className={`mx-auto ${
                !isConnected ? "pt-[28px]" : ""
              } md:pt-0 w-full md:w-[1080px]`}
            >
              <div className="font-bold text-[36px] flex mb-2 justify-center md:justify-start items-center">
                {" "}
                <p>Video Conferencing</p>
                <Button
                  onClick={() => setShowVideoDetails(true)}
                  variant="link"
                  size="icon"
                  className="ml-2"
                >
                  <SvgFilledInformationCircle className="w-4 h-4" />
                </Button>
              </div>{" "}
              {user.activeProfile === "coach" && (
                <p className="text-[#3A434A] text-base mb-4 text-center md:text-left">
                  {" "}
                  Check out our{" "}
                  <a
                    href="https://intercom.help/zoee/en/articles/8107152-schedule-invite-and-host-a-session"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    <span className="font-bold text-blurple">how-to guide</span>
                  </a>{" "}
                  to unlock all the powerful features that make connecting with
                  clients effortless.
                </p>
              )}
              {!isConnected && !inWaitingRoom && (
                <div className="flex-wrap justify-center md:justify-start flex w-full gap-[12px] mb-[32px] font-bold text-base">
                  <Button
                    variant={showActiveSessionTab ? "utility" : "ghost"}
                    onClick={handleShowActiveSession}
                  >
                    Active Sessions
                  </Button>
                  <Button
                    variant={showPastSessionsTab ? "utility" : "ghost"}
                    onClick={handleShowPastSessions}
                  >
                    Past Sessions
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleInstructionModal}
                    className="md:ml-auto"
                  >
                    <SvgFilledInformationCircle className="w-4 h-4" />
                    Video Session Tips
                  </Button>
                </div>
              )}
              <div className="w-full">
                {showActiveSessionTab && (
                  <>
                    {isConnected ? (
                      <Conference />
                    ) : (
                      <>
                        <ActiveSessionLanding />
                      </>
                    )}
                  </>
                )}
                {showPastSessionsTab && (
                  <PastSessions
                    setDetailedPastSession={setDetailedPastSession}
                  />
                )}
                {instructionModal && (
                  <SessionTips setInstructionModal={setInstructionModal} />
                )}
              </div>
            </div>
          )}
        </SessionContext.Provider>
        <div className="h-[250px]"></div>
      </div>
      {showVideoDetails && (
        <VideoDetails setShowVideoDetails={setShowVideoDetails} />
      )}
    </>
  )
}

export default Sessions
