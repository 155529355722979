interface SectionProps {
  id: string
  children: React.ReactNode
}

export default function SectionWrapper({ id, children }: SectionProps) {
  return (
    <section id={id} className="mb-12 p-8 bg-white rounded-lg shadow-lg">
      {children}
    </section>
  )
}
