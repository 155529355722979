import React, { useContext, useState } from "react"
import DeleteDialogueModal from "../DialogueModal"
import moment from "moment"
import SubscriptionTag from "../../components/subscription/SubscriptionTag"
import useDate from "../../hooks/useDate"
import useLoading from "../../hooks/useLoading"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SubscriptionContext } from "./SubscriptionContext"
import { SubscriptionRequests } from "../../api/app.service"
import { SvgChatEllipses, SvgLightning } from "../icons/"
import { useIntercom } from "react-use-intercom"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface Props {
  lifetime: any
}

const CurrentSubscription: React.FC<Props> = (lifetime) => {
  const { user } = useContext(UserCtx)
  const { subscription, getSubscription } = useContext(SubscriptionContext)
  const { renderError, renderSuccess, hideAlert } =
    useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState<boolean>(false)
  const { mdDate } = useDate()
  const { show } = useIntercom()
  const navigate = useNavigate()

  const navigateToSubPlans = () => {
    navigate("/coach/subscription/plans")
  }

  const handleCancelSubscriptionClick = () => {
    if (showCancelSubscriptionModal) {
    } else {
      setShowCancelSubscriptionModal(true)
    }
  }

  const cancelSubscription = () => {
    startLoading()
    SubscriptionRequests.cancelCoachSubscription({
      subscription_id: subscription?.subscription_id,
    })
      .then((data) => {
        console.log(data)
        getSubscription()
        renderSuccess(data.message)
        setShowCancelSubscriptionModal(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setTimeout(() => hideAlert(), 5000)
        stopLoading()
      })
  }

  const getTrialDaysRemaining = () => {
    const expiration = moment(user?.trialExpiration)
    const now = moment()

    return Math.max(0, expiration.diff(now, "days"))
  }

  const getCurrentPlanSection = () => {
    if (subscription) {
      if (
        subscription?.subscription_end_date === "None" &&
        !lifetime.lifetime
      ) {
        return (
          <>
            <div className="flex justify-start">
              <Button
                onClick={handleCancelSubscriptionClick}
                variant="ghost"
                className="h-12 px-6 py-3 rounded-[10px] border border-[#d1d9e0] flex items-center gap-2.5"
              >
                <span className="text-black text-sm font-bold font-['Beausite Classic'] leading-normal">
                  Cancel Subscription
                </span>
              </Button>
            </div>

            {showCancelSubscriptionModal && (
              <DeleteDialogueModal
                isWarningVariant
                setShowDialogueModal={setShowCancelSubscriptionModal}
                helpText={`Are you sure you want to cancel your subscription? If you cancel, your subscription will be active until ${mdDate(
                  subscription.subscription_next_payment_date
                )}`}
                header="Cancel Subscription?"
                confirmButtonText="Cancel Subscription"
                confirmCallbackFn={cancelSubscription}
              />
            )}
          </>
        )
      } else if (
        subscription?.subscription_end_date !== "None" &&
        subscription.subscription_promo_code !== "500FOREVER"
      ) {
        return
      }
    }
  }

  const BBHCendDate = new Date(
    subscription?.subscription_end_date + "T00:00:00"
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <div className="main-shadow grow rounded-[16px] p-[24px] sm:min-w-[490px] md:h-fit md:p-[32px]">
      <div className="mb-[24px] flex w-full items-center justify-between">
        <h2 className="text-[22px] font-bold">Current Plan</h2>
      </div>

      <div className="border-grayMist flex w-full flex-col rounded-[24px] border p-[24px] md:p-[32px] bg-lightGray">
        {!subscription ? (
          <>
            <div className="mb-[96px] flex w-full justify-between font-bold">
              <SubscriptionTag subscriptionName={"Free Trial"} />
              {(getTrialDaysRemaining() || 0) <= 0 ? (
                <h4 className="text-vividRed text-[32px]">Expired</h4>
              ) : (
                <h4 className="text-green text-[32px]">Free</h4>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="mb-[18px] flex flex-col gap-[8px] text-base md:mb-0">
                <div className="flex items-center gap-[8px]">
                  <SvgChatEllipses />
                  <p>Need help deciding?</p>
                </div>
                <button
                  onClick={() => {
                    show()
                  }}
                  className="text-blurple font-bold"
                >
                  Chat with our support
                </button>
              </div>
              <Button
                onClick={navigateToSubPlans}
                className="flex w-[158px] min-w-fit items-center gap-[10px]"
              >
                Upgrade Plan
                <SvgLightning />
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-[24px] flex items-center justify-between">
              {/* PLAN DETAILS */}
              <div className="flex flex-col gap-[12px]">
                <h2 className="text-[22px] font-bold">
                  {!lifetime.lifetime ? (
                    <>{`${
                      subscription?.recurring_interval === "annual"
                        ? "Annual Pro"
                        : subscription?.recurring_interval === "3-month"
                        ? "3 - Month BBHC Program"
                        : "Monthly Pro"
                    }`}</>
                  ) : (
                    <>Lifetime Subscription</>
                  )}
                </h2>
                {/* PRICE */}
                <div>
                  {subscription?.recurring_interval === "3-month" ? (
                    <Badge>one-time payment</Badge>
                  ) : lifetime.lifetime ? (
                    <Badge variant="blurpleGradient">Founding Coach</Badge>
                  ) : (
                    <></>
                  )}
                  <br />
                  {!lifetime.lifetime && (
                    <div>
                      <span className="text-black text-[42px] font-semibold font-['Beausite Classic']">
                        {subscription?.recurring_interval !== "3-month" && (
                          <>$ {subscription?.amount}</>
                        )}
                      </span>
                      <span className="text-black text-[28px] font-normal font-['Beausite Classic']">
                        {subscription?.promotion_details?.lifetime && (
                          <>/one-time</>
                        )}
                        {subscription?.recurring_interval === "annual" &&
                          subscription?.promo_duration === 2 && <>/two years</>}
                        {subscription?.recurring_interval === "annual" &&
                          subscription?.promo_duration !== 2 &&
                          !subscription?.promotion_details?.lifetime && (
                            <>/year</>
                          )}
                        {subscription?.recurring_interval === "month" && (
                          <>/month</>
                        )}
                      </span>
                      {subscription &&
                        subscription?.subscription_end_date !== "None" &&
                        subscription?.subscription_start_date !==
                          "2024-11-26" &&
                        subscription?.zoee_subscription_id !== "1005003" && (
                          <Button
                            onClick={navigateToSubPlans}
                            variant="blurpleGradient"
                            className="flex w-[158px] min-w-fit items-center gap-[10px] mt-4"
                          >
                            Resubscribe
                          </Button>
                        )}
                    </div>
                  )}
                </div>
                {subscription?.recurring_interval === "3-month" ? (
                  <p className="text-graySlate mt-2">
                    Your <b>3 Month BBHC Program access</b> runs until{" "}
                    {BBHCendDate}. <br />
                    Afterwards, you will be able to subscribe to a Zoee Plan to
                    continue <br />
                    using Zoee's features to run your coaching business.
                  </p>
                ) : lifetime.lifetime ? (
                  <div>
                    As thanks for your early commitment to Zoee, you have a
                    lifetime <br />
                    subscription ($490/year value) to Zoee features.
                  </div>
                ) : (
                  <></>
                )}
                <div></div>
              </div>
            </div>
          </>
        )}
        {getCurrentPlanSection()}
      </div>
    </div>
  )
}

export default CurrentSubscription
