import React, { useEffect, useState } from "react"
import CoachHowTos from "./CoachHowTos"
import NavShell from "./NavShell"
import UserButton from "./UserButton"
import { Button } from "../ui/button"
import { Link, useSearchParams } from "react-router-dom"
import { SearchMarketplace } from "./SearchMarketplace"
import { SvgZoeeIcon, SvgZoeeLogo } from "../icons/"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { useAuthModal } from "../auth/use-auth-modal"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import { SvgZoeeGradientFavicon } from "../icons/logos/zoee-gradient-favicon"
import { SvgZoeeGradientLogo } from "../icons/logos/zoee-gradient-logo"

interface Props {
  guideOpened: any
  setGuideOpened: any
}

const MarketplaceNav: React.FC<Props> = ({ guideOpened, setGuideOpened }) => {
  const { user } = React.useContext(UserCtx)

  const [searchParams, setSearchParams] = useSearchParams()
  const register = searchParams.get("register")
  const coachregister = searchParams.get("coachregister")
  const isCoach = user?.activeProfile === "coach"
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)

  const authenticatedUser = useAuthenticatedUser(user)
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const { showRegister, showLogin } = useAuthModal()
  const navigate = useNavigate()
  const location = useLocation()

  const signIn = () => {
    localStorage.setItem(
      "coachRedirect",
      `${location.pathname}${location.search}`
    )
    localStorage.setItem(
      "memberRedirect",
      `${location.pathname}${location.search}`
    )
    navigate("/sign_in")
  }

  useEffect(() => {
    if (register === "true") {
      showRegister()
    } else if (register === "coach") {
      setShowMobileNav(false)
      showRegister({ accountType: "coach" })
    } else if (register === "member") {
      setShowMobileNav(false)
      showRegister({ accountType: "member" })
    }
  }, [register, coachregister])

  useEffect(() => {
    if (showMobileNav) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden"
      }
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showMobileNav])

  return (
    <NavShell className="flex items-center justify-between text-base font-semibold">
      <div onClick={() => setShowMobileNav(false)}>
        <Link to="/">
          <SvgZoeeGradientLogo className="hidden md:block" />
          <SvgZoeeGradientFavicon className="md:hidden" />
        </Link>
      </div>
      {window.location.pathname === "/find-a-coach" && (
        <div className="hidden lg:block max-w-lg w-full">
          <SearchMarketplace />
        </div>
      )}
      <div className="flex">
        {authenticatedUser ? (
          <div className="flex items-center gap-4">
            <Button variant="link" asChild>
              <Link
                to={user.isCoach ? `/coach` : `/member`}
                className="font-semibold text-base text-grayCharcoal"
              >
                Back to Account
              </Link>
            </Button>
            <UserButton
              guideOpened={guideOpened}
              setGuideOpened={setGuideOpened}
            />
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <Button onClick={signIn} variant="outline">
              Sign In
            </Button>
            <Button
              onClick={() => showRegister({ accountType: "coach" })}
              variant="default"
            >
              <span className="sm:hidden">Get Started</span>
              <span className="hidden sm:inline">Create Free Account</span>
            </Button>
          </div>
        )}
      </div>
      {isCoach && guideOpened ? (
        <div
          className={` ${
            isSidePanelOpen ? "left-0" : "-left-[300px]"
          } hover:shadow-custom fixed top-[100px] md:top-[150px] z-[1001] flex h-[550px] w-[300px] cursor-pointer items-center justify-center rounded-xl transition-all duration-500 ease-in-out`}
          style={{ backgroundColor: "#4750F5" }}
        >
          <CoachHowTos />
          <div
            onClick={() => setIsSidePanelOpen(!isSidePanelOpen)}
            className={`shadow-outline } fixed sm:top-[606px] md:top-[295px] z-[1000] -mr-[349px] flex h-[50px] w-[155px] -rotate-90 transform cursor-pointer items-center justify-center rounded-bl-lg rounded-br-lg
          `}
            style={{ backgroundColor: "#4750F5" }}
          >
            {isSidePanelOpen ? (
              <h1 className="text-sm font-bold text-white">
                <span>Close</span>
              </h1>
            ) : (
              <h1 className="text-sm font-bold text-white">
                <span>How</span>
                <span className="ml-2">To's</span>
              </h1>
            )}
          </div>
        </div>
      ) : null}
    </NavShell>
  )
}

export default MarketplaceNav
