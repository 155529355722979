import React, { useCallback, useMemo, useRef } from "react"
import Element from "./Element"
import isHotkey from "is-hotkey"
import Leaf from "./Leaf"
import { Button, Toolbar } from "./EditorComponents"
import {
  createEditor,
  BaseEditor,
  Descendant,
  Editor,
  Transforms,
  Element as SlateElement,
} from "slate"
import { Elements, Text } from "./types/editorTypes"
import { Slate, Editable, withReact, ReactEditor, useSlate } from "slate-react"
import {
  SvgEditorBold,
  SvgEditorItalic,
  SvgEditorUnderline,
  SvgEditorListBulleted,
  SvgEditorListNumbered,
  SvgEditorHeader1,
  SvgEditorHeader2,
} from "../../icons"
import { withHistory } from "slate-history"

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: Elements
    Text: Text
  }
}

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
}

const LIST_TYPES = ["numbered-list", "bulleted-list"]

type Props = {
  noteValue: Descendant[]
  setNoteValue: any
}

const NoteEditor: React.FC<Props> = ({ noteValue, setNoteValue }) => {
  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const handleNoteContentChange = (noteValue: Descendant[]) => {
    setNoteValue(noteValue)
  }

  const editorContainerRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={editorContainerRef}
      className="px-[20px] pb-[0px] h-[200px] md:h-[344px] border focus-within:border-royalBlue border-grayMist rounded-[10px] overflow-y-auto"
    >
      <Slate
        onChange={handleNoteContentChange}
        editor={editor}
        value={noteValue}
      >
        <Toolbar>
          <MarkButton
            format="bold"
            icon={<SvgEditorBold className="w-4 md:w-6" />}
          />
          <MarkButton
            format="italic"
            icon={<SvgEditorItalic className="w-4 md:w-6" />}
          />
          <MarkButton
            format="underline"
            icon={<SvgEditorUnderline className="w-4 md:w-6" />}
          />
          <BlockButton
            format="heading-one"
            icon={<SvgEditorHeader1 className="w-4 md:w-6" />}
          />
          <BlockButton
            format="heading-two"
            icon={<SvgEditorHeader2 className="w-4 md:w-6" />}
          />
          <BlockButton
            format="numbered-list"
            icon={<SvgEditorListNumbered />}
          />
          <BlockButton
            format="bulleted-list"
            icon={<SvgEditorListBulleted />}
          />
        </Toolbar>
        <Editable
          className="mt-[64px] outline-none w-full max-w-full overflow-x-auto"
          placeholder={"Write your note here..."}
          spellCheck
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          scrollSelectionIntoView={() => {}}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }

            // If Enter is pressed, scroll to bottom
            if (event.key === "Enter") {
              setTimeout(() => {
                editorContainerRef.current?.scrollTo({
                  top: editorContainerRef.current.scrollHeight,
                  behavior: "smooth",
                })
              }, 0)
            }
          }}
        />
      </Slate>
    </div>
  )
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })

  let newProperties: Partial<SlateElement>

  newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isMarkActive = (editor: any, format: any) => {
  const marks: any = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isBlockActive = (editor: any, format: any, blockType = "type"): any => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType as keyof Elements] === format,
    })
  )

  return !!match
}

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      className="cursor-pointer"
      active={isBlockActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </Button>
  )
}

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      className="cursor-pointer"
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </Button>
  )
}

export default NoteEditor
