import ForceVerifyEmail from "../../pages/ForceVerifyEmail"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"
import { UserCtx } from "../../context/userContext"

const PrivateRouteEmailVerified = () => {
  const { user } = useContext(UserCtx)
  const location = useLocation()
  const navigate = useNavigate()
  const coachRedirect = localStorage.getItem("coachRedirect")
  const memberRedirect = localStorage.getItem("memberRedirect")

  const redirectPath =
    user.activeProfile === "coach" ? coachRedirect : memberRedirect

  useEffect(() => {
    if (user.emailVerified) {
      if (redirectPath) {
        navigate(redirectPath)
        localStorage.removeItem("coachRedirect")
        localStorage.removeItem("memberRedirect")
      }
    } else {
      localStorage.setItem(
        "coachRedirect",
        `${location.pathname}${location.search}`
      )
      localStorage.setItem(
        "memberRedirect",
        `${location.pathname}${location.search}`
      )
    }
  }, [user.emailVerified, location, navigate, redirectPath])

  if (!user.emailVerified) {
    return <ForceVerifyEmail />
  }

  return <Outlet />
}

export default PrivateRouteEmailVerified
