import { Link } from "react-router-dom";
import {
  SvgBilling,
  SvgBusiness,
  SvgCalendar,
  SvgChat,
  SvgCreditCard,
  SvgUsers,
} from "../../../../components/icons/";
import featureScheduling from "../../../../assets/images/feature-scheduling.webp";
import ZoeeProfilePreview from "../../../../assets/images/Profile.png";
import featureInboxOne from "../../../../assets/images/feature-inbox 1.png";
import featurePayment from "../../../../assets/images/feature-payments.png";
import featureCommunication from "../../../../assets/images/featureCommunication.png";
import clientPortals from "../../../../assets/images/Client-Portal.png";
import PublicProfilePage from "../../../../assets/images/PublicProfilePage.png";

export interface Feature {
  id: number;
  text: string;
  icon: React.ReactNode;
  content: React.ReactNode;
  description: string;
  image: string;
}

export const features: Feature[] = [
  {
    id: 1,
    text: "Personalized Zoee Website",
    icon: <SvgBusiness />,
    content: (
      <div className="flex flex-col justify-center">
        <img
          className="mx-auto max-w-full md:max-h-[560px] max-h-[360px]"
          src={ZoeeProfilePreview}
          alt="custom programs features"
        />
        <h4 className="text-[22px] font-bold mb-[12px]">Personalized Zoee Website</h4>
        <p className="mb-[32px] font-thin">
          Promote your brand on Zoee's customizable website where you can
          share your expertise, tailored client offerings (including individual
          and group programs), generate new bookings, add video and blog
          content, and seamlessly integrate all of your back-end business admin.
          <Link
            to="/features#public-profile"
            className="ml-2 text-blurple"
          >
            Learn More
          </Link>
        </p>
      </div>
    ),
    description:
      "Promote your brand on Zoee's customizable website where you can share your expertise, tailored client offerings (including individual and group programs), generate new bookings, add video and blog content, and seamlessly integrate all of your back-end business admin.",
    image: PublicProfilePage,
  },
  {
    id: 2,
    text: "Automated Invoicing + Payments",
    icon: <SvgBilling />,
    content: (
      <div className="flex flex-col justify-center">
        <img
          className="mx-auto max-w-full md:max-h-[360px] max-h-[300px]"
          src={featureInboxOne}
          alt="inbox features"
        />
        <h4 className="text-[22px] font-bold mb-[12px]">
          Automatic Billing + Payments
        </h4>
        <p className="mb-[32px]">
          Once your clients select a program and book their first session with
          you, we'll take the reins. Zoee eliminates the need for you to
          manually handle client invoices, payments, cancellations, and tracking
          all of your hours and financial transactions.
          <Link
            to="/features#automatic-billing-and-payments"
            className="ml-2 text-blurple"
          >
            Learn More
          </Link>
        </p>
      </div>
    ),
    description:
      "Once your clients select a program and book their first session with you, we'll take the reins. Zoee eliminates the need for you to manually handle client invoices, payments, cancellations, and tracking all of your hours and financial transactions.",
    image: featureInboxOne,
  },
  {
    id: 3,
    text: "Simplified Scheduling + Booking",
    icon: <SvgCalendar />,
    content: (
      <div className="flex flex-col justify-center">
        <img
          className="mx-auto max-w-full md:max-h-[400px] max-h-[300px]"
          src={featureScheduling}
          alt="client management features"
        />
        <h4 className="text-[22px] font-bold mb-[12px]">
          Simplified Scheduling + Booking
        </h4>
        <p className="mb-[32px]">
          Zoee simplifies your booking and scheduling process through joint
          calendar availability for easy booking and rescheduling, session
          reminders, with option to sync all of your calendars to increase
          bookings, prevent missed appointments and eliminate calendar chaos.
          <Link
            to="/features#simplified-scheduling-and-booking"
            className="ml-2 text-blurple "
          >
            Learn More
          </Link>
        </p>
      </div>
    ),
    description:
      "Zoee simplifies your booking and scheduling process through joint calendar availability for easy booking and rescheduling, session reminders, with option to sync all of your calendars to increase bookings, prevent missed appointments and eliminate calendar chaos.",
    image: featureScheduling,
  },
  {
    id: 4,
    text: "Effortless Client Management",
    icon: <SvgUsers />,
    content: (
      <div className="flex flex-col justify-center">
        <img
          className="mx-auto max-w-full md:max-h-[400px] max-h-[320px]"
          src={featurePayment}
          alt="scheduling features"
        />
        <h4 className="text-[22px] font-bold mb-[12px]">
          Effortless Client Management
        </h4>
        <p className="mb-[12px]">
          Easily store and manage all of your contacts in one place. Zoee
          organizes your prospects, client and group info, tracks client hours,
          invoice histories, program subscriptions and view session notes— all
          synced automatically within Zoee.
          <Link
            to="/features#efforless-client-management"
            className="ml-2 text-blurple"
          >
            Learn More
          </Link>
        </p>
      </div>
    ),
    description:
      "Easily store and manage all of your contacts in one place. Zoee organizes your prospects, client and group info, tracks client hours, invoice histories, program subscriptions and view session notes— all synced automatically within Zoee.",
    image: featurePayment,
  },
  {
    id: 5,
    text: "Client Communication Hub",
    icon: <SvgChat />,
    content: (
      <div className="flex flex-col justify-center">
        <img
          className="mx-auto max-w-full md:max-h-[520px] max-h-[380px]"
          src={featureCommunication}
          alt="video communication features"
        />
        <h4 className="text-[22px] font-bold mb-[12px]">
          Client Communication Hub
        </h4>
        <p className="mb-[32px]">
          Streamline all your communications in one place so you can focus on
          connecting with your clients. Zoee enables seamless video sessions,
          chat messaging with notifications, calendar integrations, and digital
          note-taking–automatically saved for future reference.
          <Link
            to="/features#client-communication-hub"
            className="ml-2 text-blurple"
          >
            Learn More
          </Link>
        </p>
      </div>
    ),
    description:
      "Streamline all your communications in one place so you can focus on connecting with your clients. Zoee enables seamless video sessions, chat messaging with notifications, calendar integrations, and digital note-taking–automatically saved for future reference.",
    image: featureCommunication,
  },
  {
    id: 6,
    text: "Client Portals",
    icon: <SvgCreditCard />,
    content: (
      <div className="flex flex-col justify-center">
        <img
          className="mx-auto max-w-full md:max-h-[520px] max-h-[360px]"
          src={clientPortals}
          alt="note taking features"
        />
        <h4 className="text-[22px] font-bold mb-[12px]">
          Client Portals
        </h4>
        <p className="mb-[32px]">
          Everything your client needs in one secure, centralized place. The
          portal allows your clients to communicate with you via chat and video
          sessions, share files, access their personal development journal and
          calendar, book sessions, view their invoices, and more.
          <Link
            to="/features#client-portals"
            className="ml-2 text-blurple"
          >
            Learn More
          </Link>
        </p>
      </div>
    ),
    description:
      "Everything your client needs in one secure, centralized place. The portal allows your clients to communicate with you via chat and video sessions, share files, access their personal development journal and calendar, book sessions, view their invoices, and more.",
    image: clientPortals,
  },
];
