import React, { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { daysInWeek, hours } from "../../../objects/timeObjects"
import { FilterType } from "../../../types/schedulingTypes"
import { Meeting } from "../../../types/meetingTypes"
import { MeetingRequests } from "../../../api/app.service"
import { SvgWarning } from "../../icons"
import { UserCtx } from "../../../context/userContext"

interface Props {
  formErrors: any
  serviceValues: any
  setServiceValues: any
  serviceType: any
  isGroupService: boolean
  service: any
  handleMatchingMeetingIds: (ids: number[]) => void
}

const GroupServiceDetailsForm: React.FC<Props> = ({
  formErrors,
  serviceValues,
  setServiceValues,
  serviceType,
  service,
  handleMatchingMeetingIds,
}) => {
  const { renderError } = React.useContext(CommonFunctionCtx)
  const [matchingMeetingIds, setMatchingMeetingIds] = useState<number[]>([])
  const { user } = useContext(UserCtx)
  const [loadingMeetings, setLoadingMeetings] = useState<boolean>(false)
  const [activeDate, setActiveDate] = useState<Date>(
    moment(new Date()).startOf("day").tz(user?.timezone?.value)?.toDate()
  )
  const [meetings, setMeetings] = useState<Meeting[]>([])
  const [meetingDates, setMeetingDates] = useState<moment.Moment[]>([])
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([
    "zoee-active-profile",
    "zoee-inactive-profile",
    "other",
  ])
  const [activeQueryMonth, setActiveQueryMonth] = useState<number | null>(null)
  const addOneYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1)
    return date
  }

  // const updateEnrollByDate = (date: Date) => {
  //   setServiceValues({
  //     ...serviceValues,
  //     group: {
  //       ...serviceValues?.group,
  //       enrollByDate: date,
  //     },
  //   })
  // }

  const getSessionDateText = () => {
    switch (serviceType) {
      case "individual":
        return "When will the group session take place?"
      case "bundle":
        return "When will the group sessions take place?"
      case "recurring":
        return "When will the group sessions take place?"
      default:
        return "When will the group session(s) take place?"
    }
  }

  const updateDayInWeek = (e: any, index: number) => {
    console.log(e.target.value)
    console.log(index)
  }

  const updateTimeOfDay = (e: any, index: number) => {
    console.log(e.target.value)
    console.log(index)
  }

  const updateMeetingDate = (date: Date, index: number) => {
    let meetingDates = serviceValues?.group?.meetingDates
    const lastMeetingDate = meetingDates[index - 1]
    if (lastMeetingDate && date <= lastMeetingDate) {
      renderError("Meeting date must be greater than prior meeting date")
      return
    }
    meetingDates[index] = date
    setServiceValues({
      ...serviceValues,
      group: {
        ...serviceValues?.group,
        meetingDates: meetingDates,
      },
    })
  }

  const incrementDate = (date: Date) => {
    let count: any = 1
    let unit: any = "days"
    if (["individual", "bundle"].includes(serviceType)) {
      count = 7
      unit = "days"
    } else if (serviceType === "recurring") {
      count = 7
      unit = "days"
    }
    return moment(date).add(count, unit).toDate()
  }

  const getRecurringCount = () => {
    const sessionsPerPeriod = serviceValues?.recurring?.sessionsPerPeriod
    const expireTime = serviceValues?.expiration?.expireTime
    return parseInt(sessionsPerPeriod) * parseInt(expireTime)
  }

  const updateMonthlyDayTimeArray = () => {
    let weeklyDayTimes = []
    let weeklySessionCount = 0
    weeklySessionCount = serviceValues?.recurring?.sessionsPerPeriod
    let i = 0
    const time = { value: "18:00", label: "6:00 PM" }
    while (i < weeklySessionCount) {
      const day = daysInWeek[i]
      weeklyDayTimes.push({ day: day, time: time })
      i++
    }
    setServiceValues({
      ...serviceValues,
      group: {
        ...serviceValues.group,
        weeklyDayTimes: weeklyDayTimes,
      },
    })
  }
  const updateMeetingDateArray = () => {
    let meetingDates = []
    let meetingCount = 0
    if (serviceType === "individual") {
      meetingCount = 1
    } else if (serviceType === "bundle") {
      meetingCount = parseInt(serviceValues.package.packageSessions)
    } else if (serviceType === "recurring") {
      meetingCount = getRecurringCount()
    }
    let i = 0
    let date
    let lastDate
    while (i < meetingCount) {
      const doWeHaveDateAlready = !!serviceValues?.group?.meetingDates?.[i]

      if (doWeHaveDateAlready) {
        lastDate = serviceValues.group.meetingDates[i]
      } 
      if (serviceValues?.group?.meetingDates?.[i]) {
        meetingDates.push(serviceValues?.group?.meetingDates?.[i])
        lastDate = date = serviceValues?.group?.meetingDates?.[i]
      } else {
        date = incrementDate(lastDate)
        meetingDates.push(date)
      }
      i++

      if( !serviceValues?.group?.meetingDates?.[i] && i !== (meetingCount)){
        lastDate = date
      }
    }
    setServiceValues({
      ...serviceValues,
      group: {
        ...serviceValues.group,
        meetingDates: meetingDates,
      },
    })
  }

  const showDatepickers = () => {
    let show = false
    if (["individual", "bundle"].includes(serviceType)) {
      show = true
    }
    if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "monthly"
    ) {
      show = true
    }
    return show
  }

  const showDayTimeSelectors = () => {
    let show = false
    if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "weekly"
    ) {
      show = true
    }
    return show
  }

  const updateGroupMeetingDetails = () => {
    if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "monthly"
    ) {
      updateMeetingDateArray()
    } else if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "weekly"
    ) {
      updateMonthlyDayTimeArray()
    } else if (["bundle", "individual"].includes(serviceType) &&
      serviceValues?.package?.packageSessions > 0
    ) {
      
      updateMeetingDateArray()
    }
  }

  React.useEffect(() => {
    updateGroupMeetingDetails()
  }, [
    serviceType,
    serviceValues?.package?.packageSessions,
    serviceValues?.recurring?.sessionsPerPeriod,
    serviceValues?.expiration?.expireTime,
  ])

  const calcDuration = (endTime: any, startTime: any) => {
    const calculated = moment
      .utc(endTime)
      .diff(moment.utc(startTime), "minutes")
    let duration
    if (calculated >= 1) {
      duration = calculated
    } else {
      duration = 1
    }
    return duration
  }

  const filterMeetings = (meetings: Meeting[]) => {
    if (!meetings) {
      return
    }
    if (activeFilters.length === 0) {
      setMeetings([])
    } else if (activeFilters.length === 1) {
      if (activeFilters[0] === "zoee-active-profile") {
        setMeetings(
          meetings.filter(
            (meeting) => meeting.is_managed && meeting.belongs_to_profile
          )
        )
      } else if (activeFilters[0] === "zoee-inactive-profile") {
        setMeetings(
          meetings.filter(
            (meeting) => meeting.is_managed && !meeting.belongs_to_profile
          )
        )
      } else {
        setMeetings(meetings.filter((meeting) => !meeting.is_managed))
      }
    } else if (activeFilters.length === 2) {
      if (
        activeFilters.includes("zoee-active-profile") &&
        activeFilters.includes("zoee-inactive-profile")
      ) {
        setMeetings(meetings.filter((meeting) => meeting.is_managed))
      } else if (
        activeFilters.includes("zoee-active-profile") &&
        activeFilters.includes("other")
      ) {
        setMeetings(
          meetings.filter(
            (meeting) =>
              (meeting.is_managed && meeting.belongs_to_profile) ||
              !meeting.is_managed
          )
        )
      } else if (
        activeFilters.includes("zoee-inactive-profile") &&
        activeFilters.includes("other")
      ) {
        setMeetings(
          meetings.filter(
            (meeting) =>
              (meeting.is_managed && !meeting.belongs_to_profile) ||
              !meeting.is_managed
          )
        )
      }
    } else {
      setMeetings(meetings)
    }
  }

  const getMeetings = () => {
    if (user) {
      setLoadingMeetings(true)
      const startOfActiveDate = moment(activeDate)
        ?.startOf("day")
        ?.utc()
        ?.tz(user?.timezone?.value)
        ?.toISOString()
      const endOfSixMonths = moment(activeDate)
        ?.add(6, "month")
        ?.endOf("day")
        ?.utc()
        ?.tz(user.timezone.value)
        ?.toISOString()

      MeetingRequests.getMeetings({
        user_id: parseInt(user.user_id),
        profile_id: user.activeProfileId,
        from_date: startOfActiveDate,
        to_date: endOfSixMonths,
      })
        .then((data) => {
          const matchingMeetings = data.meetings.filter(
            (m) => m.stripe_product_id === `prod_${service?.service_id}`
          )
          const meetingIds = matchingMeetings.map((m) => m.meeting_id)

          if (meetingIds.length > 0) {
            setMatchingMeetingIds(meetingIds)
          } else {
            setMatchingMeetingIds([])
          }

          const meetings: Array<Meeting> = []
          data.meetings.forEach((m) => {
            let parsedStartTime = moment
              .utc(m.start_time)
              .tz(user.timezone.value)
              .toDate()
            let parsedEndTime = null
            if (m.end_time) {
              parsedEndTime = moment
                .utc(m.end_time)
                .tz(user.timezone.value)
                .toDate()
            }
            let parsedDate = moment
              .utc(m.start_time)
              .tz(user.timezone.value)
              .toDate()

            const obj: Meeting = {
              id: m.meeting_id,
              inProgress: m.in_progress,
              meetingType: m.meeting_type,
              coach_user_id: m.coach_user_id,
              type: m.type,
              contacts: m.participants,
              meetingName: m.name,
              status: m.status,
              medium: m.medium,
              isCreator: m.is_creator,
              date: parsedDate,
              startTime: parsedStartTime,
              endTime: parsedEndTime,
              duration: calcDuration(m.end_time, m.start_time),
              // TODO need to know what external calendar it is coming from (e.g. google, office365) in order to use different views
              // or decide to only have one color for all non_managed calendars
              // 9/23/2022: For 2.0 launch, will just use the is_managed property to distinguish between Zoee-managed and others. Return to this property in future versions.
              calendar: `${m.is_managed ? "zoee" : "google"}`,
              timezone: user.timezone,
              service_usage_id: m.service_usage_id,
              service_details: m.service_details,
              // TODO backend for notifications not ready yet
              notifications: [],
              belongs_to_profile: m.belongs_to_profile,
              is_managed: m.is_managed,
              stripe_product_id: m.stripe_product_id,
            }

            meetings.push(obj)
          })

          const sortedMeetings = meetings.sort((a: any, b: any) => {
            const bDate: any = new Date(b.startTime)
            const aDate: any = new Date(a.startTime)
            return aDate - bDate
          })

          setMeetings(sortedMeetings)
          filterMeetings(meetings)
          setMeetingDates(createMeetingDates(sortedMeetings))
          setActiveQueryMonth(moment(activeDate).month())
          setLoadingMeetings(false)
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          setLoadingMeetings(false)
        })
    }
  }

  const createMeetingDates = (meetings: Meeting[]) => {
    return meetings.map((meeting) => {
      return moment(meeting.date)
    })
  }

  useEffect(() => {
    if (matchingMeetingIds.length > 0) {
      handleMatchingMeetingIds(matchingMeetingIds)
    }
  }, [matchingMeetingIds, handleMatchingMeetingIds])

  useEffect(() => {
    getMeetings()
  }, [])

  return (
    <div
      className="md:w-[620px] main-shadow flex flex-col rounded-[16px]
            bg-white p-[32px] md:p-[40px]"
    >
      <h2 className="font-bold text-[22px] mb-[20px] md:mb-[24px]">
        Group Service Details
      </h2>
      <p className="text-graySlate text-base mb-[32px]">
        Set up more of the specifics around this group service.
      </p>

      {/* SESSION DATE(S) */}

      <h4 className="font-bold text-base mb-[16px]">{getSessionDateText()}</h4>
      <div className="flex flex-col gap-[12px]">
        {showDatepickers() && (
          <>
            {serviceValues?.group?.meetingDates?.map(
              (meetingDate: Date, index: number) => {
                return (
                  <div key={index}>
                    <h4 className="font-bold text-base mb-[4px] text-graySlate">
                      {`Session #${index + 1}`}
                    </h4>
                    <div className="w-full relative">
                      <DatePicker
                        selected={meetingDate}
                        onChange={(date: Date) =>
                          updateMeetingDate(date, index)
                        }
                        showTimeSelect
                        minDate={new Date()}
                        maxDate={addOneYear(new Date())}
                        dateFormat={"MM/dd/yyyy hh:mm a"}
                        required
                        className="text-center w-full pr-[25px]"
                        disabled={service.enrolled_count >= 1}
                      />
                    </div>
                  </div>
                )
              }
            )}
          </>
        )}
        {showDayTimeSelectors() && (
          <>
            {serviceValues?.group?.weeklyDayTimes?.map(
              (dayAndTime: any, index: number) => {
                return (
                  <div key={index}>
                    <h4 className="font-bold text-base mb-[4px] text-graySlate">
                      {`Weekly Session #${index + 1}`}
                    </h4>
                    <div className="w-full relative flex items-center justify-between">
                      <Select
                        className="basic-single w-[48%] border-grayCloud text-graySlate rounded-[10px]"
                        classNamePrefix="select"
                        value={daysInWeek.filter(
                          (day) => day.value === dayAndTime?.day?.value
                        )}
                        options={daysInWeek}
                        name="Day of Week"
                        placeholder="Day of Week"
                        onChange={(e: any) => updateDayInWeek(e, index)}
                      />
                      <Select
                        className="basic-single w-[48%] border-grayCloud text-graySlate rounded-[10px]"
                        classNamePrefix="select"
                        value={hours.filter(
                          (hour) => hour.value === dayAndTime?.time?.value
                        )}
                        options={daysInWeek}
                        name="Day of Week"
                        placeholder="Day of Week"
                        onChange={(e: any) => updateTimeOfDay(e, index)}
                      />
                    </div>
                  </div>
                )
              }
            )}
          </>
        )}
      </div>

      <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>

      {/* MAX CLIENTS */}
      <h4 className="font-bold text-base mb-[16px]">
        What is the maximum number of clients that can enroll?
      </h4>
      <div
        className={`${
          formErrors?.maxClients && "input-error"
        } flex items-center gap-[12px]`}
      >
        <input
          type="number"
          min="0"
          max="20"
          onWheel={() => (document.activeElement as HTMLElement)?.blur()}
          className="w-[54px] h-[54px] bg-white focus:outline-0 border
                    border-1 border-grayCloud rounded-[10px] text-graySlate text-center"
          name="maximumClients"
          value={serviceValues.group.maximumClients}
          onChange={(e: any) =>
            setServiceValues({
              ...serviceValues,
              group: {
                ...serviceValues.group,
                maximumClients: e.target.value,
              },
            })
          }
        />
        <span className="font-bold text-base">clients</span>
      </div>
      {formErrors?.maxClients && (
        <div className="field-error w-[100%]">
          <SvgWarning />
          {formErrors?.maxClients}
        </div>
      )}

      {/* <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>

      <h4 className="font-bold text-base mb-[8px]">
        Do you want Zoee to start a group chat for this service?
      </h4>
      <div className="flex items-center gap-2">
        <Switch
          onCheckedChange={() =>
            setServiceValues({
              ...serviceValues,
              group: { groupChat: !serviceValues?.group?.groupChat },
            })
          }
          checked={serviceValues?.group?.groupChat}
          id="group-chat"
        />
        <Label htmlFor="group-chat">Yes</Label>
      </div> */}
    </div>
  )
}

export default GroupServiceDetailsForm
