import SectionWrapper from "./SectionWrapper"
import { Button } from "../../components/ui/button"

export default function CMCoachFormsMobile() {
  return (
    <SectionWrapper id="coach-forms">
      {/* Page Title */}
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">
          Receiving and Completing{" "}
          <span className="text-[#4750F5]">Coach Forms</span>
        </h1>
      </div>

      {/* Introduction */}
      <p className="text-gray-700 mt-6 text-center">
        Your coach may send you forms to gather important information about your
        needs, goals, and background. This guide explains how to{" "}
        <a>understand coach forms, </a>
        <a
          href="#receiving-forms"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          receive{" "}
        </a>
        forms,{" "}
        <a
          href="#completing-forms"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          complete{" "}
        </a>
        forms, and{" "}
        <a
          href="#returning-forms"
          className="text-[#4750F5] underline font-semibold hover:text-[#3742d9]"
        >
          return{" "}
        </a>
        completed forms.
      </p>

      {/* Understanding Coach Forms */}
      <h3 className="text-2xl font-semibold text-blue-700 mt-8">
        Understanding Coach Forms
      </h3>
      <div className="bg-white p-6 rounded-lg ">
        <p className="text-gray-700 mb-2">
          Coach forms serve several important purposes in your coaching
          relationship:
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Initial assessments to understand your current situation.</li>
          <li>Goal-setting documents to clarify what you want to achieve.</li>
          <li>
            Background questionnaires to help your coach understand your
            history.
          </li>
          <li>
            Legal agreements such as confidentiality or coaching policies.
          </li>
          <li>
            Session preparation worksheets to maximize your coaching time.
          </li>
        </ul>
      </div>

      {/* Receiving Forms */}
      <h3
        id="receiving-forms"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Receiving Forms
      </h3>
      <p className="text-gray-700 mt-2">
        Forms are typically delivered in one of two ways:
      </p>

      {/* When Booking a Discovery Call */}
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        When Booking a Discovery Call
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            <strong>Complete the Booking Process</strong>
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Select a time for your discovery call with your coach.</li>
            <li>Fill in your contact information.</li>
            <li>Confirm your appointment.</li>
          </ul>
          <li>
            <strong>Check Your Email</strong>
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Open your email inbox.</li>
            <li>
              Look for an automated confirmation email from{" "}
              <strong>no-reply@zoee.com</strong>.
            </li>
            <li>
              This email will contain any pre-session forms as attachments.
            </li>
          </ul>
          <li>
            <strong>Save the Forms</strong>
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Download the attached forms to your device.</li>
            <li>Save them in a location where you can easily find them.</li>
          </ul>
        </ul>
      </div>

      {/* When Purchasing a Coaching Service */}
      <h4 className="text-xl font-semibold text-gray-700 mt-6">
        When Purchasing a Coaching Service
      </h4>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            <strong>Complete Your Purchase</strong>
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Select and purchase your chosen coaching service.</li>
            <li>Complete the payment process.</li>
            <li>Receive your confirmation.</li>
          </ul>
          <li>
            <strong>Check Your Email</strong>
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Open your email inbox.</li>
            <li>Find the service confirmation email from Zoee.</li>
            <li>Any required forms will be included as attachments.</li>
          </ul>
          <li>
            <strong>Save the Forms</strong>
          </li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Download the attached forms to your device.</li>
            <li>Save them in a location where you can easily find them.</li>
          </ul>
        </ul>
      </div>

      {/* Completing Forms */}
      <h3
        id="completing-forms"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Completing Forms
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Open the form using appropriate software:</li>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              <strong>PDF forms:</strong> Use Adobe Acrobat Reader or similar
              PDF viewer.
            </li>
            <li>
              <strong>Word documents:</strong> Open with Microsoft Word or a
              compatible word processor.
            </li>
            <li>
              <strong>Google Docs:</strong> Open directly in your web browser.
            </li>
          </ul>
          <li>
            Complete all required fields (usually marked with an asterisk *).
          </li>
          <li>Provide detailed responses to open-ended questions.</li>
          <li>
            If a digital signature is required, follow the instructions in the
            form.
          </li>
          <li>
            Save the document with your responses and consider adding your name
            to the filename (example:{" "}
            <strong>"Goals_Worksheet_YourName.pdf"</strong>).
          </li>
        </ul>
      </div>

      {/* Returning Completed Forms to Your Coach */}
      <h3
        id="returning-forms"
        className="text-2xl font-semibold text-blue-700 mt-8"
      >
        Returning Completed Forms to Your Coach
      </h3>
      <div className="bg-white p-6 rounded-lg mt-0">
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Log into your Zoee account and navigate to your dashboard.</li>
          <li>
            From the Homepage, click on the hamburger menu on the top left of
            the screen
          </li>
          <li>Select “Inbox” from the emerging side menu.</li>
          <li>Find or start a conversation with your coach.</li>
          <li>
            Click on the attachment icon (paperclip or similar) in the message
            composer.
          </li>
          <li>Select the completed form file from your device.</li>
          <li>
            Add a message to your coach (example:{" "}
            <strong>"Here is my completed intake form."</strong>).
          </li>
          <li>
            Click <strong>"Send"</strong>.
          </li>
        </ul>
      </div>

      {/* Troubleshooting Form Issues */}
      <h3 className="text-2xl font-semibold text-blue-700 mt-8">
        Troubleshooting Form Issues
      </h3>
      <p className="text-gray-700 mt-2 italic">
        If you encounter problems with your forms:
      </p>
      <ul className="list-disc pl-6 text-gray-700 space-y-2">
        <li>
          <strong>Can't Open the Form:</strong> Ask your coach to send the form
          in a different format.
        </li>
        <li>
          <strong>Can't Edit the Form:</strong> Ensure you're using compatible
          software.
        </li>
        <li>
          <strong>File Too Large to Send:</strong> Try compressing the file
          before attaching it.
        </li>
        <li>
          <strong>Unsure About a Question:</strong> Message your coach directly
          for clarification.
        </li>
      </ul>

      <div className="flex justify-center mt-8">
        <Button variant="default" asChild>
          <a href="#table-of-contents">Back to Quick Start</a>
        </Button>
      </div>
    </SectionWrapper>
  )
}
