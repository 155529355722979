import axios, { type AxiosResponse } from "axios"
import type {
  CreateUserResponseType,
  CreateUserType,
  ForgotPasswordType,
  LoginEmailResponseType,
  LoginEmailType,
  LoginOTPResponseType,
  LoginOTPType,
  MessageResponseType,
  ResetPasswordType,
  VerifyConfirmationCodeResponseType,
  VerifyConfirmationCodeType,
  VerifyEmailType,
  VerifyEmailTokenRegisterType,
  VerifyEmailTokenRegisterResponseType,
  VerifyEmailTokenStatusResponseType, 
  VerifyRecaptchaResponseType,
  VerifyUserExistsResponseType,
  VerifyUserExistsType,
  VerifyRecaptchaCodeType,
  sendEmailLinkRegisterType,
  sendEmailLinkRegisterResponseType,
} from "../models/auth.interface"

// INSTANCES

const auth_instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
  timeout: 15000,
})

// REQUESTS

const auth_requests = {
  post: (url: string, object: {}) =>
    auth_instance
      .post(url, object)
      .then((response: AxiosResponse) => response.data),
}

// API Calls

export const UserExists = {
  verifyUserExists: (
    object: VerifyUserExistsType
  ): Promise<VerifyUserExistsResponseType> =>
    auth_requests.post("verifyUserExists", object),
}

export const sendEmailLinkRegister = (
  object: sendEmailLinkRegisterType
): Promise<sendEmailLinkRegisterResponseType> =>
  auth_requests.post("sendEmailLinkRegister", object)

export const EmailLogin = {
  loginEmail: (object: LoginEmailType): Promise<LoginEmailResponseType> =>
    auth_requests.post("loginEmail", object),
  resetPasswordSendLink: (
    object: ForgotPasswordType
  ): Promise<MessageResponseType> =>
    auth_requests.post("resetPasswordSendLink", object),
  resetPasswordChangePassword: (
    object: ResetPasswordType
  ): Promise<MessageResponseType> =>
    auth_requests.post("resetPasswordChangePassword", object),
  loginOTP: (object: LoginOTPType): Promise<LoginOTPResponseType> =>
    auth_requests.post("loginOTP", object),
}

export const VerifyEmail = {
  verifyEmailCode: (object: VerifyEmailType): Promise<VerifyEmailTokenStatusResponseType> =>
    auth_requests.post("confirmEmailVerifyLink", object),

  verifyEmailToken: (
    object: VerifyEmailTokenRegisterType
  ): Promise<VerifyEmailTokenRegisterResponseType> =>
    auth_requests.post("verifyEmailTokenRegister", object),

  // verifyEmailTokenStatus: (
  //   object: VerifyEmailTokenRegisterType
  // ): Promise<VerifyEmailTokenRegisterResponseType> =>
  //   auth_requests.post("verifyEmailTokenStatus", object),
  
}

export const VerifyConfirmationCode = {
  verifyConfirmationCode: (
    object: VerifyConfirmationCodeType
  ): Promise<VerifyConfirmationCodeResponseType> =>
    auth_requests.post("verifyConfirmationCode", object),
}

export const User = {
  createUser: (object: CreateUserType): Promise<CreateUserResponseType> =>
    auth_requests.post("registerNewUser", object),
}

export const VerifyRecaptcha = {
  recaptchaVerification: (
    object: VerifyRecaptchaCodeType
  ): Promise<VerifyRecaptchaResponseType> =>
    auth_requests.post("recaptchaVerification", object),
}
